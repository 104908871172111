import { Box, Grid, Paper, TextField } from '@mui/material';
// import { Box } from "@mui/system";

function index() {
  return (
    <Paper style={{ padding: 50 }}>
      <Box sx={{ p: 2, border: '1px solid grey' }}>
        <Grid container spacing={3}>
          <Grid item lg={12}>
            <TextField name="company" label="Template for First Contact" />
          </Grid>
          <Grid item lg={12}>
            <TextField name="company" label="Templates for First Conversation (1 day)" />
          </Grid>
          <Grid item lg={12}>
            <TextField name="company" label="Response Based on Intentions" />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}

export default index;
