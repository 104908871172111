import { Button as MuiButton, ButtonProps } from '@mui/material';

/**
 * Material Ui Button
 *
 * https://mui.com/material-ui/api/button/
 */
const Button = (props: ButtonProps) => {
  return <MuiButton {...props} />;
};

export default Button;
