import { object, string } from 'yup';

export const initialValues = {
  email: '',
};

export const validationSchema = object({
  email: string().email('Email Address is not valid').required("Email can't be blank"),
});

export const editInitialValues = {
  token: '',
  password: '',
};

export const editValidationSchema = object({
  password: string().required("Password can't be blank"),
});
