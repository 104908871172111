import React, { ReactNode } from 'react';
import { styled, Box, Typography, useTheme } from '@mui/material';

export interface WidgetHeaderProps {
  /**
   * Widget title.
   */
  title: string;
  /**
   * Content of the right side of widget header.
   */
  children?: ReactNode;
  /**
   * If `true` bottom line will be disabled.
   * @default false
   */
  disableLine?: boolean;
}

const WidgetHeaderContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: 42,
});

// ==========================================================================

/**
 * Wrapper for widget header. This component should be placed inside `Widget` component.
 */
const WidgetHeader = ({ title, disableLine = false, children }: WidgetHeaderProps) => {
  const { palette } = useTheme();
  return (
    <WidgetHeaderContainer
      sx={{
        pb: 2.4,
        px: 2,
        mx: -2,
        borderBottom: disableLine ? undefined : `solid 1px ${palette.neutral.light}`,
      }}>
      <Typography component="h2" variant="b2" sx={{ fontWeight: 700 }}>
        {title}
      </Typography>
      {children}
    </WidgetHeaderContainer>
  );
};

export default WidgetHeader;
