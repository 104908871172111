import React from 'react';
import { Breadcrumbs as MuiBreadcrumbs, Typography } from '@mui/material';
import Link from '../Link';

export interface BreadcrumbsProps {
  items: Array<{
    label: string;
    link: string;
  }>;
}

const Breadcrumbs = ({ items }: BreadcrumbsProps) => {
  return (
    <MuiBreadcrumbs aria-label="breadcrumb" sx={{ color: 'neutral.main' }}>
      {items.map((item, index) => (
        <Typography
          key={item.label}
          component={Link}
          href={item.link}
          variant="b2"
          color={index === items.length - 1 ? 'primary.main' : 'neutral.main'}
          sx={{ textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}>
          {item.label}
        </Typography>
      ))}
    </MuiBreadcrumbs>
  );
};

export default Breadcrumbs;
