const OTPIcon = (props: any) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.293 8.293a1 1 0 0 1 1.414 0l3 3a1 1 0 0 1 0 1.414l-3 3a1 1 0 0 1-1.414-1.414L18.586 12l-2.293-2.293a1 1 0 0 1 0-1.414ZM7.707 15.707a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 0-1.414l3-3a1 1 0 0 1 1.414 1.414L5.414 12l2.293 2.293a1 1 0 0 1 0 1.414ZM13.553 6.204a1 1 0 0 1 .707 1.225l-2.588 9.66a1 1 0 1 1-1.932-.519l2.588-9.659a1 1 0 0 1 1.225-.707Z"
      fill="#545454"
    />
  </svg>
);

export default OTPIcon;
