import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Typography,
  Divider,
  Tooltip,
  IconButton,
} from '@mui/material';
import { useFormik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PhoneInput, { CountryData } from 'react-phone-input-2';

import {
  editInitialValues as initialValues,
  editValidationSchema as validationSchema,
} from './schemeValidation';

import { ToastManagerContext } from '../../components/ToastManager';
import { get, updateCompanyAsync, requestDataUpdate } from '../../redux/modules/company';
import { AppDispatch } from '../../redux/store';

import Breadcrumbs from '../../components/Breadcrumbs';
import DashboardHeader from '../../components/DashboardHeader';

import Confirm from '../../components/Confirm';
import Container from '../../components/Container';
import { QuestionMarkSqfr, InfoSqfr } from '@xcidic/icons';

import 'react-phone-input-2/lib/material.css';

import WhatsAppId from './component/whatsappId';

export default function index() {
  const { create } = useContext(ToastManagerContext);
  const [isConfirm, setIsConfirm] = useState(false);
  const [open, setOpen] = useState(false);
  const [type, setType] = useState('');

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const {
    handleChange,
    handleBlur,
    // setSubmitting,
    handleSubmit,
    setFieldValue,
    dirty,
    // resetForm,
    values,
    isValid,
    touched,
    errors,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async () => {
      setIsLoading(true);
      try {
        if (values.isUpdate) {
          const data: requestDataUpdate = {
            name: values.name,
            id: values.companyId,
            phones: [
              {
                number: values.waNumber?.replace(values.countryCode, ''),
                countryCode: values.countryCode,
                waApiToken: values.apiKey,
                waPhoneId: String(values.phoneID),
                waBusinessAccountId: String(values.waBusinessAccountId),
              },
            ],
          };

          dispatch(updateCompanyAsync(data))
            .unwrap()
            .then((res) => {
              setIsLoading(false);

              create('success', 'Success', res.responseApi.message);
              navigate('/company');
            })
            .catch((err) => {
              setIsLoading(false);

              create('error', 'Error', err);
            });
        }
      } catch (error) {
        // handle any rejections/errors
      }
    },
  });

  useEffect(() => {
    dispatch(get())
      .unwrap()
      .then((res) => {
        if (res.list.data.length > 0) {
          const dataAPI = res.list.data[0];
          setFieldValue('isUpdate', true);
          setFieldValue('companyId', dataAPI?.id);
          setFieldValue('name', dataAPI?.name);
          // eslint-disable-next-line
          setFieldValue('waNumber', dataAPI?.phones[0].countryCode + dataAPI?.phones[0].number);
          setFieldValue('countryCode', dataAPI?.phones[0].countryCode);
          setFieldValue('phoneID', dataAPI?.phones[0].waPhoneId);
          setFieldValue('waBusinessAccountId', dataAPI?.phones[0].waBusinessAccountId);
          setFieldValue('apiKey', dataAPI?.phones[0].waApiToken);
        }
        // create('success', 'Success', res.list.message);
      })
      .catch((err) => {
        create('error', 'Error', err);
      });
  }, []);

  const handleChangePhoneNumber = (value: string, country: CountryData) => {
    // const { setFieldValue } = this.props;
    if (country?.dialCode !== values?.countryCode) {
      // console.log;
      setFieldValue('waNumber', country?.dialCode);
      setFieldValue('countryCode', country?.dialCode);
    } else {
      setFieldValue('waNumber', value);
      setFieldValue('countryCode', country?.dialCode);
    }
  };
  return (
    <Container>
      <Breadcrumbs
        items={[
          {
            label: 'Business Info',
            link: '/company',
          },
          {
            label: 'Company',
            link: '/company',
          },
          {
            label: 'Edit Company',
            link: '/company/edit',
          },
        ]}
      />
      <DashboardHeader title="Edit Company" />
      <Paper>
        <Grid container spacing={3}>
          <Grid item lg={12}>
            <h2>Account Information</h2>
          </Grid>
          <Grid item lg={12}>
            <Grid container spacing={3}>
              <Grid item lg={3} style={{ display: 'flex', alignItems: 'center' }}>
                <Typography style={{ color: touched.name && errors.name ? '#e11900' : undefined }}>
                  Company Name
                </Typography>
              </Grid>
              <Grid item lg={9}>
                <TextField
                  fullWidth
                  // autoFocus
                  name="name"
                  variant="outlined"
                  placeholder="Your company name"
                  // type="email"
                  // label="Company Name *"
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="off"
                  className="mt-0 mb-4"
                  size="medium"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={12}>
            <Grid container spacing={3}>
              <Grid item lg={3} style={{ display: 'flex', alignItems: 'center' }}>
                <Typography>WhatsApp Number</Typography>
              </Grid>
              <Grid item lg={9}>
                <PhoneInput
                  inputStyle={{ width: '100%' }}
                  containerClass="phoneInput"
                  // name="phone"
                  // type="number"
                  specialLabel=""
                  // variant="outlined"
                  // size="small"
                  // margin="normal"
                  value={values.waNumber}
                  onChange={handleChangePhoneNumber}
                  onBlur={handleBlur}
                  // error={Boolean(touched.waNumber && errors.waNumber)}
                  // helperText={touched.waNumber && errors.waNumber}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={12}>
            <Divider />
          </Grid>
          <Grid item lg={12}>
            <h2>
              Meta Business Account{' '}
              <Tooltip
                placement="top"
                title="You could get WhatsApp business account ID, phone ID, and API token from meta business account">
                <IconButton style={{ color: '#258BEA' }}>
                  <QuestionMarkSqfr />
                </IconButton>
              </Tooltip>{' '}
            </h2>
          </Grid>
          <Grid item lg={12}>
            <Grid container spacing={3}>
              <Grid item lg={3} style={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  style={{
                    color:
                      touched.waBusinessAccountId && errors.waBusinessAccountId
                        ? '#e11900'
                        : undefined,
                  }}>
                  WhatsApp Business Account ID
                  <span>
                    <IconButton
                      style={{ color: '#258BEA' }}
                      onClick={() => {
                        setOpen(true);
                        setType('accountId');
                      }}>
                      <InfoSqfr />
                    </IconButton>
                  </span>
                </Typography>
              </Grid>
              <Grid item lg={9}>
                <TextField
                  fullWidth
                  // autoFocus
                  name="waBusinessAccountId"
                  variant="outlined"
                  type="number"
                  // type="email"
                  // label="WhatsApp Business Account ID *"
                  placeholder="Your WhatsApp business account ID"
                  error={Boolean(touched.waBusinessAccountId && errors.waBusinessAccountId)}
                  helperText={touched.waBusinessAccountId && errors.waBusinessAccountId}
                  value={values.waBusinessAccountId}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="off"
                  className="mt-0 mb-4"
                  size="medium"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={12}>
            <Grid container spacing={3}>
              <Grid item lg={3} style={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  style={{ color: touched.phoneID && errors.phoneID ? '#e11900' : undefined }}>
                  WhatsApp Business Phone ID&nbsp;&nbsp;&nbsp;&nbsp;
                  <span>
                    <IconButton
                      style={{ color: '#258BEA' }}
                      onClick={() => {
                        setOpen(true);
                        setType('phoneId');
                      }}>
                      <InfoSqfr />
                    </IconButton>
                  </span>
                </Typography>
              </Grid>
              <Grid item lg={9}>
                <TextField
                  fullWidth
                  // autoFocus
                  name="phoneID"
                  variant="outlined"
                  // type="email"
                  type="number"
                  // label="WhatsApp Business Phone ID *"
                  placeholder="Your WhatsApp phone ID"
                  error={Boolean(touched.phoneID && errors.phoneID)}
                  helperText={touched.phoneID && errors.phoneID}
                  value={values.phoneID}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="off"
                  className="mt-0 mb-4"
                  size="medium"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={12}>
            <Grid container spacing={3}>
              <Grid item lg={3} style={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  style={{ color: touched.apiKey && errors.apiKey ? '#e11900' : undefined }}>
                  WhatsApp Business API Token&nbsp;
                  <span>
                    <IconButton
                      style={{ color: '#258BEA' }}
                      onClick={() => {
                        setOpen(true);
                        setType('');
                      }}>
                      <InfoSqfr />
                    </IconButton>
                  </span>
                </Typography>
              </Grid>
              <Grid item lg={9}>
                <TextField
                  fullWidth
                  // autoFocus
                  name="apiKey"
                  variant="outlined"
                  // type="email"
                  // label="WhatsApp Business API Token *"
                  placeholder="Your WhatsApp business API token"
                  error={Boolean(touched.apiKey && errors.apiKey)}
                  helperText={touched.apiKey && errors.apiKey}
                  value={values.apiKey}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="off"
                  className="mt-0 mb-4"
                  size="medium"
                />
              </Grid>
            </Grid>
          </Grid>
          <WhatsAppId
            open={open}
            onClose={() => {
              setOpen(false);
              setType('');
            }}
            type={type}
          />

          <Grid item lg={12}>
            <a href="https://developers.facebook.com/" target={'_blank'} rel="noreferrer">
              Click this link to register or login to your meta account
            </a>
          </Grid>

          <Grid item lg={12} style={{ justifyContent: 'flex-end', display: 'flex', marginTop: 10 }}>
            <Button
              name="company"
              variant="outlined"
              color="primary"
              style={{ marginRight: 10 }}
              onClick={() => navigate('/company')}
              // disabled={!(dirty && isValid)}
            >
              Cancel
            </Button>
            <Button
              name="company"
              variant="contained"
              color="primary"
              onClick={() => setIsConfirm(true)}
              disabled={!(dirty && isValid && values.countryCode !== values.waNumber)}>
              {isLoading ? (
                <CircularProgress style={{ color: 'white' }} size={30} />
              ) : (
                <span className="loginButtonCaption">
                  {' '}
                  {values?.isUpdate ? `Update Company` : `Create Company`}
                </span>
              )}
            </Button>
            <Confirm
              type={'Create'}
              open={isConfirm}
              handleAksi={() => handleSubmit()}
              loading={false}
              handleClose={() => setIsConfirm(false)}
              disable={false}
              handleContent={{
                title: 'Create Company',
                message: 'Do you wish to Create this Company?',
                button: 'Submit',
              }}
            />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
