import { ReactElement, ElementType } from 'react';
import {
  useTheme,
  Box,
  Stack,
  Snackbar,
  SnackbarProps,
  Typography,
  IconButton,
} from '@mui/material';
import { RemoveSqfr2, CheckCrfr, CloseCrfr, NoticeAlert, InfoSqfr } from '@xcidic/icons';

export type ToastVariant = 'success' | 'warning' | 'error' | 'info';

export interface ToastProps extends SnackbarProps {
  title: string;
  message: string;
  variant?: ToastVariant;
}

const getVariantIcon = (variant: ToastVariant): ElementType => {
  switch (variant) {
    case 'success':
      return CheckCrfr;
    case 'warning':
      return NoticeAlert;
    case 'error':
      return CloseCrfr;
    case 'info':
      return InfoSqfr;
    default:
      return InfoSqfr;
  }
};

const Toast = ({
  key,
  title,
  message,
  onClose,
  sx,
  open = true,
  autoHideDuration = 5000,
  variant = 'info',
}: ToastProps): ReactElement => {
  const { palette } = useTheme();

  const handleCloseButton = () => {
    if (onClose !== undefined) onClose({} as Event, 'clickaway');
  };

  const variantColor = palette[variant];
  const ToastIcon = getVariantIcon(variant);

  return (
    <Snackbar
      key={key}
      open={open}
      onClose={onClose}
      autoHideDuration={autoHideDuration}
      ClickAwayListenerProps={{ onClickAway: () => null }}
      sx={sx}>
      <Box
        sx={{
          background: variantColor.main,
          maxWidth: 500,
          borderLeft: `solid 4px ${variantColor.light}`,
          padding: '10px 18px',
          borderRadius: '4px',
          boxShadow: '2px 0px 3px rgba(115, 115, 139, 0.1), 0px 4px 20px rgba(115, 115, 139, 0.15)',
        }}>
        <Stack direction="row" spacing={2} sx={{ color: palette.neutral.white }}>
          <ToastIcon sx={{ fontSize: 28 }} />
          <Stack spacing={1}>
            <Typography component="p" variant="b1" sx={{ fontWeight: 500 }}>
              {title}
            </Typography>
            <Typography component="p" variant="b3">
              {message}
            </Typography>
          </Stack>
          <IconButton
            onClick={handleCloseButton}
            sx={{ width: '20px', height: '20px', color: 'inherit' }}>
            <RemoveSqfr2 sx={{ fontSize: 16 }} />
          </IconButton>
        </Stack>
      </Box>
    </Snackbar>
  );
};

export default Toast;
