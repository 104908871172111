/*eslint-disable */

import {
  Button,
  Divider,
  Grid,
  Typography,
  Box,
  styled,
  List,
  ListItem,
  ListItemText,
  Stack,
} from '@mui/material';
import { BankCards, TrashCan, Atm, FileDownloadOulc } from '@xcidic/icons';
import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { ToastManagerContext } from '../../components/ToastManager';
import { getDetailBilling } from '../../redux/modules/xendit';
import { AppDispatch } from '../../redux/store';
import moment from 'moment';

import Breadcrumbs from '../../components/Breadcrumbs';
import DashboardHeader from '../../components/DashboardHeader';
import Container from '../../components/Container';
import Field from '../../components/Fields';
import Table from '../../components/Table';
import Widget, { WidgetHeader, WidgetFooter, WidgetContent } from '../../components/Widget';

interface invcoiceDetail {
  createdOn: number;
  description: string;
  disc: number;
  invoiceNumber: string;
  id: string;
  status: string;
  tax: number;
  totalAmount: number;
  invoiceUrl: string;
}

interface invoiceFor {
  email: string;
  name: string;
  phone: string;
}

interface propsData {
  invoiceFor: invoiceFor;
  invoiceDetail: invcoiceDetail;
}

interface CircleProps {
  color: 'success' | 'warning' | 'error';
}

const Circle = ({ color }: CircleProps) => (
  <Box
    sx={{
      width: 12,
      height: 12,
      borderRadius: '50%',
      backgroundColor: (theme) => theme.palette[color].main,
    }}
  />
);

export default function index() {
  const { create } = useContext(ToastManagerContext);

  const { id } = useParams();
  const dispatch = useDispatch<AppDispatch>();

  const [data, setData] = useState<propsData>();
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    refreshData();
  }, []);
  const refreshData = () => {
    dispatch(getDetailBilling({ id: id ?? '' }))
      .unwrap()
      .then((res) => {
        // console.log(res.responseApi);
        setData(res.responseApi?.data);
      })
      .catch((err) => {
        // console.log(err)
        create('error', 'Error', err);
      });
  };

  const download = () => {
    dispatch(getDetailBilling({ id: `payment-receipt/${id}` ?? '' }))
      .unwrap()
      .then((res) => {
        const linkSource = `data:application/pdf;base64,${res.responseApi.data}`;
        const downloadLink = document.createElement('a');
        const fileName = `Payment Receipt-AXME ${id}.pdf`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      })
      .catch((err) => {
        create('error', 'Error', err);
      });
  };
  const capitalizeFirstLetter = (string: string) => {
    return string[0].toUpperCase() + string.slice(1);
  };
  return (
    <Container>
      <Breadcrumbs
        items={[
          {
            label: 'Billing',
            link: '/billing',
          },
          {
            label: 'Balance',
            link: '/billing',
          },
          {
            label: 'Balance Detail',
            link: `/billing/${id ?? ''}`,
          },
        ]}
      />
      <DashboardHeader title="Balance Detail" />
      <Grid container spacing={2}>
        <Grid item lg={9}>
          <Widget style={{ minHeight: 700 }}>
            <Box component="img" src="/axme-logo.svg" width={100} mb={8} />
            <Grid container spacing={3}>
              <Grid item lg={6}>
                <Typography variant="b2" fontWeight="bold">
                  Receipt from
                </Typography>
                <Typography variant="b2" fontWeight="bold">
                  AXME
                </Typography>
                <Typography variant="b3">axme@gmail.com</Typography>
                <Typography variant="b3">+62 812 3456 7890</Typography>
              </Grid>
              <Grid item lg={6}>
                <Typography variant="b2" fontWeight="bold">
                  Receipt to:
                </Typography>
                <Typography variant="b2" fontWeight="bold">
                  {data?.invoiceFor.name}
                </Typography>
                <Typography variant="b3">{data?.invoiceFor.email}</Typography>
                <Typography variant="b3">{data?.invoiceFor.phone}</Typography>
              </Grid>
            </Grid>
            <Box py={5}>
              <Table
                data={[
                  {
                    description: data?.invoiceDetail?.description,
                    amount: `${data?.invoiceDetail?.totalAmount}`,
                  },
                ]}
                config={[
                  {
                    label: 'Description',
                    key: 'description',
                  },
                  {
                    label: 'Amount',
                    key: 'amount',
                    render: (row) => {
                      return (
                        <p>
                          {`IDR ${parseInt(row.amount ?? 0)
                            .toLocaleString('id-ID', {
                              style: 'currency',
                              currency: 'IDR',
                              maximumFractionDigits: 0,
                            })
                            .replace('Rp', '')}`}
                        </p>
                      );
                    },
                  },
                ]}
              />
            </Box>
            <Box display="flex" justifyContent="flex-end">
              <Stack spacing={2} width={320}>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="b3" color="netral.400">
                    Total :
                  </Typography>
                  <Typography variant="b3" fontWeight="bold" color="netral.600">
                    {(data?.invoiceDetail.totalAmount ?? 0)
                      .toLocaleString('id-ID', {
                        style: 'currency',
                        currency: 'IDR',
                        maximumFractionDigits: 0,
                      })
                      .replace('Rp', 'IDR')}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="b3" color="netral.400">
                    Tax (11%) :
                  </Typography>
                  <Typography variant="b3" fontWeight="bold" color="netral.600">
                    {(data?.invoiceDetail.tax ?? 0)
                      .toLocaleString('id-ID', {
                        style: 'currency',
                        currency: 'IDR',
                        maximumFractionDigits: 0,
                      })
                      .replace('Rp', 'IDR')}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="b3" color="netral.400">
                    Discount :
                  </Typography>
                  <Typography variant="b3" fontWeight="bold" color="netral.600">
                    {(data?.invoiceDetail.disc ?? 0)
                      .toLocaleString('id-ID', {
                        style: 'currency',
                        currency: 'IDR',
                        maximumFractionDigits: 0,
                      })
                      .replace('Rp', 'IDR')}
                  </Typography>
                </Box>
                <Divider />
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="b3" color="netral.400">
                    Total Price :
                  </Typography>
                  <Typography variant="b3" fontWeight="bold" color="netral.600">
                    {(
                      (data?.invoiceDetail.totalAmount ?? 0) +
                      (data?.invoiceDetail.tax ?? 0) -
                      (data?.invoiceDetail.disc ?? 0)
                    )
                      .toLocaleString('id-ID', {
                        style: 'currency',
                        currency: 'IDR',
                        maximumFractionDigits: 0,
                      })
                      .replace('Rp', 'IDR')}
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Widget>
        </Grid>
        <Grid item lg={3}>
          <Widget>
            <WidgetHeader
              title={
                data?.invoiceDetail?.status === 'paid' ? 'Payment Receipt Detail' : 'Invoice Detail'
              }
            />
            <WidgetContent sx={{ py: 0 }}>
              <Stack spacing={2}>
                <List>
                  <ListItem disableGutters>
                    <ListItemText
                      primary="Invoice Number:"
                      secondary={data?.invoiceDetail.id?.slice(
                        data?.invoiceDetail?.id?.length - 10,
                        data?.invoiceDetail?.id?.length
                      )}
                      primaryTypographyProps={{ variant: 'b3', color: 'neutral.400' }}
                      secondaryTypographyProps={{
                        variant: 'b3',
                        color: 'neutral.600',
                        fontWeight: 'bold',
                      }}
                    />
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemText
                      primary="Purchased Date:"
                      secondary={moment((data?.invoiceDetail.createdOn ?? 0) * 1000).format(
                        'MMM DD, YYYY | HH:mm'
                      )}
                      primaryTypographyProps={{ variant: 'b3', color: 'neutral.400' }}
                      secondaryTypographyProps={{
                        variant: 'b3',
                        color: 'neutral.600',
                        fontWeight: 'bold',
                      }}
                    />
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemText
                      primary="Status:"
                      secondary={
                        data?.invoiceDetail?.status === 'paid' ? (
                          <Box display="flex" alignItems="center" gap={1}>
                            <Circle color="success" />
                            <Typography variant="b3" fontWeight="bold" color="neutral.600">
                              Paid
                            </Typography>
                          </Box>
                        ) : data?.invoiceDetail?.status === 'pending' ? (
                          <Box display="flex" alignItems="center" gap={1}>
                            <Circle color="warning" />
                            <Typography variant="b3" fontWeight="bold" color="neutral.600">
                              Pending
                            </Typography>
                          </Box>
                        ) : (
                          <Box display="flex" alignItems="center" gap={1}>
                            <Circle color="error" />
                            <Typography variant="b3" fontWeight="bold" color="neutral.600">
                              Expired
                            </Typography>
                          </Box>
                        )
                      }
                      primaryTypographyProps={{ variant: 'b3', color: 'neutral.400' }}
                    />
                  </ListItem>
                </List>

                {data?.invoiceDetail?.status === 'pending' && (
                  <Button
                    name="company"
                    variant="contained"
                    color="primary"
                    fullWidth
                    startIcon={<Atm />}
                    onClick={() => window.open(data?.invoiceDetail?.invoiceUrl ?? '', '_blank')}
                    sx={{ fontSize: 12 }}>
                    Pay Invoice
                  </Button>
                )}

                {(data?.invoiceDetail?.status === 'paid' ||
                  data?.invoiceDetail?.status === 'expired') && (
                  <List>
                    <ListItem disableGutters>
                      <ListItemText
                        primary="Total :"
                        primaryTypographyProps={{ variant: 'b3', color: 'neutral.400' }}
                      />
                      <ListItemText
                        primary={(data?.invoiceDetail.totalAmount)
                          .toLocaleString('id-ID', {
                            style: 'currency',
                            currency: 'IDR',
                            maximumFractionDigits: 0,
                          })
                          .replace('Rp', 'IDR')}
                        primaryTypographyProps={{
                          variant: 'b3',
                          fontWeight: 'bold',
                          color: 'neutral.600',
                          textAlign: 'right',
                        }}
                      />
                    </ListItem>
                    <ListItem disableGutters>
                      <ListItemText
                        primary="Tax (11%) :"
                        primaryTypographyProps={{ variant: 'b3', color: 'neutral.400' }}
                      />
                      <ListItemText
                        primary={(data?.invoiceDetail.tax)
                          .toLocaleString('id-ID', {
                            style: 'currency',
                            currency: 'IDR',
                            maximumFractionDigits: 0,
                          })
                          .replace('Rp', 'IDR')}
                        primaryTypographyProps={{
                          variant: 'b3',
                          fontWeight: 'bold',
                          color: 'neutral.600',
                          textAlign: 'right',
                        }}
                      />
                    </ListItem>
                    <ListItem disableGutters divider>
                      <ListItemText
                        primary="Discount :"
                        primaryTypographyProps={{ variant: 'b3', color: 'neutral.400' }}
                      />
                      <ListItemText
                        primary={(data?.invoiceDetail.disc)
                          .toLocaleString('id-ID', {
                            style: 'currency',
                            currency: 'IDR',
                            maximumFractionDigits: 0,
                          })
                          .replace('Rp', 'IDR')}
                        primaryTypographyProps={{
                          variant: 'b3',
                          fontWeight: 'bold',
                          color: 'neutral.600',
                          textAlign: 'right',
                        }}
                      />
                    </ListItem>
                    <ListItem disableGutters>
                      <ListItemText
                        primary="Total Price :"
                        primaryTypographyProps={{ variant: 'b3', color: 'neutral.400' }}
                      />
                      <ListItemText
                        primary={(
                          data?.invoiceDetail.totalAmount +
                          data?.invoiceDetail.tax -
                          data?.invoiceDetail.disc
                        )
                          .toLocaleString('id-ID', {
                            style: 'currency',
                            currency: 'IDR',
                            maximumFractionDigits: 0,
                          })
                          .replace('Rp', 'IDR')}
                        primaryTypographyProps={{
                          variant: 'b3',
                          fontWeight: 'bold',
                          color: 'neutral.600',
                          textAlign: 'right',
                        }}
                      />
                    </ListItem>
                  </List>
                )}

                {data?.invoiceDetail?.status === 'paid' && (
                  <Button
                    name="company"
                    variant="outlined"
                    color="primary"
                    fullWidth
                    startIcon={<FileDownloadOulc />}
                    onClick={() => download()}
                    sx={{ fontSize: 12 }}>
                    Download Payment Receipt
                  </Button>
                )}
              </Stack>
            </WidgetContent>
          </Widget>
        </Grid>
      </Grid>
    </Container>
  );
}
