import { Button, Divider, Grid, Paper, Typography, Box, styled } from '@mui/material';
import { BankCards, TrashCan } from '@xcidic/icons';
import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { ToastManagerContext } from '../../components/ToastManager';
import {
  deleteService,
  getServiceById,
  updateService,
  requestDataUpdate,
  paramsById,
} from '../../redux/modules/otpConfiguration';
import { AppDispatch } from '../../redux/store';
import { useNavigate } from 'react-router-dom';

import Breadcrumbs from '../../components/Breadcrumbs';
import DashboardHeader from '../../components/DashboardHeader';
import Confirm from '../../components/Confirm';
import Container from '../../components/Container';
import Field from '../../components/Fields';
import EditModal from './component/editModal';

interface ObjectPropsData {
  id: string;
  name: string;
  length: number;
  format: string;
  duration: number;
  productName: string;
  defaultLanguageCode: string;
  isActive: Boolean;
  isAllowMultipleActive: Boolean;
}

interface requestDataEdit {
  id: string;
  name: string;
  length: number;
  format: string;
  duration: number;
  productName: string;
  defaultLanguageCode: string;
  isActive: boolean;
  isAllowMulti: Boolean;
}

const NotifStatusContainerActive = styled(Box)(({ theme }) => ({
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '5px',
    // right: '3px',
    width: 12,
    height: 12,
    borderRadius: '50%',
    backgroundColor: '#12B76A',
    border: 'solid 1px white',
  },
}));
const NotifStatusContainerInActive = styled(Box)(({ theme }) => ({
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '5px',
    // right: '3px',
    width: 12,
    height: 12,
    borderRadius: '50%',
    backgroundColor: '#e11900',
    border: 'solid 1px white',
  },
}));
export default function index() {
  const { create } = useContext(ToastManagerContext);
  const [isConfirm, setIsConfirm] = useState(false);
  const [isConfirmStatus, setIsConfirmStatus] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const [data, setData] = useState<ObjectPropsData>({
    id: '0',
    name: '',
    length: 0,
    format: 'alpha',
    duration: 0,
    productName: '',
    defaultLanguageCode: 'en',
    isActive: false,
    isAllowMultipleActive: false,
  });
  const [isEdit, setIsEdit] = useState(false);

  const [editValue, setEditValue] = useState<requestDataEdit>({
    id: '0',
    name: '',
    length: 0,
    format: 'alpha',
    duration: 0,
    productName: '',
    defaultLanguageCode: 'en',
    isActive: false,
    isAllowMulti: false,
  });

  useEffect(() => {
    refreshData();
  }, []);
  const refreshData = () => {
    dispatch(getServiceById({ id: id ?? '' }))
      .unwrap()
      .then((res) => {
        // create('success', 'Success', res.responseApi.message);

        setData(res.responseApi?.data);
      })
      .catch((err) => {
        // console.log(err)
        create('error', 'Error', err);
      });
  };
  const deleteData = (id: string) => {
    const data: paramsById = {
      id,
    };
    dispatch(deleteService(data))
      .unwrap()
      .then((res) => {
        // console.log(res)
        create('success', 'Success', res.responseApi.message);
        // refreshData();
        navigate('/otp/configuration');
      })
      .catch((err) => {
        create('success', 'Error', err);

        // console.log(err)
      });
  };
  const updateData = (id: string, name: string, isActive: Boolean) => {
    const data: requestDataUpdate = {
      id,
      name,
      isActive: !isActive,
    };
    dispatch(updateService(data))
      .unwrap()
      .then((res) => {
        // console.log(res)
        create('success', 'Success', res.responseApi.message);
        refreshData();
      })
      .catch((err) => {
        create('success', 'Error', err);

        // console.log(err)
      });
  };
  const capitalizeFirstLetter = (string: string) => {
    return string[0].toUpperCase() + string.slice(1);
  };
  return (
    <Container>
      <Breadcrumbs
        items={[
          {
            label: 'OTP',
            link: '/otp',
          },
          {
            label: 'Otp Configuration',
            link: '/otp/configuration',
          },
          {
            label: 'Configuration Detail',
            link: `/otp/configuration/${id ?? ''}`,
          },
        ]}
      />
      <DashboardHeader title="Configuration Detail" />

      <Paper>
        <Field withElement={false} small={false} label="Configuration Name" value={data.name} />
        <Divider />
        <div style={{ margin: '16px 16px 16px 0px' }}>
          <Typography style={{ fontWeight: 500, fontStyle: 'normal', fontSize: '14px' }}>
            Status
          </Typography>
          {data?.isActive && (
            <NotifStatusContainerActive>
              <Typography
                style={{
                  fontWeight: 300,
                  fontStyle: 'normal',
                  fontSize: '14px',
                  color: '#5d5d5d',
                  marginLeft: 14,
                }}>
                {data.isActive ? 'Active' : 'InActive'}
              </Typography>
            </NotifStatusContainerActive>
          )}
          {!data?.isActive && (
            <NotifStatusContainerInActive>
              <Typography
                style={{
                  fontWeight: 300,
                  fontStyle: 'normal',
                  fontSize: '14px',
                  color: '#5d5d5d',
                  marginLeft: 14,
                }}>
                {data.isActive ? 'Active' : 'InActive'}
              </Typography>
            </NotifStatusContainerInActive>
          )}
        </div>

        <Divider />
        <Field
          withElement={false}
          small={false}
          label="OTP Length"
          value={`${data.length} seconds`}
        />
        <Divider />

        <Field
          withElement={false}
          small={false}
          label="OTP Format"
          value={data?.format === 'alpha' ? 'Alphabet' : capitalizeFirstLetter(data.format)}
        />
        <Divider />
        <Field withElement={false} small={false} label="OTP Duration" value={data.duration} />

        <Divider />
        <Field withElement={false} small={false} label="Product Name" value={data.productName} />
        <Divider />
        <div style={{ margin: '16px 16px 16px 0px' }}>
          <Typography style={{ fontWeight: 500, fontStyle: 'normal', fontSize: '14px' }}>
            Configuration ID
          </Typography>
          <Typography
            style={{ fontWeight: 300, fontStyle: 'normal', fontSize: '14px', color: '#5d5d5d' }}>
            {data.id}
            {/* {isToken ? data?.id ?? '' : data?.id?.split('').map(() => '*') ?? ''} */}
            {/* <IconButton onClick={() => setIsToken(!isToken)}>
            {isToken ? <EyeOff /> : <Eye />}
          </IconButton> */}
            <Button
              style={{ marginLeft: 10 }}
              name="copy"
              variant="outlined"
              color="primary"
              // fullWidth
              startIcon={<BankCards />}
              onClick={() => {
                navigator.clipboard.writeText(data?.id ?? '');
                create('success', 'Copy', 'Success Copy Configuratuin ID');
              }}
              // disabled={!(dirty && isValid)}
              // onClick={() => setIsConfirm(true)}
            >
              Copy
            </Button>
          </Typography>
        </div>

        {/* <Divider /> */}

        <Grid container spacing={3} style={{ marginTop: 10 }}>
          <Grid item lg={12} style={{ justifyContent: 'flex-end', display: 'flex' }}>
            <Grid item spacing={3}>
              <Button
                name="company"
                style={{ marginRight: 10 }}
                // variant="contained"
                color={data.isActive ? 'error' : 'primary'}
                onClick={() => {
                  setIsConfirmStatus(true);
                  setEditValue({
                    id: data.id,
                    isAllowMulti: Boolean(data.isAllowMultipleActive),
                    name: data.name,
                    length: data.length,
                    format: data.format,
                    duration: data.duration,
                    productName: data.productName,
                    defaultLanguageCode: data.defaultLanguageCode,
                    isActive: Boolean(data.isActive),
                  });
                }}>
                {data.isActive ? 'Deactive' : 'ReActive'}
              </Button>

              <Button
                name="company"
                startIcon={<TrashCan />}
                style={{ marginRight: 10 }}
                variant="outlined"
                color="error"
                onClick={() => {
                  setEditValue({
                    id: data.id,
                    isAllowMulti: Boolean(data.isAllowMultipleActive),
                    name: data.name,
                    length: data.length,
                    format: data.format,
                    duration: data.duration,
                    productName: data.productName,
                    defaultLanguageCode: data.defaultLanguageCode,
                    isActive: Boolean(data.isActive),
                  });
                  setIsConfirm(true);
                }}>
                Delete Configuration
              </Button>
              <Button
                name="company"
                variant="contained"
                color="primary"
                onClick={() => {
                  setIsEdit(true);
                  setEditValue({
                    id: data.id,
                    name: data.name,
                    length: data.length,
                    isAllowMulti: Boolean(data.isAllowMultipleActive),
                    format: data.format,
                    duration: data.duration,
                    productName: data.productName,
                    defaultLanguageCode: data.defaultLanguageCode,
                    isActive: Boolean(data.isActive),
                  });
                }}>
                Edit Configuration
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {isEdit && (
          <EditModal
            open={isEdit}
            onClose={() => {
              setIsEdit(false);
              refreshData();
            }}
            isAllowMultipleActive={editValue.isAllowMulti}
            id={editValue.id}
            name={editValue.name}
            length={editValue.length}
            format={editValue.format}
            duration={editValue.duration}
            productName={editValue.productName}
            defaultLanguageCode={editValue.defaultLanguageCode}
          />
        )}

        <Confirm
          type={'Create'}
          open={isConfirmStatus}
          handleAksi={() => {
            updateData(String(editValue.id), editValue.name, editValue.isActive);
            setIsConfirmStatus(false);
          }}
          loading={false}
          handleClose={() => setIsConfirmStatus(false)}
          disable={false}
          handleContent={{
            title: editValue.isActive ? 'Deactive Configuration' : 'ReActive Configuration',
            message: editValue.isActive
              ? 'Do you want to change Configuration status to inactive?'
              : 'Do you want to change Configuration status to active?',
            button: 'Confirm',
          }}
        />
        <Confirm
          open={isConfirm}
          handleAksi={() => {
            deleteData(String(editValue.id));
            setIsConfirm(false);
          }}
          loading={false}
          handleClose={() => setIsConfirm(false)}
          disable={false}
          type={'Delete'}
          handleContent={{
            title: 'Delete OTP Configuration',
            message: 'Do you wish to Delete this OTP Configuration?',
            button: 'Confirm',
          }}
        />
      </Paper>
    </Container>
  );
}
