/* eslint-disable */
import { Box, Divider, Grid, IconButton, styled, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useDispatch } from 'react-redux';

import { ChevronLeftSqfr, ChevronRightSqfr } from '@xcidic/icons';
import { ApexOptions } from 'apexcharts';
import { getService } from '../../../redux/modules/statistic';
import { AppDispatch } from '../../../redux/store';
import './Tooltip.css';

interface propsModal {
  range: string;
}

const Regular = styled(Box)(({ theme }) => ({
  marginTop: 20,
  marginRight: 30,
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '5px',
    // right: '3px',
    width: 12,
    height: 12,
    borderRadius: '50%',
    backgroundColor: '#276EF1',
    border: 'solid 1px white',
  },
}));

const Shared = styled(Box)(({ theme }) => ({
  position: 'relative',
  marginTop: 20,
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '5px',
    // right: '3px',
    width: 12,
    height: 12,
    borderRadius: '50%',
    backgroundColor: '#C2D5F9',
    border: 'solid 1px white',
  },
}));

interface data {
  costReguler: number[];
  costShared: number[];
  regulerSent: number[];
  sharedSent: number[];
}

interface apiData {
  labels: string[];
  data: data;
  totalCost: number;
  totalCostReguler: number;
  totalCostShared: number;
  totalRegulerSent: number;
  totalSent: number;
  totalSharedSent: number;
}

const MessageSent = ({ range }: propsModal) => {
  // const [update, setUpdate] = useState(false);
  const [key, setKey] = useState(0);
  const [show, setShow] = useState(false);

  // const forceUpdate = useForceUpdate();
  // eslint-disable-next-line
  const [options, setOption] = useState<ApexOptions | undefined>({
    xaxis: {
      categories: [],
    },
  });
  // eslint-disable-next-line
  const [series, setSeries] = useState<ApexAxisChartSeries | ApexNonAxisChartSeries | undefined>([
    {
      name: '',
      data: [],
    },
  ]);
  // eslint-disable-next-line
  const [seriesDonut, setSeriesDonut] = useState<ApexNonAxisChartSeries>([0, 0]);
  // const [optionsDonut, setOptionDonut] = useState<ApexOptions | undefined>();
  const [page, setPage] = useState(0);

  const [limit, setLimit] = useState(7);

  const [totalCost, setTotalCost] = useState(0);
  const [regularCost, setRegularCost] = useState(0);
  const [sharedCost, setSharedCost] = useState(0);

  const [dataApi, setDataApi] = useState<apiData>();
  // const [percent, setPercent] = useState(0);
  // const [status, setStatus] = useState('');

  // const [labels,setLabel] = useState([])

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    setShow(false);
    dispatch(getService({ url: 'cost-overview', type: `range=${range}` }))
      .unwrap()
      .then((res) => {
        // console.log(res.responseApi, 'response');
        setDataApi(res.responseApi.data);
        setTotalCost(res.responseApi.data.totalCost);
        setRegularCost(res.responseApi.data.totalCostReguler);

        setSharedCost(res.responseApi.data.totalCostShared);

        setSeriesDonut([
          res.responseApi.data.totalRegulerSent,
          res.responseApi.data.totalSharedSent,
        ]);
        setKey(key + 1);
        //  setOptionDonut();
        // setShow(true);
        setSeries([
          {
            name: 'Regular Message',
            data:
              res.responseApi.data.data.costReguler.slice(page === 0 ? page : limit - 7, limit) ??
              [],
          },
          {
            name: 'Shared Message',
            data:
              res.responseApi.data.data.costShared.slice(page === 0 ? page : limit - 7, limit) ??
              [],
          },
        ]);
        // setTimeout(() => {
        // setOption();
        // }, 1000);

        // setUpdate(!update);
        // window.dispatchEvent(new Event('resize'));
        // create('success', 'Success', res.list.message)
      })
      .catch(() => {
        // create('error', 'Error', err);
      });
  }, [range, page]);

  useEffect(() => {
    setShow(true);
  }, [options]);

  // console.log(dataApi, 'seris donut');
  return (
    <Box>
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <Regular>
          <Typography
            style={{
              fontWeight: 300,
              fontStyle: 'normal',
              fontSize: '14px',
              color: '#5d5d5d',
              marginLeft: 14,
            }}>
            Regular Messages
          </Typography>
        </Regular>
        {/* <Typography
              style={{
                fontWeight: 300,
                fontStyle: 'normal',
                fontSize: '14px',
                color: '#5d5d5d',
                marginBottom: 16,
                // marginLeft: 14,
              }}>
              {`IDR ${regularCost}`}
            </Typography>
            <Divider /> */}
        <Shared>
          <Typography
            style={{
              fontWeight: 300,
              fontStyle: 'normal',
              fontSize: '14px',
              color: '#5d5d5d',
              marginLeft: 14,
            }}>
            Shared Messages
          </Typography>
        </Shared>
        {/* <Typography
              style={{
                fontWeight: 300,
                fontStyle: 'normal',
                fontSize: '14px',
                color: '#5d5d5d',
                // marginLeft: 14,
              }}>
              {`IDR ${sharedCost}`}
            </Typography> */}
      </div>
      <Grid container>
        <Grid item lg={4}>
          <ReactApexChart
            type={'donut'}
            // width={'106px'}
            key={key}
            width={'100%'}
            // height={'80%'}
            series={seriesDonut?.findIndex((val: any) => val > 0) !== -1 ? seriesDonut : [1000]}
            options={{
              labels:
                seriesDonut?.findIndex((val: number) => val > 0) !== -1
                  ? ['Reguler Message', 'Shared Message']
                  : ['dummy'],
              chart: {
                // id: 'message-sent',
                // width: '200px',
                toolbar: { show: false },
              },
              plotOptions: {
                pie: {
                  donut: {
                    labels: {
                      show: true,

                      total: {
                        showAlways: true,
                        show: true,
                        label: '',
                        formatter: (w) => {
                          return `IDR ${totalCost}`;
                        },
                      },
                    },
                  },
                },
              },
              tooltip: {
                enabled: seriesDonut?.findIndex((val: number) => val > 0) !== -1,
                custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                  // console.log(series, seriesIndex, dataPointIndex, w);
                  const cost =
                    w.config.labels[seriesIndex] === 'Reguler Message'
                      ? dataApi?.totalCostReguler
                      : dataApi?.totalCostShared;
                  // console.log(w.config.labels[dataPointIndex]);
                  return (
                    '<div class="arrow_box">' +
                    '<span>' +
                    series[seriesIndex] +
                    ' Messages' +
                    '<h4>' +
                    'IDR ' +
                    cost +
                    '</h4>' +
                    '</span>' +
                    '</div>'
                  );
                },
              },
              dataLabels: {
                enabled: false,
              },
              colors:
                seriesDonut?.findIndex((val: number) => val > 0) !== -1
                  ? ['#276EF1', '#C2D5F9']
                  : ['#E2E2E2'],
              legend: {
                show: false,
              },
            }}
          />
        </Grid>
        <Grid item lg={8}>
          <div style={{ position: 'relative' }}>
            <div style={{ paddingLeft: 40, paddingRight: 40 }}>
              <ReactApexChart
                type={'line'}
                // key={keyLine}
                // key={key}
                // width={'106px'}
                width={'100%'}
                height={'400px'}
                series={series}
                options={{
                  xaxis: {
                    categories: dataApi?.labels.slice(page === 0 ? page : limit - 7, limit),
                    tooltip: {
                      enabled: false,
                    },
                  },
                  stroke: {
                    curve: 'smooth',
                  },
                  colors: ['#276EF1', '#C2D5F9'],
                  chart: {
                    id: 'message-sent',
                    toolbar: { show: false },
                  },
                  dataLabels: {
                    enabled: false,
                  },
                  legend: {
                    show: false,
                  },

                  tooltip: {
                    y: {
                      formatter: function (val) {
                        return `${val}`;
                      },
                    },
                    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                      const totalSent =
                        w.globals.seriesNames[seriesIndex] === 'Regular Message'
                          ? dataApi?.data.regulerSent[dataPointIndex]
                          : dataApi?.data.sharedSent[dataPointIndex];
                      // console.log(totalSent);
                      return (
                        '<div class="arrow_box">' +
                        '<span>' +
                        totalSent +
                        ' Messages' +
                        '<h4>' +
                        'IDR ' +
                        series[seriesIndex][dataPointIndex] +
                        '</h4>' +
                        '</span>' +
                        '</div>'
                      );
                    },
                  },
                }}
              />
            </div>

            {range?.includes('month') && (
              <div style={{ position: 'absolute', bottom: 14, left: 20 }}>
                <IconButton
                  onClick={() => {
                    if (page > 0) {
                      setPage(page - 1);
                      setLimit(limit - 7);
                    }
                  }}>
                  <ChevronLeftSqfr />
                </IconButton>
              </div>
            )}

            {range?.includes('month') && (
              <div style={{ position: 'absolute', bottom: 14, right: 20 }}>
                <IconButton
                  onClick={() => {
                    if (page < 4) {
                      setPage(page + 1);
                      setLimit(limit + 7);
                    }
                  }}>
                  <ChevronRightSqfr />
                </IconButton>
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MessageSent;
