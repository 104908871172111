import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { BASE_URL } from '../../utils/url';
import axios from '../../utils/request';
import { getUser } from '../../utils/localStorage';

interface objectState {
  countryCode: string;
  number: string;
  waPhoneId: string;
  waApiToken: string;
  waBusinessAccountId: string;
}

export interface requestData {
  name: string;
  phones: objectState[];
}
export interface requestDataUpdate {
  id: string;
  name: string;
  phones: objectState[];
}

interface errorRequest {
  message: string;
}

const initialState = {
  list: [],
  responseApi: {},
};

const getCompany = () => {
  const { token } = getUser();
  return axios.get(`${BASE_URL}/company/my`, {
    headers: {
      Authorization: `Bearer ${token as string}`,
    },
  });
};
// eslint-disable-next-line
export const get = createAsyncThunk('module/getCompany', async (undefined, thunkAPI) => {
  try {
    const data = await getCompany();
    // console.log(data);
    thunkAPI.fulfillWithValue(data.data);
    return { list: data?.data };
  } catch (error) {
    const err = error as AxiosError;
    // console.log(err);
    return thunkAPI.rejectWithValue((err.response?.data as errorRequest).message);
  }
});

const createCompany = (name: string, phones: objectState[]) => {
  const { token } = getUser();

  return axios.post(
    `${BASE_URL}/company/my`,
    {
      name,
      phones,
    },
    {
      headers: {
        Authorization: `Bearer ${token as string}`,
      },
    }
  );
};

export const createCompanyAsync = createAsyncThunk(
  'module/updateCompany',
  async ({ name, phones }: requestData, thunkAPI) => {
    try {
      const data = await createCompany(name, phones);
      thunkAPI.fulfillWithValue(data.data);
      return { responseApi: data?.data };
    } catch (error) {
      const err = error as AxiosError;
      return thunkAPI.rejectWithValue((err.response?.data as errorRequest).message);
    }
  }
);

const updateCompany = (id: string, name: string, phones: objectState[]) => {
  const { token } = getUser();

  return axios.patch(
    `${BASE_URL}/company/my/${id}`,
    {
      name,
      phones,
    },
    {
      headers: {
        Authorization: `Bearer ${token as string}`,
      },
    }
  );
};

export const updateCompanyAsync = createAsyncThunk(
  'module/updateCompany',
  async ({ id, name, phones }: requestDataUpdate, thunkAPI) => {
    try {
      const data = await updateCompany(id, name, phones);
      thunkAPI.fulfillWithValue(data.data);
      return { responseApi: data?.data };
    } catch (error) {
      const err = error as AxiosError;
      return thunkAPI.rejectWithValue((err.response?.data as errorRequest).message);
    }
  }
);

const company = createSlice({
  name: 'company',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createCompanyAsync.fulfilled, (state, action) => {
      state.responseApi = action.payload.responseApi;
    });
    builder.addCase(createCompanyAsync.rejected, (state, action) => {
      state.responseApi = {};
    });
    builder.addCase(get.fulfilled, (state, action) => {
      state.list = action.payload.list;
      // state.isLoggedIn = true;
      // state.user = action.payload.user;
    });
    builder.addCase(get.rejected, (state, action) => {
      state.list = [];

      // state.isLoggedIn = false;
      // state.user = null;
    });
  },
});

const { reducer } = company;
export default reducer;
