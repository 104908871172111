import { useState } from 'react';
import { InputBase, Button, Grid } from '@mui/material';
import { LocationArrow } from '@xcidic/icons';

interface props<T> {
  onSubmit: (data: T) => void;
}

export default function index({ onSubmit }: props<string>) {
  const [message, setMessages] = useState('');
  return (
    <div style={{ borderTop: '0.1em solid #E2E2E2', paddingTop: 16, paddingLeft: 10 }}>
      <Grid container>
        <Grid item lg={11}>
          <InputBase
            multiline
            placeholder="Type Something..."
            fullWidth
            value={message}
            onChange={(val) => setMessages(val.target.value)}
          />
        </Grid>
        <Grid item lg={1}>
          <Button
            disabled={message === ''}
            onClick={() => {
              onSubmit(message);
              setMessages('');
            }}
            variant="contained"
            color="primary"
            startIcon={<LocationArrow />}>
            Send
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
