import { ThemeOptions } from '@mui/material';

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h1: true;
    h2: true;
    h3: true;
    h4: true;

    b1: true;
    b2: true;
    b3: true;
    b4: true;

    p1: true;
    p2: true;
    p3: true;
    p4: true;

    l1: true;
    l2: true;
    l3: true;
  }
}

export default function Typography(): ThemeOptions['components'] {
  return {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h1',
          h2: 'h2',
          h3: 'h3',
          h4: 'h4',

          b1: 'p',
          b2: 'p',
          b3: 'p',
          b4: 'p',

          p1: 'p',
          p2: 'p',
          p3: 'p',
          p4: 'p',

          l1: 'p',
          l2: 'p',
          l3: 'p',
        },
      },
    },
  };
}
