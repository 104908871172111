import { Button, CircularProgress, Box, TextField, Autocomplete } from '@mui/material';
import { useFormik } from 'formik';
import { useContext, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import {
  addContactInitialValues as initialValues,
  addContactvalidationSchema as validationSchema,
} from './schemeValidation';

import { ToastManagerContext } from '../../../components/ToastManager';

import {
  createByCategoryService,
  addContact as requestData,
  idContact,
} from '../../../redux/modules/category';
import { AppDispatch } from '../../../redux/store';

import { getService, getServiceByCategories } from '../../../redux/modules/contact';
import Confirm from '../../../components/Confirm';

interface ObjectPropsData {
  countryCode: string;
  number: string;
  name: string;
  id: string;
}

interface PropsModal {
  // onClose: (reason: CloseReason) => void;
  onClose: Function;
}
export default function index({ onClose }: PropsModal) {
  const { create } = useContext(ToastManagerContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [list, setList] = useState<ObjectPropsData[]>();
  // const [listExist, setListExist] = useState<ObjectPropsData[]>();

  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getService({ limit: 1000, offset: 0 }))
      .unwrap()
      .then((res) => {
        // create('success', 'Success', res.listToken.message);
        // setList(res.listToken?.data);
        dispatch(
          getServiceByCategories({
            limit: 1000,
            offset: 0,
            id: id ?? '',
          })
        )
          .unwrap()
          .then((resList) => {
            // create('success', 'Success', res.listToken.message);
            // setTotal(res.listToken.meta);
            const listAll = res.listToken.data as ObjectPropsData[];
            const existing = resList.listToken.data as ObjectPropsData[];
            const newArray: ObjectPropsData[] = [];
            listAll.map((val) => {
              console.log(
                val,
                existing.findIndex((valFind) => val.id === valFind.id)
              );
              if (existing.findIndex((valFind) => val.id === valFind.id) < 0) {
                return newArray.push(val);
              }
              return false;
            });
            // console.log(newArray);
            setList(newArray);

            // setListExist(resList.listToken?.data);
          })
          .catch((err) => {
            // console.log(err)
            create('error', 'Error', err);
          });
      })
      .catch((err) => {
        // console.log(err)
        create('error', 'Error', err);
      });
  }, [open]);

  const {
    // handleChange,
    // handleBlur,
    // setSubmitting,
    handleSubmit,
    setFieldValue,
    dirty,
    resetForm,
    values,
    isValid,
    touched,
    errors,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async () => {
      setIsLoading(true);
      try {
        const contactsArray = (values.contacts as idContact[]).map((val) => {
          return { id: val?.id };
        });
        const data: requestData = {
          id: id ?? '',
          contacts: contactsArray,
        };
        dispatch(createByCategoryService(data))
          .unwrap()
          .then((res) => {
            setIsLoading(false);

            create('success', 'Success', res.responseApi.message);
            resetForm();
            onClose();
          })
          .catch((err) => {
            setIsLoading(false);

            create('error', 'Error', err);
          });
      } catch (error) {
        // handle any rejections/errors
      }
    },
  });
  return (
    // <Dialog open={open} onClose={() => onClose()}>
    <Box mt={2}>
      <Autocomplete
        id="tags-outlined"
        multiple
        onChange={(e, val) => {
          // setSelected(val !== null ? val : []);
          // console.log(val.map((val) => val._id));
          // console.log(val)
          setFieldValue('contacts', val !== null ? val : []);
        }}
        // value={values?.intention}
        options={list ?? []}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField
            {...params}
            error={Boolean(touched.contacts && errors.contacts)}
            helperText={touched.contacts && errors.contacts}
            variant="outlined"
            placeholder="Choose Contacts"
            // placeholder="Contact Categories"
          />
        )}
      />

      <Button
        sx={{ mt: 2 }}
        fullWidth
        name="company"
        variant="contained"
        color="primary"
        disabled={!(dirty && isValid)}
        onClick={() => setIsConfirm(true)}>
        {isLoading ? (
          <CircularProgress style={{ color: 'white' }} size={30} />
        ) : (
          <span className="loginButtonCaption">Add Contact</span>
        )}
      </Button>
      <Confirm
        type={'Create'}
        open={isConfirm}
        handleAksi={() => {
          setIsConfirm(false);
          handleSubmit();
        }}
        loading={false}
        handleClose={() => setIsConfirm(false)}
        disable={false}
        handleContent={{
          title: 'Add Contact',
          message: 'Do you wish to Add this Contact?',
          button: 'Submit',
        }}
      />
    </Box>

    // </Dialog>
  );
}
