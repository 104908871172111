import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  completed: false,
  guide: '',
  run: false,
  stepIndex: 0,
  steps: [],
  callback: () => {},
};

const tourGuideSlicer = createSlice({
  name: 'tourGuide',
  initialState,
  reducers: {
    markCompleted: (state, action) => {
      state.completed = true;
    },
    updateGuide: (state, action) => {
      state.guide = action.payload;
    },
    toggleStartGuide: (state, action) => {
      state.run = action.payload;
    },
    updateSteps: (state, action) => {
      state.steps = action.payload;
    },
    clearSteps: (state) => {
      state.steps = [];
    },
    updateStepIndex: (state, action) => {
      state.stepIndex = action.payload;
    },
    updateCallback: (state, action) => {
      state.callback = action.payload;
    },
  },
});

export const {
  markCompleted,
  updateGuide,
  toggleStartGuide,
  updateSteps,
  clearSteps,
  updateStepIndex,
  updateCallback,
} = tourGuideSlicer.actions;

const { reducer } = tourGuideSlicer;
export default reducer;
