import {
  Button,
  Grid,
  // Pagination,
  Paper,
  Typography,
  Divider,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { ToastManagerContext } from '../../components/ToastManager';
import { deleteService, getServiceById } from '../../redux/modules/contact';
// import { getService } from '../../redux/modules/category';

import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { AppDispatch } from '../../redux/store';
import { TrashCan, BankCards } from '@xcidic/icons';

// import ListToken from './component'
import Edit from './component/modal';
// import Edit from './component/editModal'
import { useNavigate } from 'react-router-dom';
import Field from '../../components/Fields';

import Breadcrumbs from '../../components/Breadcrumbs';
import DashboardHeader from '../../components/DashboardHeader';
import Confirm from '../../components/Confirm';
import Container from '../../components/Container';
// import Table from '../../components/Table';

interface ObjectPropsData {
  name: string;
  id: string;
}

interface userObject {
  id: string;
  name: String;
  countryCode: string;
  number: string;
  categories: ObjectPropsData[];
}
// interface meta {
//   total: number;
// }

export default function index() {
  const { create } = useContext(ToastManagerContext);
  const { id } = useParams();
  const [isConfirm, setIsConfirm] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch<AppDispatch>();
  const [user, setUser] = useState<userObject>({
    id: '',
    name: '',
    countryCode: '',
    number: '',
    categories: [{ id: '', name: '' }],
  });
  // eslint-disable-next-line
  const [list, setList] = useState<ObjectPropsData[]>();

  const [isOpen, setIsOpen] = useState(false);
  // const [totalData, setTotal] = useState<meta>({ total: 1 });

  // const [page, setPage] = useState(1);
  // const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
  //   setPage(value);
  // };

  useEffect(() => {
    dispatch(getServiceById({ id: id ?? '' }))
      .unwrap()
      .then((res) => {
        setUser(res?.responseApi?.data);
        setList(res.responseApi?.data?.categories);
        // console.log(res.responseApi)
        // setTotal(res.responseApi?.data.meta);

        // create('success', 'Success', res.responseApi.message);
      })
      .catch((err) => {
        // console.log(err)
        create('error', 'Error', err);
      });
  }, []);
  const updateToken = () => {
    dispatch(getServiceById({ id: id ?? '' }))
      .unwrap()
      .then((res) => {
        setUser(res?.responseApi?.data);
        setList(res.responseApi?.data?.categories);
        // setTotal(res.responseApi?.data.meta);

        create('success', 'Success', res.responseApi.message);

        // setList(res.listToken?.data)
      })
      .catch((err) => {
        // console.log(err)
        create('error', 'Error', err);
      });
  };
  const deleteData = () => {
    dispatch(deleteService({ id: id ?? '' }))
      .unwrap()
      .then((res) => {
        navigate('/message/contact');
        create('success', 'Success', res.responseApi.message);
      })
      .catch((err) => {
        // console.log(err)
        create('error', 'Error', err);
      });
  };

  return (
    <Container>
      <Breadcrumbs
        items={[
          {
            label: 'Message Blast',
            link: '/message',
          },
          {
            label: 'Contact',
            link: '/message/contact',
          },
          {
            label: 'Contact Detail',
            link: `/message/contact/${id ?? ''}`,
          },
        ]}
      />
      <DashboardHeader title="Contact Detail" />

      <Paper>
        <Field withElement={false} small={false} label="Contact Name" value={user.name} />
        <Divider />
        <Field
          withElement={false}
          small={false}
          label="WhatsApp Phone Number"
          value={`+${user.countryCode}  ${user.number}`}
        />
        <Divider />
        <Field
          withElement={false}
          small={false}
          label="Contact Category"
          value={
            list?.length !== 0
              ? list?.map((val, index) => (index + 1 === list.length ? val.name : `${val.name},`))
              : '-'
          }
        />
        <Divider />
        <div style={{ margin: '16px 16px 16px 0px' }}>
          <Typography style={{ fontWeight: 500, fontStyle: 'normal', fontSize: '14px' }}>
            Configuration ID
          </Typography>
          <Typography
            style={{ fontWeight: 300, fontStyle: 'normal', fontSize: '14px', color: '#5d5d5d' }}>
            {user.id}
            {/* {isToken ? data?.id ?? '' : data?.id?.split('').map(() => '*') ?? ''} */}
            {/* <IconButton onClick={() => setIsToken(!isToken)}>
            {isToken ? <EyeOff /> : <Eye />}
          </IconButton> */}
            <Button
              style={{ marginLeft: 10 }}
              name="copy"
              variant="outlined"
              color="primary"
              // fullWidth
              startIcon={<BankCards />}
              onClick={() => {
                navigator.clipboard.writeText(user?.id ?? '');
                create('success', 'Copy', 'Success Copy Configuratuin ID');
              }}
              // disabled={!(dirty && isValid)}
              // onClick={() => setIsConfirm(true)}
            >
              Copy
            </Button>
          </Typography>
        </div>
        {/* <Grid item lg={12} style={{ marginTop: '30px', marginBottom: '30px' }}>
          <h2>Category Contact</h2>
        </Grid> */}
        {/* <Table
          // idHref="id"
          // name="message/category"
          data={list ?? []}
          config={[
            {
              label: 'Id',
              key: 'id',
              render: (row) => {
                return (
                  <p
                    style={{ cursor: 'pointer' }}
                    onClick={() => navigate(`/message/category/${row.id}`)}>
                    {row.id}
                  </p>
                );
              },
            },
            {
              label: 'Name',
              key: 'name',
              render: (row) => {
                return (
                  <p
                    style={{ cursor: 'pointer' }}
                    onClick={() => navigate(`/message/category/${row.id}`)}>
                    {row.name}
                  </p>
                );
              },
            },
          ]}
          actions={[
            {
              label: 'Edit',
              icon: <Pencil />,
              onAction: (row) => {
                navigate(`/message/category/${row.id}`);
              },
            },
          ]}
        /> */}

        {/* <Grid container spacing={3} style={{ marginTop: 10 }}>
          <Grid item lg={12} style={{ justifyContent: 'center', display: 'flex' }}>
            <Pagination
              count={Math.ceil(totalData?.total / 5)}
              showFirstButton
              showLastButton
              onChange={handleChange}
            />
          </Grid>
        </Grid> */}
        {/* <GenerateToken open={isOpen} onClose={() => { setIsOpen(false); updateToken() }} /> */}
        <Grid container spacing={3} style={{ marginTop: 10, marginBottom: 10 }}>
          <Grid item lg={12} style={{ justifyContent: 'flex-end', display: 'flex' }}>
            <Button
              name="company"
              startIcon={<TrashCan />}
              variant="outlined"
              color="error"
              style={{ marginRight: 20 }}
              onClick={() => setIsConfirm(true)}>
              Delete Contact
            </Button>

            <Button
              name="company"
              variant="contained"
              color="primary"
              onClick={() => setIsOpen(true)}>
              Edit Contact
            </Button>
          </Grid>
        </Grid>
        <Edit
          open={isOpen}
          onClose={() => {
            setIsOpen(false);
            updateToken();
          }}
          content={user}
        />
        <Confirm
          type={'Delete'}
          open={isConfirm}
          handleAksi={() => deleteData()}
          loading={false}
          handleClose={() => setIsConfirm(false)}
          disable={false}
          handleContent={{
            title: 'Delete Contact',
            message: 'Do you wish to Delete this Contact?',
            button: 'Delete',
          }}
        />
      </Paper>
    </Container>
  );
}
