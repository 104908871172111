import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import './Fields.css';

interface PropsField {
  label: String;
  withElement: Boolean;
  small: Boolean;
  value: any;
}

const Field = ({ label, value, withElement, small }: PropsField) => (
  <div className={small ? 'fieldSmall' : 'fieldsRoot'}>
    <Typography style={{ fontWeight: 500 }} className="fieldsLabel">
      {label ?? '-'}
    </Typography>
    {withElement ? (
      value ?? <div />
    ) : (
      <Typography className="fieldsValue">{value ?? '-'}</Typography>
    )}
  </div>
);

Field.propTypes = {
  label: PropTypes.string,
  withElement: PropTypes.bool,
  small: PropTypes.bool,
  value: PropTypes.any,
};

export default Field;
