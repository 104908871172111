import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { BASE_URL } from '../../utils/url';
import axios from '../../utils/request';
import { getUser } from '../../utils/localStorage';

interface params {
  limit: number;
  offset: number;
}
export interface paramsById {
  id: string;
}
export interface requestData {
  name: string;
  length: number;
  format: string;
  duration: number;
  productName: string;
  defaultLanguageCode: string;
  isAllowMultipleActive: boolean;
}

export interface requestDataUpdate {
  id: string;
  name: string;
  isActive: Boolean;
}

export interface requestDataEdit {
  id: string;
  name: string;
  length: number;
  format: string;
  duration: number;
  productName: string;
  defaultLanguageCode: string;
  isAllowMultipleActive: boolean;
}

export interface updateHandler {
  companyPhoneId: string;
  isSet: Boolean;
}

interface errorRequest {
  message: string;
}

const initialState = {
  list: [],
  responseApi: {},
};

const userHandler = (companyPhoneId: string, isSet: Boolean) => {
  const { token } = getUser();

  return axios.post(
    `${BASE_URL}/otp/handler/my`,
    {
      companyPhoneId,
      isSet,
    },
    {
      headers: {
        Authorization: `Bearer ${token as string}`,
      },
    }
  );
};

export const changeUserHandler = createAsyncThunk(
  'module/changeUserHandler',
  async ({ companyPhoneId, isSet }: updateHandler, thunkAPI) => {
    try {
      const data = await userHandler(companyPhoneId, isSet);
      thunkAPI.fulfillWithValue(data.data);
      return { responseApi: data?.data };
    } catch (error) {
      const err = error as AxiosError;
      return thunkAPI.rejectWithValue((err.response?.data as errorRequest).message);
    }
  }
);

const defaultHandler = (companyPhoneId: string, isSet: Boolean) => {
  const { token } = getUser();

  return axios.post(
    `${BASE_URL}/otp/default-handler/my`,
    {
      companyPhoneId,
      isSet,
    },
    {
      headers: {
        Authorization: `Bearer ${token as string}`,
      },
    }
  );
};

export const changeDefaultHandler = createAsyncThunk(
  'module/changeDefaultHandler',
  async ({ companyPhoneId, isSet }: updateHandler, thunkAPI) => {
    try {
      const data = await defaultHandler(companyPhoneId, isSet);
      thunkAPI.fulfillWithValue(data.data);
      return { responseApi: data?.data };
    } catch (error) {
      const err = error as AxiosError;
      return thunkAPI.rejectWithValue((err.response?.data as errorRequest).message);
    }
  }
);

const createConfig = (
  name: string,
  length: number,
  format: string,
  duration: number,
  productName: string,
  defaultLanguageCode: string,
  isAllowMultipleActive: boolean
) => {
  const { token } = getUser();

  return axios.post(
    `${BASE_URL}/otp/config/my`,
    {
      name,
      length,
      format,
      duration,
      productName,
      defaultLanguageCode,
      isAllowMultipleActive,
    },
    {
      headers: {
        Authorization: `Bearer ${token as string}`,
      },
    }
  );
};

export const createService = createAsyncThunk(
  'module/createOtpConfig',
  async (
    {
      name,
      length,
      format,
      duration,
      productName,
      defaultLanguageCode,
      isAllowMultipleActive,
    }: requestData,
    thunkAPI
  ) => {
    try {
      const data = await createConfig(
        name,
        length,
        format,
        duration,
        productName,
        defaultLanguageCode,
        isAllowMultipleActive
      );
      thunkAPI.fulfillWithValue(data.data);
      return { responseApi: data?.data };
    } catch (error) {
      const err = error as AxiosError;
      return thunkAPI.rejectWithValue((err.response?.data as errorRequest).message);
    }
  }
);

const editConfig = (
  id: string,
  name: string,
  length: number,
  format: string,
  duration: number,
  productName: string,
  defaultLanguageCode: string,
  isAllowMultipleActive: boolean
) => {
  const { token } = getUser();

  return axios.patch(
    `${BASE_URL}/otp/config/my/${id}`,
    {
      name,
      length,
      format,
      duration,
      productName,
      defaultLanguageCode,
      isAllowMultipleActive,
    },
    {
      headers: {
        Authorization: `Bearer ${token as string}`,
      },
    }
  );
};

export const editService = createAsyncThunk(
  'module/editOtpConfig',
  async (
    {
      id,
      name,
      length,
      format,
      duration,
      productName,
      defaultLanguageCode,
      isAllowMultipleActive,
    }: requestDataEdit,
    thunkAPI
  ) => {
    try {
      const data = await editConfig(
        id,
        name,
        length,
        format,
        duration,
        productName,
        defaultLanguageCode,
        isAllowMultipleActive
      );
      thunkAPI.fulfillWithValue(data.data);
      return { responseApi: data?.data };
    } catch (error) {
      const err = error as AxiosError;
      return thunkAPI.rejectWithValue((err.response?.data as errorRequest).message);
    }
  }
);

const updateConfig = (id: string, name: string, isActive: Boolean) => {
  const { token } = getUser();

  return axios.patch(
    `${BASE_URL}/otp/config/my/${id}`,
    {
      name,
      isActive,
    },
    {
      headers: {
        Authorization: `Bearer ${token as string}`,
      },
    }
  );
};

export const updateService = createAsyncThunk(
  'module/updateOtpConfig',
  async ({ id, name, isActive }: requestDataUpdate, thunkAPI) => {
    try {
      const data = await updateConfig(id, name, isActive);
      thunkAPI.fulfillWithValue(data.data);
      return { responseApi: data?.data };
    } catch (error) {
      const err = error as AxiosError;
      return thunkAPI.rejectWithValue((err.response?.data as errorRequest).message);
    }
  }
);

const getOtpConfig = (limit: number, offset: number) => {
  const { token } = getUser();
  return axios.get(`${BASE_URL}/otp/config/my?limit=${limit}&offset=${offset}`, {
    headers: {
      Authorization: `Bearer ${token as string}`,
    },
  });
};

export const get = createAsyncThunk(
  'module/getOtpConfig',
  async ({ limit, offset }: params, thunkAPI) => {
    try {
      const data = await getOtpConfig(limit, offset);
      // // console.log(data);
      thunkAPI.fulfillWithValue(data.data);
      return { listToken: data?.data };
    } catch (error) {
      const err = error as AxiosError;
      // // console.log(err);
      return thunkAPI.rejectWithValue((err.response?.data as errorRequest).message);
    }
  }
);
const getById = (id: string) => {
  const { token } = getUser();
  return axios.get(`${BASE_URL}/otp/config/my/${id}`, {
    headers: {
      Authorization: `Bearer ${token as string}`,
    },
  });
};

export const getServiceById = createAsyncThunk(
  'module/getOtpTemplateId',
  async ({ id }: paramsById, thunkAPI) => {
    try {
      const data = await getById(id);
      // // console.log(data);
      thunkAPI.fulfillWithValue(data.data);
      return { responseApi: data?.data };
    } catch (error) {
      const err = error as AxiosError;
      // // console.log(err);
      return thunkAPI.rejectWithValue((err.response?.data as errorRequest).message);
    }
  }
);
const deleteOtpConfig = (id: string) => {
  const { token } = getUser();
  return axios.delete(`${BASE_URL}/otp/config/my/${id}`, {
    headers: {
      Authorization: `Bearer ${token as string}`,
    },
  });
};

export const deleteService = createAsyncThunk(
  'module/deleteOtpConfig',
  async ({ id }: paramsById, thunkAPI) => {
    try {
      const data = await deleteOtpConfig(id);
      thunkAPI.fulfillWithValue(data.data);
      return { responseApi: data?.data };
    } catch (error) {
      const err = error as AxiosError;
      // // console.log(err);
      return thunkAPI.rejectWithValue((err.response?.data as errorRequest).message);
    }
  }
);

const otpConfig = createSlice({
  name: 'otpConfig',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(changeUserHandler.fulfilled, (state, action) => {
      state.responseApi = action.payload.responseApi;
    });
    builder.addCase(changeUserHandler.rejected, (state, action) => {
      state.responseApi = {};
    });
    builder.addCase(changeDefaultHandler.fulfilled, (state, action) => {
      state.responseApi = action.payload.responseApi;
    });
    builder.addCase(changeDefaultHandler.rejected, (state, action) => {
      state.responseApi = {};
    });
    builder.addCase(createService.fulfilled, (state, action) => {
      state.responseApi = action.payload.responseApi;
    });
    builder.addCase(createService.rejected, (state, action) => {
      state.responseApi = {};
    });
    builder.addCase(editService.fulfilled, (state, action) => {
      state.responseApi = action.payload.responseApi;
    });
    builder.addCase(editService.rejected, (state, action) => {
      state.responseApi = {};
    });
    builder.addCase(updateService.fulfilled, (state, action) => {
      state.responseApi = action.payload.responseApi;
    });
    builder.addCase(updateService.rejected, (state, action) => {
      state.responseApi = {};
    });

    builder.addCase(deleteService.fulfilled, (state, action) => {
      state.responseApi = action.payload.responseApi;
    });
    builder.addCase(deleteService.rejected, (state, action) => {
      state.responseApi = {};
    });
    builder.addCase(get.fulfilled, (state, action) => {
      state.list = action.payload.listToken;
      // state.isLoggedIn = true;
      // state.user = action.payload.user;
    });
    builder.addCase(get.rejected, (state, action) => {
      state.list = [];

      // state.isLoggedIn = false;
      // state.user = null;
    });
    builder.addCase(getServiceById.fulfilled, (state, action) => {
      state.responseApi = action.payload.responseApi;
    });
    builder.addCase(getServiceById.rejected, (state, action) => {
      state.responseApi = {};
    });
  },
});

const { reducer } = otpConfig;
export default reducer;
