import { Button, Divider, Grid, Paper, Dialog } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import 'react-phone-input-2/lib/material.css';
// import { useNavigate } from 'react-router-dom';

// redux
import { get } from '../../redux/modules/company';
import { AppDispatch } from '../../redux/store';

// components
import { ToastManagerContext } from '../../components/ToastManager';
import Breadcrumbs from '../../components/Breadcrumbs';
import Container from '../../components/Container';
import Field from '../../components/Fields';
import EmptyState from '../../components/EmptyState';
import Link from '../../components/Link';
import DashboardHeader from '../../components/DashboardHeader';

// icons
import { PlusSqfr, Pencil, Eye } from '@xcidic/icons';

// coachmark
import { useMount } from 'react-use';
import { useAppContext } from '../FTU/context';

interface objectState {
  id: string;
  countryCode: string;
  number: string;
  waPhoneId: string;
  waApiToken: string;
  waBusinessAccountId: string;
}

interface requestData {
  name: String;
  phones: objectState[];
}
export default function index() {
  const { create } = useContext(ToastManagerContext);
  const dispatch = useDispatch<AppDispatch>();
  const [data, setData] = useState<requestData>({
    name: '',
    phones: [
      {
        countryCode: '',
        number: '',
        waPhoneId: '',
        waApiToken: '',
        waBusinessAccountId: '',
        id: '',
      },
    ],
  });
  const [token, setIsToken] = useState(false);
  // const navigate = useNavigate();

  useEffect(() => {
    dispatch(get())
      .unwrap()
      .then((res) => {
        if (res.list.data.length > 0) {
          const dataAPI = res.list.data[0];
          setData(dataAPI);
        }
        // console.log(res.list)
        // create('success', 'Success', res.list.message);
      })
      .catch((err) => {
        create('error', 'Error', err);
      });
  }, []);

  const {
    setState,
    state: { tourActive },
  } = useAppContext();

  useMount(() => {
    if (tourActive) {
      setTimeout(() => {
        setState({ run: true, stepIndex: 2 });
      }, 100);
    }
  });

  return (
    <Container>
      <Breadcrumbs
        items={[
          {
            label: 'Business Info',
            link: '/company',
          },
          {
            label: 'Company',
            link: '/company',
          },
        ]}
      />
      <DashboardHeader title="Company Detail">
        <div style={{ display: 'flex' }} id="create-company">
          {data?.name !== '' && (
            <Button
              name="company"
              variant="outlined"
              color="primary"
              startIcon={<Eye />}
              style={{ marginRight: 10 }}
              onClick={() => setIsToken(true)}>
              Show WhatsApp Token
            </Button>
          )}
          <Button
            variant="contained"
            startIcon={data?.name !== '' ? <Pencil /> : <PlusSqfr />}
            LinkComponent={Link}
            href={data?.name !== '' ? '/company/edit' : '/company/create'}>
            {data?.name !== '' ? `Update Company` : `Add Company`}
          </Button>
        </div>
      </DashboardHeader>
      <Paper>
        {data?.name === '' ? (
          <div>
            <EmptyState module="Company" button="Add Company" />
          </div>
        ) : (
          <div>
            <Field withElement={false} small={false} label="Name" value={data?.name} />
            <Divider />
            <Field
              withElement={false}
              small={false}
              label="Handler Id"
              value={data?.phones[0].id}
            />
            <Divider />
            <Field
              withElement={false}
              small={false}
              label="Whatsapp Number"
              value={data?.phones[0].countryCode + data?.phones[0].number}
            />
            <Divider />
            <Field
              withElement={false}
              small={false}
              label="Whatsapp Business Account Id"
              value={data?.phones[0].waBusinessAccountId}
            />

            <Divider />
            <Grid
              item
              lg={12}
              style={{ justifyContent: 'flex-end', display: 'flex', marginTop: 10 }}>
              {/* <Button
                name="company"
                variant="outlined"
                color="primary"
                // style={{ marginRight: 10 }}
                onClick={() => setIsToken(true)}>
                Show WhatsApp Token
              </Button> */}
              {/* <Button
                name="company"
                variant="outlined"
                color="primary"
                onClick={() =>
                  data?.name !== '' ? navigate('/company/edit') : navigate('/company/create')
                }>
                {data?.name !== '' ? `Update Company` : `Create Company`}
              </Button> */}
            </Grid>
          </div>
        )}
        {/* <Grid container spacing={3}> */}

        <Dialog open={token} onClose={() => setIsToken(false)}>
          <Field
            withElement={false}
            small={false}
            label="Whatsapp Business Api Token"
            value={data?.phones[0].waApiToken}
          />
          <Divider />
        </Dialog>
        {/* </Grid> */}
      </Paper>
    </Container>
  );
}
