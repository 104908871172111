/* eslint-disable */
import { Box, Divider, Grid, IconButton, styled, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useDispatch } from 'react-redux';

import { ChevronLeftSqfr, ChevronRightSqfr } from '@xcidic/icons';
import { ApexOptions } from 'apexcharts';
import ProgressBar from './ProgressBar';
import { getService } from '../../../redux/modules/statistic';
import { AppDispatch } from '../../../redux/store';

interface propsModal {
  range: string;
}

interface data {
  read: number;
  unread: number;
  count: number;
  countRead: number;
  countUnread: number;
}
interface responseApi {
  blast: data;
  otp: data;
  reply: data;
}

interface dataCard {
  read: number;
  unread: number;
  count: number;
  type: string;
  countRead: number;
  countUnread: number;
}

const Read = styled(Box)(({ theme }) => ({
  marginTop: 20,
  borderRight: 'solid 1px #E2E2E2',
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '5px',
    // right: '3px',
    width: 12,
    height: 12,
    borderRadius: '50%',
    backgroundColor: '#276EF1',
    border: 'solid 1px white',
  },
}));

const Unread = styled(Box)(({ theme }) => ({
  marginTop: 20,
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '5px',
    // right: '3px',
    width: 12,
    height: 12,
    borderRadius: '50%',
    backgroundColor: '#C2D5F9',
    border: 'solid 1px white',
  },
}));

const Card = ({ read, unread, count, type, countRead, countUnread }: dataCard) => {
  return (
    <div style={{ width: '100%' }}>
      <Typography
        style={{
          fontWeight: 300,
          fontStyle: 'normal',
          fontSize: '14px',
          color: '#5d5d5d',
          marginBottom: 10,
        }}>
        <strong>{count}</strong> {type}
      </Typography>
      <ProgressBar
        read={String(read)}
        unread={String(unread)}
        countRead={String(countRead)}
        countUnread={String(countUnread)}
        bgColorRead={'#276EF1'}
        bgColorUnread={'#C2D5F9'}
      />
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Read>
            <Grid container style={{ justifyContent: 'space-between', paddingRight: 10 }}>
              <Typography
                style={{
                  fontWeight: 300,
                  fontStyle: 'normal',
                  fontSize: '14px',
                  color: '#5d5d5d',
                  marginLeft: 14,
                }}>
                Read
              </Typography>
              <Typography component={'h4'}>{`${read.toFixed(0)}%`}</Typography>
            </Grid>
          </Read>
        </Grid>
        <Grid item xs={6}>
          <Unread>
            <Grid container style={{ justifyContent: 'space-between', paddingRight: 10 }}>
              <Typography
                style={{
                  fontWeight: 300,
                  fontStyle: 'normal',
                  fontSize: '14px',
                  color: '#5d5d5d',
                  marginLeft: 14,
                }}>
                Unread
              </Typography>
              <Typography component={'h4'}>{`${unread.toFixed(0)}%`}</Typography>
            </Grid>
          </Unread>
        </Grid>
      </Grid>
    </div>
  );
};

const AverageMessagesRead = ({ range }: propsModal) => {
  const dispatch = useDispatch<AppDispatch>();
  const [dataApi, setDataApi] = useState<responseApi>();
  useEffect(() => {
    dispatch(getService({ url: 'average-message-read', type: `range=${range}` }))
      .unwrap()
      .then((res) => {
        // console.log(res.responseApi);
        setDataApi(res.responseApi.data);
      })
      .catch(() => {
        // create('error', 'Error', err);
      });
  }, [range]);

  // console.log(totalCost, 'seris donut');
  return (
    <Box style={{ paddingTop: 20 }}>
      <Grid container spacing={3}>
        <Grid
          item
          xs={4}
          style={{
            borderRight: 'solid 1px #E2E2E2',
            paddingRight: 10,
          }}>
          <Card
            countRead={dataApi?.otp?.countRead || 0}
            countUnread={dataApi?.otp?.countUnread || 0}
            read={dataApi?.otp?.read || 0}
            unread={dataApi?.otp?.unread || 0}
            count={dataApi?.otp.count || 0}
            type={'OTP Messages'}
          />
        </Grid>
        <Grid
          item
          xs={4}
          style={{
            borderRight: 'solid 1px #E2E2E2',
            paddingRight: 10,
          }}>
          <Card
            countRead={dataApi?.blast?.countRead || 0}
            countUnread={dataApi?.blast?.countUnread || 0}
            read={dataApi?.blast?.read || 0}
            unread={dataApi?.blast?.unread || 0}
            count={dataApi?.blast.count || 0}
            type={'Blast Messages'}
          />
        </Grid>
        <Grid
          item
          xs={4}
          style={{
            paddingRight: 10,
          }}>
          <Card
            countRead={dataApi?.reply?.countRead || 0}
            countUnread={dataApi?.reply?.countUnread || 0}
            read={dataApi?.reply?.read || 0}
            unread={dataApi?.reply?.unread || 0}
            count={dataApi?.reply.count || 0}
            type={'Auto Reply Messages'}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AverageMessagesRead;
