import { Breakpoint } from '@mui/material';
import { CSSProperties } from '@mui/material/styles/createTypography';
import breakpoints from '../theme/breakpoints';

export type ResponsiveStyle = { [key in Breakpoint]?: CSSProperties };

const responsiveStyle = (styles: ResponsiveStyle) => {
  const bp = breakpoints.values;

  if (bp === undefined) return {};

  const bpKeys = Object.keys(bp) as Breakpoint[];
  const initalStyles: CSSProperties = {};
  const cssStyles: CSSProperties = bpKeys.reduce(
    (obj, key) => ({ ...obj, [`@media (min-width: ${bp[key]}px)`]: styles[key] }),
    initalStyles
  );

  return cssStyles;
};

export default responsiveStyle;
