import Guide from '../Guide';
import { Step } from 'react-joyride';

const otpGuideSteps: Step[] = [
  {
    target: '#otp',
    content: (
      <Guide
        title="Create an OTP"
        content="Integrate and send your OTP messages with this feature."
        step="1"
        totalSteps="7"
      />
    ),
    disableBeacon: true,
    offset: 25,
    placement: 'bottom-start',
  },
  {
    target: '#otp-list',
    content: (
      <Guide
        title="Create an OTP"
        content="To create an OTP, you have to create configuration, template, then integrate you OTP code."
        step="2"
        totalSteps="7"
      />
    ),
    disableBeacon: true,
    offset: 25,
    placement: 'bottom-start',
  },
  {
    target: '#otp-config',
    content: (
      <Guide
        title="Create an OTP"
        content="Click here to open “Configuration” menu and find “Add Configuration” button."
        step="3"
        totalSteps="7"
      />
    ),
    disableBeacon: true,
    offset: 25,
    placement: 'bottom-start',
  },
  {
    target: '#otp-add-config',
    content: (
      <Guide
        title="Create an OTP"
        content="Click here to create configuration, this configuration act as rule for your OTP."
        step="4"
        totalSteps="7"
      />
    ),
    disableBeacon: true,
    offset: 25,
    placement: 'bottom-start',
  },
  {
    target: '#otp-template',
    content: (
      <Guide
        title="Create an OTP"
        content="Click here to open “Template” menu and find “Add Template” button."
        step="5"
        totalSteps="7"
      />
    ),
    disableBeacon: true,
    offset: 25,
    placement: 'bottom-start',
  },
  {
    target: '#otp-add-template',
    content: (
      <Guide
        title="Create an OTP"
        content="Click here to create template for your OTP. This template message will be sent to your costumer."
        step="6"
        totalSteps="7"
      />
    ),
    disableBeacon: true,
    offset: 25,
    placement: 'bottom-start',
  },
  {
    target: '#otp-integrated-guide',
    content: (
      <Guide
        title="Create an OTP"
        content="Click here to open “Integrated Guide” menu to integrate your OTP by choosing the program language and copy the code."
        step="7"
        totalSteps="7"
      />
    ),
    disableBeacon: true,
    offset: 25,
    placement: 'bottom-start',
  },
];

export default otpGuideSteps;
