import { Theme, ThemeOptions } from '@mui/material';
import { pxToRem } from '../typography';

export default function Tabs(theme: Theme): ThemeOptions['components'] {
  return {
    MuiTabs: {
      styleOverrides: {
        root: {
          '.MuiTab-textColorPrimary': {
            padding: 0,
            color: theme.palette.neutral.gray,
            fontSize: pxToRem(14),
            fontWeight: 500,
            textTransform: 'capitalize',
          },
        },
      },
    },
    MuiTab: {
      defaultProps: {
        iconPosition: 'start',
      },
    },
  };
}
