import React from 'react';
import { styled, Box, Typography, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import Pagination, { PaginationProps } from '../Pagination';
// import TextField from '../TextField';

export interface TablePaginationProps extends PaginationProps {
  count: number;
  page: number;
  rowsPerPage?: number;
  rowsPerPageOptions?: number[];
  onPageChange?: (event: unknown, newPage: number) => void;
  onRowsPerPageChange?: (event: SelectChangeEvent<number>, child?: React.ReactNode) => void;
}

const StyledSelect = styled(Select<number>)(({ theme }) => ({
  color: '#afafaf',
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiOutlinedInput-input': {
    fontSize: 12,
  },
}));

const SelectRowPerPageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 1,
  border: `solid 1px ${theme.palette.neutral.light}`,
  borderRadius: 6,
  padding: '0 7.2px',
  height: 41.4,
}));

// ---------------------------------------------------------------------------------

const TablePagination = (props: TablePaginationProps) => {
  const {
    rowsPerPage = 10,
    rowsPerPageOptions = [10, 25, 50, 100],
    count,
    onRowsPerPageChange,
    onPageChange,
    page,
  } = props;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <SelectRowPerPageContainer>
        <Typography variant="b3" color="neutral.main">
          Showing :{' '}
        </Typography>
        <StyledSelect value={rowsPerPage} onChange={onRowsPerPageChange}>
          {rowsPerPageOptions.map((num) => (
            <MenuItem key={num} value={num}>
              {num}
            </MenuItem>
          ))}
        </StyledSelect>
        <Typography variant="b3" color="neutral.main">
          of {count}
        </Typography>
      </SelectRowPerPageContainer>
      <Pagination count={Math.ceil(count / rowsPerPage)} page={page} onChange={onPageChange} />
    </Box>
  );
};

export default TablePagination;
