// import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Typography, Button } from '@mui/material';

export default function index() {
  const navigate = useNavigate();

  return (
    <div className="container">
      <div>
        <div style={{ justifyContent: 'center', display: 'flex', width: '100%' }}>
          <Box component="img" src={`/500.svg`} />
        </div>
        <div style={{ textAlign: 'center' }}>
          <h2>Sorry.. We don&apos;t expect this to happen..</h2>
          <Typography>
            We are fixing an internal server problem right now. Please come back later
          </Typography>
          <Button
            color="primary"
            variant="contained"
            style={{ marginTop: 20 }}
            onClick={() => navigate(-1)}>
            Go Back
          </Button>
        </div>
      </div>
    </div>
  );
}
