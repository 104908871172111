import { Autocomplete, Button, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import {
  editInitialValues as initialValues,
  editValidationSchema as validationSchema,
} from './schemeValidation';
import Confirm from '../../../components/Confirm';
import { ToastManagerContext } from '../../../components/ToastManager';
import { getService } from '../../../redux/modules/category';
import { editService, requestDataEdit, getServiceById } from '../../../redux/modules/contact';
import { AppDispatch } from '../../../redux/store';
import Dialog from '../../../components/Dialog';

// import { date } from 'yup/lib/locale';
// type CloseReason = 'backdropClick' | 'escapeKeyDown' | 'closeButtonClick';
interface ObjectPropsData {
  name: string;
  id: string;
}

interface userObject {
  name: String;
  countryCode: string;
  number: string;
  categories: ObjectPropsData[];
}
interface PropsModal {
  open: boolean;
  // onClose: (reason: CloseReason) => void;
  idProps?: string;
  onClose: Function;
  content?: userObject;
}

interface categories {
  id: string;
}

export default function index({ open, onClose, content, idProps }: PropsModal) {
  const { create } = useContext(ToastManagerContext);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams();
  const [list, setList] = useState<ObjectPropsData[]>();
  const [isConfirm, setIsConfirm] = useState(false);

  const {
    handleChange,
    handleBlur,
    // setSubmitting,
    handleSubmit,
    setFieldValue,
    dirty,
    resetForm,
    values,
    isValid,
    touched,
    errors,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async () => {
      setIsLoading(true);
      const useId = idProps ?? id;

      try {
        const data: requestDataEdit = {
          id: useId ?? '',
          name: values.name,
          number: values.number?.replace(values.countryCode, ''),
          countryCode: values.countryCode,
          categories: values?.categories?.map((val: categories) => ({
            id: val?.id,
          })),
        };
        dispatch(editService(data))
          .unwrap()
          .then((res) => {
            setIsLoading(false);

            create('success', 'Success', res.responseApi.message);
            resetForm();
            onClose();
          })
          .catch((err) => {
            setIsLoading(false);

            create('error', 'Error', err);
          });
      } catch (error) {
        // handle any rejections/errors
      }
    },
  });

  useEffect(() => {
    dispatch(getService({ limit: 1000, offset: 0 }))
      .unwrap()
      .then((res) => {
        setList(res.listToken?.data);
        // create('success', 'Success', res.listToken.message);
      })
      .catch((err) => {
        // console.log(err)
        create('error', 'Error', err);
      });
    if (content === undefined) {
      // console.log('zero');
      dispatch(getServiceById({ id: idProps ?? '' }))
        .unwrap()
        .then((res) => {
          // setUser(res?.responseApi?.data);
          // setList(res.responseApi?.data?.categories);
          // console.log(res.responseApi, 'modal');
          const contentRes = res.responseApi.data as userObject;
          // setTotal(res.responseApi?.data.meta);
          setFieldValue('name', contentRes?.name);
          setFieldValue('number', contentRes?.countryCode + contentRes?.number);
          setFieldValue('countryCode', contentRes?.countryCode);
          setFieldValue('categories', contentRes?.categories);
          // create('success', 'Success', res.responseApi.message);
        })
        .catch((err) => {
          // console.log(err)
          create('error', 'Error', err);
        });
    } else {
      setFieldValue('name', content?.name);
      setFieldValue('number', content?.countryCode + content?.number);
      setFieldValue('countryCode', content?.countryCode);
      setFieldValue('categories', content?.categories);
    }
  }, [open]);

  const handleChangePhoneNumber = (value: string, country: CountryData) => {
    // const { setFieldValue } = this.props;
    if (country?.dialCode !== values?.countryCode) {
      // console.log;
      setFieldValue('number', country?.dialCode);
      setFieldValue('countryCode', country?.dialCode);
    } else {
      setFieldValue('number', value);
      setFieldValue('countryCode', country?.dialCode);
    }
    // setFieldValue('number', value);
    // setFieldValue('countryCode', country?.dialCode);
  };
  return (
    <Dialog open={open} onClose={() => onClose()}>
      <Typography variant="h3" color="neutral.black" fontWeight="700" mb={2}>
        Edit Contact
      </Typography>
      <Grid container rowSpacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            // autoFocus
            name="name"
            variant="outlined"
            // type="email"
            label="Customer Name *"
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
            value={values.name ?? ''}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
            className="mt-0 mb-4"
            size="medium"
          />
        </Grid>
        <Grid item xs={12}>
          <PhoneInput
            inputStyle={{ width: '100%' }}
            // style={{ marginBottom: 10, marginTop: 10 }}
            containerClass="phoneInput"
            // name="phone"
            // type="number"
            specialLabel="Customer WA Number *"
            // variant="outlined"
            // size="small"
            // margin="normal"
            value={values.number}
            onChange={handleChangePhoneNumber}
            onBlur={handleBlur}
            // error={Boolean(touched.waNumber && errors.waNumber)}
            // helperText={touched.waNumber && errors.waNumber}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            id="tags-outlined"
            multiple
            onChange={(e, val) => {
              // setSelected(val !== null ? val : []);
              // console.log(val.map((val) => val._id));
              // console.log(val)
              setFieldValue('categories', val !== null ? val : []);
            }}
            value={values?.categories ?? []}
            options={list ?? []}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Contact Categories"
                // placeholder="Contact Categories"
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Button
            name="company"
            variant="contained"
            color="primary"
            fullWidth
            disabled={!(dirty && isValid && values.countryCode !== values.number)}
            onClick={() => setIsConfirm(true)}>
            {isLoading ? (
              <CircularProgress style={{ color: 'white' }} size={30} />
            ) : (
              <span className="loginButtonCaption">Save Change</span>
            )}
          </Button>
          <Confirm
            type={'Create'}
            open={isConfirm}
            handleAksi={() => {
              setIsConfirm(false);
              handleSubmit();
            }}
            loading={false}
            handleClose={() => setIsConfirm(false)}
            disable={false}
            handleContent={{
              title: 'Edit Contact',
              message: 'Do you wish to Edit this Contact?',
              button: 'Submit',
            }}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
}
