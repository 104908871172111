import { Tooltip } from '@mui/material';
import './style.css';
interface props {
  bgColorRead: string;
  bgColorUnread: string;
  read: string;
  unread: string;
  countRead: string;
  countUnread: string;
}

const ProgressBar = ({
  bgColorRead,
  bgColorUnread,
  read,
  unread,
  countRead,
  countUnread,
}: props) => {
  return (
    <div className="multicolor-bar">
      <div className="bars">
        {Number(unread) <= 0 && Number(read) <= 0 && (
          <div className="bar" style={{ backgroundColor: '#E2E2E2', width: '100%' }} />

          // <Tooltip
          //   placement="top"
          //   title="You could get WhatsApp business account ID, phone ID, and API token from meta business account">
          // </Tooltip>
        )}
        {Number(read) > 0 && (
          <Tooltip placement="top" arrow title={`${countRead} messages`}>
            <div className="bar" style={{ backgroundColor: bgColorRead, width: read + '%' }} />
          </Tooltip>
        )}
        {Number(unread) > 0 && (
          <Tooltip placement="top" arrow title={`${countUnread} messages`}>
            <div className="bar" style={{ backgroundColor: bgColorUnread, width: unread + '%' }} />
          </Tooltip>
        )}
      </div>
    </div>
  );
};
export default ProgressBar;
