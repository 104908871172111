/* eslint-disable */
import { Box, Divider, Grid, IconButton, styled, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useDispatch } from 'react-redux';

import { ChevronLeftSqfr, ChevronRightSqfr } from '@xcidic/icons';
import { ApexOptions } from 'apexcharts';
import { getService } from '../../../redux/modules/statistic';
import { AppDispatch } from '../../../redux/store';
import './Tooltip.css';

interface propsModal {
  range: string;
}

const OTP = styled(Box)(({ theme }) => ({
  marginTop: 20,
  marginRight: 30,
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '5px',
    // right: '3px',
    width: 12,
    height: 12,
    borderRadius: '50%',
    backgroundColor: '#276EF1',
    border: 'solid 1px white',
  },
}));

const MessageBlast = styled(Box)(({ theme }) => ({
  position: 'relative',
  marginTop: 20,
  marginRight: 30,
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '5px',
    // right: '3px',
    width: 12,
    height: 12,
    borderRadius: '50%',
    backgroundColor: '#6F9EF6',
    border: 'solid 1px white',
  },
}));
//
const Reply = styled(Box)(({ theme }) => ({
  position: 'relative',
  marginTop: 20,
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '5px',
    // right: '3px',
    width: 12,
    height: 12,
    borderRadius: '50%',
    backgroundColor: '#C2D5F9',
    border: 'solid 1px white',
  },
}));

interface apiData {
  countAllService: number;
  countBlast: number;
  countOtp: number;
  countReply: number;
  persentaceBlast: string;
  persentaceOtp: string;
  persentaceReply: string;
}
const Usage = ({ range }: propsModal) => {
  // const [update, setUpdate] = useState(false);
  const [key, setKey] = useState(0);
  // const forceUpdate = useForceUpdate();
  // eslint-disable-next-line
  const [seriesDonut, setSeriesDonut] = useState<ApexNonAxisChartSeries>([0, 0, 0]);
  // const [optionsDonut, setOptionDonut] = useState<ApexOptions | undefined>();
  const [page, setPage] = useState(0);

  const [limit, setLimit] = useState(7);

  const [totalUsage, setTotalUsage] = useState(0);
  const [dataApi, setDataApi] = useState<apiData>();

  // const [percent, setPercent] = useState(0);
  // const [status, setStatus] = useState('');

  // const [labels,setLabel] = useState([])

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getService({ url: 'usage-service', type: `range=${range}` }))
      .unwrap()
      .then((res) => {
        // console.log(res);
        setDataApi(res.responseApi.data);
        setTotalUsage(res.responseApi.data.countAllService);
        setSeriesDonut([
          res.responseApi.data.countBlast,
          res.responseApi.data.countOtp,
          res.responseApi.data.countReply,
        ]);
        setKey(key + 1);
      })
      .catch(() => {
        // create('error', 'Error', err);
      });
  }, [range, page]);

  // console.log(totalCost, 'seris donut');
  return (
    <Box>
      <ReactApexChart
        type={'donut'}
        // width={'106px'}
        key={key}
        width={'100%'}
        // height={'100%'}
        series={seriesDonut?.findIndex((val: any) => val > 0) !== -1 ? seriesDonut : [1000]}
        options={{
          labels:
            seriesDonut?.findIndex((val: number) => val > 0) !== -1
              ? ['Message Blast', 'OTP', 'Auto Reply']
              : ['dummy'],
          chart: {
            // id: 'message-sent',
            // width: '200px',
            toolbar: { show: false },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,

                  total: {
                    showAlways: true,
                    show: true,
                    label: '',
                    formatter: (w) => {
                      return `${totalUsage}`;
                    },
                  },
                },
              },
            },
          },
          tooltip: {
            enabled: seriesDonut?.findIndex((val: number) => val > 0) !== -1,
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
              console.log(series, seriesIndex, dataPointIndex, w);
              const percentage =
                w.config.labels[seriesIndex] === 'OTP'
                  ? dataApi?.persentaceOtp
                  : w.config.labels[seriesIndex] === 'Message Blast'
                  ? dataApi?.persentaceBlast
                  : dataApi?.persentaceReply;
              // const cost =
              //   w.config.labels[seriesIndex] === 'Reguler Message'
              //     ? dataApi?.totalCostReguler
              //     : dataApi?.totalCostShared;
              // console.log(w.config.labels[dataPointIndex]);
              return (
                '<div class="arrow_box">' +
                '<span>' +
                '<p class="percentageText">' +
                percentage +
                '% ' +
                w.config.labels[seriesIndex] +
                '</p>' +
                series[seriesIndex] +
                ' Messages' +
                // '<h4>' +
                // 'IDR ' +
                // // cost +
                // '</h4>' +
                '</span>' +
                '</div>'
              );
            },
          },
          dataLabels: {
            enabled: false,
          },
          colors:
            seriesDonut?.findIndex((val: number) => val > 0) !== -1
              ? ['#6F9EF6', '#276EF1', '#C2D5F9']
              : ['#E2E2E2'],
          legend: {
            show: false,
          },
        }}
      />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <OTP>
          <Typography
            style={{
              fontWeight: 300,
              fontStyle: 'normal',
              fontSize: '14px',
              color: '#5d5d5d',
              marginLeft: 14,
            }}>
            OTP
          </Typography>
        </OTP>
        <MessageBlast>
          <Typography
            style={{
              fontWeight: 300,
              fontStyle: 'normal',
              fontSize: '14px',
              color: '#5d5d5d',
              marginLeft: 14,
            }}>
            Message Blast
          </Typography>
        </MessageBlast>
        <Reply>
          <Typography
            style={{
              fontWeight: 300,
              fontStyle: 'normal',
              fontSize: '14px',
              color: '#5d5d5d',
              marginLeft: 14,
            }}>
            Auto Reply
          </Typography>
        </Reply>
      </div>
      <Grid container>
        <Grid item lg={12}></Grid>
      </Grid>
    </Box>
  );
};

export default Usage;
