import {
  Button,
  CircularProgress,
  InputAdornment,
  Grid,
  Card,
  Typography,
  Box,
  useTheme,
  Stack,
} from '@mui/material';

import { useFormik } from 'formik';

import { useContext, useState } from 'react';
import { Coins2 } from '@xcidic/icons';
import { useDispatch } from 'react-redux';
import { number, object } from 'yup';
import Confirm from '../../../components/Confirm';
import Dialog from '../../../components/Dialog';
import TextField from '../../../components/TextField';
import { ToastManagerContext } from '../../../components/ToastManager';
import { createService, requestData } from '../../../redux/modules/xendit';
import { AppDispatch } from '../../../redux/store';

interface PropsModal {
  open: boolean;
  onClose: Function;
}

export const initialValues = {
  amount: '',
};

export const validationSchema = object({
  amount: number().min(50000, 'Amount cant be less than 50000').required('Amount Cant be null'),
});

const amountOptions = [50000, 100000, 200000, 300000, 500000, 1000000];

export default function index({ open, onClose }: PropsModal) {
  const { palette } = useTheme();
  const { create } = useContext(ToastManagerContext);
  const [amountState, setAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);

  const dispatch = useDispatch<AppDispatch>();
  const {
    handleBlur,
    handleSubmit,
    setFieldValue,
    dirty,
    resetForm,
    values,
    isValid,
    touched,
    errors,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async () => {
      setIsLoading(true);
      try {
        const data: requestData = {
          amount: Number(values.amount),
        };
        dispatch(createService(data))
          .unwrap()
          .then((res) => {
            window.open(res.responseApi.data.invoice_url ?? '', '_blank');
            setIsLoading(false);
            create('success', 'Success', res.responseApi.message);
            resetForm();
            setAmount(0);
            onClose();
          })
          .catch((err) => {
            setIsLoading(false);
            create('error', 'Error', err);
          });
      } catch (error) {
        // handle any rejections/errors
      }
    },
  });

  return (
    <>
      <Dialog open={open} onClose={() => onClose()}>
        <Typography component="p" variant="h3" fontWeight="bold" mb={4}>
          Add Balance
        </Typography>
        <Stack spacing={3}>
          <TextField
            label="Amount"
            fullWidth
            name="amount"
            type="number"
            error={Boolean(touched.amount && errors.amount)}
            errorText={errors.amount}
            value={values.amount}
            onChange={(e) => {
              setFieldValue('amount', e.target.value);
              setAmount(parseInt(e.target.value));
            }}
            onBlur={handleBlur}
            autoComplete="off"
            className="mt-0 mb-4"
            size="medium"
            InputProps={{
              startAdornment: <InputAdornment position="end">IDR</InputAdornment>,
            }}
          />

          <Box>
            <Grid container spacing={3}>
              {amountOptions.map((amount) => {
                const selected = amountState === amount;
                return (
                  <Grid key={amount} item xs={4}>
                    <Card
                      onClick={() => {
                        setAmount(amount);
                        setFieldValue('amount', amount);
                      }}
                      sx={{
                        cursor: 'pointer',
                        ...(selected
                          ? {
                              backgroundColor: palette.primary.light,
                              color: palette.neutral.white,
                            }
                          : {}),
                      }}>
                      <Box display="flex" alignItems="center" gap={1}>
                        <Coins2 htmlColor={selected ? 'inherit' : palette.primary.light} />
                        <Typography variant="b3">
                          {amount
                            .toLocaleString('id-ID', {
                              style: 'currency',
                              currency: 'IDR',
                              maximumFractionDigits: 0,
                            })
                            .replace('Rp', 'IDR')}
                        </Typography>
                      </Box>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </Box>

          <Button
            name="company"
            variant="contained"
            color="primary"
            fullWidth
            disabled={!(dirty && isValid)}
            onClick={() => setIsConfirm(true)}>
            {isLoading ? (
              <CircularProgress style={{ color: 'white' }} size={30} />
            ) : (
              <span className="loginButtonCaption">Add Balance</span>
            )}
          </Button>
        </Stack>
      </Dialog>
      <Confirm
        type={'Create'}
        open={isConfirm}
        handleAksi={() => {
          setIsConfirm(false);
          handleSubmit();
        }}
        loading={false}
        handleClose={() => setIsConfirm(false)}
        disable={false}
        handleContent={{
          title: 'Top Up Credit',
          message: 'Do you wish to Top Up Credit?',
          button: 'Submit',
        }}
      />
    </>
  );
}
