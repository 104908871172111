import {
  Dialog as MuiDialog,
  DialogContent,
  DialogProps as MuiDialogProps,
  IconButton,
} from '@mui/material';
import { RemoveSqfr2 } from '@xcidic/icons';

export interface DialogProps extends Omit<MuiDialogProps, 'onClose'> {
  onClose: () => void;
}

const Dialog = ({ children, onClose, ...others }: DialogProps) => {
  return (
    <MuiDialog {...others} onClose={onClose}>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 24,
          top: 16,
          transition: '0.3s all ease',
          color: (theme) => theme.palette.neutral[600],
          '&:hover': {
            backgroundColor: (theme) => theme.palette.primary.main,
            color: (theme) => theme.palette.neutral.white,
          },
        }}>
        <RemoveSqfr2 sx={{ fontSize: 14 }} />
      </IconButton>
      <DialogContent sx={{ padding: 4 }}>{children}</DialogContent>
    </MuiDialog>
  );
};

export default Dialog;
