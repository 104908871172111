import { Fragment } from 'react';

import { Box, Typography } from '@mui/material';

export default function index() {
  return (
    <Fragment>
      <div style={{ justifyContent: 'center', display: 'flex', width: '100%' }}>
        <Box component="img" src={`/emptyState.svg`} />
      </div>
      <div style={{ textAlign: 'center' }}>
        <h2>You still don’t have any invoices</h2>
        <Typography>
          Monthly Usage will be created at the end of the month after charging your balance
        </Typography>
      </div>
    </Fragment>
  );
}
