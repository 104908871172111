import { useMemo } from 'react';
import { merge } from 'lodash';
import ReactJoyride, { Props as ReactJoyrideProps } from 'react-joyride';
import { CustomTooltip } from './CustomTooltip';

const defaultProps: Partial<ReactJoyrideProps> = {
  continuous: true,
  tooltipComponent: CustomTooltip,
  floaterProps: {
    hideArrow: true,
  },
};

export const Joyride = (props: ReactJoyrideProps) => {
  const mergedProps = useMemo(() => merge(defaultProps, props), [props]);

  return <ReactJoyride {...mergedProps} />;
};
