// use create slicer from redux toolkit to combine all the action and reducer
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { BASE_URL } from '../../utils/url';
import axios from '../../utils/request';

// setupInterceptorsTo(axios);

export interface loginData {
  email: String;
  password: String;
}

export interface resendEmailValidation {
  email: string;
}

interface errorLogin {
  message: String;
}

const loginService = (email: String, password: String) => {
  return axios.post(BASE_URL + '/auth/login', {
    email,
    password,
  });
};

const resendService = (email: string) => {
  return axios.post(BASE_URL + '/account/resend-email-verification', {
    email,
  });
};

const logoutService = () => {
  localStorage.removeItem('user');
};

const user = JSON.parse(localStorage.getItem('user') ?? '{}');

export const login = createAsyncThunk(
  'auth/login',
  async ({ email, password }: loginData, thunkAPI) => {
    try {
      const data = await loginService(email, password);
      // console.log(data);
      localStorage.setItem('user', JSON.stringify(data?.data));
      return { user: data?.data };
    } catch (error) {
      const err = error as AxiosError;
      return thunkAPI.rejectWithValue((err.response?.data as errorLogin).message);
    }
  }
);

export const resendValidation = createAsyncThunk(
  'auth/resendValidation',
  async ({ email }: resendEmailValidation, thunkAPI) => {
    try {
      const data = await resendService(email);
      // console.log(data);
      // localStorage.setItem('user', JSON.stringify(data?.data));
      return { response: data?.data };
    } catch (error) {
      const err = error as AxiosError;
      return thunkAPI.rejectWithValue((err.response?.data as errorLogin).message);
    }
  }
);

export const logout = createAsyncThunk('auth/logout', async () => {
  await logoutService();
});

const initialState = user ? { isLoggedIn: true, user } : { isLoggedIn: false, user: null };

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    });
    builder.addCase(resendValidation.fulfilled, (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    });
    builder.addCase(resendValidation.rejected, (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    });
    builder.addCase(logout.fulfilled, (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    });
  },
});

const { reducer } = authSlice;
export default reducer;
