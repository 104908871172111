import { Button, Divider, Grid, Paper, Typography, styled, Box } from '@mui/material';
import { BankCards, TrashCan } from '@xcidic/icons';
import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { ToastManagerContext } from '../../components/ToastManager';
import {
  deleteService,
  getServiceById,
  updateService,
  requestDataUpdate,
  paramsById,
} from '../../redux/modules/otpTemplate';
import { AppDispatch } from '../../redux/store';
import { useNavigate } from 'react-router-dom';

import Breadcrumbs from '../../components/Breadcrumbs';
import DashboardHeader from '../../components/DashboardHeader';
import Confirm from '../../components/Confirm';
import Container from '../../components/Container';
import Field from '../../components/Fields';
import EditModal from './component/editModal';

interface requestDataEdit {
  id: string;
  name: string;
  content: string;
  isActive: Boolean;
}
interface ObjectPropsData {
  id: string;
  name: string;
  content: string;
  isActive: Boolean;
}
interface parametersContent {
  type: string;
  text: string;
}

interface componentContent {
  type: string;
  parameters: parametersContent[];
}
interface jsonDataRequest {
  name: string;
  language: {
    code: string;
  };
  components: componentContent[];
}

const NotifStatusContainerActive = styled(Box)(({ theme }) => ({
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '5px',
    // right: '3px',
    width: 12,
    height: 12,
    borderRadius: '50%',
    backgroundColor: '#12B76A',
    border: 'solid 1px white',
  },
}));
const NotifStatusContainerInActive = styled(Box)(({ theme }) => ({
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '5px',
    // right: '3px',
    width: 12,
    height: 12,
    borderRadius: '50%',
    backgroundColor: '#e11900',
    border: 'solid 1px white',
  },
}));
export default function index() {
  const { create } = useContext(ToastManagerContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [isConfirmStatus, setIsConfirmStatus] = useState(false);

  const [isConfirm, setIsConfirm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editValue, setEditValue] = useState<requestDataEdit>({
    id: '0',
    name: '',
    content: '',
    isActive: false,
  });
  const [data, setData] = useState<ObjectPropsData>({
    id: '0',
    name: '',
    content: `{
      "name": "verification",
      "language": {
        "code": "en"
      },
      "components": [
        {
          "type": "body",
          "parameters": [
            {
              "type": "text",
              "text": "[[otp]]"
            },
            {
              "type": "text",
              "text": "[[product]]"
            },
            {
              "type": "text",
              "text": "Hello World"
            }
          ]
        }
      ]
    }`,
    isActive: false,
  });

  useEffect(() => {
    refreshTemplate();
  }, []);
  const refreshTemplate = () => {
    dispatch(getServiceById({ id: id ?? '' }))
      .unwrap()
      .then((res) => {
        // create('success', 'Success', res.responseApi.message);

        setData(res.responseApi?.data);
      })
      .catch((err) => {
        // console.log(err)
        create('error', 'Error', err);
      });
  };
  const deleteData = (id: string) => {
    const data: paramsById = {
      id,
    };
    dispatch(deleteService(data))
      .unwrap()
      .then((res) => {
        // console.log(res)
        create('success', 'Success', res.responseApi.message);
        // refreshTemplate();
        navigate('/otp/configuration');
      })
      .catch((err) => {
        create('success', 'Error', err);

        // console.log(err)
      });
  };
  const updateData = (id: string, name: string, isActive: Boolean) => {
    const data: requestDataUpdate = {
      id,
      name,
      isActive: !isActive,
    };
    dispatch(updateService(data))
      .unwrap()
      .then((res) => {
        // console.log(res)
        create('success', 'Success', res.responseApi.message);
        refreshTemplate();
      })
      .catch((err) => {
        create('success', 'Error', err);

        // console.log(err)
      });
  };
  const isJsonString = (str: string) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  const jsonData: jsonDataRequest =
    isJsonString(data?.content) &&
    JSON.parse(
      data?.content ??
        `{
    "name":"verification",
    "language":{
       "code":"en"
    },
    "components":[
       {
          "type":"body",
          "parameters":[
             {
                "type":"text",
                "text":"[[otp]]"
             },
             {
                "type":"text",
                "text":"[[product]]"
             },
             {
                "type":"text",
                "text":"Hello World"
             }
          ]
       }
    ]
 }`
    );
  return (
    <Container>
      <Breadcrumbs
        items={[
          {
            label: 'OTP',
            link: '/otp',
          },
          {
            label: 'Template',
            link: '/otp/template',
          },
          {
            label: 'Template Detail',
            link: `/otp/template/${id ?? ''}`,
          },
        ]}
      />
      <DashboardHeader title="Template Detail" />

      <Paper>
        {/* <Field withElement={true} small={false} label="Id" value={data?.id} /> */}
        {/* <Divider /> */}
        <Field withElement={false} small={false} label="Name" value={data?.name} />
        <Divider />
        <div style={{ margin: '16px 16px 16px 0px' }}>
          <Typography style={{ fontWeight: 500, fontStyle: 'normal', fontSize: '14px' }}>
            Status
          </Typography>
          {data?.isActive && (
            <NotifStatusContainerActive>
              <Typography
                style={{
                  fontWeight: 300,
                  fontStyle: 'normal',
                  fontSize: '14px',
                  color: '#5d5d5d',
                  marginLeft: 14,
                }}>
                {data.isActive ? 'Active' : 'InActive'}
              </Typography>
            </NotifStatusContainerActive>
          )}
          {!data?.isActive && (
            <NotifStatusContainerInActive>
              <Typography
                style={{
                  fontWeight: 300,
                  fontStyle: 'normal',
                  fontSize: '14px',
                  color: '#5d5d5d',
                  marginLeft: 14,
                }}>
                {data.isActive ? 'Active' : 'InActive'}
              </Typography>
            </NotifStatusContainerInActive>
          )}
        </div>
        <Divider />

        <Field
          withElement={false}
          small={false}
          label="Language Code"
          value={jsonData?.language?.code === 'en' ? 'English' : 'Indonesian'}
        />
        <Divider />
        {isJsonString(data.content) && (
          <Field
            withElement={false}
            small={false}
            label="Messages"
            value={jsonData?.components[0]?.parameters?.map((val) => `${val.text} `)}
          />
        )}

        <Divider />

        {/* {!isJsonString(data.content) && (
          <div>
            <Field withElement={false} small={false} label="Content Body" value={data.content} />
            <Divider />
          </div>
        )}
        {isJsonString(data.content) &&
          jsonData?.components[0]?.parameters?.map((valParams, index) => (
            <div key={index}>
              <Field
                withElement={true}
                small={false}
                label="Content Body"
                value={valParams?.text}
              />
              <Divider />
            </div>
          ))} */}

        <div style={{ margin: '16px 16px 16px 0px' }}>
          <Typography style={{ fontWeight: 500, fontStyle: 'normal', fontSize: '14px' }}>
            Template ID
          </Typography>
          <Typography
            style={{ fontWeight: 300, fontStyle: 'normal', fontSize: '14px', color: '#5d5d5d' }}>
            {data.id}
            {/* {isToken ? data?.id ?? '' : data?.id?.split('').map(() => '*') ?? ''} */}
            {/* <IconButton onClick={() => setIsToken(!isToken)}>
            {isToken ? <EyeOff /> : <Eye />}
          </IconButton> */}
            <Button
              style={{ marginLeft: 10 }}
              name="copy"
              variant="outlined"
              color="primary"
              // fullWidth
              startIcon={<BankCards />}
              onClick={() => {
                navigator.clipboard.writeText(data?.id ?? '');
                create('success', 'Copy', 'Success Copy Template ID');
              }}
              // disabled={!(dirty && isValid)}
              // onClick={() => setIsConfirm(true)}
            >
              Copy
            </Button>
          </Typography>
        </div>

        <Grid container spacing={3} style={{ marginTop: 10 }}>
          <Grid item lg={12} style={{ justifyContent: 'flex-end', display: 'flex' }}>
            <Grid item spacing={3}>
              <Button
                name="company"
                style={{ marginRight: 10 }}
                // variant="contained"
                color={data.isActive ? 'error' : 'primary'}
                onClick={() => {
                  setEditValue({
                    id: data.id,
                    name: data.name,
                    content: data.content,
                    isActive: data.isActive,
                  });
                  setIsConfirmStatus(true);
                  // updateData(val.id, val.name, val.isActive)
                }}>
                {data.isActive ? 'Deactive' : 'ReActive'}
              </Button>
              <Button
                name="company"
                startIcon={<TrashCan />}
                style={{ marginRight: 10 }}
                variant="outlined"
                color="error"
                onClick={() => {
                  setIsConfirm(true);
                  setEditValue({
                    id: data.id,
                    name: data.name,
                    content: data.content,
                    isActive: data.isActive,
                  });
                  // deleteData(val.id)
                }}>
                Delete Template
              </Button>
              <Button
                name="company"
                variant="contained"
                color="primary"
                onClick={() => {
                  // setIsEdit(true);
                  // setEditValue({
                  //   id: data.id,
                  //   name: data.name,
                  //   content: data.content,
                  //   isActive: data.isActive,
                  // });
                  navigate(`/otp/template/edit/${id ?? ''}`);
                }}>
                Edit Template
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <EditModal
          open={isEdit}
          onClose={() => {
            setIsEdit(false);
            refreshTemplate();
          }}
          id={editValue.id}
          name={editValue.name}
          content={editValue.content}
        />
        <Confirm
          type={'Create'}
          open={isConfirmStatus}
          handleAksi={() => {
            updateData(String(editValue.id), editValue.name, editValue.isActive);
            setIsConfirmStatus(false);
          }}
          loading={false}
          handleClose={() => setIsConfirmStatus(false)}
          disable={false}
          handleContent={{
            title: editValue.isActive ? 'Deactive Template' : 'ReActive Template',
            message: editValue.isActive
              ? 'Do you want to change Template status to inactive?'
              : 'Do you want to change Template status to active?',
            button: 'Confirm',
          }}
        />
        <Confirm
          open={isConfirm}
          handleAksi={() => {
            deleteData(String(editValue.id));
            setIsConfirm(false);
          }}
          loading={false}
          handleClose={() => setIsConfirm(false)}
          disable={false}
          type={'Delete'}
          handleContent={{
            title: 'Delete OTP Template',
            message: 'Do you wish to Delete this OTP Template?',
            button: 'Confirm',
          }}
        />
      </Paper>
    </Container>
  );
}
