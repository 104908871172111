import { Button, CircularProgress, Dialog, Grid, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useContext, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Confirm from '../../../components/Confirm';
import { ToastManagerContext } from '../../../components/ToastManager';
import { editService, requestDataEdit } from '../../../redux/modules/token';
import { AppDispatch } from '../../../redux/store';
import {
  initialValuesEdit as initialValues,
  validationSchemaEdit as validationSchema,
} from './schemeValidation';

// import { date } from 'yup/lib/locale';
// type CloseReason = 'backdropClick' | 'escapeKeyDown' | 'closeButtonClick';
interface PropsModal {
  open: boolean;
  // onClose: (reason: CloseReason) => void;
  onClose: Function;
  id: string;
  description: String;
}

export default function index({ open, onClose, id, description }: PropsModal) {
  const { create } = useContext(ToastManagerContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);

  const dispatch = useDispatch<AppDispatch>();

  const {
    handleChange,
    handleBlur,
    // setSubmitting,
    handleSubmit,
    setFieldValue,
    dirty,
    resetForm,
    values,
    isValid,
    touched,
    errors,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async () => {
      setIsLoading(true);
      try {
        const data: requestDataEdit = {
          id,
          description: values.description,
        };
        dispatch(editService(data))
          .unwrap()
          .then((res) => {
            setIsLoading(false);

            create('success', 'Success', res.responseApi.message);
            resetForm();
            onClose();
          })
          .catch((err) => {
            setIsLoading(false);

            create('error', 'Error', err);
          });
      } catch (error) {
        // handle any rejections/errors
      }
    },
  });
  useEffect(() => {
    setFieldValue('description', description);
  }, [open]);
  return (
    <Dialog open={open} onClose={() => onClose()}>
      <Grid container spacing={3} style={{ padding: 20 }}>
        <Grid item lg={12} spacing={2}>
          <Grid item lg={12} style={{ display: 'flex', justifyContent: 'end' }}>
            <p style={{ cursor: 'pointer' }} onClick={() => onClose()}>
              X
            </p>
          </Grid>
          <Grid item lg={12}>
            <h2>Edit Token</h2>
          </Grid>
        </Grid>
        <Grid item lg={12}>
          <Typography
            style={{
              color: touched.description && errors.description ? '#e11900' : undefined,
            }}>
            Token Name
          </Typography>
          <TextField
            fullWidth
            // autoFocus
            name="description"
            variant="outlined"
            // type="email"
            // label="Token Name *"
            error={Boolean(touched.description && errors.description)}
            helperText={touched.description && errors.description}
            value={values.description}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
            className="mt-0 mb-4"
            size="medium"
          />
        </Grid>

        <Grid item lg={12}>
          <Button
            fullWidth
            name="company"
            variant="contained"
            color="primary"
            disabled={!(dirty && isValid)}
            onClick={() => setIsConfirm(true)}>
            {isLoading ? (
              <CircularProgress style={{ color: 'white' }} size={30} />
            ) : (
              <span className="loginButtonCaption"> Save Changes</span>
            )}
          </Button>
          <Confirm
            type={'Create'}
            open={isConfirm}
            handleAksi={() => {
              setIsConfirm(false);
              handleSubmit();
            }}
            loading={false}
            handleClose={() => setIsConfirm(false)}
            disable={false}
            handleContent={{
              title: 'Edit Token',
              message: 'Do you wish to Edit this Token?',
              button: 'Submit',
            }}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
}
