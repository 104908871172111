import { Button, CircularProgress, Box, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { initialValues, validationSchema } from './schemeValidation';

import { ToastManagerContext } from '../../../components/ToastManager';

import { createService, requestData } from '../../../redux/modules/contact';
import { AppDispatch } from '../../../redux/store';

import PhoneInput, { CountryData } from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import Confirm from '../../../components/Confirm';

// import { date } from 'yup/lib/locale';
// type CloseReason = 'backdropClick' | 'escapeKeyDown' | 'closeButtonClick';

interface PropsModal {
  // onClose: (reason: CloseReason) => void;
  onClose: Function;
}
export default function index({ onClose }: PropsModal) {
  const { create } = useContext(ToastManagerContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);

  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams();

  const {
    handleChange,
    handleBlur,
    // setSubmitting,
    handleSubmit,
    setFieldValue,
    dirty,
    resetForm,
    values,
    isValid,
    touched,
    errors,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async () => {
      setIsLoading(true);
      try {
        const data: requestData = {
          name: values.name,
          number: values.number?.replace(values.countryCode, ''),
          countryCode: values.countryCode,
          categories: [{ id: id ?? '' }],
        };
        dispatch(createService(data))
          .unwrap()
          .then((res) => {
            setIsLoading(false);

            create('success', 'Success', res.responseApi.message);
            resetForm();
            onClose();
          })
          .catch((err) => {
            setIsLoading(false);

            create('error', 'Error', err);
          });
      } catch (error) {
        // handle any rejections/errors
      }
    },
  });

  const handleChangePhoneNumber = (value: string, country: CountryData) => {
    // const { setFieldValue } = this.props;
    if (country?.dialCode !== values?.countryCode) {
      // console.log;
      setFieldValue('number', country?.dialCode);
      setFieldValue('countryCode', country?.dialCode);
    } else {
      setFieldValue('number', value);
      setFieldValue('countryCode', country?.dialCode);
    }
  };
  return (
    <Box>
      <TextField
        fullWidth
        // autoFocus
        name="name"
        variant="outlined"
        // type="email"
        label="Customer Name *"
        error={Boolean(touched.name && errors.name)}
        helperText={touched.name && errors.name}
        value={values.name}
        onChange={handleChange}
        onBlur={handleBlur}
        autoComplete="off"
        className="mt-0 mb-4"
        size="medium"
        sx={{ mb: 2 }}
      />

      <PhoneInput
        inputStyle={{ width: '100%' }}
        // style={{ marginBottom: 10, marginTop: 10 }}
        containerClass="phoneInput"
        // name="phone"
        // type="number"
        specialLabel="Customer WA Number *"
        // variant="outlined"
        // size="small"
        // margin="normal"
        value={values.number}
        onChange={handleChangePhoneNumber}
        onBlur={handleBlur}
        // error={Boolean(touched.waNumber && errors.waNumber)}
        // helperText={touched.waNumber && errors.waNumber}
      />

      <Button
        fullWidth
        sx={{ mt: 2 }}
        name="company"
        variant="contained"
        color="primary"
        disabled={!(dirty && isValid)}
        onClick={() => setIsConfirm(true)}>
        {isLoading ? (
          <CircularProgress style={{ color: 'white' }} size={30} />
        ) : (
          <span className="loginButtonCaption">Add Contact</span>
        )}
      </Button>
      <Confirm
        type={'Create'}
        open={isConfirm}
        handleAksi={() => {
          setIsConfirm(false);
          handleSubmit();
        }}
        loading={false}
        handleClose={() => setIsConfirm(false)}
        disable={false}
        handleContent={{
          title: 'Add Contact',
          message: 'Do you wish to Add this Contact?',
          button: 'Submit',
        }}
      />
    </Box>
  );
}
