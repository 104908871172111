import Guide from '../Guide';
import { Step } from 'react-joyride';

const companyGuideSteps: Step[] = [
  {
    target: '#business-information',
    content: (
      <Guide
        title="Complete your company detail"
        content="Fill in sender identity to test your messages integration. Click here to search for “Company” menu."
        step="1"
        totalSteps="3"
      />
    ),
    disableBeacon: true,
    offset: 25,
    placement: 'bottom-start',
  },
  {
    target: '#business-company',
    content: (
      <Guide
        title="Complete your company detail"
        content="Click here to open “Company” menu and find “Add Company” button."
        step="2"
        totalSteps="3"
      />
    ),
    disableBeacon: true,
    offset: 25,
    placement: 'bottom-start',
  },
  {
    target: '#create-company',
    content: (
      <Guide
        title="Complete your company detail"
        content="Click here to add company and complete your company detail."
        step="3"
        totalSteps="3"
      />
    ),
    disableBeacon: true,
    offset: 25,
    placement: 'bottom-start',
  },
];

export default companyGuideSteps;
