import { DEV_URL } from '../../utils/url';

export const js = (token: string, templateId: string, handlerId: string) => `
var myHeaders = new Headers();
myHeaders.append("x-token", "${token}");
myHeaders.append("Content-Type", "application/json");

var raw = JSON.stringify({
  "handlerId": "${handlerId}",
  "blastId": "${templateId}"
});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch("${DEV_URL}/blast/wa", requestOptions)
  .then(response => response.text())
  .then(result => console.log(result))
  .catch(error => console.log('error', error));
`;

export const python = (token: string, templateId: string, handlerId: string) => `
import requests
import json

url = "${DEV_URL}/blast/wa"

payload = json.dumps({
  "handlerId": "${handlerId}",
  "blastId": "${templateId}"
})
headers = {
  'x-token': '${token}',
  'Content-Type': 'application/json'
}

response = requests.request("POST", url, headers=headers, data=payload)

print(response.text)

`;

export const php = (token: string, templateId: string, handlerId: string) => `
<?php

$curl = curl_init();

curl_setopt_array($curl, array(
  CURLOPT_URL => '${DEV_URL}/blast/wa',
  CURLOPT_RETURNTRANSFER => true,
  CURLOPT_ENCODING => '',
  CURLOPT_MAXREDIRS => 10,
  CURLOPT_TIMEOUT => 0,
  CURLOPT_FOLLOWLOCATION => true,
  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
  CURLOPT_CUSTOMREQUEST => 'POST',
  CURLOPT_POSTFIELDS =>'{
    "handlerId":"${handlerId}",
    "blastId":"${templateId}"
}',
  CURLOPT_HTTPHEADER => array(
    'x-token: ${token}',
    'Content-Type: application/json'
  ),
));

$response = curl_exec($curl);

curl_close($curl);
echo $response;

`;

export const cSharp = (token: string, templateId: string, handlerId: string) => `
var client = new RestClient("${DEV_URL}/blast/wa");
client.Timeout = -1;
var request = new RestRequest(Method.POST);
request.AddHeader("x-token", "${token}");
request.AddHeader("Content-Type", "application/json");
var body = @"{
" + "\n" +
@"    ""handlerId"":""${handlerId}"",
" + "\n" +
@"    ""blastId"":""${templateId}""
" + "\n" +
@"}";
request.AddParameter("application/json", body,  ParameterType.RequestBody);
IRestResponse response = client.Execute(request);
Console.WriteLine(response.Content);
`;
