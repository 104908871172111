import { useState } from 'react';
import Searchbar from '../../components/Searchbar';
import User from './component/User';
// import Container from '../../components/Container';

interface dataUserInterface {
  customerContactName: string | null;
  customerPhoneNumber: string;
  lastMessageOn: number;
  id: string;
  isNewMessage: boolean;
  newMessageCount: number;
  lastMessagePreview: string;
  isReplied: boolean;
  isResolved: boolean;
}

interface props<T> {
  id: string;
  dataUser: dataUserInterface[];
  onAction: (data: T) => void;
}
export default function index({ id, dataUser, onAction }: props<string>) {
  // const { socket, message } = useSockets();
  const [search, onSearch] = useState('');
  return (
    <div style={{ padding: 16 }}>
      <Searchbar
        placeholder="Search for customer"
        fullWidth
        value={search}
        onChange={(e) => onSearch(e.target.value)}
      />
      {dataUser?.length === 0 && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 600,
          }}>
          <p style={{ color: '#AFAFAF' }}>No Chat Yet</p>
        </div>
      )}
      <div style={{ marginTop: 27 }}>
        {dataUser
          ?.filter(
            (valFind) =>
              valFind.customerContactName?.toLowerCase().includes(search) ??
              valFind.customerPhoneNumber?.includes(search)
          )
          ?.map((val, index) => {
            return (
              <div key={index}>
                <User
                  isReplied={val.isReplied}
                  isResolved={val.isResolved}
                  selectedId={id}
                  id={val.id}
                  name={val.customerContactName ?? val.customerPhoneNumber}
                  messageProps={val.lastMessagePreview}
                  timeMessage={val.lastMessageOn}
                  handleClick={() => onAction(val.id)}
                  isNewMessage={val.isNewMessage}
                  lastMessage={val.newMessageCount}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
}
