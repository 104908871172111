const AutoReplyIcon = (props: any) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.357 3h3.679c.901 0 1.629 0 2.22.04.61.042 1.148.13 1.657.34a5 5 0 0 1 2.706 2.707c.212.51.3 1.048.34 1.656.041.592.041 1.32.041 2.221v.072c0 .901 0 1.629-.04 2.22-.042.61-.13 1.148-.34 1.657a5 5 0 0 1-2.707 2.706c-.51.212-1.048.3-1.656.34-.592.041-1.32.041-2.222.041H11.43c-.46 0-.552.005-.626.02a1 1 0 0 0-.469.234c-.057.05-.115.121-.391.489l-.022.029c-.605.807-1.088 1.45-1.483 1.916-.371.438-.791.877-1.292 1.085A3 3 0 0 1 3.42 19.53c-.277-.466-.349-1.07-.383-1.642C3 17.278 3 16.474 3 15.464v-5.107c0-1.084 0-1.958.058-2.666.06-.728.185-1.369.487-1.96A5 5 0 0 1 5.73 3.544c.592-.302 1.233-.428 1.961-.487C8.4 3 9.273 3 10.357 3ZM7.854 5.051c-.605.05-.953.142-1.216.276a3 3 0 0 0-1.311 1.311c-.134.263-.226.611-.276 1.216C5.001 8.471 5 9.264 5 10.4v5.029c0 1.053 0 1.797.033 2.338.016.268.039.456.066.589.022.112.042.155.043.157a1 1 0 0 0 1.237.413c.002-.002.043-.024.129-.1.1-.09.232-.228.405-.432.351-.414.798-1.008 1.43-1.851l.043-.058c.208-.278.391-.522.617-.723a3 3 0 0 1 1.405-.703c.296-.06.601-.06.949-.059H14c.946 0 1.605 0 2.12-.036.508-.034.803-.099 1.028-.192a3 3 0 0 0 1.624-1.624c.093-.225.158-.52.192-1.027C19 11.605 19 10.946 19 10s0-1.605-.036-2.12c-.034-.507-.099-.803-.192-1.028a3 3 0 0 0-1.624-1.624c-.225-.093-.52-.158-1.027-.192C15.605 5 14.946 5 14 5h-3.6c-1.137 0-1.929 0-2.546.051Z"
      fill="#545454"
    />
    <path
      d="M8 11a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM13 10a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM17 10a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"
      fill="#545454"
    />
  </svg>
);

export default AutoReplyIcon;
