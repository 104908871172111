import axios from 'axios';
import { setupInterceptorsTo } from './interceptor';
import { BASE_URL } from './url';

const instance = axios.create({
  baseURL: `${BASE_URL}`,
  timeout: 10000,
  headers: { 'Content-Type': 'application/json' },
});

setupInterceptorsTo(instance);

export default instance;
