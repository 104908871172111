import { Dialog, Box, Grid } from '@mui/material';

interface PropsModal {
  open: boolean;
  type: string;
  // onClose: (reason: CloseReason) => void;
  onClose: Function;
}
export default function index({ open, onClose, type }: PropsModal) {
  return (
    <Dialog open={open} onClose={() => onClose()} maxWidth="md">
      <Grid item lg={12} spacing={2}>
        <Grid item lg={12} style={{ display: 'flex', justifyContent: 'end' }}>
          <p style={{ cursor: 'pointer' }} onClick={() => onClose()}>
            X
          </p>
        </Grid>
        <Grid item lg={12}>
          <h2>
            {type === 'accountId'
              ? 'WhatsApp Business Account ID Information'
              : type === 'phoneId'
              ? 'WhatsApp Business Phone ID Information'
              : 'WhatsApp Business API Token Information'}
          </h2>
        </Grid>
      </Grid>
      <Box
        component="img"
        src={
          type === 'accountId'
            ? '/accountId.png'
            : type === 'phoneId'
            ? '/phoneId.png'
            : '/apiToken.png'
        }
        sx={{ width: 852, marginTop: 5, marginBottom: 5 }}
      />
      {type === 'accountId' ? (
        <>
          <p style={{ fontSize: 14 }}>
            1. Open{' '}
            <a href="https://developers.facebook.com/" target={'_blank'} rel="noreferrer">
              developers.facebook
            </a>
          </p>
          <p style={{ fontSize: 14 }}>
            {'2. Choose your app preference then click on menu Whatsapp > Getting Started'}
          </p>
        </>
      ) : type === 'phoneId' ? (
        <>
          <p style={{ fontSize: 14 }}>
            1. Open{' '}
            <a href="https://developers.facebook.com/" target={'_blank'} rel="noreferrer">
              developers.facebook
            </a>
          </p>
          <p style={{ fontSize: 14 }}>
            {'2. Choose your app preference then click on menu Whatsapp > Getting Started'}
          </p>
        </>
      ) : (
        <>
          <p style={{ fontSize: 14 }}>
            1. Open{' '}
            <a href="https://business.facebook.com/" target={'_blank'} rel="noreferrer">
              business.facebook
            </a>
          </p>
          <p style={{ fontSize: 14 }}>
            {
              '2.Choose your business preference then click on menu Business Settings > Users > System Users > Generate New Token'
            }
          </p>
        </>
      )}
    </Dialog>
  );
}
