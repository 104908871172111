import { date, object, string } from 'yup';

export const initialValues = {
  description: '',
  withExpiration: false,
  date: new Date(),
  expiresOn: 0,
};

export const validationSchema = object({
  description: string().required("Name can't be blank"),
  date: date(),
});

export const initialValuesEdit = {
  id: '',
  description: '',
};

export const validationSchemaEdit = object({
  description: string().required("Name can't be blank"),
});
