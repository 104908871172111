import React, { useState, useContext, useEffect } from 'react';
import { Grid, Button, Typography, Box, Stack, List, ListItem, ListItemText } from '@mui/material';
import { PlusSqfr, Coins2 } from '@xcidic/icons';
import ProgressBar from '../../components/ProgressBar';
import { useNavigate } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { ToastManagerContext } from '../../components/ToastManager';
import { getServiceBilling, getTotalBilling } from '../../redux/modules/xendit';
import { AppDispatch } from '../../redux/store';
import Container from '../../components/Container';
import Table from '../../components/Table';
import Breadcrumbs from '../../components/Breadcrumbs';
import DashboardHeader from '../../components/DashboardHeader';
import moment from 'moment';
import Searchbar from '../../components/Searchbar';

import AddBalance from './component/addBalance';

import EmptyState from '../../components/EmptyState';
import Widget, { WidgetFooter } from '../../components/Widget';
import Pagination from '../../components/TablePagination';
interface meta {
  total: number;
}

interface propsBilling {
  status: string;
  transactionId: string;
  value: number;
  createdOn: number;
  invoiceUrl: string;
}

interface currentBalance {
  idr: number;
}

interface current {
  expired: number;
  paid: number;
  pending: number;
  total: number;
  currentBalance: currentBalance;
}

interface CircleProps {
  color: 'success' | 'warning' | 'error';
}

const Circle = ({ color }: CircleProps) => (
  <Box
    sx={{
      width: 12,
      height: 12,
      borderRadius: '50%',
      backgroundColor: (theme) => theme.palette[color].main,
    }}
  />
);

function index() {
  const { create } = useContext(ToastManagerContext);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [orderBy, setOrderBy] = useState('createdOn');
  const [order, setOrder] = useState('desc');
  const [search, onSearch] = useState('');
  // eslint-disable-next-line
  const [current, setCurrent] = useState<current>();
  const [list, setList] = useState<propsBilling[]>([]);
  const [realList, setRealList] = useState<propsBilling[]>([]);

  const [isAddBalance, setIsAddBalance] = useState(false);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  // eslint-disable-next-line
  const [totalData, setTotal] = useState<meta>({ total: 1 });
  useEffect(() => {
    dispatch(
      getServiceBilling({
        limit,
        offset: `${page === 1 ? 0 : page === 2 ? limit : limit * page}`,
      })
    )
      .unwrap()
      .then((res) => {
        setTotal(res.listToken.meta);
        setList(res.listToken?.data);
        setRealList(res.listToken?.data);
      })
      .catch((err) => {
        create('error', 'Error', err);
      });
  }, []);
  useEffect(() => {
    dispatch(
      getServiceBilling({
        limit,
        offset: `${
          page === 1 ? 0 : page === 2 ? limit : limit * page
        }&order=${order}&orderBy=${orderBy}&keyword=${search}`,
      })
    )
      .unwrap()
      .then((res) => {
        setTotal(res.listToken.meta);
        setList(res.listToken?.data);
      })
      .catch((err) => {
        create('error', 'Error', err);
      });
    dispatch(getTotalBilling())
      .unwrap()
      .then((res) => {
        setCurrent(res.responseApi.data);
      })
      .catch((err) => {
        create('error', 'Error', err);
      });
  }, [page, order, orderBy, search]);
  return (
    <Container>
      <Breadcrumbs
        items={[
          {
            label: 'Billing',
            link: '/billing',
          },
          {
            label: 'Balance',
            link: '/billing',
          },
        ]}
      />
      <DashboardHeader title="Billings" />
      <Grid container spacing={2}>
        <Grid item lg={10}>
          <Widget>
            {realList?.length === 0 && (
              <EmptyState module="Billing" button="Add Balance" temp="bill" />
            )}
            {realList?.length !== 0 && (
              <>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 20 }}>
                  <Searchbar
                    placeholder="Search"
                    // fullWidth
                    value={search}
                    onChange={(e) => {
                      setPage(1);

                      onSearch(e.target.value);
                    }}
                  />
                </div>
                <Table
                  data={list ?? []}
                  config={[
                    {
                      label: 'Amount',
                      key: 'value',
                      render: (row) => (
                        <Typography variant="b2">
                          IDR
                          {` ${row.value
                            .toLocaleString('id-ID', {
                              style: 'currency',
                              currency: 'IDR',
                              maximumFractionDigits: 0,
                            })
                            .replace('Rp', '')}`}
                        </Typography>
                      ),
                      onSort: () => {
                        setOrder(order === 'asc' ? 'desc' : 'asc');
                        setOrderBy('value');
                      },
                    },
                    {
                      label: 'Status',
                      key: 'status',
                      render: (row) => {
                        return row.status === 'paid' ? (
                          <Box display="flex" alignItems="center" gap={1}>
                            <Circle color="success" />
                            <Typography variant="b3" fontWeight="bold">
                              Paid
                            </Typography>
                          </Box>
                        ) : row.status === 'pending' ? (
                          <Box display="flex" alignItems="center" gap={1}>
                            <Circle color="warning" />
                            <Typography variant="b3" fontWeight="bold">
                              Pending
                            </Typography>
                          </Box>
                        ) : (
                          <Box display="flex" alignItems="center" gap={1}>
                            <Circle color="error" />
                            <Typography variant="b3" fontWeight="bold">
                              Expired
                            </Typography>
                          </Box>
                        );
                      },
                      onSort: () => {
                        setOrder(order === 'asc' ? 'desc' : 'asc');
                        setOrderBy('status');
                      },
                    },
                    {
                      label: 'Purchased Date',
                      key: 'transactionId',
                      render: (row) => {
                        return <p>{moment(row.createdOn * 1000).format('MMM DD, YYYY | HH:mm')}</p>;
                      },
                      onSort: () => {
                        setOrder(order === 'asc' ? 'desc' : 'asc');
                        setOrderBy('createdOn');
                      },
                    },
                    {
                      label: 'Invoice Id',
                      key: 'transactionId',
                      render: (row) => {
                        return <p>{row.transactionId}</p>;
                      },
                      onSort: () => {
                        setOrder(order === 'asc' ? 'desc' : 'asc');
                        setOrderBy('transactionId');
                      },
                    },
                    {
                      label: 'Action',
                      key: 'transactionId',
                      render: (row) => {
                        return (
                          <Button
                            name="company"
                            variant="contained"
                            color="primary"
                            fullWidth
                            disabled={row.status !== 'pending'}
                            onClick={() => window.open(row?.invoiceUrl ?? '', '_blank')}>
                            Pay
                          </Button>
                        );
                      },
                      onSort: () => {
                        setOrder(order === 'asc' ? 'desc' : 'asc');
                        setOrderBy('status');
                      },
                    },
                  ]}
                  onSelectRow={(row) => navigate(`/billing/${row.transactionId}`)}
                />
                <WidgetFooter>
                  <Pagination
                    page={page}
                    count={totalData?.total}
                    rowsPerPage={limit}
                    onPageChange={(_, newPage) => setPage(newPage)}
                    onRowsPerPageChange={(e) => {
                      setLimit(e.target.value as number);
                      setPage(1);
                    }}
                  />
                </WidgetFooter>
              </>
            )}

            <AddBalance open={isAddBalance} onClose={() => setIsAddBalance(false)} />
          </Widget>
        </Grid>
        <Grid item lg={2}>
          <Widget>
            <Stack spacing={2}>
              <Box>
                <Typography variant="h5" fontWeight={600}>
                  Current Balance
                </Typography>
                <Box display="flex" alignItems="center">
                  <Coins2 />
                  <Typography variant="b1" fontWeight={600}>
                    IDR
                    {` ${(current?.currentBalance?.idr ?? 0)
                      .toLocaleString('id-ID', {
                        style: 'currency',
                        currency: 'IDR',
                        maximumFractionDigits: 0,
                      })
                      .replace('Rp', '')}`}
                  </Typography>
                </Box>
              </Box>

              <Box>
                <Typography variant="b3">
                  <Typography component="span" variant="b2" fontWeight={700}>
                    {current?.total}
                  </Typography>{' '}
                  Invoices
                </Typography>
                <ProgressBar
                  bgColor="#12B76A"
                  paid={`${String(((current?.paid ?? 0) / (current?.total ?? 0)) * 100)}`}
                  pending={`${String(((current?.pending ?? 0) / (current?.total ?? 0)) * 100)}`}
                  failed={`${String(((current?.expired ?? 0) / (current?.total ?? 0)) * 100)}`}
                />
              </Box>

              <List>
                <ListItem disableGutters divider>
                  <Circle color="success" />
                  <ListItemText
                    primary="Paid"
                    primaryTypographyProps={{ variant: 'b3' }}
                    sx={{ width: '100%', ml: 1 }}
                  />
                  <ListItemText
                    primary={current?.paid}
                    primaryTypographyProps={{ variant: 'b3' }}
                  />
                </ListItem>
                <ListItem disableGutters divider>
                  <Circle color="warning" />
                  <ListItemText
                    primary="Pending"
                    primaryTypographyProps={{ variant: 'b3' }}
                    sx={{ width: '100%', ml: 1 }}
                  />
                  <ListItemText
                    primary={current?.pending}
                    primaryTypographyProps={{ variant: 'b3' }}
                  />
                </ListItem>
                <ListItem disableGutters divider>
                  <Circle color="error" />
                  <ListItemText
                    primary="Expired"
                    primaryTypographyProps={{ variant: 'b3' }}
                    sx={{ width: '100%', ml: 1 }}
                  />
                  <ListItemText
                    primary={current?.expired}
                    primaryTypographyProps={{ variant: 'b3' }}
                  />
                </ListItem>
              </List>

              <Button
                name="company"
                variant="contained"
                color="primary"
                fullWidth
                startIcon={<PlusSqfr />}
                onClick={() => setIsAddBalance(true)}>
                Add Balance
              </Button>
            </Stack>
          </Widget>
        </Grid>
      </Grid>
    </Container>
  );
}

export default index;
