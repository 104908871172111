import React, { ReactNode } from 'react';
import { Box, useTheme } from '@mui/material';

export interface WidgetFooterProps {
  /**
   * Content of widget footer.
   */
  children?: ReactNode;
  /**
   * If `true` top line will be disabled.
   * @default false
   */
  disableLine?: boolean;
}

/**
 * Wrapper for widget header. This component should be placed inside `Widget` component.
 */
const WidgetFooter = ({ children, disableLine = false }: WidgetFooterProps) => {
  const { palette } = useTheme();
  return (
    <Box
      sx={{
        pt: 2.4,
        px: 2,
        mx: -2,
        borderTop: disableLine ? undefined : `solid 1px ${palette.neutral.light}`,
      }}>
      {children}
    </Box>
  );
};

export default WidgetFooter;
