import React from 'react';
import { Box, ButtonProps, Stack, Typography, Button } from '@mui/material';
import Searchbar from '../Searchbar';
import TableFilter, { TableFilterProps } from './TableFilter';

export interface BulkActionProps<DataType> extends Omit<ButtonProps, 'onChange'> {
  label: string;
  onAction?: (selected: DataType[]) => void;
}

export interface TableToolbarProps<DataType> {
  selected: DataType[];
  filters: TableFilterProps[];
  bulkActions: Array<BulkActionProps<DataType>>;
  searchValue?: string;
  onSearch?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

const TableToolbar = <T extends Record<string, unknown>>(props: TableToolbarProps<T>) => {
  const { filters, selected, bulkActions, searchValue, onSearch } = props;

  const handleBulkAction = (onAction?: (selected: T[]) => void) => () => {
    onAction?.(selected);
  };

  const showBulkActionsBtn = selected.length > 0;
  const showSearchField = Boolean(onSearch && selected.length === 0);
  const showFilters = filters.length > 0 && selected.length === 0;

  const onlyOneItem = showBulkActionsBtn || !showSearchField || !showFilters;
  return (
    <Box
      sx={{
        p: 2,
        pt: 0,
        display: 'flex',
        justifyContent: onlyOneItem ? 'flex-end' : 'space-between',
      }}>
      {showSearchField && <Searchbar value={searchValue} onChange={onSearch} />}
      <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
        {selected.length > 0 ? (
          <>
            <Typography variant="b2" sx={{ fontWeight: 500 }}>
              {selected.length} selected
            </Typography>

            {bulkActions.map(({ label, onAction, color = 'primary', ...params }) => (
              <Button
                key={label}
                variant="outlined"
                color={color}
                {...params}
                onClick={handleBulkAction(onAction)}>
                {label}
              </Button>
            ))}
          </>
        ) : (
          filters.map((params) => <TableFilter key={params.label} {...params} />)
        )}
      </Stack>
    </Box>
  );
};

export default TableToolbar;
