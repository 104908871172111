import {
  Button,
  CircularProgress,
  Dialog,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { MinusCrfr, Plus } from '@xcidic/icons';
import { useFormik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  editInitialValues as initialValues,
  editValidationSchema as validationSchema,
} from './schemeValidation';
import Confirm from '../../../components/Confirm';
import { ToastManagerContext } from '../../../components/ToastManager';
import { editService, requestDataEdit } from '../../../redux/modules/otpTemplate';
import { AppDispatch } from '../../../redux/store';

// import { date } from 'yup/lib/locale';
// type CloseReason = 'backdropClick' | 'escapeKeyDown' | 'closeButtonClick';
interface PropsModal {
  open: boolean;
  // onClose: (reason: CloseReason) => void;
  onClose: Function;
  id: String;
  name: String;
  content: string;
}

interface parametersContent {
  type: String;
  text: String;
}

interface componentContent {
  type: String;
  parameters: parametersContent[];
}
interface jsonDataRequest {
  name: String;
  language: {
    code: String;
  };
  components: componentContent[];
}

export default function index({ open, onClose, id, name, content }: PropsModal) {
  const { create } = useContext(ToastManagerContext);
  const [isLoading, setIsLoading] = useState(false);
  const [contents, setContent] = useState('');
  const dispatch = useDispatch<AppDispatch>();
  const [errorContent, setErrorContent] = useState('');
  const [isConfirm, setIsConfirm] = useState(false);

  const {
    handleChange,
    handleBlur,
    // setSubmitting,
    handleSubmit,
    setFieldValue,
    dirty,
    // resetForm,
    values,
    isValid,
    touched,
    errors,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async () => {
      setIsLoading(true);
      try {
        const contentJson = {
          name: values.name,
          language: {
            code: values.languageCode,
          },
          components: [
            {
              type: 'body',
              parameters: values?.component?.map((val) => {
                return {
                  type: 'text',
                  text: val,
                };
              }),
            },
          ],
        };
        const data: requestDataEdit = {
          id: values.id,
          name: values.name,
          content: JSON.stringify(contentJson),
        };
        dispatch(editService(data))
          .unwrap()
          .then((res) => {
            setIsLoading(false);

            create('success', 'Success', res.responseApi.message);
            onClose();
          })
          .catch((err) => {
            setIsLoading(false);

            create('error', 'Error', err);
          });
      } catch (error) {
        // handle any rejections/errors
      }
    },
  });

  const isJsonString = (str: string) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    setFieldValue('id', id);
    setFieldValue('name', name);
    const jsonData: jsonDataRequest =
      isJsonString(content) &&
      JSON.parse(
        content ??
          `{
      "name":"verification",
      "language":{
         "code":"en"
      },
      "components":[
         {
            "type":"body",
            "parameters":[
               {
                  "type":"text",
                  "text":"[[otp]]"
               },
               {
                  "type":"text",
                  "text":"[[product]]"
               },
               {
                  "type":"text",
                  "text":"Hello World"
               }
            ]
         }
      ]
   }`
      );

    if (isJsonString(content)) {
      setFieldValue('languageCode', jsonData?.language?.code);

      const component = jsonData?.components[0]?.parameters?.map((val: any) => {
        return val?.text;
      });

      setFieldValue('component', component);
    } else {
      setFieldValue('languageCode', 'en');
      setFieldValue('component', []);
    }
  }, [open]);

  const handleAdd = () => {
    // eslint-disable-next-line
    setFieldValue('component', [...values?.component, content]);
    setContent('');
    setErrorContent('');
  };

  const handleDelete = (index: number) => {
    const data = values.component;
    data?.splice(index, 1);
    setFieldValue('component', data);
  };
  const checkSubmit = () => {
    if (
      !values?.component?.find((val) => val === '[[otp]]') ??
      !values?.component?.find((val) => val === '[[product]]')
    ) {
      setErrorContent('Must Have [[otp]] and [[product]]');
      return;
    }
    setIsConfirm(true);
  };
  return (
    <Dialog open={open} onClose={() => onClose()}>
      <Grid container spacing={3} style={{ padding: 20 }}>
        <Grid item lg={12}>
          <TextField
            fullWidth
            // autoFocus
            name="name"
            variant="outlined"
            // type="email"
            label="Template Name *"
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
            className="mt-0 mb-4"
            size="medium"
          />
        </Grid>
        <Grid item lg={12}>
          <InputLabel>Language Code</InputLabel>
          <Select
            value={values.languageCode}
            onChange={(e) => setFieldValue('languageCode', e.target.value as string)}>
            <MenuItem value={'en'}>English</MenuItem>
            <MenuItem value={'id'}>Indonesian</MenuItem>
          </Select>
        </Grid>
        {/* <Grid item lg={12}>
          <TextField
            fullWidth
            // autoFocus
            name="content"
            variant="outlined"
            // type="email"
            label="Content *"
            multiline
            error={Boolean(touched.content && errors.content)}
            helperText={touched.content && errors.content}
            value={values.content}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
            className="mt-0 mb-4"
            size="medium"
          />
          <p>{`Use {{otp}} for Genrate OTP, Use {{company}} for Your Company Name`}</p>
        </Grid> */}
        {values?.component?.map((val, index) => (
          <Grid item lg={12} key={index}>
            <Grid container spacing={2}>
              <Grid item lg={8}>
                <TextField
                  fullWidth
                  // autoFocus
                  name="Content"
                  variant="outlined"
                  // type="email"
                  label="Content *"
                  // error={Boolean(touched.name && errors.name)}
                  // helperText={touched.name && errors.name}
                  value={val}
                  disabled
                  //  onChange={(e) => setContent(e.target.value)}
                  // onBlur={handleBlur}
                  autoComplete="off"
                  className="mt-0 mb-4"
                  size="medium"
                />
              </Grid>

              <Grid item lg={4}>
                <Button
                  variant="contained"
                  color="primary"
                  // disabled={content === ""}
                  onClick={() => handleDelete(index)}>
                  <MinusCrfr />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ))}

        <Grid item lg={12}>
          <Grid container spacing={2}>
            <Grid item lg={8}>
              <TextField
                fullWidth
                // autoFocus
                name="Content"
                variant="outlined"
                // type="email"
                label="Content *"
                // error={Boolean(touched.name && errors.name)}
                // helperText={touched.name && errors.name}
                value={contents}
                onChange={(e) => setContent(e.target.value)}
                // onBlur={handleBlur}
                autoComplete="off"
                className="mt-0 mb-4"
                size="medium"
              />
            </Grid>

            <Grid item lg={4}>
              <Button
                variant="contained"
                color="primary"
                disabled={content === ''}
                onClick={handleAdd}>
                <Plus />
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={12}>
          <p style={{ color: 'red' }}>{errorContent}</p>
        </Grid>
        <Grid item lg={12} style={{ justifyContent: 'flex-end', display: 'flex' }}>
          <Button
            name="company"
            variant="contained"
            color="primary"
            disabled={!(dirty && isValid)}
            onClick={() => checkSubmit()}>
            {isLoading ? (
              <CircularProgress style={{ color: 'white' }} size={30} />
            ) : (
              <span className="loginButtonCaption"> Edit Template</span>
            )}
          </Button>
          <Confirm
            type={'Create'}
            open={isConfirm}
            handleAksi={() => {
              setIsConfirm(false);
              handleSubmit();
            }}
            loading={false}
            handleClose={() => setIsConfirm(false)}
            disable={false}
            handleContent={{
              title: 'Edit Template',
              message: 'Do you wish to Edit this Template?',
              button: 'Submit',
            }}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
}
