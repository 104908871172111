import React, { PropsWithChildren } from 'react';
// material
import { Box, Divider, Typography } from '@mui/material';

export interface DashboardHeaderProps extends PropsWithChildren {
  title: string;
}

const DashboardHeader = ({ title, children }: DashboardHeaderProps) => {
  return (
    <Box mb={2}>
      <Box sx={{ mb: 1.2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography
          component="h1"
          variant="h3"
          sx={{ fontWeight: 700 }}
          style={{ fontSize: '1.5rem', lineHeight: '2rem' }}>
          {title}
        </Typography>
        {children}
      </Box>
      <Divider />
    </Box>
  );
};

export default DashboardHeader;
