import { Paper, PaperProps } from '@mui/material';

export interface WidgetProps extends PaperProps {}

const Widget = ({ sx, ...others }: WidgetProps) => {
  return (
    <Paper
      sx={{
        px: 2,
        py: 2.4,
        backgroundColor: '#fff',
        borderRadius: '12px',
        ...sx,
      }}
      {...others}
    />
  );
};

export default Widget;
