import React, { useRef, useState, ReactNode } from 'react';
import {
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
} from '@mui/material';
import { MoreSqfr } from '@xcidic/icons';

export interface ActionItem<T> {
  label: string;
  icon: ReactNode;
  renderLabel?: (row: T) => string;
  render?: (row: T) => ReactNode;
  onAction?: (data: T) => void;
}
const renderIconLabel = <T extends {}>(
  row: T,
  label: string,
  render?: (row: T) => ReactNode
): ReactNode => {
  if (render) {
    const rendered = render(row);
    return rendered;
  }

  return label;
};

const renderIcon = <T extends {}>(
  row: T,
  icon: ReactNode,
  render?: (row: T) => ReactNode
): ReactNode => {
  if (render) {
    const rendered = render(row);
    return rendered;
  }

  return icon;
};
export interface ActionMenuProps<T> {
  data: T;
  actions: Array<ActionItem<T>>;
}

// -----------------------------------------------------------------------------

const ActionMenu = <T extends {}>({ data, actions = [] }: ActionMenuProps<T>) => {
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAction = (data: T, onAction?: (data: T) => void) => () => {
    onAction?.(data);
    setOpen(false);
  };

  return (
    <>
      <IconButton ref={ref} onClick={handleOpen}>
        <MoreSqfr />
      </IconButton>
      <Popover
        open={isOpen}
        anchorEl={ref.current}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: { minWidth: 150, maxWidth: '100%' },
        }}>
        <List>
          {actions.map((action) => (
            <ListItemButton key={action.label} onClick={handleAction(data, action.onAction)}>
              <ListItemIcon sx={{ color: 'inherit', minWidth: 36 }}>
                {renderIcon(data, action.icon, action.render)}
              </ListItemIcon>
              <ListItemText primary={renderIconLabel(data, action.label, action.renderLabel)} />
            </ListItemButton>
          ))}
        </List>
      </Popover>
    </>
  );
};

export default ActionMenu;
