import { useContext, useEffect, useState } from 'react';
import { ToastManagerContext } from '../../components/ToastManager';
import Container from '../../components/Container';
import GettingStarted from './GettingStarted';
import FeatureGuide from './FeatureGuide';

// redux
import { AppDispatch } from '../../redux/store';
import { useDispatch } from 'react-redux';
import { get as getCompanyData } from '../../redux/modules/company';
import { get as getTokenData } from '../../redux/modules/token';

function FTU() {
  // state init
  const dispatch = useDispatch<AppDispatch>();
  const { create } = useContext(ToastManagerContext);
  const [companyGuideCompleted, setCompanyGuideCompleted] = useState(false);
  const [tokenGuideCompleted, setTokenGuideCompleted] = useState(false);

  useEffect(() => {
    // if there is any company data, then it will mark the company guide as completed
    dispatch(getCompanyData())
      .unwrap()
      .then((res) => {
        if (res.list.data.length > 0) {
          setCompanyGuideCompleted(true);
        }
      })
      .catch((err) => {
        create('error', 'Error', err);
      });

    // if there is any token data, then it will mark the token guide as completed
    dispatch(getTokenData({ limit: 1, offset: 0 }))
      .unwrap()
      .then((res) => {
        if (res.listToken.data.length > 0) {
          setTokenGuideCompleted(true);
        }
      })
      .catch((err) => {
        create('error', 'Error', err);
      });
  }, []);

  return (
    <Container>
      {!companyGuideCompleted || !tokenGuideCompleted ? <GettingStarted /> : <FeatureGuide />}
    </Container>
  );
}

export default FTU;
