import { Button, CircularProgress, Typography, Stack } from '@mui/material';
import { useFormik } from 'formik';
import { useContext, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  editInitialValues as initialValues,
  editValidationSchema as validationSchema,
} from './schemeValidation';
import Confirm from '../../../components/Confirm';
import Dialog from '../../../components/Dialog';
import TextField from '../../../components/TextField';
import { ToastManagerContext } from '../../../components/ToastManager';
import { editService, requestDataEdit } from '../../../redux/modules/responseAutoReply';
import { AppDispatch } from '../../../redux/store';

interface PropsModal {
  open: boolean;
  onClose: Function;
  id: string;
  value: string;
}

export default function index({ open, onClose, id, value }: PropsModal) {
  const { create } = useContext(ToastManagerContext);
  const dispatch = useDispatch<AppDispatch>();
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    dirty,
    resetForm,
    values,
    isValid,
    touched,
    errors,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async () => {
      setIsLoading(true);
      try {
        const data: requestDataEdit = {
          id: values.id,
          value: values.value,
        };
        dispatch(editService(data))
          .unwrap()
          .then((res) => {
            setIsLoading(false);

            create('success', 'Success', res.responseApi.message);
            resetForm();
            onClose();
          })
          .catch((err) => {
            setIsLoading(false);

            create('error', 'Error', err);
          });
      } catch (err) {
        console.error(err);
      }
    },
  });
  useEffect(() => {
    setFieldValue('id', id);
    setFieldValue('value', value);
  }, [open]);

  return (
    <>
      <Dialog open={open} onClose={() => onClose()} maxWidth="sm" fullWidth>
        <Typography variant="h3" fontWeight={600} mb={4}>
          Edit Auto Reply Answer
        </Typography>
        <Stack spacing={3}>
          <TextField
            label="Auto Reply Answer"
            id="standard-textarea"
            fullWidth
            name="value"
            multiline
            rows={4}
            error={Boolean(touched.value && errors.value)}
            errorText={errors.value}
            value={values.value}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
            size="medium"
          />
          <Button
            fullWidth
            name="company"
            variant="contained"
            color="primary"
            disabled={!(dirty && isValid)}
            onClick={() => setIsConfirm(true)}>
            {isLoading ? (
              <CircularProgress sx={{ color: 'white' }} size={30} />
            ) : (
              <span className="loginButtonCaption"> Save Changes</span>
            )}
          </Button>
        </Stack>
      </Dialog>
      <Confirm
        open={isConfirm}
        type={'Create'}
        handleAksi={() => {
          setIsConfirm(false);
          handleSubmit();
        }}
        loading={false}
        handleClose={() => setIsConfirm(false)}
        disable={false}
        handleContent={{
          title: 'Edit Anwer',
          message: 'Do you wish to Edit this Answer?',
          button: 'Submit',
        }}
      />
    </>
  );
}
