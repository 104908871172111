import { Paper } from '@mui/material';
// import { Box } from "@mui/system";
import { useDispatch } from 'react-redux';
// import Logo from 'src/components/LogoSign';
import { useEffect, useState } from 'react';
import { get } from '../../redux/modules/token';
import { AppDispatch } from '../../redux/store';
import CompanyComponent from '../Company/Edit';
import UserComponent from './user';

function index() {
  const dispatch = useDispatch<AppDispatch>();
  // eslint-disable-next-line
  const [list, setList] = useState([]);
  useEffect(() => {
    dispatch(get({ limit: 5, offset: 1 }))
      .unwrap()
      .then((res) => {
        setList(res.listToken?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // const updateToken = () => {
  //   dispatch(get({ limit: 5, offset: 1 }))
  //     .unwrap()
  //     .then((res) => {
  //       console.log(res?.listToken?.data);
  //       setList(res.listToken?.data);
  //     })
  //     .catch((err) => {
  //       // console.log(err)
  //     });
  // };
  return (
    <Paper style={{ padding: 50 }}>
      <CompanyComponent />
      <UserComponent />
      {/* <TokenComponent listToken={list} updateToken={updateToken} /> */}
    </Paper>
  );
}

export default index;
