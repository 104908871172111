/* eslint-disable */
import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useDispatch } from 'react-redux';
import { Box, Typography, Grid, Tooltip } from '@mui/material';
// import { Theme } from '@mui/material/styles';
// import { makeStyles } from '@mui/styles';
import { renderToString } from 'react-dom/server';
import { getService } from '../../../redux/modules/statistic';
import { AppDispatch } from '../../../redux/store';
import { Line2Up, Line2Down } from '@xcidic/icons';
import './Tooltip.css';

interface propsModal {
  title: string;
  url: string;
  type: string;
  typeChart:
    | 'area'
    | 'line'
    | 'bar'
    | 'histogram'
    | 'pie'
    | 'donut'
    | 'radialBar'
    | 'scatter'
    | 'bubble'
    | 'heatmap'
    | 'treemap'
    | 'boxPlot'
    | 'candlestick'
    | 'radar'
    | 'polarArea'
    | 'rangeBar'
    | undefined;
}

export default function index({ title, url, type, typeChart }: propsModal) {
  // const [options, setOption] = useState<ApexOptions | undefined>();
  // eslint-disable-next-line
  const [series, setSeries] = useState<ApexAxisChartSeries | ApexNonAxisChartSeries | undefined>([
    {
      name: '',
      data: [],
    },
  ]);
  const [total, setTotal] = useState(0);
  const [percent, setPercent] = useState(0);
  const [status, setStatus] = useState('');

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getService({ url, type }))
      .unwrap()
      .then((res) => {
        // console.log({
        //   name: type?.toLocaleUpperCase(),
        //   data: res.responseApi.data.data ?? [],
        // });
        setSeries([
          {
            name: type?.toLocaleUpperCase(),
            data: res.responseApi.data.data ?? [],
          },
        ]);
        setTotal(res.responseApi.data.total);
        setPercent(res.responseApi.data.persentance);
        setStatus(res.responseApi.data.type);

        // create('success', 'Success', res.list.message)
      })
      .catch(() => {
        // create('error', 'Error', err);
      });
  }, []);
  return (
    <Box>
      {/* <Box sx={{ display: 'flex', gap: 1 }}> */}
      <Grid container>
        <Grid lg={5} sx={{ mb: 1 }}>
          <Typography variant="b3">{title}</Typography>
          <Typography variant="h4" sx={{ fontWeight: 700 }}>
            {total}
          </Typography>
        </Grid>
        <Grid lg={7}>
          <Box sx={{ width: '100%' }}>
            <ReactApexChart
              type={typeChart}
              // width={'106px'}
              width={'100%'}
              height={'50%'}
              series={series}
              options={{
                chart: {
                  toolbar: { show: false },
                },

                tooltip: {
                  custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                    // console.log(series, seriesIndex, dataPointIndex, w);
                    // console.log(w.config.labels[dataPointIndex]);
                    // return renderToString(
                    //   <div className="tooltip">
                    //     <span className="tooltiptext">{w.globals.labels[dataPointIndex]}</span>
                    //   </div>
                    // );
                    // console.log(series[seriesIndex][dataPointIndex]);
                    return (
                      // eslint-disable-next-line
                      '<div class="arrow_box">' +
                      '<span >' +
                      new Date().toLocaleString('en-US', { month: 'short' }) +
                      ` ` +
                      w.globals.labels[dataPointIndex] +
                      ',' +
                      new Date().getFullYear() +
                      '<div class="blueDot"></div> ' +
                      '<p class="textBlueDot">' +
                      series[seriesIndex][dataPointIndex] +
                      ' ' +
                      'messages' +
                      '</p>' +
                      '</span>' +
                      '</div>'
                    );
                  },
                },
                xaxis: {
                  axisBorder: {
                    show: false,
                  },
                  axisTicks: {
                    show: false,
                  },
                  labels: {
                    show: false,
                  },
                  tooltip: {
                    enabled: false,
                  },
                },
                yaxis: {
                  show: false,
                },
                stroke: {
                  curve: 'smooth',
                },
                grid: {
                  yaxis: {
                    lines: {
                      show: false,
                    },
                  },
                  xaxis: {
                    lines: {
                      show: false,
                    },
                  },
                  show: false,
                },

                legend: {
                  show: false,
                },
              }}
            />
          </Box>
        </Grid>
      </Grid>
      {/* </Box> */}
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <Box
          sx={{
            background: status === 'increase' ? '#BEF6DC' : '#f08c80',
            color: status === 'increase' ? '#12B76A' : '#e11900',
            padding: '2px 4px',
            borderRadius: '4px',
            display: 'flex',
            alignItems: 'center',
          }}>
          {status === 'increase' ? (
            <Line2Up sx={{ fontSize: 14 }} />
          ) : status === 'decrease' ? (
            <Line2Down sx={{ fontSize: 14 }} />
          ) : null}
          <Typography variant="b3">
            {status === 'increase' ? '+' : ''}
            {percent}%
          </Typography>
        </Box>
        <Typography variant="b3">This Month</Typography>
      </Box>
    </Box>
  );
}
