import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { BASE_URL } from '../../utils/url';
import axios from '../../utils/request';
import { getUser } from '../../utils/localStorage';

interface params {
  limit: number;
  offset: number;
}

export interface paramsById {
  id: string;
}

export interface requestData {
  name: string;
  content: string;
}

export interface requestDataUpdate {
  id: string;
  name: string;
  isActive: Boolean;
}

export interface requestDataEdit {
  id: string;
  name: string;
  content: string;
}

interface errorRequest {
  message: string;
}

const initialState = {
  list: [],
  responseApi: {},
};

const createTemplate = (name: string, content: string) => {
  const { token } = getUser();

  return axios.post(
    `${BASE_URL}/otp/template/my`,
    {
      name,
      content,
    },
    {
      headers: {
        Authorization: `Bearer ${token as string}`,
      },
    }
  );
};

export const createService = createAsyncThunk(
  'module/createOtpTemplate',
  async ({ name, content }: requestData, thunkAPI) => {
    try {
      const data = await createTemplate(name, content);
      thunkAPI.fulfillWithValue(data.data);
      return { responseApi: data?.data };
    } catch (error) {
      const err = error as AxiosError;
      return thunkAPI.rejectWithValue((err.response?.data as errorRequest).message);
    }
  }
);

const editTemplate = (id: string, name: string, content: string) => {
  const { token } = getUser();

  return axios.patch(
    `${BASE_URL}/otp/template/my/${id}`,
    {
      name,
      content,
    },
    {
      headers: {
        Authorization: `Bearer ${token as string}`,
      },
    }
  );
};

export const editService = createAsyncThunk(
  'module/editOtpTemplate',
  async ({ id, name, content }: requestDataEdit, thunkAPI) => {
    try {
      const data = await editTemplate(id, name, content);
      thunkAPI.fulfillWithValue(data.data);
      return { responseApi: data?.data };
    } catch (error) {
      const err = error as AxiosError;
      return thunkAPI.rejectWithValue((err.response?.data as errorRequest).message);
    }
  }
);
const updateTemplate = (id: string, name: string, isActive: Boolean) => {
  const { token } = getUser();

  return axios.patch(
    `${BASE_URL}/otp/template/my/${id}`,
    {
      name,
      isActive,
    },
    {
      headers: {
        Authorization: `Bearer ${token as string}`,
      },
    }
  );
};

export const updateService = createAsyncThunk(
  'module/updateOtpTemplate',
  async ({ id, name, isActive }: requestDataUpdate, thunkAPI) => {
    try {
      const data = await updateTemplate(id, name, isActive);
      thunkAPI.fulfillWithValue(data.data);
      return { responseApi: data?.data };
    } catch (error) {
      const err = error as AxiosError;
      return thunkAPI.rejectWithValue((err.response?.data as errorRequest).message);
    }
  }
);

const getOtpTemplate = (limit: number, offset: number) => {
  const { token } = getUser();
  return axios.get(`${BASE_URL}/otp/template/my?limit=${limit}&offset=${offset}`, {
    headers: {
      Authorization: `Bearer ${token as string}`,
    },
  });
};

export const get = createAsyncThunk(
  'module/getOtpTemplate',
  async ({ limit, offset }: params, thunkAPI) => {
    try {
      const data = await getOtpTemplate(limit, offset);
      // console.log(data);
      thunkAPI.fulfillWithValue(data.data);
      return { listToken: data?.data };
    } catch (error) {
      const err = error as AxiosError;
      // console.log(err);
      return thunkAPI.rejectWithValue((err.response?.data as errorRequest).message);
    }
  }
);
const getById = (id: string) => {
  const { token } = getUser();
  return axios.get(`${BASE_URL}/otp/template/my/${id}`, {
    headers: {
      Authorization: `Bearer ${token as string}`,
    },
  });
};

export const getServiceById = createAsyncThunk(
  'module/getOtpTemplateId',
  async ({ id }: paramsById, thunkAPI) => {
    try {
      const data = await getById(id);
      // // console.log(data);
      thunkAPI.fulfillWithValue(data.data);
      return { responseApi: data?.data };
    } catch (error) {
      const err = error as AxiosError;
      // // console.log(err);
      return thunkAPI.rejectWithValue((err.response?.data as errorRequest).message);
    }
  }
);
const deleteOtpTemplate = (id: string) => {
  const { token } = getUser();
  return axios.delete(`${BASE_URL}/otp/template/my/${id}`, {
    headers: {
      Authorization: `Bearer ${token as string}`,
    },
  });
};

export const deleteService = createAsyncThunk(
  'module/deleteOtpTemplate',
  async ({ id }: paramsById, thunkAPI) => {
    try {
      const data = await deleteOtpTemplate(id);
      thunkAPI.fulfillWithValue(data.data);
      return { responseApi: data?.data };
    } catch (error) {
      const err = error as AxiosError;
      // console.log(err);
      return thunkAPI.rejectWithValue((err.response?.data as errorRequest).message);
    }
  }
);

const otpTemplate = createSlice({
  name: 'otpTemplate',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createService.fulfilled, (state, action) => {
      state.responseApi = action.payload.responseApi;
    });
    builder.addCase(createService.rejected, (state, action) => {
      state.responseApi = {};
    });

    builder.addCase(editService.fulfilled, (state, action) => {
      state.responseApi = action.payload.responseApi;
    });
    builder.addCase(editService.rejected, (state, action) => {
      state.responseApi = {};
    });

    builder.addCase(updateService.fulfilled, (state, action) => {
      state.responseApi = action.payload.responseApi;
    });
    builder.addCase(updateService.rejected, (state, action) => {
      state.responseApi = {};
    });

    builder.addCase(deleteService.fulfilled, (state, action) => {
      state.responseApi = action.payload.responseApi;
    });
    builder.addCase(deleteService.rejected, (state, action) => {
      state.responseApi = {};
    });
    builder.addCase(get.fulfilled, (state, action) => {
      state.list = action.payload.listToken;
      // state.isLoggedIn = true;
      // state.user = action.payload.user;
    });
    builder.addCase(get.rejected, (state, action) => {
      state.list = [];

      // state.isLoggedIn = false;
      // state.user = null;
    });

    builder.addCase(getServiceById.fulfilled, (state, action) => {
      state.responseApi = action.payload.responseApi;
    });
    builder.addCase(getServiceById.rejected, (state, action) => {
      state.responseApi = {};
    });
  },
});

const { reducer } = otpTemplate;
export default reducer;
