import Prism from 'prismjs';
import 'prismjs/plugins/copy-to-clipboard/prism-copy-to-clipboard.min';
import 'prismjs/plugins/toolbar/prism-toolbar.min';
import 'prismjs/plugins/toolbar/prism-toolbar.min.css';
import 'prismjs/themes/prism-tomorrow.css';
import { useEffect } from 'react';
import './style.css';

interface props {
  code: string;
  language: string;
}

export default function Code({ code, language }: props) {
  useEffect(() => {
    Prism.highlightAll();
  }, [code, language]);
  return (
    <div className="Code">
      <pre>
        <code className={`language-${language}`}>{code}</code>
      </pre>
    </div>
  );
}
