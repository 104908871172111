import { Button, Divider, Grid, Paper, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ToastManagerContext } from '../../components/ToastManager';
import {
  deleteService,
  getServiceById,
  sendService,
  getUsageStatus,
} from '../../redux/modules/blastMessage';
import { get as getCompany } from '../../redux/modules/company';
import { AppDispatch } from '../../redux/store';

import 'react-phone-input-2/lib/material.css';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import Field from '../../components/Fields';

import Breadcrumbs from '../../components/Breadcrumbs';
import DashboardHeader from '../../components/DashboardHeader';
import Confirm from '../../components/Confirm';
import Container from '../../components/Container';
import { TrashCan, BankCards } from '@xcidic/icons';
import NotifWallet from '../../components/NotifWallet';

interface categories {
  id: string;
  name: string;
}

interface requestData {
  id: string;
  name: string;
  content: string;
  targetContacts: categories[];
  targetCategories: categories[];
}

interface parametersContent {
  type: string;
  text: string;
}

interface componentContent {
  type: string;
  parameters: parametersContent[];
}
interface jsonDataRequest {
  name: string;
  language: {
    code: string;
  };
  components: componentContent[];
}

interface objectState {
  id: string;
}

interface requestDataCompany {
  name: string;
  phones: objectState[];
}

interface usageStatus {
  restriction: boolean;
}

export default function index() {
  const { create } = useContext(ToastManagerContext);
  const [isConfirm, setIsConfirm] = useState(false);
  const [isConfirmSend, setIsConfirmSend] = useState(false);
  const [restriction, setIsRestriction] = useState<usageStatus>({ restriction: false });
  const dispatch = useDispatch<AppDispatch>();
  const [data, setData] = useState<requestData>();

  const [dataCompany, setDataCompany] = useState<requestDataCompany>({
    name: '',
    phones: [{ id: '' }],
  });

  const navigate = useNavigate();
  // const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    dispatch(getServiceById({ id: id ?? '' }))
      .unwrap()
      .then((res) => {
        // setUserContact(res.listToken?.data)
        setData(res?.responseApi?.data);
        // create('success', 'Success', res.responseApi.message);
      })
      .catch((err) => {
        // console.log(err)
        create('error', 'Error', err);
      });
    dispatch(getCompany())
      .unwrap()
      .then((res) => {
        if (res.list.data.length > 0) {
          const dataAPI = res.list.data[0];
          setDataCompany(dataAPI);
        }
        // console.log(res.list)
        // create('success', 'Success', res.list.message)
      })
      .catch((err) => {
        create('error', 'Error', err);
      });
    dispatch(getUsageStatus())
      .unwrap()
      .then((res) => {
        setIsRestriction(res.responseApi);
        // console.log(res.responseApi);
        // create('success', 'Success', res.list.message)
      })
      .catch((err) => {
        create('error', 'Error', err);
      });
  }, []);

  const isJsonstring = (str: string) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };
  const jsonData: jsonDataRequest =
    isJsonstring(String(data?.content)) &&
    JSON.parse(
      String(data?.content) ??
        `{
    "name":"verification",
    "language":{
       "code":"en"
    },
    "components":[
       {
          "type":"body",
          "parameters":[
             {
                "type":"text",
                "text":"[[otp]]"
             },
             {
                "type":"text",
                "text":"[[product]]"
             },
             {
                "type":"text",
                "text":"Hello World"
             }
          ]
       }
    ]
 }`
    );

  const deleteData = () => {
    dispatch(deleteService({ id: id ?? '' }))
      .unwrap()
      .then((res) => {
        navigate('/message/blast');
        create('success', 'Success', res.responseApi.message);
      })
      .catch((err) => {
        // console.log(err)
        create('error', 'Error', err);
      });
  };

  const sendMessage = () => {
    dispatch(sendService({ blastId: id ?? '', handlerId: dataCompany?.phones[0]?.id }))
      .unwrap()
      .then((res) => {
        // navigate('/message/blast')
        create('success', 'Success', res.responseApi.message);
      })
      .catch((err) => {
        // console.log(err)
        create('error', 'Error', err);
      });
  };
  return (
    <Container>
      <Breadcrumbs
        items={[
          {
            label: 'Message Blast',
            link: '/message',
          },
          {
            label: 'Template',
            link: '/message/blast',
          },
          {
            label: 'Message Blast Template Detail',
            link: `/message/blast/view/${id ?? ''}`,
          },
        ]}
      />
      <DashboardHeader title="Message Blast Template Detail" />
      {restriction?.restriction && (
        <div style={{ marginBottom: 16 }}>
          <NotifWallet isBefore={false} />
        </div>
      )}

      <Paper>
        <Field withElement={false} small={false} label="Template Name" value={data?.name} />
        <Divider />

        <Field
          withElement={true}
          small={false}
          label="Language Code"
          value={jsonData?.language?.code === 'en' ? 'English' : 'Indonesian'}
        />
        <Divider />

        {/* {!isJsonstring(String(data?.content)) && (
          <div>
            <Field
              withElement={true}
              small={false}
              label="Content Body"
              value={data?.content ?? ''}
            />
            <Divider />
          </div>
        )} */}
        {isJsonstring(String(data?.content)) && (
          <Field
            withElement={false}
            small={false}
            label="Messages"
            value={jsonData?.components[0]?.parameters?.map((val, index) =>
              index + 1 === jsonData.components[0].parameters.length ? val.text : `${val.text},`
            )}
          />
        )}
        <Divider />
        <Field
          withElement={false}
          small={false}
          label="Target Contact"
          value={
            <div style={{ display: 'flex' }}>
              {data?.targetContacts?.map((val, index) => {
                return (
                  <p
                    key={index}
                    style={{
                      backgroundColor: '#276EF1',
                      color: 'white',
                      width: 'fit-content',
                      padding: 8,
                    }}>
                    {val.name}
                  </p>
                );
              })}
            </div>
          }
        />
        <Divider />
        <Field
          withElement={false}
          small={false}
          label="Target Categories"
          value={
            <div style={{ display: 'flex' }}>
              {data?.targetCategories?.map((val, index) => {
                return (
                  <p
                    key={index}
                    style={{
                      marginRight: 10,
                      backgroundColor: '#276EF1',
                      color: 'white',
                      width: 'fit-content',
                      padding: 8,
                    }}>
                    {val.name}
                  </p>
                );
              })}
            </div>
            // data?.targetCategories?.map((val, index) => {
            //   return (
            //     <div key={index} style={{ display: 'flex' }}>
            //       <p
            //         style={{
            //           backgroundColor: '#276EF1',
            //           color: 'white',
            //           width: 'fit-content',
            //           padding: 8,
            //         }}>
            //         {val.name}
            //       </p>
            //     </div>
            //   );
            // }) ?? '-'
          }
        />
        <Divider />
        <div style={{ margin: '16px 16px 16px 0px' }}>
          <Typography style={{ fontWeight: 500, fontStyle: 'normal', fontSize: '14px' }}>
            Template ID
          </Typography>
          <Typography
            style={{ fontWeight: 300, fontStyle: 'normal', fontSize: '14px', color: '#5d5d5d' }}>
            {data?.id}
            {/* {isToken ? data?.id ?? '' : data?.id?.split('').map(() => '*') ?? ''} */}
            {/* <IconButton onClick={() => setIsToken(!isToken)}>
            {isToken ? <EyeOff /> : <Eye />}
          </IconButton> */}
            <Button
              style={{ marginLeft: 10 }}
              name="copy"
              variant="outlined"
              color="primary"
              // fullWidth
              startIcon={<BankCards />}
              onClick={() => {
                navigator.clipboard.writeText(data?.id ?? '');
                create('success', 'Copy', 'Success Copy Template ID');
              }}
              // disabled={!(dirty && isValid)}
              // onClick={() => setIsConfirm(true)}
            >
              Copy
            </Button>
          </Typography>
        </div>
        <Grid container spacing={3}>
          <Grid item lg={12} style={{ justifyContent: 'flex-end', display: 'flex', marginTop: 10 }}>
            <Button
              name="company"
              variant="outlined"
              color="error"
              startIcon={<TrashCan />}
              style={{ marginRight: 10 }}
              onClick={() => setIsConfirm(true)}>
              Delete Blast Message Template
            </Button>
            <Button
              name="company"
              variant="outlined"
              color="primary"
              disabled={restriction?.restriction}
              style={{ marginRight: 10 }}
              onClick={() =>
                dataCompany?.phones[0]?.id !== ''
                  ? setIsConfirmSend(true)
                  : navigate('/company/create')
              }>
              {dataCompany?.phones[0]?.id !== '' ? 'Send Message' : 'Create Company'}
            </Button>
            <Button
              name="company"
              variant="contained"
              color="primary"
              // eslint-disable-next-line
              onClick={() => navigate(`/message/blast/edit/${data?.id}`)}>
              Edit Blast Message Template
            </Button>
            <Confirm
              open={isConfirm}
              type={'Delete'}
              handleAksi={() => deleteData()}
              loading={false}
              handleClose={() => setIsConfirm(false)}
              disable={false}
              handleContent={{
                title: 'Delete Blast Message Template',
                message: 'Do you wish to Delete this Blast Message Template?',
                button: 'Delete',
              }}
            />
            <Confirm
              open={isConfirmSend}
              handleAksi={() => {
                sendMessage();
                setIsConfirmSend(false);
              }}
              loading={false}
              handleClose={() => setIsConfirmSend(false)}
              disable={false}
              type={'Create'}
              handleContent={{
                title: 'Send Message Blast',
                message: 'Do you wish to Send this Blast Message Template?',
                button: 'Send',
              }}
            />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
