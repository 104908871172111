/* eslint-disable */
import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useDispatch } from 'react-redux';
import { Box, Typography, Grid, Divider } from '@mui/material';

import { getService } from '../../../redux/modules/statistic';
import { AppDispatch } from '../../../redux/store';

interface regularCost {
  blast: number;
  count: number;
  otp: number;
  reply: number;
}

interface sharedCost {
  count: number;
  otp: number;
}

interface data {
  regular: regularCost;
  shared: sharedCost;
}

const Message = () => {
  const dispatch = useDispatch<AppDispatch>();

  const [dataApi, setDataApi] = useState<data>();
  useEffect(() => {
    dispatch(getService({ url: 'message', type: '' }))
      .unwrap()
      .then((res) => {
        // console.log(res);
        setDataApi(res.responseApi.data);
        // create('success', 'Success', res.list.message)
      })
      .catch(() => {
        // create('error', 'Error', err);
      });
  }, []);
  return (
    <div>
      <Typography variant="h4" sx={{ fontWeight: 700 }} display={'inline'}>
        {dataApi?.regular.count}
      </Typography>
      <Typography variant="body2" display={'inline'}>
        &nbsp;&nbsp;Regular Messages Used
      </Typography>
      {/* <div
        style={{
          backgroundColor: '#276EF1',
          color: '#FFF',
          // textAlign: 'center',
          paddingLeft: 12,
          paddingRight: 12,
          paddingTop: 6,
          paddingBottom: 6,
          width: 'fit-content',
          borderRadius: 4,
          marginTop: 8,
        }}>
        <Typography variant="body2" display={'inline'}>
          Cost {dataApi?.regular.count} messages
        </Typography>
      </div> */}
      <div
        style={{
          marginTop: 28,
          marginBottom: 12,
          display: 'flex',
          justifyContent: 'space-between',
        }}>
        <Typography variant="body2" display={'inline'} style={{ color: '#545454' }}>
          OTP
        </Typography>
        <Typography variant="h4" display={'inline'} sx={{ fontWeight: 700 }}>
          {dataApi?.regular.otp}
        </Typography>
      </div>
      <Divider />

      <div
        style={{
          marginTop: 12,
          marginBottom: 12,
          display: 'flex',
          justifyContent: 'space-between',
        }}>
        <Typography variant="body2" display={'inline'} style={{ color: '#545454' }}>
          Blast Message
        </Typography>
        <Typography variant="h4" display={'inline'} sx={{ fontWeight: 700 }}>
          {dataApi?.regular.blast}
        </Typography>
      </div>
      <Divider />
      <div
        style={{
          marginTop: 12,
          marginBottom: 12,
          display: 'flex',
          justifyContent: 'space-between',
        }}>
        <Typography variant="body2" display={'inline'} style={{ color: '#545454' }}>
          Auto Reply
        </Typography>
        <Typography variant="h4" display={'inline'} sx={{ fontWeight: 700 }}>
          {dataApi?.regular.reply}
        </Typography>
      </div>
      <Typography variant="p4" display={'inline'} style={{ color: '#AFAFAF', marginBottom: 12 }}>
        *Apply for each messages
      </Typography>
      <Divider style={{ marginBottom: 20 }} />
      <Typography variant="h4" sx={{ fontWeight: 700 }} display={'inline'}>
        {dataApi?.shared.count}
      </Typography>
      <Typography variant="body2" display={'inline'}>
        &nbsp;&nbsp;Shared Messages Used
      </Typography>
      <div style={{ display: 'flex' }}>
        {/* <div
          style={{
            backgroundColor: '#276EF1',
            color: '#FFF',
            // textAlign: 'center',
            paddingLeft: 12,
            paddingRight: 12,
            paddingTop: 6,
            paddingBottom: 6,
            width: 'fit-content',
            borderRadius: 4,
            marginTop: 8,
            marginRight: 12,
          }}>
          <Typography variant="body2" display={'inline'}>
            Cost {dataApi?.shared.count} messages
          </Typography>
        </div> */}
        {/* <div
          style={{
            backgroundColor: '#E11900',
            color: '#FFF',
            // textAlign: 'center',
            paddingLeft: 12,
            paddingRight: 12,
            paddingTop: 6,
            paddingBottom: 6,
            width: 'fit-content',
            borderRadius: 4,
            marginTop: 8,
          }}>
          <Typography variant="body2" display={'inline'}>
            Insufficient balance for this action
          </Typography>
        </div> */}
      </div>

      <div
        style={{
          marginTop: 28,
          // marginBottom: 12,
          display: 'flex',
          justifyContent: 'space-between',
        }}>
        <Typography variant="body2" display={'inline'} style={{ color: '#545454' }}>
          OTP
        </Typography>
        <Typography variant="h4" display={'inline'} sx={{ fontWeight: 700 }}>
          {dataApi?.shared.otp}
        </Typography>
      </div>
    </div>
  );
};

export default Message;
