import React, { useContext, useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { CallBackProps } from 'react-joyride';
import { useNavigate } from 'react-router-dom';
import Widget, { WidgetHeader, WidgetContent } from '../../components/Widget';
import FTUCard from './Card';
import { ToastManagerContext } from '../../components/ToastManager';

// icons
import CompanyIcon from './Icons/Company';
import TokenIcon from './Icons/Token';

// redux
import { useDispatch, useSelector } from 'react-redux';
import type { RootState } from '../../redux/store';
import { get as getCompanyData } from '../../redux/modules/company';
import { get as getTokenData } from '../../redux/modules/token';
import { AppDispatch } from '../../redux/store';
import {
  updateGuide,
  toggleStartGuide,
  updateSteps,
  clearSteps,
  updateStepIndex,
  updateCallback,
} from '../../redux/modules/tourGuide';

// tour guides
import companyGuideSteps from './Tours/CompanyGuide';
import tokenGuideSteps from './Tours/TokenGuide';

function GettingStarted() {
  // variable init
  const navigate = useNavigate();
  const { create } = useContext(ToastManagerContext);
  const dispatch = useDispatch<AppDispatch>();
  const [companySetup, setCompanySetup] = useState(false);
  const [tokenSetup, setTokenSetup] = useState(false);
  const [tourRemain, setTourRemain] = useState(2);
  const userName = useSelector((state: RootState) => state.auth.user.name);

  useEffect(() => {
    // if there is any company data, then it will mark the company guide as completed
    dispatch(getCompanyData())
      .unwrap()
      .then((res) => {
        if (res.list.data.length > 0) {
          setCompanySetup(true);
        }
      })
      .catch((err) => {
        create('error', 'Error', err);
      });

    // if there is any token data, then it will mark the token guide as completed
    dispatch(getTokenData({ limit: 1, offset: 0 }))
      .unwrap()
      .then((res) => {
        if (res.listToken.data.length > 0) {
          setTokenSetup(true);
        }
      })
      .catch((err) => {
        create('error', 'Error', err);
      });
  }, []);

  useEffect(() => {
    setTourRemain(companySetup && tokenSetup ? 0 : companySetup && !tokenSetup ? 1 : tourRemain);
  }, [companySetup, tokenSetup]);

  // company guide callback
  const companyGuideCallback = (data: CallBackProps) => {
    const { lifecycle, type, index } = data;

    if (type === 'step:after' && index === 0) {
      dispatch(updateStepIndex(1));
    } else if (type === 'step:after' && index === 1) {
      navigate('/company');
      dispatch(updateStepIndex(2));
    } else if (type === 'step:after' && index === 2) {
      navigate('/');
      dispatch(updateGuide(''));
      dispatch(toggleStartGuide(false));
      dispatch(updateStepIndex(0));
      dispatch(clearSteps());
    } else if (lifecycle === 'complete') {
      navigate('/');
      dispatch(updateGuide(''));
      dispatch(toggleStartGuide(false));
      dispatch(updateStepIndex(0));
      dispatch(clearSteps());
    }
  };

  // token guide callback
  const tokenGuideCallback = (data: CallBackProps) => {
    const { lifecycle, type, index } = data;

    if (type === 'step:after' && index === 0) {
      dispatch(updateStepIndex(1));
    } else if (type === 'step:after' && index === 1) {
      navigate('/token');
      dispatch(updateStepIndex(2));
    } else if (type === 'step:after' && index === 2) {
      navigate('/');
      dispatch(updateGuide(''));
      dispatch(toggleStartGuide(false));
      dispatch(updateStepIndex(0));
      dispatch(clearSteps());
    } else if (lifecycle === 'complete') {
      navigate('/');
      dispatch(updateGuide(''));
      dispatch(toggleStartGuide(false));
      dispatch(updateStepIndex(0));
      dispatch(clearSteps());
    }
  };

  // trigger the company guide tour
  const startCompanyGuide = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    dispatch(updateGuide('Company Guide'));
    dispatch(toggleStartGuide(true));
    dispatch(updateSteps(companyGuideSteps));
    dispatch(updateCallback(companyGuideCallback));
  };

  // trigger the token guide tour
  const startTokenGuide = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    dispatch(updateGuide('Token Guide'));
    dispatch(toggleStartGuide(true));
    dispatch(updateSteps(tokenGuideSteps));
    dispatch(updateCallback(tokenGuideCallback));
  };

  return (
    <Box>
      <Grid container justifyContent="center" spacing={2} rowSpacing={3}>
        <Grid item xs={12}>
          <Typography
            mb={1}
            component="h1"
            variant="h1"
            textAlign="center"
            sx={{ fontWeight: 700, lineHeight: '2rem' }}>
            Welcome, {userName}!
          </Typography>
          <Typography textAlign="center">
            Let’s start optimizing your account! so you can enhance your promotional communication
            channel easily
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Widget>
            <WidgetHeader title="Getting started">
              <Typography fontSize={14} fontWeight={500} sx={{ color: '#AFAFAF' }}>
                {tourRemain <= 0 ? 0 : tourRemain} step(s) left
              </Typography>
            </WidgetHeader>
            <WidgetContent>
              <Typography mt={2.5} mb={4} fontWeight={500} id="test">
                To better use for your{' '}
                <Box component="span" sx={{ fontWeight: 700 }}>
                  promotional plan
                </Box>
                , here are some helpful steps to get started:
              </Typography>
              <Grid container columnSpacing={4}>
                <Grid item xs={6}>
                  <FTUCard
                    cardIcon={CompanyIcon}
                    title="Complete your company detail"
                    desc="Before sending messages, you will need a sender identity for your messages integration."
                    buttonName={companySetup ? 'Completed' : 'Create Company'}
                    onClick={companySetup ? () => {} : startCompanyGuide}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FTUCard
                    cardIcon={TokenIcon}
                    title="Request authentication with token"
                    desc="Create a token first to request authentication from AXME developer API and use our features."
                    buttonName={tokenSetup ? 'Completed' : 'Create Token'}
                    onClick={tokenSetup ? () => {} : startTokenGuide}
                    disabled={!companySetup}
                  />
                </Grid>
              </Grid>
            </WidgetContent>
          </Widget>
        </Grid>
      </Grid>
    </Box>
  );
}

export default GettingStarted;
