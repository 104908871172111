import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getService } from '../../../redux/modules/statistic';
// import { Typography, Box, styled } from '@mui/material';
import { AppDispatch } from '../../../redux/store';
import moment from 'moment';
import Table from '../../../components/Table';

import { WidgetFooter } from '../../../components/Widget';
import Pagination from '../../../components/TablePagination';
interface meta {
  total: number;
}

interface propsTable {
  contact: string;
  count: string;
  last: number;
}

interface props {
  statusFilter: string;
}
export default function index({ statusFilter }: props) {
  const dispatch = useDispatch<AppDispatch>();
  const [list, setList] = useState<propsTable[]>([]);

  // const [typeFilter, setTypeFilter] = useState('All');
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  const [totalData, setTotal] = useState<meta>({ total: 1 });

  // const [statusFilter, setStatusFilter] = useState('month');
  // const [search, setSearch] = useState('');

  useEffect(() => {
    dispatch(
      getService({
        url: 'contact',
        type: `limit=${limit}&offset=${
          page === 1 ? 0 : page === 2 ? limit : limit * page
        }&status=delivered&range=${statusFilter}`,
      })
    )
      .unwrap()
      .then((res) => {
        // console.log(res);
        setList(res.responseApi.data);
        setTotal({ total: res.responseApi.data.length });
      })
      .catch(() => {
        // create('error', 'Error', err);
      });
  }, [page, statusFilter, limit]);

  return (
    <div>
      <Table
        data={list ?? []}
        config={[
          {
            label: 'WhatsApp Number',
            key: 'contact',
          },

          {
            label: 'Total Unread Blast Message',
            key: 'count',
            // render: (row) => {
            //   return <p>{moment(row.createdOn * 1000).format('MMM DD, YYYY | HH:mm')}</p>;
            // },
          },
          {
            label: 'Last Seen',
            key: 'status',
            render: (row) => {
              return <p>{moment(row.last * 1000).format('MMM DD, YYYY | HH:mm')}</p>;
            },
          },
        ]}
        // filters={[
        //   // {
        //   //   label: 'Type',
        //   //   value: typeFilter,
        //   //   options: [
        //   //     'All',
        //   //     'Unassigned',
        //   //     'Subscription',
        //   //     { label: 'Non-Subscription', value: 'NonSubscription' },
        //   //   ],
        //   //   onChange: (value) => setTypeFilter(value),
        //   // },
        //   {
        //     label: 'Status',
        //     value: statusFilter,
        //     options: [
        //       {
        //         label: 'This Week',
        //         value: 'week',
        //       },
        //       {
        //         label: 'This Month',
        //         value: 'month',
        //       },
        //     ],
        //     onChange: (value) => {
        //       setPage(1);
        //       setStatusFilter(value);
        //     },
        //   },
        // ]}
        // searchValue={search}
        // onSearch={(e) => {
        //   setPage(1);
        //   setSearch(e.target.value);
        // }}
      />
      <WidgetFooter>
        <Pagination
          page={page}
          count={totalData?.total}
          rowsPerPage={limit}
          onPageChange={(_, newPage) => setPage(newPage)}
          onRowsPerPageChange={(e) => {
            setLimit(e.target.value as number);
            setPage(1);
          }}
        />
      </WidgetFooter>
    </div>
  );
}
