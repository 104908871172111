import { object, string } from 'yup';

export const initialValues = {
  intention: '',
  langCode: 'en',
  value: '',
};

export const validationSchema = object({
  intention: string().required("Intention can't be blank"),
  value: string().required("Answer can't be blank"),
});

export const editInitialValues = {
  id: '',
  value: '',
};

export const editValidationSchema = object({
  value: string().required("Answer can't be blank"),
});
