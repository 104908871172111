import { Home, User, FolderCode, ChatDots, CoinDollar, Microchip, Megaphone } from '@xcidic/icons';

const navItems = [
  {
    id: 'overview',
    icon: <Home />,
    label: 'Dashboard',
    pathname: '/',
  },
  {
    id: 'business-information',
    icon: <User />,
    label: 'Business Information',
    subItems: [
      {
        id: 'business-company',
        label: 'Company',
        pathname: '/company',
      },
      {
        id: 'business-user',
        label: 'User',
        pathname: '/user',
      },
      {
        id: 'business-token',
        label: 'Token',
        pathname: '/token',
      },
    ],
  },
  {
    id: 'otp',
    icon: <Microchip />,
    label: 'OTP',
    subItems: [
      {
        id: 'otp-config',
        label: 'Configuration',
        pathname: '/otp/configuration',
      },
      {
        id: 'otp-template',
        label: 'Template',
        pathname: '/otp/template',
      },
      {
        id: 'otp-integrated-guide',
        label: 'Integrated Guide',
        pathname: '/otp/guide',
      },
    ],
  },
  {
    id: 'message-blast',
    icon: <Megaphone />,
    label: 'Message Blast',
    subItems: [
      {
        id: 'blast-category',
        label: 'Contact Category',
        pathname: '/message/category',
      },
      {
        id: 'blast-contact',
        label: 'Contact',
        pathname: '/message/contact',
      },
      {
        id: 'blast-template',
        label: 'Template',
        pathname: '/message/blast',
      },
      {
        id: 'blast-guide',
        label: 'Integrated Guide',
        pathname: '/message/blast/guide',
      },
    ],
  },
  {
    id: 'auto-reply',
    icon: <ChatDots />,
    label: 'Auto Reply',
    subItems: [
      {
        id: 'auto-answer',
        label: 'Answer',
        pathname: '/auto/answer',
      },
      {
        label: 'Chat',
        pathname: '/auto/chat',
      },
    ],
  },
  {
    id: 'billing',
    icon: <CoinDollar />,
    label: 'Billing',
    subItems: [
      {
        label: 'Balance',
        pathname: '/billing',
      },
      {
        label: 'Monthly Usage',
        pathname: '/invoice',
      },
    ],
  },
  {
    id: 'documentation',
    icon: <FolderCode />,
    newTab: true,
    label: 'API Documentation',
    pathname: '/docs',
  },
];

export default navItems;
