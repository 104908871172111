import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../redux/store';
import { getServiceMonthly } from '../../redux/modules/xendit';
import { useParams } from 'react-router';

import { Box, Paper, Grid, Typography, Divider, styled, Button, Stack } from '@mui/material';
import { FileDownloadOulc } from '@xcidic/icons';

import Container from '../../components/Container';
import Table from '../../components/Table';
import Breadcrumbs from '../../components/Breadcrumbs';
import DashboardHeader from '../../components/DashboardHeader';
import Widget, { WidgetHeader, WidgetFooter, WidgetContent } from '../../components/Widget';
import Pagination from '../../components/TablePagination';
import moment from 'moment';

const Paid = styled(Box)(({ theme }) => ({
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '3px',
    // right: '3px',
    width: 12,
    height: 12,
    borderRadius: '50%',
    backgroundColor: '#12B76A',
    border: 'solid 1px white',
  },
}));

interface meta {
  total: number;
}

interface invoice {
  email: string;
  name: string;
  waPhone: number;
}

interface sentData {
  priceBlast: number;
  priceOtp: number;
  priceReply: number;
  totalBlast: number;
  totalOtp: number;
  totalReply: number;
}

interface detail {
  invoiceNumber: string;
  invoiceId: string;
  createdOn: number;
  totalCharged: number;
  totalRegular: number;
  totalShared: number;
  priceShared: number;
  priceRegular: number;
}

interface props {
  receiptFor: invoice;
  totalSent: sentData;
  receiptDetail: detail;
}

interface itemObject {
  qty: number;
  unitPrice: number;
  price: number;
}
interface countObject {
  blast: itemObject;
  otp: itemObject;
  reply: itemObject;
  sharedOtp: itemObject;
}
interface list {
  costService: number;
  costShared: number;
  totalPrice: number;
  count: countObject;
  startingOn: number;
}
export default function index() {
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams();

  const [totalData, setTotal] = useState<meta>({ total: 1 });
  const [data, setData] = useState<props>();
  const [list, setList] = useState<list[]>();

  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(
      getServiceMonthly({
        id: `/${id ?? ''}`,
      })
    )
      .unwrap()
      .then((res) => {
        setData(res.responseApi.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    dispatch(
      getServiceMonthly({
        id: `/${id ?? ''}/daily?offset=${
          page === 1 ? 0 : page === 2 ? limit : limit * page
        }&limit=${limit}&orderBy=startingOn&order=desc`,
      })
    )
      .unwrap()
      .then((res) => {
        setTotal(res.responseApi.meta);
        setList(res.responseApi.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [page, limit]);

  const download = () => {
    dispatch(getServiceMonthly({ id: `/monthly-usage/${id ?? ''}` ?? '' }))
      .unwrap()
      .then((res) => {
        console.log(res);
        // eslint-disable-next-line
        const linkSource = `data:application/pdf;base64,${res.responseApi.data}`;
        const downloadLink = document.createElement('a');
        const fileName = `Monthly Usage-AXME ${id ?? ''}.pdf`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      })
      .catch((err) => {
        console.error(err);
      });
  };
  return (
    <Container>
      <Breadcrumbs
        items={[
          {
            label: 'Billing',
            link: '/billing',
          },
          {
            label: 'Monthly Usage',
            link: '/invoice',
          },
          {
            label: 'Monthly Usage Detail',
            link: `/invoice/${id ?? ''}`,
          },
        ]}
      />
      <DashboardHeader title="Monthly Usage Detail"></DashboardHeader>
      <Grid container spacing={2}>
        <Grid item lg={9}>
          <Paper>
            <Box component="img" src="/axme-logo.svg" sx={{ width: 100 }} />
            <Grid container spacing={3} style={{ marginTop: 50 }}>
              <Grid item lg={6}>
                <Typography style={{ fontWeight: 'bold', fontSize: 14 }}>Receipt from</Typography>
                <Typography style={{ fontWeight: 'bold', fontSize: 14 }}>AXME</Typography>
                <Typography style={{ fontSize: 12 }}>axme@gmail.com</Typography>
                <Typography style={{ fontSize: 12 }}>+62 812 3456 7890</Typography>
              </Grid>
              <Grid item lg={6}>
                <Typography style={{ fontWeight: 'bold', fontSize: 14 }}>Receipt to:</Typography>
                <Typography style={{ fontWeight: 'bold', fontSize: 14 }}>
                  {data?.receiptFor.name}
                </Typography>
                <Typography style={{ fontSize: 12 }}>{data?.receiptFor.email}</Typography>
                <Typography style={{ fontSize: 12 }}>{data?.receiptFor.waPhone}</Typography>
              </Grid>
            </Grid>
            <div style={{ marginTop: 32 }}>
              <Table
                data={list ?? []}
                config={[
                  {
                    label: 'Date',
                    key: 'date',
                    render: (row) => moment(row.startingOn * 1000).format('MMM DD, YYYY'),
                  },
                  {
                    label: 'Type',
                    key: 'type',
                    render: (row) => {
                      return (
                        <div>
                          {row.count.sharedOtp.qty > 0 && <p>OTP Shared</p>}
                          {row.count.blast.qty > 0 && <p>Message Blast</p>}
                          {row.count.reply.qty > 0 && <p>Auto Reply</p>}
                          {row.count.otp.qty > 0 && <p>OTP Regular</p>}
                        </div>
                      );
                    },
                  },
                  {
                    label: 'Qty',
                    key: 'qty',
                    render: (row) => {
                      return (
                        <div>
                          {row.count.sharedOtp.qty > 0 && <p>{row.count.sharedOtp.qty}</p>}
                          {row.count.blast.qty > 0 && <p>{row.count.blast.qty}</p>}
                          {row.count.reply.qty > 0 && <p>{row.count.reply.qty}</p>}
                          {row.count.otp.qty > 0 && <p>{row.count.otp.qty}</p>}
                        </div>
                      );
                    },
                  },
                  {
                    label: 'Cost Service',
                    key: 'costService',
                    render: (row) => `IDR ${row?.costService}`,
                  },
                  {
                    label: 'Cost Shared',
                    key: 'costShared',
                    render: (row) => `IDR ${row?.costShared}`,
                  },
                  {
                    label: 'Total Price',
                    key: 'totalPrice',
                    render: (row) => `IDR ${row?.totalPrice}`,
                  },
                ]}
              />
              <WidgetFooter>
                <Pagination
                  page={page}
                  count={totalData?.total}
                  rowsPerPage={limit}
                  onPageChange={(_, newPage) => setPage(newPage)}
                  onRowsPerPageChange={(e) => {
                    setLimit(e.target.value as number);
                    setPage(1);
                  }}
                />
              </WidgetFooter>
            </div>
          </Paper>
          <div style={{ display: 'flex', justifyContent: 'end', marginTop: 24 }}></div>
        </Grid>
        <Grid item lg={3}>
          <Widget>
            <WidgetHeader title="Receipt Detail" />
            <WidgetContent sx={{ pb: 0 }}>
              <Stack spacing={2}>
                <Box>
                  <Typography variant="b3" color="neutral.400">
                    Invoice Number:
                  </Typography>
                  <Typography variant="b3" fontWeight="bold">
                    {data?.receiptDetail.invoiceId ?? ''}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="b3" color="neutral.400">
                    Date Created:
                  </Typography>
                  <Typography variant="b3" fontWeight="bold">
                    {moment((data?.receiptDetail?.createdOn ?? 0) * 1000).format(
                      'MMM DD, YYYY | HH:MM'
                    )}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="b3" color="neutral.400">
                    Invoice ID:
                  </Typography>
                  <Typography variant="b3" fontWeight="bold">
                    {' '}
                    {data?.receiptDetail.invoiceNumber ?? ''}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="b3" color="neutral.400">
                    Status
                  </Typography>
                  <Paid>
                    <p
                      style={{
                        marginLeft: 15,
                        fontSize: 12,
                        color: '#545454',
                        fontWeight: 'bold',
                      }}>
                      Paid
                    </p>{' '}
                  </Paid>
                </Box>

                <Box>
                  <Typography variant="b3" color="neutral.400">
                    <span style={{ fontSize: 14, fontWeight: '700' }}>
                      {data?.receiptDetail.totalRegular}
                    </span>{' '}
                    Regular Message Sent
                  </Typography>
                  <Typography variant="b3" fontWeight="bold">
                    IDR {data?.receiptDetail.priceRegular}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="b3" color="neutral.400">
                    <span style={{ fontSize: 14, fontWeight: '700' }}>
                      {data?.receiptDetail.totalShared}
                    </span>{' '}
                    Shared Message Sent
                  </Typography>
                  <Typography variant="b3" fontWeight="bold">
                    IDR {data?.receiptDetail.priceShared}
                  </Typography>
                </Box>
                <Divider />
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="b3" color="neutral.400">
                    Total Charged:
                  </Typography>
                  <Typography variant="b3" fontWeight="bold">
                    IDR{' '}
                    {data?.receiptDetail.totalCharged
                      .toLocaleString('id-ID', {
                        style: 'currency',
                        currency: 'IDR',
                        maximumFractionDigits: 2,
                      })
                      .replace('Rp', '')}
                  </Typography>
                </Box>
              </Stack>
              <Button
                sx={{ mt: 4 }}
                name="company"
                variant="outlined"
                color="primary"
                fullWidth
                startIcon={<FileDownloadOulc />}
                onClick={() => download()}>
                Download Payment Receipt (.pdf)
              </Button>
            </WidgetContent>
          </Widget>
        </Grid>
      </Grid>
    </Container>
  );
}
