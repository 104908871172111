import React from 'react';
import { styled, SelectChangeEvent, Select, Box, Typography, MenuItem } from '@mui/material';

export interface TableFilterProps {
  label: string;
  value: string;
  options: Array<string | { label: string; value: string }>;
  onChange?: (value: string) => void;
}

const RootContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 1,
  border: `solid 1px ${theme.palette.neutral.light}`,
  borderRadius: 6,
  paddingLeft: '7.2px',
  height: 41.4,
}));

const StyledSelect = styled(Select<string>)(({ theme }) => ({
  color: theme.palette.primary.main,
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiOutlinedInput-input': {
    fontSize: 12,
  },
}));

// --------------------------------------------------------------------------

const TableFilter = (props: TableFilterProps) => {
  const { label, value, options, onChange } = props;

  const handleChange = (event: SelectChangeEvent<string>) => {
    onChange?.(event.target.value);
  };

  return (
    <RootContainer>
      <Typography variant="b3" color="neutral.main">
        {label}
      </Typography>
      <StyledSelect value={value} onChange={handleChange}>
        {options.map((opt) =>
          typeof opt === 'string' ? (
            <MenuItem key={opt} value={opt}>
              {opt}
            </MenuItem>
          ) : (
            <MenuItem key={opt.label} value={opt.value}>
              {opt.label}
            </MenuItem>
          )
        )}
      </StyledSelect>
    </RootContainer>
  );
};

export default TableFilter;
