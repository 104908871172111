const PRIMARY = {
  light: '#258BEA',
  main: '#0D46A8',
};

const SECONDARY = {
  light: '#f3e5f5',
  main: '#ce93d8',
  dark: '#ab47bc',
};

const SUCCESS = {
  light: '#61cf9c',
  main: '#12b76a',
  dark: '#0f9858',
};

const WARNING = {
  light: '#ffd582',
  main: '#ffc043',
  dark: '#d5a038',
};

const ERROR = {
  light: '#f08c80',
  main: '#e11900',
  dark: '#961100',
};

const INFO = {
  light: '#6f9ef6',
  main: '#276ef1',
  dark: '#1a49a1',
};

const NEUTRAL = {
  white: '#ffffff',
  light: '#e2e2e2',
  dashboardBg: '#f6f8fc',
  200: '#e2e2e2',
  400: '#afafaf',
  600: '#545454',
  gray: '#94A3B8',
  black: '#1f1f1f',
};

const SPECIAL = {
  teal400: '#218ea4',
  cobalt400: '#0e1fc1',
  magenta400: '#d51eb3',
  indigo400: '#a100ff',
  purple400: '#8359df',
  lime400: '#67a421',
  brown400: '#99644c',
};

const palette = {
  primary: PRIMARY,
  secondary: SECONDARY,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  info: INFO,
  neutral: NEUTRAL,
  special: SPECIAL,
};

export default palette;
