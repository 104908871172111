import {
  // alpha,
  Button,
  CircularProgress,
  // Dialog,
  FormControlLabel,
  Grid,
  // styled,
  // Switch,
  TextField,
  Typography,
  Radio,
  RadioGroup,
} from '@mui/material';
// import { blue } from '@mui/material/colors';
// import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useFormik } from 'formik';
// import moment from 'moment';
import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { initialValues, validationSchema } from './schemeValidation';
import Confirm from '../../../components/Confirm';
import DatePicker from '../../../components/DateTimePicker/DatePicker';
import { ToastManagerContext } from '../../../components/ToastManager';
import { createService, requestData } from '../../../redux/modules/token';
import { AppDispatch } from '../../../redux/store';
import Dialog from '../../../components/Dialog';

// import { date } from 'yup/lib/locale';
// type CloseReason = 'backdropClick' | 'escapeKeyDown' | 'closeButtonClick';
interface PropsModal {
  open: boolean;
  // onClose: (reason: CloseReason) => void;
  onClose: Function;
}

// const SwitchCustom = styled(Switch)(({ theme }) => ({
//   '& .MuiSwitch-switchBase': {
//     color: '#cccccc',
//     '&:hover': {
//       backgroundColor: '#cccccc',
//     },
//   },
//   '& .MuiSwitch-switchBase + .MuiSwitch-track': {
//     backgroundColor: '#cccccc',
//   },
//   '& .MuiSwitch-switchBase.Mui-checked': {
//     color: blue[600],
//     '&:hover': {
//       backgroundColor: alpha(blue[600], theme.palette.action.hoverOpacity),
//     },
//   },
//   '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
//     backgroundColor: blue[600],
//   },
// }));

export default function index({ open, onClose }: PropsModal) {
  const { create } = useContext(ToastManagerContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);

  const dispatch = useDispatch<AppDispatch>();

  const {
    handleChange,
    handleBlur,
    // setSubmitting,
    handleSubmit,
    setFieldValue,
    dirty,
    resetForm,
    values,
    isValid,
    touched,
    errors,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async () => {
      setIsLoading(true);
      try {
        // console.log(values.date);\
        const date = new Date(new Date(values.date)?.setHours(23, 58, 59))?.getTime();
        const data: requestData = {
          description: values.description,
          withExpiration: Boolean(values.withExpiration),
          expiresOn: values?.withExpiration ? Math.ceil(date / 1000) : 0,
        };
        dispatch(createService(data))
          .unwrap()
          .then((res) => {
            setIsLoading(false);

            create('success', 'Success', res.responseApi.message);
            resetForm();
            onClose();
          })
          .catch((err) => {
            setIsLoading(false);

            create('error', 'Error', err);
          });
      } catch (error) {
        // handle any rejections/errors
      }
    },
  });

  // const theme = createTheme({
  //   components: {
  //     MuiSwitch: {
  //       styleOverrides: {
  //         switchBase: {
  //           // Controls default (unchecked) color for the thumb
  //           color: '#ccc',
  //         },
  //         colorPrimary: {
  //           '&.Mui-checked': {
  //             // Controls checked color for the thumb
  //             color: '#f2ff00',
  //           },
  //         },
  //         track: {
  //           // Controls default (unchecked) color for the track
  //           opacity: 0.2,
  //           backgroundColor: '#fff',
  //           '.Mui-checked.Mui-checked + &': {
  //             // Controls checked color for the track
  //             opacity: 0.7,
  //             backgroundColor: '#fff',
  //           },
  //         },
  //       },
  //     },
  //   },
  // });

  return (
    <Dialog open={open} onClose={() => onClose()} maxWidth="xs">
      <Typography variant="h2" color="neutral.black" fontWeight="700" mb={2}>
        Add Token
      </Typography>
      <Grid container spacing={3}>
        <Grid item lg={12}>
          <Typography
            style={{
              color: touched.description && errors.description ? '#e11900' : undefined,
            }}>
            Token Name
          </Typography>
          <TextField
            fullWidth
            // autoFocus
            name="description"
            variant="outlined"
            // type="email"
            // label="Token Name *"
            placeholder="Token Name"
            error={Boolean(touched.description && errors.description)}
            helperText={touched.description && errors.description}
            value={values.description}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
            className="mt-0 mb-4"
            size="medium"
          />
        </Grid>

        <Grid item lg={12}>
          <Typography
            style={{
              color: touched.withExpiration && errors.withExpiration ? '#e11900' : undefined,
            }}>
            With Expiration
          </Typography>
          <RadioGroup
            row
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={false}
            name="radio-buttons-group"
            onChange={(event) => setFieldValue('withExpiration', event.target.value)}>
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </Grid>
        {values?.withExpiration && (
          <Grid item lg={12}>
            <Typography
              style={{
                color: touched.date && errors.date ? '#e11900' : undefined,
              }}>
              Expiration Date
            </Typography>
            <DatePicker
              // label="Expires On"
              textFieldProps={
                <TextField
                  error={Boolean(touched.date && errors.date)}
                  // helperText={touched.date && errors.date}
                />
              }
              onChange={(val) => setFieldValue('date', val)}
              value={values.date}
            />
          </Grid>
        )}

        <Grid item lg={12}>
          <Button
            fullWidth
            name="company"
            variant="contained"
            color="primary"
            disabled={!(dirty && isValid)}
            onClick={() => setIsConfirm(true)}>
            {isLoading ? (
              <CircularProgress style={{ color: 'white' }} size={30} />
            ) : (
              <span className="loginButtonCaption"> Create Token</span>
            )}
          </Button>
          <Confirm
            type={'Create'}
            open={isConfirm}
            handleAksi={() => {
              setIsConfirm(false);
              handleSubmit();
            }}
            loading={false}
            handleClose={() => setIsConfirm(false)}
            disable={false}
            handleContent={{
              title: 'Create Token',
              message: 'Do you wish to Create this Token?',
              button: 'Submit',
            }}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
}
