import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import Dialog from '../../../components/Dialog';
import ChooseContact from './addContact';
import NewContact from './modal';
interface PropsModal {
  open: boolean;
  // onClose: (reason: CloseReason) => void;
  onClose: Function;
}
export default function index({ open, onClose }: PropsModal) {
  const [type, setType] = useState('existing');
  useEffect(() => {
    setType('existing');
  }, [open]);
  return (
    <Dialog open={open} onClose={() => onClose()} maxWidth="xs">
      <Typography variant="h3" color="neutral.black" fontWeight="700" mb={1}>
        Add Contact to Category
      </Typography>
      <RadioGroup
        row
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="existing"
        name="radio-buttons-group"
        onChange={(event) => setType(event.target.value)}>
        <FormControlLabel value="existing" control={<Radio />} label="Existing Contact" />
        <FormControlLabel value="new" control={<Radio />} label="New Contact" />
      </RadioGroup>

      {type === 'existing' && <ChooseContact onClose={() => onClose()} />}
      {type === 'new' && <NewContact onClose={() => onClose()} />}
    </Dialog>
  );
}
