import React from 'react';
// import { Link } from 'react-router-dom';
import {
  List,
  ListItem,
  Popover,
  PopoverProps,
  SvgIconProps,
  useTheme,
  Typography,
  Stack,
  // Divider,
} from '@mui/material';
// import userMenu from './UserMenuItems';
import { getUser } from '../../utils/localStorage';

export interface UserMenuItem {
  icon: (props: SvgIconProps) => JSX.Element;
  label: string;
  pathname: string;
}

export interface UserMenuPopoverProps extends PopoverProps {
  activePath?: string;
}

const UserMenuPopover = ({ activePath, ...popoverProps }: UserMenuPopoverProps) => {
  const { palette } = useTheme();
  const { name, roles, email } = getUser();

  return (
    <Popover {...popoverProps} anchorOrigin={{ vertical: 60, horizontal: 'left' }}>
      <List sx={{ minWidth: 204, maxWidth: '100%' }}>
        <ListItem>
          <Stack spacing={0.5}>
            <Typography variant="b2" sx={{ fontWeight: 700, color: palette.neutral[600] }}>
              {name}
            </Typography>
            <Typography variant="b3" sx={{ color: palette.neutral[400] }}>
              {roles[0]?.name}
            </Typography>
            <Typography variant="b3" sx={{ color: palette.info.main }}>
              {email}
            </Typography>
          </Stack>
        </ListItem>

        {/* <Divider sx={{ mx: 2, my: 1 }} /> */}
        {/* 
        {(userMenu as UserMenuItem[]).map((item) => {
          const color = item.pathname === activePath ? palette.primary.main : palette.neutral[400];

          return (
            <ListItemButton key={item.label} sx={{ color }} component={Link} to={item.pathname}>
              <ListItemIcon sx={{ color: 'inherit', minWidth: 36 }}>
                {item.icon({ sx: { fontSize: 20 } })}
              </ListItemIcon>
              <ListItemText primary={item.label} primaryTypographyProps={{ variant: 'b3' }} />
            </ListItemButton>
          );
        })} */}
        {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              localStorage.clear();
              window.location.reload();
            }}>
            Logout
          </Button>
        </div> */}
      </List>
    </Popover>
  );
};

export default UserMenuPopover;
