import { Button, Paper, Typography } from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import { ToastManagerContext } from '../../components/ToastManager';
import { getService } from '../../redux/modules/contact';

import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../redux/store';

import Edit from './component/modal';
// import ListToken from './component'
import GenerateContact from './component/create';
// import Edit from './component/editModal'
import Breadcrumbs from '../../components/Breadcrumbs';
import Container from '../../components/Container';
import Table from '../../components/Table';
import { Pencil, PlusCrfr } from '@xcidic/icons';
import { useNavigate } from 'react-router-dom';
import EmptyState from '../../components/EmptyState';

import DashboardHeader from '../../components/DashboardHeader';
import { WidgetFooter } from '../../components/Widget';
import Pagination from '../../components/TablePagination';

interface ObjectPropsData {
  countryCode: string;
  number: string;
  name: string;
  id: string;
}
interface meta {
  total: number;
}

export default function index() {
  const { create } = useContext(ToastManagerContext);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [list, setList] = useState<ObjectPropsData[]>();
  const [isEditOpen, setIsEditsOpen] = useState(false);
  const [id, setId] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [totalData, setTotal] = useState<meta>({ total: 1 });
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    updateData();
  }, [page]);
  const updateData = () => {
    dispatch(getService({ limit, offset: page === 1 ? 0 : page === 2 ? limit : limit * page }))
      .unwrap()
      .then((res) => {
        // create('success', 'Success', res.listToken.message);
        setTotal(res.listToken.meta);

        setList(res.listToken?.data);
      })
      .catch((err) => {
        // console.log(err)
        create('error', 'Error', err);
      });
  };

  const combinePhone = useMemo(
    () =>
      list?.map(({ countryCode, number, ...rest }, index) => ({
        ...rest,
        number: countryCode + number,
      })),
    [list]
  );

  return (
    <Container>
      <Breadcrumbs
        items={[
          {
            label: 'Message Blast',
            link: '/message',
          },
          {
            label: 'Contact',
            link: '/message/contact',
          },
        ]}
      />
      <DashboardHeader title="User Contact">
        <Button
          id="blast-add-contact"
          variant="contained"
          startIcon={<PlusCrfr />}
          onClick={() => setIsOpen(true)}>
          Add Contact
        </Button>
      </DashboardHeader>
      <Paper>
        {combinePhone?.length === 0 && <EmptyState module="Contact" button="Add Contact" />}
        {combinePhone?.length !== 0 && (
          <Table
            // idHref="id"
            // name="message/contact"
            data={combinePhone ?? []}
            config={[
              {
                label: 'Id',
                key: 'id',
                render: (row) => {
                  return (
                    <Typography
                      style={{ cursor: 'pointer' }}
                      onClick={() => navigate(`/message/contact/${row.id}`)}>
                      {row.id}
                    </Typography>
                  );
                },
              },
              {
                label: 'Name',
                key: 'name',
                render: (row) => {
                  return (
                    <Typography
                      sx={{ cursor: 'pointer' }}
                      onClick={() => navigate(`/message/contact/${row.id}`)}>
                      {row.name}
                    </Typography>
                  );
                },
              },
              {
                label: 'WhatsApp Number',
                key: 'number',
                render: (row) => {
                  return (
                    <Typography
                      style={{ cursor: 'pointer' }}
                      onClick={() => navigate(`/message/contact/${row.id}`)}>
                      {row.number}
                    </Typography>
                  );
                },
              },
            ]}
            actions={[
              {
                label: 'Edit',
                icon: <Pencil />,
                onAction: (row) => {
                  setId(row.id);
                  setIsEditsOpen(true);
                  // navigate(`/message/contact/${row.id}`);
                },
              },
            ]}
          />
        )}

        {combinePhone?.length !== 0 && (
          <WidgetFooter>
            <Pagination
              page={page}
              count={totalData?.total}
              rowsPerPage={limit}
              onPageChange={(_, newPage) => setPage(newPage)}
              onRowsPerPageChange={(e) => {
                setLimit(e.target.value as number);
                setPage(1);
              }}
            />
          </WidgetFooter>
        )}
        <Edit
          open={isEditOpen}
          onClose={() => {
            setIsEditsOpen(false);
            updateData();
          }}
          idProps={id}
          // content={user}
        />
        <GenerateContact
          open={isOpen}
          onClose={() => {
            setIsOpen(false);
            updateData();
          }}
        />
        {/* <Edit open={isEditOpen} onClose={() => { setIsEditsOpen(false); updateToken() }} content={name} /> */}
      </Paper>
    </Container>
  );
}
