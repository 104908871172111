import React, { useId } from 'react';
import {
  TextFieldProps as MuiTextFieldProps,
  FormControl,
  OutlinedInput,
  FormLabel,
  FormHelperText,
  OutlinedInputProps,
  Select,
} from '@mui/material';
import { InfoCrfr } from '@xcidic/icons';

export type TextFieldProps = Omit<MuiTextFieldProps, 'variant' | 'InputProps'> & {
  /**
   * Text that appear only when error is `true`.
   */
  errorText?: string;
  /**
   * Props applied to the Input element
   */
  InputProps?: OutlinedInputProps;
};

/**
 * Custom component based on MUI TextField component.
 *
 * https://mui.com/material-ui/api/text-field/
 */
const TextField = React.forwardRef<HTMLDivElement, TextFieldProps>(function TextField(props, ref) {
  const {
    // Custom props
    errorText,
    // Mui Textfield props
    autoComplete,
    autoFocus = false,
    children,
    className,
    defaultValue,
    disabled = false,
    error = false,
    fullWidth = false,
    helperText,
    id,
    inputProps,
    InputProps,
    inputRef,
    label,
    maxRows,
    minRows,
    multiline = false,
    name,
    onBlur,
    onChange,
    onFocus,
    placeholder,
    required = false,
    rows,
    select = false,
    SelectProps,
    type,
    value,
    ...other
  } = props;

  const InputMore = {} as OutlinedInputProps;

  if (select) {
    // unset defaults from textbox inputs
    if (!SelectProps || !SelectProps.native) {
      InputMore.id = undefined;
    }
    InputMore['aria-describedby'] = undefined;
  }

  const isEmpty = !value;
  const fieldId = id ?? useId();
  const helperTextId = helperText && id ? `${id}-helper-text` : undefined;
  const inputLabelId = label && id ? `${id}-label` : undefined;

  const InputElement = (
    <OutlinedInput
      id={fieldId}
      placeholder={placeholder}
      aria-describedby={helperTextId}
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      defaultValue={defaultValue}
      fullWidth={fullWidth}
      multiline={multiline}
      name={name}
      rows={rows}
      maxRows={maxRows}
      minRows={minRows}
      type={type}
      value={value}
      inputRef={inputRef}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      inputProps={inputProps}
      {...InputMore}
      {...InputProps}
      sx={{
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: (theme) => (isEmpty ? theme.palette.neutral[600] : undefined),
        },
      }}
    />
  );

  return (
    <FormControl
      ref={ref}
      className={className}
      error={error}
      disabled={disabled}
      fullWidth={fullWidth}
      required={required}
      {...other}>
      <FormLabel htmlFor={fieldId}>{label}</FormLabel>
      {select ? (
        <Select
          id={fieldId}
          labelId={inputLabelId}
          value={value}
          input={InputElement}
          {...SelectProps}>
          {children}
        </Select>
      ) : (
        InputElement
      )}

      {error && errorText && (
        <FormHelperText error={error} sx={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
          <InfoCrfr sx={{ fontSize: 16 }} />
          {errorText}
        </FormHelperText>
      )}
      <FormHelperText error={false}>{helperText}</FormHelperText>
    </FormControl>
  );
});

export default TextField;
