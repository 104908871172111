import './style.css';
interface props {
  bgColor: string;
  paid: string;
  pending: string;
  failed: string;
}

const ProgressBar = ({ bgColor, paid, pending, failed }: props) => {
  return (
    <div className="multicolor-bar">
      <div className="bars">
        {Number(paid) > 0 && (
          <div className="bar" style={{ backgroundColor: bgColor, width: paid + '%' }} />
        )}
        {Number(pending) > 0 && (
          <div className="bar" style={{ backgroundColor: '#FFC043', width: pending + '%' }} />
        )}
        {Number(failed) > 0 && (
          <div className="bar" style={{ backgroundColor: '#E11900', width: failed + '%' }} />
        )}
      </div>
    </div>
  );
};
export default ProgressBar;
