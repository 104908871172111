import { object, string } from 'yup';

export const initialValues = {
  email: '',
  name: '',
};

export const validationSchema = object({
  email: string().email('Email is not valid').required("Email can't be blank"),
  name: string().required("Username can't be blank"),
});

export const editInitialValues = {
  password: '',
  oldPassword: '',
};

export const editValidationSchema = object({
  password: string().required("New Password can't be blank"),
  oldPassword: string().required("New Password can't be blank"),
});
