import React, { useEffect } from 'react';
import Prism from 'prismjs';
import 'prismjs/plugins/toolbar/prism-toolbar.min.css';
import 'prismjs/plugins/toolbar/prism-toolbar.min';
import 'prismjs/plugins/copy-to-clipboard/prism-copy-to-clipboard.min';
import 'prismjs/plugins/normalize-whitespace/prism-normalize-whitespace.min';

import 'prismjs/themes/prism-tomorrow.css';
import './style.css';

interface props {
  code: string;
  language: string;
}

export default function Code({ code, language }: props) {
  useEffect(() => {
    // Prism.plugins.NormalizeWhitespace.setDefaults({
    //   'remove-trailing': true,
    //   'remove-indent': true,
    //   'left-trim': true,
    //   'right-trim': true,
    //   'break-lines': 10,
    //   'indent': 2,
    //   'remove-initial-line-feed': false,
    //   'tabs-to-spaces': 4,
    //   'spaces-to-tabs': 4
    // });
    Prism.highlightAll();
  }, [code, language]);
  return (
    <div className="Code">
      <pre>
        <code className={`language-${language}`}>{code}</code>
      </pre>
    </div>
  );
}
