import React, { ReactElement, ReactNode, useState } from 'react';
import {
  Box,
  Table as MuiTable,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableCellProps,
  TableSortLabel,
  Typography,
  Checkbox,
  TableContainer,
} from '@mui/material';
import { isExistObjectKey } from '../../utils/types';
import { SortBy } from '@xcidic/icons';
import ActionMenu, { ActionItem } from './ActionMenu';
import TableToolbar, { BulkActionProps } from './TableToolbar';
import { TableFilterProps } from './TableFilter';

export interface ColumnConfig<T> {
  /**
   * Text that will be used as column head.
   */
  label: string;
  /**
   * Unique id for each column. If `key` is not provided, label will be used.
   */
  key?: string;
  /**
   * Function that will be used to render cell content. If render function is not provided,
   */
  render?: (row: T) => ReactNode;
  /**
   * Props for MUI TableCell component.
   *
   * https://mui.com/material-ui/api/table-cell/#main-content
   */
  cellProps?: TableCellProps;
  /**
   * Function that will be executed when clicking the column head
   */
  onSort?: () => void;
}

export type TableConfig<T> = Array<ColumnConfig<T>>;

export interface TableProps<T extends {}> {
  /**
   * Table data in form of array of object.
   */
  data: T[];
  /**
   * Array of `ColumnConfig`.
   * @default []
   */
  config?: TableConfig<T>;
  /**
   * Action menu items.
   */
  actions?: Array<ActionItem<T>>;
  /**
   * Bulk action items.
   */
  bulkActions?: Array<BulkActionProps<T>>;
  /**
   * If `true` each row will have column number.
   * @default false
   */
  number?: boolean;
  /**
   * Filter.
   */
  filters?: TableFilterProps[];
  /**
   * Search value
   */
  searchValue?: string;
  /**
   * Search function.
   */
  onSearch?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  /**
   * Functions that will be called on respective row click
   */
  onSelectRow?: (row: T) => void;
}

const renderCell = <T extends {}>(
  row: T,
  key: ColumnConfig<T>['key'],
  render: ColumnConfig<T>['render']
): ReactNode => {
  if (render) {
    const rendered = render(row);
    if (typeof rendered === 'string') {
      return <Typography fontSize="inherit">{rendered}</Typography>;
    }
    return rendered;
  }

  if (key && isExistObjectKey(row, key)) {
    const result = row[key];

    // TODO: Find a way to remove this type checking
    if (typeof result === 'string' || typeof result === 'number') {
      return <Typography fontSize="inherit">{result}</Typography>;
    }
  }

  return null;
};

const Table = <T extends {}>(props: TableProps<T>): ReactElement => {
  const {
    data,
    onSearch,
    searchValue,
    config = [],
    actions = [],
    number = false,
    filters = [],
    bulkActions = [],
    onSelectRow,
  } = props;

  const [selected, setSelected] = useState([] as number[]);

  const totalData = data.length;
  const numSelected = selected.length;

  const handleSelectAll = () => {
    if (numSelected < totalData) {
      return setSelected([...Array(totalData)].map((_, id) => id));
    }
    setSelected([]);
  };

  const handleSelect = (id: number) => {
    if (selected.includes(id)) {
      return setSelected((prev) => prev.filter((p) => p !== id));
    }
    setSelected((prev) => [id, ...prev]);
  };

  const selectedRows = selected.map((id) => data[id]);
  const showToolbar = filters.length > 0 || bulkActions.length > 0 || onSearch !== undefined;

  return (
    <Box>
      {showToolbar && (
        <TableToolbar
          filters={filters}
          selected={selectedRows}
          bulkActions={bulkActions}
          searchValue={searchValue}
          onSearch={onSearch}
        />
      )}
      <TableContainer>
        <MuiTable>
          <TableHead>
            <TableRow>
              {bulkActions.length > 0 && (
                <TableCell variant="head">
                  <Checkbox
                    color="info"
                    indeterminate={numSelected > 0 && numSelected < totalData}
                    checked={numSelected === totalData}
                    onChange={handleSelectAll}
                  />
                </TableCell>
              )}
              {number && <TableCell variant="head">No.</TableCell>}
              {config.map(({ key, label, onSort }) => {
                const configKey = key ?? label;
                return (
                  <TableCell key={configKey} variant="head">
                    {onSort ? (
                      <TableSortLabel IconComponent={SortBy} onClick={onSort} active={true}>
                        {label}
                      </TableSortLabel>
                    ) : (
                      label
                    )}
                  </TableCell>
                );
              })}
              {actions.length > 0 && <TableCell variant="head" />}
            </TableRow>
          </TableHead>

          <TableBody>
            {data.length === 0 && (
              <TableRow>
                <TableCell colSpan={config.length} style={{ textAlign: 'center' }}>
                  No Data
                </TableCell>
              </TableRow>
            )}
            {data.map((row, rowIdx) => (
              <TableRow
                key={`row-${rowIdx}`}
                onClick={onSelectRow ? () => onSelectRow(row) : undefined}
                hover={Boolean(onSelectRow)}
                sx={{ cursor: onSelectRow ? 'pointer' : undefined }}>
                {bulkActions.length > 0 && (
                  <TableCell>
                    <Checkbox
                      color="info"
                      checked={selected.includes(rowIdx)}
                      onChange={() => handleSelect(rowIdx)}
                    />
                  </TableCell>
                )}

                {number && <TableCell>{rowIdx + 1}</TableCell>}

                {config.map(({ key, label, render, cellProps }) => (
                  <TableCell key={`col-${rowIdx}-${key ?? label}`} {...cellProps}>
                    {renderCell(row, key ?? label, render)}
                  </TableCell>
                ))}

                {actions.length > 0 && (
                  <TableCell align="right">
                    <ActionMenu data={row} actions={actions} />
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </MuiTable>
      </TableContainer>
    </Box>
  );
};

export default Table;
