import { array, object, string } from 'yup';

export const initialValues = {
  name: '',
  countryCode: '',
  number: '',
  categories: [],
};

export const validationSchema = object({
  name: string().required("Name can't be blank"),
  number: string().required("Whatsapp Number can't be blank"),
  categories: array().required("Categories can't be blank"),
});

export const editInitialValues = {
  name: '',
  countryCode: '',
  number: '',
  categories: [],
};

export const editValidationSchema = object({
  name: string().required("Name can't be blank"),
  number: string().required("Whatsapp Number can't be blank"),
  categories: array().required("Categories can't be blank"),
});
