import Guide from '../Guide';
import { Step } from 'react-joyride';

const tokenGuideSteps: Step[] = [
  {
    target: '#business-information',
    content: (
      <Guide
        title="Request authentication with token"
        content="Align with AXME feature with a token. Click here to search for “Token” menu."
        step="1"
        totalSteps="3"
      />
    ),
    disableBeacon: true,
    offset: 25,
    placement: 'bottom-start',
  },
  {
    target: '#business-token',
    content: (
      <Guide
        title="Request authentication with token"
        content="Click here to open “Token” menu and find “Add Token” button."
        step="2"
        totalSteps="3"
      />
    ),
    disableBeacon: true,
    offset: 25,
    placement: 'bottom-start',
  },
  {
    target: '#create-token',
    content: (
      <Guide
        title="Request authentication with token"
        content="Click here to create a token and request authentication to AXME developer API."
        step="3"
        totalSteps="3"
      />
    ),
    disableBeacon: true,
    offset: 25,
    placement: 'bottom-start',
  },
];

export default tokenGuideSteps;
