import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { BASE_URL } from '../../utils/url';
import axios from '../../utils/request';
import { getUser } from '../../utils/localStorage';

export interface params {
  limit: number;
  offset: number;
}

export interface paramsById {
  id: string;
}
export interface requestData {
  intention: string;
  langCode: string;
  value: string;
}

export interface requestDataEdit {
  id: string;
  value: string;
}

export interface requestConfigEdit {
  id: string;
  isActive: boolean;
  handlerId: string;
}

interface errorRequest {
  message: string;
}

const initialState = {
  list: [],
  responseApi: {},
};

const create = (intention: string, langCode: string, value: string) => {
  const { token } = getUser();

  return axios.post(
    `${BASE_URL}/chat-response/my`,
    {
      intention,
      langCode,
      value,
    },
    {
      headers: {
        Authorization: `Bearer ${token as string}`,
      },
    }
  );
};

export const createService = createAsyncThunk(
  'module/createResponseMessage',
  async ({ intention, langCode, value }: requestData, thunkAPI) => {
    try {
      const data = await create(intention, langCode, value);
      thunkAPI.fulfillWithValue(data.data);
      return { responseApi: data?.data };
    } catch (error) {
      const err = error as AxiosError;
      return thunkAPI.rejectWithValue((err.response?.data as errorRequest).message);
    }
  }
);

const getIntention = () => {
  const { token } = getUser();

  return axios.get(
    `${BASE_URL}/chat-response/intention`,

    {
      headers: {
        Authorization: `Bearer ${token as string}`,
      },
    }
  );
};

export const getIntentionService = createAsyncThunk(
  'module/getIntentionResponseMessage',
  // eslint-disable-next-line
  async (undefined, thunkAPI) => {
    try {
      const data = await getIntention();
      thunkAPI.fulfillWithValue(data.data);
      return { responseApi: data?.data };
    } catch (error) {
      const err = error as AxiosError;
      return thunkAPI.rejectWithValue((err.response?.data as errorRequest).message);
    }
  }
);

const edit = (id: string, value: string) => {
  const { token } = getUser();

  return axios.patch(
    `${BASE_URL}/chat-response/my/${id}`,
    {
      value,
    },
    {
      headers: {
        Authorization: `Bearer ${token as string}`,
      },
    }
  );
};

export const editService = createAsyncThunk(
  'module/editResponseMessage',
  async ({ id, value }: requestDataEdit, thunkAPI) => {
    try {
      const data = await edit(id, value);
      thunkAPI.fulfillWithValue(data.data);
      return { responseApi: data?.data };
    } catch (error) {
      const err = error as AxiosError;
      return thunkAPI.rejectWithValue((err.response?.data as errorRequest).message);
    }
  }
);

const get = (limit: number, offset: number) => {
  const { token } = getUser();
  return axios.get(`${BASE_URL}/chat-response/my?limit=${limit}&offset=${offset}`, {
    headers: {
      Authorization: `Bearer ${token as string}`,
    },
  });
};

export const getService = createAsyncThunk(
  'module/getResponseMessage',
  async ({ limit, offset }: params, thunkAPI) => {
    try {
      const data = await get(limit, offset);
      // // console.log(data);
      thunkAPI.fulfillWithValue(data.data);
      return { listToken: data?.data };
    } catch (error) {
      const err = error as AxiosError;
      // // console.log(err);
      return thunkAPI.rejectWithValue((err.response?.data as errorRequest).message);
    }
  }
);

const getConfig = () => {
  const { token } = getUser();
  return axios.get(`${BASE_URL}/chat-auto-config/my`, {
    headers: {
      Authorization: `Bearer ${token as string}`,
    },
  });
};

export const getConfigService = createAsyncThunk(
  'module/getConfigService',
  // eslint-disable-next-line
  async (undefined, thunkAPI) => {
    try {
      const data = await getConfig();
      // // console.log(data);
      thunkAPI.fulfillWithValue(data.data);
      return { responseApi: data?.data };
    } catch (error) {
      const err = error as AxiosError;
      // // console.log(err);
      return thunkAPI.rejectWithValue((err.response?.data as errorRequest).message);
    }
  }
);

const editConfigById = (id: string, isActive: boolean, handlerId: string) => {
  const { token } = getUser();
  return axios.patch(
    `${BASE_URL}/chat-auto-config/my/${id}`,
    {
      isActive,
      handlerId,
    },
    {
      headers: {
        Authorization: `Bearer ${token as string}`,
      },
    }
  );
};

export const editConfigByIdService = createAsyncThunk(
  'module/editConfigByIdService',
  async ({ id, isActive, handlerId }: requestConfigEdit, thunkAPI) => {
    try {
      const data = await editConfigById(id, isActive, handlerId);
      // // console.log(data);
      thunkAPI.fulfillWithValue(data.data);
      return { responseApi: data?.data };
    } catch (error) {
      const err = error as AxiosError;
      // // console.log(err);
      return thunkAPI.rejectWithValue((err.response?.data as errorRequest).message);
    }
  }
);

const getById = (id: string) => {
  const { token } = getUser();
  return axios.get(`${BASE_URL}/chat-response/my/${id}`, {
    headers: {
      Authorization: `Bearer ${token as string}`,
    },
  });
};

export const getServiceById = createAsyncThunk(
  'module/getResponseMessageById',
  async ({ id }: paramsById, thunkAPI) => {
    try {
      const data = await getById(id);
      // // console.log(data);
      thunkAPI.fulfillWithValue(data.data);
      return { responseApi: data?.data };
    } catch (error) {
      const err = error as AxiosError;
      // // console.log(err);
      return thunkAPI.rejectWithValue((err.response?.data as errorRequest).message);
    }
  }
);

const deleteContact = (id: string) => {
  const { token } = getUser();
  return axios.delete(`${BASE_URL}/chat-response/my/${id}`, {
    headers: {
      Authorization: `Bearer ${token as string}`,
    },
  });
};

export const deleteService = createAsyncThunk(
  'module/deleteResponseMessage',
  async ({ id }: paramsById, thunkAPI) => {
    try {
      const data = await deleteContact(id);
      // console.log(data);
      thunkAPI.fulfillWithValue(data.data);
      return { responseApi: data?.data };
    } catch (error) {
      const err = error as AxiosError;
      // // console.log(err);
      return thunkAPI.rejectWithValue((err.response?.data as errorRequest).message);
    }
  }
);

const responseMessage = createSlice({
  name: 'responseMessage',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createService.fulfilled, (state, action) => {
      state.responseApi = action.payload.responseApi;
    });
    builder.addCase(createService.rejected, (state, action) => {
      state.responseApi = {};
    });
    builder.addCase(getIntentionService.fulfilled, (state, action) => {
      state.responseApi = action.payload.responseApi;
    });
    builder.addCase(getIntentionService.rejected, (state, action) => {
      state.responseApi = {};
    });
    builder.addCase(getConfigService.fulfilled, (state, action) => {
      state.responseApi = action.payload.responseApi;
    });
    builder.addCase(getConfigService.rejected, (state, action) => {
      state.responseApi = {};
    });
    builder.addCase(editService.fulfilled, (state, action) => {
      state.responseApi = action.payload.responseApi;
    });
    builder.addCase(editService.rejected, (state, action) => {
      state.responseApi = {};
    });

    builder.addCase(editConfigByIdService.fulfilled, (state, action) => {
      state.responseApi = action.payload.responseApi;
    });
    builder.addCase(editConfigByIdService.rejected, (state, action) => {
      state.responseApi = {};
    });

    builder.addCase(deleteService.fulfilled, (state, action) => {
      state.responseApi = action.payload.responseApi;
    });
    builder.addCase(deleteService.rejected, (state, action) => {
      state.responseApi = {};
    });
    builder.addCase(getServiceById.fulfilled, (state, action) => {
      state.responseApi = action.payload.responseApi;
    });
    builder.addCase(getServiceById.rejected, (state, action) => {
      state.responseApi = {};
    });
    builder.addCase(getService.fulfilled, (state, action) => {
      state.list = action.payload.listToken;
      // state.isLoggedIn = true;
      // state.user = action.payload.user;
    });
    builder.addCase(getService.rejected, (state, action) => {
      state.list = [];

      // state.isLoggedIn = false;
      // state.user = null;
    });
  },
});

const { reducer } = responseMessage;
export default reducer;
