import { Box, CircularProgress, Collapse, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { verify, requestData } from '../../redux/modules/verifyEmail';
import { AppDispatch } from '../../redux/store';
import './style.css';

export default function index() {
  const [isLoading, setIsLoading] = useState(true);
  const [redirect, setIsRedirect] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const { id } = useParams();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    try {
      const data: requestData = { id: id ?? '' };
      dispatch(verify(data))
        .unwrap()
        .then((res) => {
          setMessage(res.responseApi.message);
          setIsLoading(false);
          setTimeout(() => {
            setIsRedirect(true);
          }, 5000);
        })
        .catch((err) => {
          setIsLoading(false);
          setMessage(err);
        });
    } catch (error) {
      // console.log()
    }
  }, []);
  return (
    <div className="container">
      <div>
        <Collapse in={isLoading && !redirect}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Box className="box-content">
              <CircularProgress style={{ color: 'primary' }} size={45} />
            </Box>
          </div>
        </Collapse>
        <Collapse in={!isLoading && !redirect}>
          <div style={{ justifyContent: 'center' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 5 }}>
              <Box component="img" src="/check.svg" sx={{ width: 144 }} />
            </Box>
            {/* <Box className="box-content"> */}
            <div style={{ textAlign: 'center', marginBottom: '10px' }}>
              <h2>Email Activation</h2>
              <Typography>{message}</Typography>
            </div>

            <Button
              fullWidth
              className="loginButton"
              // disabled={!(dirty && isValid)}
              onClick={() => navigate('/signin')}
              variant="contained"
              color="primary">
              <span className="loginButtonCaption">Login</span>
            </Button>
            {/* </Box> */}
          </div>
        </Collapse>
        <Collapse in={!isLoading && redirect}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {/* <Box className="box-content"> */}
            {/* <h2>Email Activation</h2>
              <p>{message}</p> */}
            <a
              style={{
                paddingTop: '10px',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
              href="/signin">
              Move to dashboard login page
            </a>
            {/* </Box> */}
          </div>
        </Collapse>
      </div>
    </div>
  );
}
