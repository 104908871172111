import { array, object, string } from 'yup';

export const initialValues = {
  name: '',
  // content: "",
  component: [],
  languageCode: 'en',
  contacts: [],
  contactCategories: [],
};

export const validationSchema = object({
  name: string().required("Name can't be blank"),
  component: array().required("Components can't be blank"),
  // content: string().required("content can't be blank"),
});

export const editInitialValues = {
  name: '',
  // content: "",
  component: [],
  languageCode: 'en',
  contacts: [],
  contactsCategories: [],
};

export const editValidationSchema = object({
  name: string().required("Name can't be blank"),
  component: array().required("Components can't be blank"),
  // content: string().required("content can't be blank"),
});
