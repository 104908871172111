import {
  Autocomplete,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  Checkbox,
} from '@mui/material';
import { SolidOff, CheckSqfr } from '@xcidic/icons';
import { useFormik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import 'react-phone-input-2/lib/material.css';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { initialValues, validationSchema } from './schemeValidation';
import Breadcrumbs from '../../components/Breadcrumbs';
import DashboardHeader from '../../components/DashboardHeader';
import Confirm from '../../components/Confirm';
import Container from '../../components/Container';
import { ToastManagerContext } from '../../components/ToastManager';
import { AppDispatch } from '../../redux/store';

import { createService, requestData, paramsById } from '../../redux/modules/blastMessage';
import { getService as getCategoryContact } from '../../redux/modules/category';
import { getService as getUserContact } from '../../redux/modules/contact';

interface ObjectPropsDataUserContact {
  countryCode: string;
  number: string;
  name: string;
  id: string;
}
interface ObjectPropsDataCategoryContact {
  name: string;
  id: string;
}

export default function index() {
  const { create } = useContext(ToastManagerContext);
  const dispatch = useDispatch<AppDispatch>();
  const [userContact, setUserContact] = useState<ObjectPropsDataUserContact[]>();
  const [categoryContact, setCategoryContact] = useState<ObjectPropsDataCategoryContact[]>();
  const [isConfirm, setIsConfirm] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  // const [content, setContent] = useState('');

  useEffect(() => {
    dispatch(getUserContact({ limit: 10000, offset: 0 }))
      .unwrap()
      .then((res) => {
        setUserContact(res.listToken?.data);
        // create('success', 'Success', res.listToken.message);
      })
      .catch((err) => {
        // console.log(err)
        create('error', 'Error', err);
      });
    dispatch(getCategoryContact({ limit: 10000, offset: 0 }))
      .unwrap()
      .then((res) => {
        setCategoryContact(res.listToken?.data);
        // create('success', 'Success', res.listToken.message);
      })
      .catch((err) => {
        // console.log(err)
        create('error', 'Error', err);
      });
  }, []);

  const {
    handleChange,
    handleBlur,
    // setSubmitting,
    handleSubmit,
    setFieldValue,
    dirty,
    resetForm,
    values,
    isValid,
    touched,
    errors,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async () => {
      setIsLoading(true);
      try {
        // const parameters =
        const contentJson = {
          name: values.name,
          language: {
            code: values.languageCode,
          },
          components: [
            {
              type: 'body',
              parameters: values?.component?.map((val) => {
                return {
                  type: 'text',
                  text: val,
                };
              }),
            },
          ],
        };

        const data: requestData = {
          name: values.name,
          content: JSON.stringify(contentJson),
          contactCategories: values?.contactCategories?.map((val: paramsById) => ({
            id: val?.id,
          })),
          contacts: values?.contacts?.map((val: paramsById) => ({
            id: val?.id,
          })),
        };
        dispatch(createService(data))
          .unwrap()
          .then((res) => {
            setIsLoading(false);

            create('success', 'Success', res.responseApi.message);
            navigate('/message/blast');

            resetForm();
          })
          .catch((err) => {
            setIsLoading(false);

            create('error', 'Error', err);
          });
      } catch (error) {
        // handle any rejections/errors
      }
    },
  });

  // const handleAdd = () => {
  //   // eslint-disable-next-line
  //   setFieldValue('component', [...values?.component, content]);
  //   setContent('');
  // };

  // const handleDelete = (index: number) => {
  //   const data = values.component;
  //   data?.splice(index, 1);
  //   setFieldValue('component', data);
  // };

  return (
    <Container>
      <Breadcrumbs
        items={[
          {
            label: 'Message Blast',
            link: '/message',
          },
          {
            label: 'Template',
            link: '/message/blast',
          },
          {
            label: 'Add Message Blast Template',
            link: '/message/blast/create',
          },
        ]}
      />
      <DashboardHeader title="Create Message Blast Template" />

      <Paper>
        <Grid container spacing={3}>
          <Grid item lg={12}>
            <Grid container spacing={3}>
              <Grid item lg={2} sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography sx={{ color: touched.name && errors.name ? '#e11900' : undefined }}>
                  Template Name
                </Typography>
              </Grid>
              <Grid item lg={10}>
                <TextField
                  fullWidth
                  // autoFocus
                  name="name"
                  variant="outlined"
                  // type="password"
                  // label="Template Name *"
                  placeholder="Template name"
                  // multiline
                  // rows={4}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="off"
                  className="mt-0 mb-4"
                  size="medium"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={12}>
            <Grid container spacing={3}>
              <Grid item lg={2} sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  sx={{
                    color: touched.languageCode && errors.languageCode ? '#e11900' : undefined,
                  }}>
                  Default Code Language
                </Typography>
              </Grid>
              <Grid item lg={10}>
                <Select
                  fullWidth
                  value={values.languageCode}
                  onChange={(e) => setFieldValue('languageCode', e.target.value as string)}>
                  <MenuItem value={'en'}>English</MenuItem>
                  <MenuItem value={'id'}>Indonesian</MenuItem>
                </Select>
              </Grid>
            </Grid>
          </Grid>
          {/* {values?.component?.map((val, index) => (
            <Grid item lg={12} key={index}>
              <Grid container spacing={2}>
                <Grid item lg={8}>
                  <TextField
                    fullWidth
                    // autoFocus
                    name="Content"
                    variant="outlined"
                    // type="email"
                    label="Content *"
                    // error={Boolean(touched.name && errors.name)}
                    // helperText={touched.name && errors.name}
                    value={val}
                    disabled
                    //  onChange={(e) => setContent(e.target.value)}
                    // onBlur={handleBlur}
                    autoComplete="off"
                    className="mt-0 mb-4"
                    size="medium"
                  />
                </Grid>

                <Grid item lg={4}>
                  <Button
                    variant="contained"
                    color="primary"
                    // disabled={content === ""}
                    onClick={() => handleDelete(index)}>
                    <MinusCrfr />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          ))}
          <Grid item lg={12}>
            <Grid container spacing={2}>
              <Grid item lg={8}>
                <TextField
                  fullWidth
                  // autoFocus
                  name="Content"
                  variant="outlined"
                  // type="email"
                  label="Content *"
                  // error={Boolean(touched.name && errors.name)}
                  // helperText={touched.name && errors.name}
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                  // onBlur={handleBlur}
                  autoComplete="off"
                  className="mt-0 mb-4"
                  size="medium"
                />
              </Grid>

              <Grid item lg={4}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={content === ''}
                  onClick={handleAdd}>
                  <Plus />
                </Button>
              </Grid>
            </Grid>
          </Grid> */}
          <Grid item lg={12}>
            <Grid container spacing={3}>
              <Grid item lg={2} style={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  sx={{ color: touched.component && errors.component ? '#e11900' : undefined }}>
                  Add Variables
                </Typography>
              </Grid>
              <Grid item lg={10}>
                <Autocomplete
                  multiple
                  open={false}
                  onChange={(e, val) => {
                    // setSelected(val !== null ? val : []);
                    // console.log(val.map((val) => val._id));
                    // console.log(val);
                    setFieldValue('component', val !== null ? val : []);
                  }}
                  freeSolo
                  id="free-solo-2-demo"
                  disableClearable
                  options={[]}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // label="Add Variable"
                      placeholder="Add variables"
                      InputProps={{
                        ...params.InputProps,
                        type: 'search',
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={12}>
            <Grid container spacing={3}>
              <Grid item lg={2} style={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  style={{ color: touched.contacts && errors.contacts ? '#e11900' : undefined }}>
                  Contact
                </Typography>
              </Grid>
              <Grid item lg={10}>
                <Autocomplete
                  id="tags-outlined"
                  multiple
                  onChange={(e, val) => {
                    // setSelected(val !== null ? val : []);
                    // console.log(val.map((val) => val._id));
                    // console.log(val)
                    setFieldValue('contacts', val !== null ? val : []);
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={<SolidOff />}
                        checkedIcon={<CheckSqfr style={{ color: '#0D46A8' }} />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </li>
                  )}
                  disableCloseOnSelect
                  value={values?.contacts}
                  options={userContact ?? []}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      // label="User Contact"
                      placeholder="Choose Contact"
                      // placeholder="Contact Categories"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={12}>
            <Grid container spacing={3}>
              <Grid item lg={2} style={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  style={{
                    color:
                      touched.contactCategories && errors.contactCategories ? '#e11900' : undefined,
                  }}>
                  Contact Categories
                </Typography>
              </Grid>
              <Grid item lg={10}>
                <Autocomplete
                  id="tags-outlined"
                  multiple
                  onChange={(e, val) => {
                    // setSelected(val !== null ? val : []);
                    // console.log(val.map((val) => val._id));
                    // console.log(val)
                    setFieldValue('contactCategories', val !== null ? val : []);
                  }}
                  disableCloseOnSelect
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={<SolidOff />}
                        checkedIcon={<CheckSqfr style={{ color: '#0D46A8' }} />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </li>
                  )}
                  value={values?.contactCategories}
                  options={categoryContact ?? []}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      // label="Contact Categories"
                      placeholder="Choose Contact Categories"

                      // placeholder="Contact Categories"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={12} style={{ justifyContent: 'flex-end', display: 'flex', marginTop: 10 }}>
            <Button
              name="company"
              variant="outlined"
              color="primary"
              style={{ marginRight: 10 }}
              onClick={() => navigate('/message/blast')}
              // disabled={!(dirty && isValid)}
            >
              Cancel
            </Button>
            <Button
              name="company"
              variant="contained"
              color="primary"
              disabled={!(dirty && isValid)}
              onClick={() => setIsConfirm(true)}>
              {isLoading ? (
                <CircularProgress style={{ color: 'white' }} size={30} />
              ) : (
                <span className="loginButtonCaption">Create Message Blast Template</span>
              )}
            </Button>
            <Confirm
              open={isConfirm}
              handleAksi={() => handleSubmit()}
              loading={false}
              handleClose={() => setIsConfirm(false)}
              disable={false}
              type={'Create'}
              handleContent={{
                title: 'Create Blast Message Template',
                message: 'Do you wish to create this Blast Message Template?',
                button: 'Submit',
              }}
            />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
