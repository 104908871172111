import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { BASE_URL } from '../../utils/url';
import axios from '../../utils/request';
import { getUser } from '../../utils/localStorage';

interface params {
  limit: number;
  offset: number;
}

export interface paramsById {
  id: string;
}

export interface requestData {
  name: string;
  content: string;
  contacts: paramsById[];
  contactCategories: paramsById[];
}

export interface requestDataEdit {
  id: string;
  name: string;
  content: string;
  contacts: paramsById[];
  contactCategories: paramsById[];
}

interface errorRequest {
  message: string;
}

const initialState = {
  list: [],
  responseApi: {},
};

interface sendMessage {
  handlerId: string;
  blastId: string;
}

const create = (
  name: string,
  content: string,
  contacts: paramsById[],
  contactCategories: paramsById[]
) => {
  const { token } = getUser();

  return axios.post(
    `${BASE_URL}/blast-wa/my`,
    {
      name,
      content,
      contacts,
      contactCategories,
    },
    {
      headers: {
        Authorization: `Bearer ${token as string}`,
      },
    }
  );
};

export const createService = createAsyncThunk(
  'module/createTemplateMessageBlast',
  async ({ name, content, contacts, contactCategories }: requestData, thunkAPI) => {
    try {
      const data = await create(name, content, contacts, contactCategories);
      thunkAPI.fulfillWithValue(data.data);
      return { responseApi: data?.data };
    } catch (error) {
      const err = error as AxiosError;
      return thunkAPI.rejectWithValue((err.response?.data as errorRequest).message);
    }
  }
);

const send = (handlerId: string, blastId: string) => {
  const { token } = getUser();

  return axios.post(
    `${BASE_URL}/blast-wa/my/start`,
    {
      handlerId,
      blastId,
    },
    {
      headers: {
        Authorization: `Bearer ${token as string}`,
      },
    }
  );
};

export const sendService = createAsyncThunk(
  'module/sendTemplateMessageBlast',
  async ({ handlerId, blastId }: sendMessage, thunkAPI) => {
    try {
      const data = await send(handlerId, blastId);
      thunkAPI.fulfillWithValue(data.data);
      return { responseApi: data?.data };
    } catch (error) {
      const err = error as AxiosError;
      return thunkAPI.rejectWithValue((err.response?.data as errorRequest).message);
    }
  }
);

const edit = (
  id: string,
  name: string,
  content: string,
  contacts: paramsById[],
  contactCategories: paramsById[]
) => {
  const { token } = getUser();

  return axios.patch(
    `${BASE_URL}/blast-wa/my/${id}`,
    {
      name,
      content,
      contacts,
      contactCategories,
    },
    {
      headers: {
        Authorization: `Bearer ${token as string}`,
      },
    }
  );
};

export const editService = createAsyncThunk(
  'module/editTemplateMessageBlast',
  async ({ id, name, content, contacts, contactCategories }: requestDataEdit, thunkAPI) => {
    try {
      const data = await edit(id, name, content, contacts, contactCategories);
      thunkAPI.fulfillWithValue(data.data);
      return { responseApi: data?.data };
    } catch (error) {
      const err = error as AxiosError;
      return thunkAPI.rejectWithValue((err.response?.data as errorRequest).message);
    }
  }
);

const get = (limit: number, offset: number) => {
  const { token } = getUser();
  return axios.get(`${BASE_URL}/blast-wa/my?limit=${limit}&offset=${offset}`, {
    headers: {
      Authorization: `Bearer ${token as string}`,
    },
  });
};

export const getService = createAsyncThunk(
  'module/getTemplateMessageBlast',
  async ({ limit, offset }: params, thunkAPI) => {
    try {
      const data = await get(limit, offset);
      // // console.log(data);
      thunkAPI.fulfillWithValue(data.data);
      return { listToken: data?.data };
    } catch (error) {
      const err = error as AxiosError;
      // // console.log(err);
      return thunkAPI.rejectWithValue((err.response?.data as errorRequest).message);
    }
  }
);

const getById = (id: string) => {
  const { token } = getUser();
  return axios.get(`${BASE_URL}/blast-wa/my/${id}`, {
    headers: {
      Authorization: `Bearer ${token as string}`,
    },
  });
};

export const getServiceById = createAsyncThunk(
  'module/getContactById',
  async ({ id }: paramsById, thunkAPI) => {
    try {
      const data = await getById(id);
      // // console.log(data);
      thunkAPI.fulfillWithValue(data.data);
      return { responseApi: data?.data };
    } catch (error) {
      const err = error as AxiosError;
      // // console.log(err);
      return thunkAPI.rejectWithValue((err.response?.data as errorRequest).message);
    }
  }
);

const checkUsage = () => {
  const { token } = getUser();
  return axios.get(`${BASE_URL}/status/usage`, {
    headers: {
      Authorization: `Bearer ${token as string}`,
    },
  });
};

export const getUsageStatus = createAsyncThunk(
  'module/getUsageStatus',
  // eslint-disable-next-line
  async (undefined, thunkAPI) => {
    try {
      const data = await checkUsage();
      // // console.log(data);
      thunkAPI.fulfillWithValue(data.data);
      return { responseApi: data?.data };
    } catch (error) {
      const err = error as AxiosError;
      // // console.log(err);
      return thunkAPI.rejectWithValue((err.response?.data as errorRequest).message);
    }
  }
);
const deleteContact = (id: string) => {
  const { token } = getUser();
  return axios.delete(`${BASE_URL}/blast-wa/my/${id}`, {
    headers: {
      Authorization: `Bearer ${token as string}`,
    },
  });
};

export const deleteService = createAsyncThunk(
  'module/deleteContact',
  async ({ id }: paramsById, thunkAPI) => {
    try {
      const data = await deleteContact(id);
      // console.log(data);
      thunkAPI.fulfillWithValue(data.data);
      return { responseApi: data?.data };
    } catch (error) {
      const err = error as AxiosError;
      // // console.log(err);
      return thunkAPI.rejectWithValue((err.response?.data as errorRequest).message);
    }
  }
);

const messageBlast = createSlice({
  name: 'messageBlast',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createService.fulfilled, (state, action) => {
      state.responseApi = action.payload.responseApi;
    });
    builder.addCase(createService.rejected, (state, action) => {
      state.responseApi = {};
    });
    builder.addCase(sendService.fulfilled, (state, action) => {
      state.responseApi = action.payload.responseApi;
    });
    builder.addCase(sendService.rejected, (state, action) => {
      state.responseApi = {};
    });
    builder.addCase(editService.fulfilled, (state, action) => {
      state.responseApi = action.payload.responseApi;
    });
    builder.addCase(editService.rejected, (state, action) => {
      state.responseApi = {};
    });

    builder.addCase(deleteService.fulfilled, (state, action) => {
      state.responseApi = action.payload.responseApi;
    });
    builder.addCase(deleteService.rejected, (state, action) => {
      state.responseApi = {};
    });
    builder.addCase(getServiceById.fulfilled, (state, action) => {
      state.responseApi = action.payload.responseApi;
    });
    builder.addCase(getServiceById.rejected, (state, action) => {
      state.responseApi = {};
    });
    builder.addCase(getUsageStatus.fulfilled, (state, action) => {
      state.responseApi = action.payload.responseApi;
    });
    builder.addCase(getUsageStatus.rejected, (state, action) => {
      state.responseApi = {};
    });
    builder.addCase(getService.fulfilled, (state, action) => {
      state.list = action.payload.listToken;
      // state.isLoggedIn = true;
      // state.user = action.payload.user;
    });
    builder.addCase(getService.rejected, (state, action) => {
      state.list = [];

      // state.isLoggedIn = false;
      // state.user = null;
    });
  },
});

const { reducer } = messageBlast;
export default reducer;
