import { Box, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button';

// redux
import { AppDispatch } from '../../redux/store';
import { useDispatch } from 'react-redux';
import { toggleStartGuide, updateStepIndex, clearSteps } from '../../redux/modules/tourGuide';

export interface GuideProps {
  title: string;
  content: string;
  step: string;
  totalSteps: string;
}

function Guide({ title, content, step, totalSteps }: GuideProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const skipGuide = () => {
    navigate('/');
    dispatch(toggleStartGuide(false));
    dispatch(updateStepIndex(0));
    dispatch(clearSteps());
  };

  return (
    <Box sx={{ height: '120px', width: '320px' }}>
      <Grid container sx={{ height: 'inherit', width: 'inherit' }}>
        <Grid item xs={12} sx={{ height: '75%' }}>
          <Typography
            mb={1}
            sx={{ color: '#1F1F1F', fontSize: '14px', fontWeight: '700', lineHeight: '20px' }}>
            {title}
          </Typography>
          <Typography sx={{ fontSize: '12px', fontWeight: '500', lineHeight: '16px' }}>
            {content}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container alignItems="center">
            <Grid item xs={10}>
              <Typography sx={{ fontSize: '12px', fontWeight: '500', lineHeight: '16px' }}>
                {step} of {totalSteps}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Button variant="text" size="small" onClick={skipGuide}>
                Skip
              </Button>
            </Grid>
            {/* <Grid item xs={2}>
              <Button variant="text" size="small">
                Next
              </Button>
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Guide;
