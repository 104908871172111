import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { BASE_URL, TRANS_URL, CRON_URL } from '../../utils/url';
import axios from '../../utils/request';
import { getUser } from '../../utils/localStorage';

interface params {
  limit: number;
  offset: string;
}

interface getInterface {
  id: string;
  limit: number;
  offset: number;
}

interface paramsById {
  id: string;
}

export interface requestData {
  amount: number;
}

interface requestDataEdit {
  id: string;
  amount: string;
}

interface deleteData {
  id: string;
}

interface errorRequest {
  message: string;
}

const initialState = {
  list: [],
  responseApi: {},
};

const create = (amount: number) => {
  const { token } = getUser();

  return axios.post(
    `${TRANS_URL}/transaction`,
    {
      amount,
    },
    {
      headers: {
        Authorization: `Bearer ${token as string}`,
      },
    }
  );
};

export const createService = createAsyncThunk(
  'module/createTemplateMessageBlast',
  async ({ amount }: requestData, thunkAPI) => {
    try {
      const data = await create(amount);
      thunkAPI.fulfillWithValue(data.data);
      return { responseApi: data?.data };
    } catch (error) {
      const err = error as AxiosError;
      return thunkAPI.rejectWithValue((err.response?.data as errorRequest).message);
    }
  }
);

const edit = (id: string, amount: string) => {
  const { token } = getUser();

  return axios.patch(
    `${BASE_URL}/billing/my/${id}`,
    {
      amount,
    },
    {
      headers: {
        Authorization: `Bearer ${token as string}`,
      },
    }
  );
};

export const editService = createAsyncThunk(
  'module/editTemplateMessageBlast',
  async ({ id, amount }: requestDataEdit, thunkAPI) => {
    try {
      const data = await edit(id, amount);
      thunkAPI.fulfillWithValue(data.data);
      return { responseApi: data?.data };
    } catch (error) {
      const err = error as AxiosError;
      return thunkAPI.rejectWithValue((err.response?.data as errorRequest).message);
    }
  }
);

const get = (id: string, limit: number, offset: number) => {
  const { token } = getUser();
  return axios.get(`${BASE_URL}/billing/my/${id}?limit=${limit}&offset=${offset}`, {
    headers: {
      Authorization: `Bearer ${token as string}`,
    },
  });
};

export const getService = createAsyncThunk(
  'module/getTemplateMessageBlast',
  async ({ id, limit, offset }: getInterface, thunkAPI) => {
    try {
      const data = await get(id, limit, offset);
      // // console.log(data);
      thunkAPI.fulfillWithValue(data.data);
      return { listToken: data?.data };
    } catch (error) {
      const err = error as AxiosError;
      // // console.log(err);
      return thunkAPI.rejectWithValue((err.response?.data as errorRequest).message);
    }
  }
);

const getBilling = (limit: number, offset: string) => {
  const { token } = getUser();
  return axios.get(`${TRANS_URL}/transaction?limit=${limit}&offset=${offset}`, {
    headers: {
      Authorization: `Bearer ${token as string}`,
    },
  });
};

export const getServiceBilling = createAsyncThunk(
  'module/getServiceBilling',
  async ({ limit, offset }: params, thunkAPI) => {
    try {
      const data = await getBilling(limit, offset);
      // // console.log(data);
      thunkAPI.fulfillWithValue(data.data);
      return { listToken: data?.data };
    } catch (error) {
      const err = error as AxiosError;
      // // console.log(err);
      return thunkAPI.rejectWithValue((err.response?.data as errorRequest).message);
    }
  }
);

const getTotal = () => {
  const { token } = getUser();
  return axios.get(`${TRANS_URL}/transaction/total`, {
    headers: {
      Authorization: `Bearer ${token as string}`,
    },
  });
};

export const getTotalBilling = createAsyncThunk(
  'module/getTotalBilling',
  // eslint-disable-next-line
  async (undefined, thunkAPI) => {
    try {
      const data = await getTotal();
      // // console.log(data);
      thunkAPI.fulfillWithValue(data.data);
      return { responseApi: data?.data };
    } catch (error) {
      const err = error as AxiosError;
      // // console.log(err);
      return thunkAPI.rejectWithValue((err.response?.data as errorRequest).message);
    }
  }
);

const getDetail = (id: string) => {
  const { token } = getUser();
  return axios.get(`${TRANS_URL}/transaction/${id}`, {
    headers: {
      Authorization: `Bearer ${token as string}`,
    },
  });
};

export const getDetailBilling = createAsyncThunk(
  'module/getDetailBilling',
  // eslint-disable-next-line
  async ({ id }: paramsById, thunkAPI) => {
    try {
      const data = await getDetail(id);
      // // console.log(data);
      thunkAPI.fulfillWithValue(data.data);
      return { responseApi: data?.data };
    } catch (error) {
      const err = error as AxiosError;
      // // console.log(err);
      return thunkAPI.rejectWithValue((err.response?.data as errorRequest).message);
    }
  }
);

const getMonthly = (id: string) => {
  const { token } = getUser();
  return axios.get(`${CRON_URL}/invoice${id}`, {
    headers: {
      Authorization: `Bearer ${token as string}`,
    },
  });
};

export const getServiceMonthly = createAsyncThunk(
  'module/getServiceMonthly',
  async ({ id }: paramsById, thunkAPI) => {
    try {
      const data = await getMonthly(id);
      // // console.log(data);
      thunkAPI.fulfillWithValue(data.data);
      return { responseApi: data?.data };
    } catch (error) {
      const err = error as AxiosError;
      // // console.log(err);
      return thunkAPI.rejectWithValue((err.response?.data as errorRequest).message);
    }
  }
);

const deleteContact = (id: string) => {
  const { token } = getUser();
  return axios.delete(`${BASE_URL}/billing/my/${id}`, {
    headers: {
      Authorization: `Bearer ${token as string}`,
    },
  });
};

export const deleteService = createAsyncThunk(
  'module/deleteContact',
  async ({ id }: deleteData, thunkAPI) => {
    try {
      const data = await deleteContact(id);
      // console.log(data);
      thunkAPI.fulfillWithValue(data.data);
      return { responseApi: data?.data };
    } catch (error) {
      const err = error as AxiosError;
      // // console.log(err);
      return thunkAPI.rejectWithValue((err.response?.data as errorRequest).message);
    }
  }
);

const xendit = createSlice({
  name: 'billing',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createService.fulfilled, (state, action) => {
      state.responseApi = action.payload.responseApi;
    });
    builder.addCase(createService.rejected, (state, action) => {
      state.responseApi = {};
    });
    builder.addCase(editService.fulfilled, (state, action) => {
      state.responseApi = action.payload.responseApi;
    });
    builder.addCase(editService.rejected, (state, action) => {
      state.responseApi = {};
    });
    builder.addCase(getServiceMonthly.fulfilled, (state, action) => {
      state.responseApi = action.payload.responseApi;
    });
    builder.addCase(getServiceMonthly.rejected, (state, action) => {
      state.responseApi = {};
    });

    builder.addCase(deleteService.fulfilled, (state, action) => {
      state.responseApi = action.payload.responseApi;
    });
    builder.addCase(deleteService.rejected, (state, action) => {
      state.responseApi = {};
    });
    builder.addCase(getServiceBilling.fulfilled, (state, action) => {
      state.list = action.payload.listToken;
    });
    builder.addCase(getServiceBilling.rejected, (state, action) => {
      state.list = [];
    });
    builder.addCase(getTotalBilling.fulfilled, (state, action) => {
      state.responseApi = action.payload.responseApi;
    });
    builder.addCase(getTotalBilling.rejected, (state, action) => {
      state.responseApi = {};
    });

    builder.addCase(getDetailBilling.fulfilled, (state, action) => {
      state.responseApi = action.payload.responseApi;
    });
    builder.addCase(getDetailBilling.rejected, (state, action) => {
      state.responseApi = {};
    });
    builder.addCase(getService.fulfilled, (state, action) => {
      state.list = action.payload.listToken;
      // state.isLoggedIn = true;
      // state.user = action.payload.user;
    });
    builder.addCase(getService.rejected, (state, action) => {
      state.list = [];

      // state.isLoggedIn = false;
      // state.user = null;
    });
  },
});

const { reducer } = xendit;
export default reducer;
