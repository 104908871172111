import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { BASE_URL } from '../../utils/url';
import axios from '../../utils/request';

export interface requestData {
  token: string;
  password: String;
}

interface errorRequest {
  message: String;
}

const initialState = {
  responseApi: {
    statusCode: 404,
    message: 'Verification code is invalid or has expired',
    error: 'Not Found',
  },
};

const changePassword = (token: String, password: String) => {
  return axios.post(`${BASE_URL}/account/reset-password`, {
    token,
    password,
  });
};

export const changePasswordAsync = createAsyncThunk(
  'module/changePassword',
  async ({ token, password }: requestData, thunkAPI) => {
    try {
      const data = await changePassword(token, password);
      thunkAPI.fulfillWithValue(data.data);
      return { responseApi: data?.data };
    } catch (error) {
      const err = error as AxiosError;
      return thunkAPI.rejectWithValue((err.response?.data as errorRequest).message);
    }
  }
);

const changePasswordSlice = createSlice({
  name: 'changePassword',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(changePasswordAsync.fulfilled, (state, action) => {
      state.responseApi = action.payload.responseApi;
      // state.isLoggedIn = true;
      // state.user = action.payload.user;
    });
    builder.addCase(changePasswordAsync.rejected, (state, action) => {
      state.responseApi = {
        statusCode: 404,
        message: 'Verification code is invalid or has expired',
        error: 'Not Found',
      };

      // state.isLoggedIn = false;
      // state.user = null;
    });
  },
});

const { reducer } = changePasswordSlice;
export default reducer;
