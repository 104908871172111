import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import CoachMark from './modules/coachMark';
import Auth from './auth';
import verifyEmail from './modules/verifyEmail';
import resetPassword from './modules/resetPassword';
import changePassword from './modules/changePassword';
import token from './modules/token';
import tour from './modules/tourGuide';
import updateUser from './modules/user';
import otpConfig from './modules/otpConfiguration';
import otpTemplate from './modules/otpTemplate';
import contactCategory from './modules/category';
import contact from './modules/contact';
import messageBlast from './modules/blastMessage';
import xendit from './modules/xendit';
import statistic from './modules/statistic';
export const store = configureStore({
  reducer: {
    auth: Auth,
    coachMark: CoachMark,
    // eslint-disable-next-line
    verifyEmail: verifyEmail,
    resetPassword,
    changePassword,
    token,
    tour,
    updateUser,
    otpConfig,
    otpTemplate,
    contactCategory,
    contact,
    messageBlast,
    xendit,
    statistic,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
