import { Theme, ThemeOptions } from '@mui/material';

export default function Switch(theme: Theme): ThemeOptions['components'] {
  return {
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          opacity: 1,
          color: theme.palette.neutral.white,
          '&.Mui-checked': {
            color: theme.palette.neutral.white,
          },
          '&.Mui-checked+.MuiSwitch-track': {
            opacity: 1,
            color: theme.palette.neutral.white,
            backgroundColor: theme.palette.primary.main,
          },
        },
        sizeMedium: {
          paddingTop: 9.5,
          paddingBottom: 9.5,

          '.MuiSwitch-switchBase': {
            transform: 'translate(7px, 3px)',
            '&.Mui-checked': {
              transform: 'translate(20px, 3px)',
            },
          },

          '.MuiSwitch-thumb': {
            width: 13,
            height: 13,
          },
        },
        sizeSmall: {
          paddingTop: 5,
          paddingBottom: 5,

          '.MuiSwitch-switchBase': {
            transform: 'translate(7px, 3px)',
            '&.Mui-checked': {
              transform: 'translate(16px, 3px)',
            },
          },

          '.MuiSwitch-thumb': {
            width: 9,
            height: 9,
          },
        },
        track: {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          borderRadius: 16,
        },
      },
    },
  };
}
