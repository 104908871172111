import { Routes, Route, Navigate } from 'react-router-dom';
import { PrivateRoute, OtherRoute } from '../utils/accessRoute';

// Auth
import Signin from '../modules/Authenthication/signin';
import VerifyEmail from '../modules/Verify';
// import ResetPassword from '../modules/Verify/ResetPassword';
import VerifyResetPassword from '../modules/Verify/VerifyResetPassword';

// Documentation
import Documentation from '../modules/Documentation';

// Module
import Home from '../modules/Dashboard';
import FTU from '../modules/FTU';
import BusinessInformation from '../modules/BusinessInformation';
import Response from '../modules/Response';

// Company
import Company from '../modules/Company';
import CreateCompany from '../modules/Company/Create';
import EditCompany from '../modules/Company/Edit';

// User
import User from '../modules/User';
import EditUser from '../modules/User/Edit';
import EditPassword from '../modules/User/EditPassword';

// Token
import Token from '../modules/Token';
import ViewToken from '../modules/Token/View';

// OTP
import OtpConfiguration from '../modules/OtpConfiguration';
import ViewOtpConfiguration from '../modules/OtpConfiguration/View';
import OtpTemplate from '../modules/OtpTemplate';
import ViewOtpTemplate from '../modules/OtpTemplate/View';
import OtpIntegradtedGuide from '../modules/OTP/integratedGuide';
import CreateOtpTemplate from '../modules/OtpTemplate/Create';
import EditOtpTemplate from '../modules/OtpTemplate/Edit';

// Message Blast
import UserContact from '../modules/UserContact';
import DetailContact from '../modules/UserContact/View';
import CategoryContact from '../modules/CategoryContact';
import ViewCategoryContactContact from '../modules/CategoryContact/View';
import BlastMessage from '../modules/BlastMessage';
import CreateWhatsappBlastTemplate from '../modules/BlastMessage/Create';
import ViewWhatsappBlastTempate from '../modules/BlastMessage/View';
import EditwWhatsappBlastTempate from '../modules/BlastMessage/Edit';
import GuideMessageBlast from '../modules/BlastMessage/integratedGuide';

// autoreply
// import ListIntention from '../modules/AutoReplyIntention';
import AnswerIntention from '../modules/AutoReplyAnswer/index';
import DetailAnswer from '../modules/AutoReplyAnswer/View';

// error
import Errors from '../modules/Error';
import PageNotFound from '../modules/Error/pageNotfound';

// Billing
import Billing from '../modules/Billing';
import MonthlyStatement from '../modules/Billing/monthlyStatement';
import DetailBilling from '../modules/Billing/View';

// monthlyUsage
import MonthlyUsage from '../modules/MonthlyUsage';
import ViewMonthlyUsage from '../modules/MonthlyUsage/View';

// chat
import Chat from '../modules/Chat';

// modules
/* -------
import module here then assign it to element prop on Route component, example:
import Home from '/home';
------- */

// redux
import { RootState } from '../redux/store';
import { useSelector } from 'react-redux';

function index() {
  const hideGuide = useSelector((state: RootState) => state.tour.completed);

  return (
    <Routes>
      {/* <Route path="/" element={<PrivateRoute component={Home} />} /> */}
      <Route path="/" element={<PrivateRoute component={hideGuide ? Home : FTU} />} />
      <Route path="/account" element={<PrivateRoute component={BusinessInformation} />} />
      <Route path="/configuration" element={<PrivateRoute component={Response} />} />
      <Route path="/billing" element={<PrivateRoute component={Billing} />} />
      <Route path="/billing/:id" element={<PrivateRoute component={DetailBilling} />} />

      <Route path="/invoice" element={<PrivateRoute component={MonthlyUsage} />} />
      <Route path="/invoice/:id" element={<PrivateRoute component={ViewMonthlyUsage} />} />

      <Route path="/billing/invoice/:id" element={<PrivateRoute component={MonthlyStatement} />} />

      <Route path="/company" element={<PrivateRoute component={Company} />} />
      <Route path="/company/create" element={<PrivateRoute component={CreateCompany} />} />
      <Route path="/company/edit" element={<PrivateRoute component={EditCompany} />} />

      <Route path="/token" element={<PrivateRoute component={Token} />} />
      <Route path="/token/:id" element={<PrivateRoute component={ViewToken} />} />

      <Route path="/user" element={<PrivateRoute component={User} />} />
      <Route path="/user/edit" element={<PrivateRoute component={EditUser} />} />
      <Route path="/user/editpassword" element={<PrivateRoute component={EditPassword} />} />

      {/* <Navigate to="/error" replace /> */}
      <Route path="/otp" element={<Navigate to="/otp/guide" replace />} />
      <Route path="/otp/configuration" element={<PrivateRoute component={OtpConfiguration} />} />
      <Route
        path="/otp/configuration/:id"
        element={<PrivateRoute component={ViewOtpConfiguration} />}
      />
      <Route path="/otp/template" element={<PrivateRoute component={OtpTemplate} />} />
      <Route path="/otp/template/create" element={<PrivateRoute component={CreateOtpTemplate} />} />
      <Route path="/otp/template/edit/:id" element={<PrivateRoute component={EditOtpTemplate} />} />

      <Route path="/otp/template/view/:id" element={<PrivateRoute component={ViewOtpTemplate} />} />
      <Route path="/otp/guide" element={<PrivateRoute component={OtpIntegradtedGuide} />} />

      <Route path="/message" element={<Navigate to="/message/blast/guide" replace />} />
      <Route path="/message/category" element={<PrivateRoute component={CategoryContact} />} />
      <Route
        path="/message/category/:id"
        element={<PrivateRoute component={ViewCategoryContactContact} />}
      />
      <Route path="/message/contact" element={<PrivateRoute component={UserContact} />} />
      <Route path="/message/contact/:id" element={<PrivateRoute component={DetailContact} />} />
      <Route path="/message/blast" element={<PrivateRoute component={BlastMessage} />} />
      <Route
        path="/message/blast/create"
        element={<PrivateRoute component={CreateWhatsappBlastTemplate} />}
      />
      <Route
        path="/message/blast/view/:id"
        element={<PrivateRoute component={ViewWhatsappBlastTempate} />}
      />
      <Route
        path="/message/blast/edit/:id"
        element={<PrivateRoute component={EditwWhatsappBlastTempate} />}
      />
      <Route path="/message/blast/guide" element={<PrivateRoute component={GuideMessageBlast} />} />

      <Route path="/auto" element={<Navigate to="/auto/answer" replace />} />
      {/* <Route path="/auto/question" element={<PrivateRoute component={ListIntention} />} /> */}
      <Route path="/auto/answer" element={<PrivateRoute component={AnswerIntention} />} />
      <Route path="/auto/answer/:id" element={<PrivateRoute component={DetailAnswer} />} />

      <Route path="/auto/chat" element={<PrivateRoute component={Chat} />} />

      <Route path="/signin" element={<Signin />} />
      <Route path="/verify/:id" element={<VerifyEmail />} />
      {/* <Route path="/reset-password" element={<ResetPassword />} /> */}
      <Route path="/verify-reset-password/:id" element={<VerifyResetPassword />} />

      <Route path="/docs" element={<Documentation />} />
      <Route path="/error" element={<Errors />} />
      <Route path="/404" element={<PageNotFound />} />

      <Route path="*" element={<OtherRoute />} />
    </Routes>
  );
}

export default index;
