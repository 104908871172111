import React, { useEffect, useState } from 'react';
import { Button, Box, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { CallBackProps } from 'react-joyride';
import Widget, { WidgetHeader, WidgetContent } from '../../components/Widget';
import FTUCard from './Card';

// icons
import OTPIcon from './Icons/OTP';
import MessageBlastIcon from './Icons/MessageBlast';
import AutoReplyIcon from './Icons/AutoReply';

// redux
import { AppDispatch } from '../../redux/store';
import type { RootState } from '../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { get as getOtpConfiguration } from '../../redux/modules/otpConfiguration';
import { get as getOtpTemplate } from '../../redux/modules/otpTemplate';
import { getService as getBlastCategory } from '../../redux/modules/category';
import { getService as getBlastContact } from '../../redux/modules/contact';
import { getService as getBlastTemplate } from '../../redux/modules/blastMessage';
import { getService as getAutoReplyAnswer } from '../../redux/modules/responseAutoReply';
import {
  markCompleted,
  updateGuide,
  toggleStartGuide,
  updateSteps,
  clearSteps,
  updateStepIndex,
  updateCallback,
} from '../../redux/modules/tourGuide';

// tour guides
import otpGuideSteps from './Tours/OTPGuide';
import blastGuideSteps from './Tours/BlastGuide';
import autoGuideSteps from './Tours/AutoGuide';

function FeatureGuide() {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const userName = useSelector((state: RootState) => state.auth.user.name);
  const [tourRemain, setTourRemain] = useState(3);
  const [otpConfigSetup, setOtpConfigSetup] = useState(false);
  const [otpTemplateSetup, setOtpTemplateSetup] = useState(false);
  const [blastCategorySetup, setBlastCategorySetup] = useState(false);
  const [blastContactSetup, setBlastContactSetup] = useState(false);
  const [blastTemplateSetup, setBlastTemplateSetup] = useState(false);
  const [autoReplySetup, setAutoReplySetup] = useState(false);

  useEffect(() => {
    // detect if there is any data on OTP Configuration
    dispatch(getOtpConfiguration({ limit: 1, offset: 0 }))
      .unwrap()
      .then((res) => {
        res.listToken?.data.length > 0 ? setOtpConfigSetup(true) : setOtpConfigSetup(false);
      })
      .catch((err) => {
        console.error(err);
      });

    // detect if there is any data on OTP Template
    dispatch(getOtpTemplate({ limit: 1, offset: 0 }))
      .unwrap()
      .then((res) => {
        res.listToken?.data.length > 0 ? setOtpTemplateSetup(true) : setOtpTemplateSetup(false);
      })
      .catch((err) => {
        console.error(err);
      });

    // detect if there is any data on blast category
    dispatch(getBlastCategory({ limit: 1, offset: 0 }))
      .unwrap()
      .then((res) => {
        res.listToken?.data.length > 0 ? setBlastCategorySetup(true) : setBlastCategorySetup(false);
      })
      .catch((err) => {
        console.error(err);
      });

    // detect if there is any data on blast contact
    dispatch(getBlastContact({ limit: 1, offset: 0 }))
      .unwrap()
      .then((res) => {
        res.listToken?.data.length > 0 ? setBlastContactSetup(true) : setBlastContactSetup(false);
      })
      .catch((err) => {
        console.error(err);
      });

    // detect if there is any data on blast template
    dispatch(getBlastTemplate({ limit: 1, offset: 0 }))
      .unwrap()
      .then((res) => {
        res.listToken?.data.length > 0 ? setBlastTemplateSetup(true) : setBlastTemplateSetup(false);
      })
      .catch((err) => {
        console.error(err);
      });

    // detect if there is any data on auto reply answer
    dispatch(getAutoReplyAnswer({ limit: 1, offset: 0 }))
      .unwrap()
      .then((res) => {
        res?.listToken?.data.length > 0 ? setAutoReplySetup(true) : setAutoReplySetup(false);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    const otpCompleted = otpConfigSetup && otpTemplateSetup ? 1 : 0;
    const blastCompleted = blastCategorySetup && blastContactSetup ? 1 : 0;
    const autoCompleted = autoReplySetup ? 1 : 0;

    const guideStepsRemaining = otpCompleted + blastCompleted + autoCompleted;

    // calculate the remaining guide steps
    /* -----------------------------------
    dev note: this is a hardcoded logic to define the remaining guide.
    this logic below supposed to be improved in the future.
    ----------------------------------- */
    setTourRemain(3 - guideStepsRemaining);
  }, [otpConfigSetup, otpTemplateSetup, blastCategorySetup, blastContactSetup, autoReplySetup]);

  useEffect(() => {
    if (tourRemain <= 0) {
      dispatch(markCompleted(true));
    }
  }, [tourRemain]);

  // otp guide callback
  const otpGuideCallback = (data: CallBackProps) => {
    const { lifecycle, type, index } = data;

    if (type === 'step:after' && index === 0) {
      dispatch(updateStepIndex(1));
    } else if (type === 'step:after' && index === 1) {
      navigate('/otp/configuration');
      dispatch(updateStepIndex(2));
    } else if (type === 'step:after' && index === 2) {
      dispatch(updateStepIndex(3));
    } else if (type === 'step:after' && index === 3) {
      navigate('/otp/template');
      dispatch(updateStepIndex(4));
    } else if (type === 'step:after' && index === 4) {
      dispatch(updateStepIndex(5));
    } else if (type === 'step:after' && index === 5) {
      navigate('/otp/guide');
      dispatch(updateStepIndex(6));
    } else if (type === 'step:after' && index === 6) {
      navigate('/');
      dispatch(updateGuide(''));
      dispatch(toggleStartGuide(false));
      dispatch(updateStepIndex(0));
      dispatch(clearSteps());
    } else if (lifecycle === 'complete') {
      navigate('/');
      dispatch(updateGuide(''));
      dispatch(toggleStartGuide(false));
      dispatch(updateStepIndex(0));
      dispatch(clearSteps());
    }
  };

  // message blast guide callback
  const blastGuideCallback = (data: CallBackProps) => {
    const { lifecycle, type, index } = data;

    if (type === 'step:after' && index === 0) {
      dispatch(updateStepIndex(1));
    } else if (type === 'step:after' && index === 1) {
      navigate('/message/category');
      dispatch(updateStepIndex(2));
    } else if (type === 'step:after' && index === 2) {
      dispatch(updateStepIndex(3));
    } else if (type === 'step:after' && index === 3) {
      navigate('/message/contact');
      dispatch(updateStepIndex(4));
    } else if (type === 'step:after' && index === 4) {
      dispatch(updateStepIndex(5));
    } else if (type === 'step:after' && index === 5) {
      navigate('/message/blast');
      dispatch(updateStepIndex(6));
    } else if (type === 'step:after' && index === 6) {
      dispatch(updateStepIndex(7));
    } else if (type === 'step:after' && index === 7) {
      navigate('/message/blast/guide');
      dispatch(updateStepIndex(8));
    } else if (type === 'step:after' && index === 8) {
      navigate('/');
      dispatch(updateGuide(''));
      dispatch(toggleStartGuide(false));
      dispatch(updateStepIndex(0));
      dispatch(clearSteps());
    } else if (lifecycle === 'complete') {
      navigate('/');
      dispatch(updateGuide(''));
      dispatch(toggleStartGuide(false));
      dispatch(updateStepIndex(0));
      dispatch(clearSteps());
    }
  };

  // auto reply guide callback
  const autoGuideCallback = (data: CallBackProps) => {
    const { lifecycle, type, index } = data;

    if (type === 'step:after' && index === 0) {
      dispatch(updateStepIndex(1));
    } else if (type === 'step:after' && index === 1) {
      navigate('/auto/answer');
      dispatch(updateStepIndex(2));
    } else if (type === 'step:after' && index === 2) {
      navigate('/');
      dispatch(updateGuide(''));
      dispatch(toggleStartGuide(false));
      dispatch(updateStepIndex(0));
      dispatch(clearSteps());
    } else if (lifecycle === 'complete') {
      navigate('/');
      dispatch(updateGuide(''));
      dispatch(toggleStartGuide(false));
      dispatch(updateStepIndex(0));
      dispatch(clearSteps());
    }
  };

  // trigger the otp guide tour
  const startOTPGuide = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    dispatch(updateGuide('OTP Guide'));
    dispatch(toggleStartGuide(true));
    dispatch(updateSteps(otpGuideSteps));
    dispatch(updateCallback(otpGuideCallback));
  };

  // trigger the message blast guide tour
  const startBlastGuide = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    dispatch(updateGuide('Message Blast Guide'));
    dispatch(toggleStartGuide(true));
    dispatch(updateSteps(blastGuideSteps));
    dispatch(updateCallback(blastGuideCallback));
  };

  // trigger the auto reply guide tour
  const startAutoGuide = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    dispatch(updateGuide('Auto Reply Guide'));
    dispatch(toggleStartGuide(true));
    dispatch(updateSteps(autoGuideSteps));
    dispatch(updateCallback(autoGuideCallback));
  };

  // skip all guide
  const skipGuide = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    dispatch(markCompleted(true));
    dispatch(updateGuide(''));
    dispatch(toggleStartGuide(false));
    dispatch(updateStepIndex(0));
    dispatch(clearSteps());
  };

  return (
    <div>
      <Grid container justifyContent="center" spacing={2} rowSpacing={3}>
        <Grid item xs={12}>
          <Typography
            mb={1}
            component="h1"
            variant="h1"
            textAlign="center"
            sx={{ fontWeight: 700, lineHeight: '2rem' }}>
            Welcome, {userName}!
          </Typography>
          <Typography textAlign="center">
            Let’s start optimizing your account! so you can enhance your promotional communication
            channel easily
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Widget>
            <WidgetHeader title="Let’s try our key features">
              <Typography fontSize={14} fontWeight={500} sx={{ color: '#AFAFAF' }}>
                {tourRemain <= 0 ? 0 : tourRemain} step(s) left
              </Typography>
            </WidgetHeader>
            <WidgetContent>
              <Typography mt={2.5} mb={4} fontWeight={500}>
                Here are some optional steps to optimize your{' '}
                <Box component="span" sx={{ fontWeight: 700 }}>
                  promotional plan
                </Box>
                :
              </Typography>
              <Grid container columnSpacing={4}>
                <Grid item xs={4}>
                  <FTUCard
                    fullButton
                    cardIcon={OTPIcon}
                    title="Create an OTP"
                    desc="To integrate an OTP you will need to create a configuration
                    and template."
                    buttonName={otpConfigSetup && otpTemplateSetup ? 'Completed' : 'Create OTP'}
                    onClick={otpConfigSetup && otpTemplateSetup ? () => {} : startOTPGuide}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FTUCard
                    fullButton
                    cardIcon={MessageBlastIcon}
                    title="Create a message blast"
                    desc="You can create a template and blast your messages to
                    many contacts."
                    buttonName={
                      blastCategorySetup && blastContactSetup && blastTemplateSetup
                        ? 'Completed'
                        : 'Create Message Blast'
                    }
                    onClick={
                      blastCategorySetup && blastContactSetup && blastTemplateSetup
                        ? () => {}
                        : startBlastGuide
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <FTUCard
                    fullButton
                    cardIcon={AutoReplyIcon}
                    title="Create an auto reply answer"
                    desc="Create auto reply message based on the intention for your user."
                    buttonName={autoReplySetup ? 'Completed' : 'Create Auto Reply Answer'}
                    onClick={autoReplySetup ? () => {} : startAutoGuide}
                  />
                </Grid>
              </Grid>
            </WidgetContent>
            <Box
              sx={{
                mx: -2,
                mt: '10vh',
                px: 2,
                pt: '1.25rem',
                borderTop: '1px solid #E2E2E2',
                display: 'flex',
                justifyContent: 'flex-end',
              }}>
              <Button name="skip-guide" variant="contained" color="primary" onClick={skipGuide}>
                Skip these steps
              </Button>
            </Box>
          </Widget>
        </Grid>
      </Grid>
    </div>
  );
}

export default FeatureGuide;
