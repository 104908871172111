import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../redux/store';
import { getServiceMonthly } from '../../redux/modules/xendit';
import { useParams } from 'react-router';

import { Box, Paper, Grid, Typography } from '@mui/material';

import Container from '../../components/Container';
import Table from '../../components/Table';
import Breadcrumbs from '../../components/Breadcrumbs';
import DashboardHeader from '../../components/DashboardHeader';
import { WidgetFooter } from '../../components/Widget';
import Pagination from '../../components/TablePagination';

interface meta {
  total: number;
}

interface invoice {
  email: string;
  name: string;
  waPhone: number;
}

interface sentData {
  priceBlast: number;
  priceOtp: number;
  priceReply: number;
  totalBlast: number;
  totalOtp: number;
  totalReply: number;
}

interface props {
  invoiceFor: invoice;
  sent: sentData;
}

export default function index() {
  const dispatch = useDispatch<AppDispatch>();
  const [totalData, setTotal] = useState<meta>({ total: 1 });
  const [data, setData] = useState<props>();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const { id } = useParams();

  useEffect(() => {
    dispatch(
      getServiceMonthly({
        id: `/${id ?? ''}`,
      })
    )
      .unwrap()
      .then((res) => {
        // console.log(res.responseApi);
        setData(res.responseApi.data);
        setTotal({ total: 10 });
        // setTotal(res.listToken.meta);
        // setList(res.listToken?.data);
        // create('success', 'Success', res.listToken.message);
      })
      .catch(() => {
        // console.log(err)
        // create('error', 'Error', err);
      });
  }, []);

  return (
    <Container>
      <Breadcrumbs
        items={[
          {
            label: 'Home',
            link: '/',
          },
          {
            label: 'Billings',
            link: '/billing',
          },
          {
            label: 'Invoice Detail',
            link: '/billing/invoice',
          },
        ]}
      />
      <DashboardHeader title="Invoice Detail" />
      <Paper style={{ paddingTop: 50 }}>
        <Grid container direction="row" justifyContent="space-between">
          <Grid>
            <Box component="img" src="/axme-logo.svg" sx={{ width: 124 }} />
          </Grid>
          <Grid>
            <Grid container>
              <Grid
                item
                lg={12}
                style={{
                  display: 'flex',
                  alignContent: 'center',
                  justifyContent: 'center',
                  marginBottom: 12,
                }}>
                <div
                  style={{
                    borderRadius: 4,
                    // justifyContent: 'center',
                    // alignItems: 'center',
                    // textAlign: 'center',
                    width: 'fit-content',
                    padding: 4,
                    backgroundColor: '#12B76A',
                    // color: 'white',
                  }}>
                  <Typography style={{ color: 'white' }}>Paid</Typography>
                  {/* Paid */}
                </div>
              </Grid>
              <Grid
                item
                lg={12}
                style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                <Typography>INV-1231J23</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ marginTop: 50 }}>
          <Grid item lg={6}>
            <Typography>Invoice From</Typography>
            <Typography style={{ fontWeight: 'bold' }}>AXME</Typography>
            <Typography>axme@gmail.com</Typography>

            <Typography>+62 812 3456 7890</Typography>

            {/* <h2>AXME Phone : +62 812 3456 7890</h2> */}
          </Grid>
          <Grid item lg={6}>
            <Typography>Invoice For</Typography>
            <Typography style={{ fontWeight: 'bold' }}>{data?.invoiceFor.name}</Typography>
            <Typography>{data?.invoiceFor.email}</Typography>

            <Typography>{data?.invoiceFor.waPhone}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ marginTop: 25 }}>
          <Grid item lg={6}>
            <Typography>Date Created</Typography>
            <Typography style={{ fontWeight: 'bold' }}>Oct 12, 2022</Typography>

            {/* <h2>AXME Phone : +62 812 3456 7890</h2> */}
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ marginTop: 25, marginBottom: 40 }}>
          <Grid item lg={6}>
            <Typography>Total Amount This Month</Typography>
            <h2>IDR 500.000</h2>

            {/* <h2>AXME Phone : +62 812 3456 7890</h2> */}
          </Grid>
        </Grid>
        <Grid container style={{ marginBottom: 40 }}>
          <Grid
            item
            lg={4}
            style={{
              border: '1px solid #E2E2E2',
              paddingLeft: 16,
              paddingTop: 12,
              paddingBottom: 12,
            }}>
            <Typography>
              <strong>{data?.sent.totalOtp}</strong> OTP Message Blast
            </Typography>
            <h3>IDR {data?.sent.priceOtp}</h3>
          </Grid>
          <Grid
            item
            lg={4}
            style={{
              border: '1px solid #E2E2E2',
              paddingLeft: 16,
              paddingTop: 12,
              paddingBottom: 12,
            }}>
            <Typography>
              <strong>{data?.sent.totalBlast}</strong> Blast Messages Sent
            </Typography>
            <h3>IDR {data?.sent.priceBlast}</h3>
          </Grid>
          <Grid
            item
            lg={4}
            style={{
              border: '1px solid #E2E2E2',
              paddingLeft: 16,
              paddingTop: 12,
              paddingBottom: 12,
            }}>
            <Typography>
              <strong>{data?.sent.totalReply}</strong> Auto-Reply Messages Sent
            </Typography>
            <h3>IDR {data?.sent.priceReply}</h3>
          </Grid>
        </Grid>
        <Table
          // idHref="id"
          // onCustomClick={() => console.log('')}
          // name="otp/configuration"
          data={[]}
          config={[
            {
              label: 'Date',
              key: 'date',
            },
            {
              label: 'Type',
              key: 'type',
            },
            {
              label: 'Qty',
              key: 'qty',
            },
            {
              label: 'Unit Price',
              key: 'unitPrice',
            },
            {
              label: 'Total Price',
              key: 'totalPrice',
            },
          ]}
        />
        <WidgetFooter>
          <Pagination
            page={page}
            count={totalData?.total}
            rowsPerPage={limit}
            onPageChange={(_, newPage) => setPage(newPage)}
            onRowsPerPageChange={(e) => {
              setLimit(e.target.value as number);
              setPage(1);
            }}
          />
        </WidgetFooter>
      </Paper>
    </Container>
  );
}
