import { Theme, ThemeOptions } from '@mui/material';

export default function Pagination(theme: Theme): ThemeOptions['components'] {
  return {
    MuiPagination: {
      defaultProps: {
        shape: 'circular',
      },
      styleOverrides: {
        root: {
          '.MuiPaginationItem-icon': {
            color: theme.palette.primary.main,
          },
          '.MuiPaginationItem-text': {
            color: theme.palette.neutral[400],
          },
          '& > .Mui-selected': {
            color: theme.palette.primary.main,
          },
        },
        ul: {
          '.MuiPaginationItem-root': {
            '&.Mui-selected': {
              background: 'none',
              color: theme.palette.primary.main,
              '&:hover': {
                background: theme.palette.primary.light,
              },
            },
            '&:hover': {
              background: theme.palette.primary.light,
              color: theme.palette.neutral.white,
            },
          },
        },
      },
    },
  };
}
