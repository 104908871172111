import React, { useState, MouseEvent } from 'react';
// import { faker } from '@faker-js/faker';
// material
import { Stack, Box, Typography, useTheme } from '@mui/material';
// components
import UserMenuPopover from './UserMenuPopover';
// icons
import { ChevronDownSqfr2 } from '@xcidic/icons';
import { getUser } from '../../utils/localStorage';

export interface UserMenuProps {
  activePath: string;
}

const UserMenu = ({ activePath }: UserMenuProps) => {
  const { palette } = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (e: MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const { name, roles } = getUser();
  return (
    <>
      <Stack
        direction="row"
        spacing={1}
        sx={{ alignItems: 'center', '&:hover': { cursor: 'pointer' } }}
        onClick={handleClick}>
        {/* <Avatar src={faker.image.people()} /> */}

        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Typography component="p" fontWeight="bold" variant="b2">
            {name}
          </Typography>
          <Typography component="span" variant="b4" sx={{ color: palette.neutral[400] }}>
            {roles[0]?.name}
          </Typography>
        </Box>

        <ChevronDownSqfr2 sx={{ fontSize: 24, color: palette.primary.main }} />
      </Stack>

      <UserMenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        activePath={activePath}
      />
    </>
  );
};

export default UserMenu;
