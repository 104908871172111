// coachmark
import { Joyride } from '../../../libs/react-joyride';

// redux
import { RootState } from '../../../redux/store';
import { useSelector } from 'react-redux';

function CompanyTour() {
  // variable init
  const runState = useSelector((state: RootState) => state.tour.run);
  const stepsState = useSelector((state: RootState) => state.tour.steps);
  const stepIndexState = useSelector((state: RootState) => state.tour.stepIndex);
  const callbackState = useSelector((state: RootState) => state.tour.callback);

  return stepsState.length > 0 ? (
    <Joyride
      run={runState}
      steps={stepsState}
      stepIndex={stepIndexState}
      callback={callbackState}
    />
  ) : null;
}

export default CompanyTour;
