import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

// List CoachMark Function
// company
// dispatch(changeValue('Business Information'));
// dispatch(changeType('business'));
// dispatch(changeMax(3));
// dispatch(increment());
// token
// dispatch(changeValue('Business Information'));
// dispatch(changeType('token'));
// dispatch(changeMax(3));
// dispatch(increment());
// otp
// dispatch(changeValue('OTP'));
// dispatch(changeType(''));
// dispatch(changeMax(7));
// dispatch(increment());
// message blast
// dispatch(changeValue('Message Blast'));
// dispatch(changeType(''));
// dispatch(changeMax(9));
// dispatch(increment());
// autoreply
// dispatch(changeValue('Auto Reply'));
// dispatch(changeType('autoreply'));
// dispatch(changeMax(3));
// dispatch(increment());
export interface CounterState {
  type: string;
  value: string;
  max: number;
  step: number;
}

const initialState: CounterState = {
  type: '',
  value: '',
  max: 0,
  step: 0,
};

export const counterSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    increment: (state) => {
      state.step += 1;
    },
    changeStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload;
    },
    changeMax: (state, action: PayloadAction<number>) => {
      state.max = action.payload;
    },
    changeType: (state, action: PayloadAction<string>) => {
      state.type = action.payload;
    },
    changeValue: (state, action: PayloadAction<string>) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeValue, changeType, increment, changeMax, changeStep } = counterSlice.actions;

export default counterSlice.reducer;
