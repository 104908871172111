import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { BASE_URL } from '../../utils/url';
import axios from '../../utils/request';
import { getUser } from '../../utils/localStorage';

interface params {
  limit: number;
  offset: number;
}

export interface requestData {
  description: String;
  withExpiration: Boolean;
  expiresOn: number;
}

export interface paramsById {
  id: string;
}
export interface requestDataEdit {
  id: string;
  description: String;
}

export interface deleteData {
  id: string;
}

interface errorRequest {
  message: String;
}

const initialState = {
  listToken: [],
  responseApi: {},
};

const createToken = (description: String, withExpiration: Boolean, expiresOn: number) => {
  const { token } = getUser();

  return axios.post(
    `${BASE_URL}/token/my`,
    {
      description,
      withExpiration,
      expiresOn,
    },
    {
      headers: {
        Authorization: `Bearer ${token as string}`,
      },
    }
  );
};

export const createService = createAsyncThunk(
  'module/createToken',
  async ({ description, withExpiration, expiresOn }: requestData, thunkAPI) => {
    try {
      const data = await createToken(description, withExpiration, expiresOn);
      thunkAPI.fulfillWithValue(data.data);
      return { responseApi: data?.data };
    } catch (error) {
      const err = error as AxiosError;
      return thunkAPI.rejectWithValue((err.response?.data as errorRequest).message);
    }
  }
);

const editToken = (id: string, description: String) => {
  const { token } = getUser();

  return axios.patch(
    `${BASE_URL}/token/my/${id}`,
    {
      description,
    },
    {
      headers: {
        Authorization: `Bearer ${token as string}`,
      },
    }
  );
};

export const editService = createAsyncThunk(
  'module/editToken',
  async ({ id, description }: requestDataEdit, thunkAPI) => {
    try {
      const data = await editToken(id, description);
      thunkAPI.fulfillWithValue(data.data);
      return { responseApi: data?.data };
    } catch (error) {
      const err = error as AxiosError;
      return thunkAPI.rejectWithValue((err.response?.data as errorRequest).message);
    }
  }
);

const getToken = (limit: number, offset: number) => {
  const { token } = getUser();
  return axios.get(`${BASE_URL}/token/my?limit=${limit}&offset=${offset}`, {
    headers: {
      Authorization: `Bearer ${token as string}`,
    },
  });
};

export const get = createAsyncThunk(
  'module/getToken',
  async ({ limit, offset }: params, thunkAPI) => {
    try {
      const data = await getToken(limit, offset);
      // console.log(data);
      thunkAPI.fulfillWithValue(data.data);
      return { listToken: data?.data };
    } catch (error) {
      const err = error as AxiosError;
      // console.log(err);
      return thunkAPI.rejectWithValue((err.response?.data as errorRequest).message);
    }
  }
);

const getByIdToken = (id: string) => {
  const { token } = getUser();
  return axios.get(`${BASE_URL}/token/my/${id}`, {
    headers: {
      Authorization: `Bearer ${token as string}`,
    },
  });
};

export const getById = createAsyncThunk('module/getById', async ({ id }: paramsById, thunkAPI) => {
  try {
    const data = await getByIdToken(id);
    // console.log(data);
    thunkAPI.fulfillWithValue(data.data);
    return { responseApi: data?.data };
  } catch (error) {
    const err = error as AxiosError;
    // console.log(err);
    return thunkAPI.rejectWithValue((err.response?.data as errorRequest).message);
  }
});

const deleteToken = (id: string) => {
  const { token } = getUser();
  return axios.delete(`${BASE_URL}/token/my/${id}`, {
    headers: {
      Authorization: `Bearer ${token as string}`,
    },
  });
};

export const deleteService = createAsyncThunk(
  'module/deleteToken',
  async ({ id }: deleteData, thunkAPI) => {
    try {
      const data = await deleteToken(id);
      thunkAPI.fulfillWithValue(data.data);
      return { responseApi: data?.data };
    } catch (error) {
      const err = error as AxiosError;
      // console.log(err);
      return thunkAPI.rejectWithValue((err.response?.data as errorRequest).message);
    }
  }
);

const token = createSlice({
  name: 'token',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createService.fulfilled, (state, action) => {
      state.responseApi = action.payload.responseApi;
    });
    builder.addCase(createService.rejected, (state, action) => {
      state.responseApi = {};
    });

    builder.addCase(deleteService.fulfilled, (state, action) => {
      state.responseApi = action.payload.responseApi;
    });
    builder.addCase(deleteService.rejected, (state, action) => {
      state.responseApi = {};
    });
    builder.addCase(get.fulfilled, (state, action) => {
      state.listToken = action.payload.listToken;
      // state.isLoggedIn = true;
      // state.user = action.payload.user;
    });
    builder.addCase(get.rejected, (state, action) => {
      state.listToken = [];

      // state.isLoggedIn = false;
      // state.user = null;
    });
    builder.addCase(getById.fulfilled, (state, action) => {
      state.responseApi = action.payload.responseApi;
    });
    builder.addCase(getById.rejected, (state, action) => {
      state.responseApi = {};
    });
  },
});

const { reducer } = token;
export default reducer;
