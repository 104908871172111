export const BASE_URL = process.env.REACT_APP_BASE_URL ?? 'https://user.api.dev.axme.io';

export const DEV_URL = process.env.REACT_APP_DEV_URL ?? 'https://developer.api.dev.axme.io';

export const STATS_URL = process.env.REACT_APP_STATS_URL ?? 'https://statistics.api.dev.axme.io';

export const TRANS_URL = process.env.REACT_APP_TRANS_URL ?? 'https://transaction.api.dev.axme.io';

export const CRON_URL = process.env.REACT_APP_CRON_URL ?? 'https://invoice.api.dev.axme.io';

export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL ?? 'https://chat.api.dev.axme.io';
