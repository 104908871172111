import { Button, CircularProgress, Grid, Paper, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { initialValues, validationSchema } from './schemeValidation';
import Breadcrumbs from '../../components/Breadcrumbs';
import DashboardHeader from '../../components/DashboardHeader';
import Confirm from '../../components/Confirm';
import Container from '../../components/Container';
import { ToastManagerContext } from '../../components/ToastManager';
import { updateService, requestData } from '../../redux/modules/user';
import { AppDispatch } from '../../redux/store';
import { destroyUser, getUser } from '../../utils/localStorage';

export default function index() {
  const { create } = useContext(ToastManagerContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);

  const dispatch = useDispatch<AppDispatch>();
  const {
    handleChange,
    handleBlur,
    // setSubmitting,
    handleSubmit,
    setFieldValue,
    dirty,
    // resetForm,
    values,
    isValid,
    touched,
    errors,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async () => {
      setIsLoading(true);
      try {
        const data: requestData = {
          name: values.name,
          email: values.email,
        };
        dispatch(updateService(data))
          .unwrap()
          .then((res) => {
            setIsLoading(false);
            create('success', 'Success', res.responseApi.message);
            destroyUser();
            window.location.href = '/signin';
          })
          .catch((err) => {
            setIsLoading(false);

            create('error', 'Error', err);
          });
      } catch (error) {
        // handle any rejections/errors
      }
    },
  });

  useEffect(() => {
    setFieldValue('name', getUser().name);
    setFieldValue('email', getUser().email);
  }, []);
  return (
    <Container>
      <Breadcrumbs
        items={[
          {
            label: 'Business Info',
            link: '/company',
          },
          {
            label: 'User',
            link: '/user',
          },
          {
            label: 'Edit User Detail',
            link: '/user/edit',
          },
        ]}
      />
      <DashboardHeader title="Edit User Detail" />

      <Paper>
        <Grid container spacing={3}>
          <Grid item lg={12}>
            <TextField
              fullWidth
              // autoFocus
              name="email"
              variant="outlined"
              type="email"
              label="User Company Email"
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              autoComplete="off"
              className="mt-0 mb-4"
              size="medium"
            />
          </Grid>
          <Grid item lg={12}>
            <TextField
              fullWidth
              // autoFocus
              name="name"
              variant="outlined"
              label="Username"
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              autoComplete="off"
              className="mt-0 mb-4"
              size="medium"
            />
          </Grid>
          <Grid item lg={12} style={{ justifyContent: 'flex-end', display: 'flex' }}>
            <Button
              name="company"
              variant="contained"
              color="primary"
              onClick={() => setIsConfirm(true)}
              disabled={!(dirty && isValid)}>
              {isLoading ? (
                <CircularProgress style={{ color: 'white' }} size={30} />
              ) : (
                <span className="loginButtonCaption">Update User</span>
              )}
            </Button>
            <Confirm
              type={'Create'}
              open={isConfirm}
              handleAksi={() => handleSubmit()}
              loading={false}
              handleClose={() => setIsConfirm(false)}
              disable={false}
              handleContent={{
                title: 'Edit User',
                message: 'Do you wish to Edit User?',
                button: 'Confirm',
              }}
            />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
