import { useContext, useEffect, useState } from 'react';
import { ToastManagerContext } from '../../components/ToastManager';

import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import './style.css';

import { initialValues, validationSchema } from './schemeValidation';
import { useDispatch } from 'react-redux';
import { reset, requestData } from '../../redux/modules/resetPassword';
import { AppDispatch } from '../../redux/store';
import { RemoveSqfr2 } from '@xcidic/icons';
interface propsData {
  open: boolean;
  onClose: Function;
  onSuccess: Function;
  email: string;
}

export default function index({ open, onClose, email, onSuccess }: propsData) {
  const [loading, setIsLoading] = useState(false);
  const { create } = useContext(ToastManagerContext);
  const dispatch = useDispatch<AppDispatch>();

  const {
    handleChange,
    handleBlur,
    // setSubmitting,
    handleSubmit,
    setFieldValue,
    dirty,
    // resetForm,
    values,
    isValid,
    touched,
    errors,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async () => {
      setIsLoading(true);
      try {
        const data: requestData = {
          email: values.email,
        };
        dispatch(reset(data))
          .unwrap()
          .then((res) => {
            setIsLoading(false);
            create('success', 'Success', res.responseApi.message);
            onSuccess();
          })
          .catch((err) => {
            setIsLoading(false);

            create('error', 'Error', err);
          });
      } catch (error) {
        // handle any rejections/errors
      }
    },
  });

  useEffect(() => {
    if (email !== '') {
      setFieldValue('email', email);
    }
  }, [email]);
  return (
    <Dialog open={open} onClose={() => onClose()}>
      <IconButton
        onClick={() => onClose()}
        sx={{
          position: 'absolute',
          right: 24,
          top: 16,
          transition: '0.3s all ease',
          color: (theme) => theme.palette.neutral.black,
        }}>
        <RemoveSqfr2 sx={{ fontSize: 18 }} />
      </IconButton>
      <DialogContent sx={{ padding: 4, textAlign: 'center' }}>
        <Typography variant="h2" component="h2" fontWeight="700" color="neutral.black" mb={1}>
          Reset Your Password
        </Typography>
        <Typography
          variant="b1"
          fontWeight="500"
          color="neutral.400"
          sx={{ width: '70%', margin: 'auto' }}>
          We will send a link to your email to reset your password
        </Typography>

        <Grid container spacing={3} sx={{ marginTop: 2 }}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              // autoFocus
              name="email"
              variant="outlined"
              type="email"
              label="Email *"
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              autoComplete="off"
              className="mt-0 mb-4"
              size="medium"
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              className="loginButton"
              disabled={!(dirty && isValid)}
              onClick={() => handleSubmit()}
              variant="contained"
              color="primary">
              {loading ? (
                <CircularProgress style={{ color: 'white' }} size={30} />
              ) : (
                <span className="loginButtonCaption">Reset Password</span>
              )}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
