import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { BASE_URL } from '../../utils/url';
import axios from '../../utils/request';
import { getUser } from '../../utils/localStorage';

interface params {
  limit: number;
  offset: number;
}

export interface paramsById {
  id: string;
}

export interface paramsByCategories {
  id: string;
  limit: number;
  offset: number;
}

export interface requestData {
  name: string;
  countryCode: string;
  number: string;
  categories: paramsById[];
}

export interface requestDataEdit {
  id: string;
  name: string;
  countryCode: string;
  number: string;
  categories: paramsById[];
}

interface errorRequest {
  message: string;
}

const initialState = {
  list: [],
  responseApi: {},
};

const create = (name: string, countryCode: string, number: string, categories: paramsById[]) => {
  const { token } = getUser();

  return axios.post(
    `${BASE_URL}/user-contact/my`,
    {
      name,
      countryCode,
      number,
      categories,
    },
    {
      headers: {
        Authorization: `Bearer ${token as string}`,
      },
    }
  );
};

export const createService = createAsyncThunk(
  'module/createContact',
  async ({ name, countryCode, number, categories }: requestData, thunkAPI) => {
    try {
      const data = await create(name, countryCode, number, categories);
      thunkAPI.fulfillWithValue(data.data);
      return { responseApi: data?.data };
    } catch (error) {
      const err = error as AxiosError;
      return thunkAPI.rejectWithValue((err.response?.data as errorRequest).message);
    }
  }
);

const edit = (
  id: string,
  name: string,
  countryCode: string,
  number: string,
  categories: paramsById[]
) => {
  const { token } = getUser();

  return axios.patch(
    `${BASE_URL}/user-contact/my/${id}`,
    {
      name,
      countryCode,
      number,
      categories,
    },
    {
      headers: {
        Authorization: `Bearer ${token as string}`,
      },
    }
  );
};

export const editService = createAsyncThunk(
  'module/editContact',
  async ({ id, name, countryCode, number, categories }: requestDataEdit, thunkAPI) => {
    try {
      const data = await edit(id, name, countryCode, number, categories);
      thunkAPI.fulfillWithValue(data.data);
      return { responseApi: data?.data };
    } catch (error) {
      const err = error as AxiosError;
      return thunkAPI.rejectWithValue((err.response?.data as errorRequest).message);
    }
  }
);

const get = (limit: number, offset: number) => {
  const { token } = getUser();
  return axios.get(`${BASE_URL}/user-contact/my?limit=${limit}&offset=${offset}`, {
    headers: {
      Authorization: `Bearer ${token as string}`,
    },
  });
};

export const getService = createAsyncThunk(
  'module/getContact',
  async ({ limit, offset }: params, thunkAPI) => {
    try {
      const data = await get(limit, offset);
      // // console.log(data);
      thunkAPI.fulfillWithValue(data.data);
      return { listToken: data?.data };
    } catch (error) {
      const err = error as AxiosError;
      // // console.log(err);
      return thunkAPI.rejectWithValue((err.response?.data as errorRequest).message);
    }
  }
);

const getByCategories = (limit: number, offset: number, id: string) => {
  const { token } = getUser();
  return axios.get(`${BASE_URL}/user-contact/my?limit=${limit}&offset=${offset}&categoryId=${id}`, {
    headers: {
      Authorization: `Bearer ${token as string}`,
    },
  });
};

export const getServiceByCategories = createAsyncThunk(
  'module/getContactByCategories',
  async ({ limit, offset, id }: paramsByCategories, thunkAPI) => {
    try {
      const data = await getByCategories(limit, offset, id);
      // // console.log(data);
      thunkAPI.fulfillWithValue(data.data);
      return { listToken: data?.data };
    } catch (error) {
      const err = error as AxiosError;
      // // console.log(err);
      return thunkAPI.rejectWithValue((err.response?.data as errorRequest).message);
    }
  }
);

const getById = (id: string) => {
  const { token } = getUser();
  return axios.get(`${BASE_URL}/user-contact/my/${id}`, {
    headers: {
      Authorization: `Bearer ${token as string}`,
    },
  });
};

export const getServiceById = createAsyncThunk(
  'module/getContactById',
  async ({ id }: paramsById, thunkAPI) => {
    try {
      const data = await getById(id);
      // // console.log(data);
      thunkAPI.fulfillWithValue(data.data);
      return { responseApi: data?.data };
    } catch (error) {
      const err = error as AxiosError;
      // // console.log(err);
      return thunkAPI.rejectWithValue((err.response?.data as errorRequest).message);
    }
  }
);
const deleteContact = (id: string) => {
  const { token } = getUser();
  return axios.delete(`${BASE_URL}/user-contact/my/${id}`, {
    headers: {
      Authorization: `Bearer ${token as string}`,
    },
  });
};

export const deleteService = createAsyncThunk(
  'module/deleteContact',
  async ({ id }: paramsById, thunkAPI) => {
    try {
      const data = await deleteContact(id);
      thunkAPI.fulfillWithValue(data.data);
      return { responseApi: data?.data };
    } catch (error) {
      const err = error as AxiosError;
      // // console.log(err);
      return thunkAPI.rejectWithValue((err.response?.data as errorRequest).message);
    }
  }
);

const contactCategory = createSlice({
  name: 'contact',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createService.fulfilled, (state, action) => {
      state.responseApi = action.payload.responseApi;
    });
    builder.addCase(createService.rejected, (state, action) => {
      state.responseApi = {};
    });
    builder.addCase(editService.fulfilled, (state, action) => {
      state.responseApi = action.payload.responseApi;
    });
    builder.addCase(editService.rejected, (state, action) => {
      state.responseApi = {};
    });

    builder.addCase(deleteService.fulfilled, (state, action) => {
      state.responseApi = action.payload.responseApi;
    });
    builder.addCase(deleteService.rejected, (state, action) => {
      state.responseApi = {};
    });
    builder.addCase(getServiceById.fulfilled, (state, action) => {
      state.responseApi = action.payload.responseApi;
    });
    builder.addCase(getServiceById.rejected, (state, action) => {
      state.responseApi = {};
    });
    builder.addCase(getService.fulfilled, (state, action) => {
      state.list = action.payload.listToken;
      // state.isLoggedIn = true;
      // state.user = action.payload.user;
    });
    builder.addCase(getService.rejected, (state, action) => {
      state.list = [];

      // state.isLoggedIn = false;
      // state.user = null;
    });
    builder.addCase(getServiceByCategories.fulfilled, (state, action) => {
      state.list = action.payload.listToken;
      // state.isLoggedIn = true;
      // state.user = action.payload.user;
    });
    builder.addCase(getServiceByCategories.rejected, (state, action) => {
      state.list = [];

      // state.isLoggedIn = false;
      // state.user = null;
    });
  },
});

const { reducer } = contactCategory;
export default reducer;
