import * as React from 'react';

const TokenIcon = (props: any) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 8a1 1 0 1 1 0 2h-1v4h1a1 1 0 1 1 0 2h-1.018a6.714 6.714 0 0 1-.013.195c-.032.395-.103.789-.296 1.167a3 3 0 0 1-1.311 1.311c-.378.193-.772.264-1.167.296-.063.006-.128.01-.195.013V20a1 1 0 1 1-2 0v-1h-4v1a1 1 0 1 1-2 0v-1.018a6.723 6.723 0 0 1-.195-.013c-.395-.032-.789-.103-1.167-.296a3 3 0 0 1-1.311-1.311c-.193-.378-.264-.772-.296-1.167A6.826 6.826 0 0 1 5.018 16H4a1 1 0 1 1 0-2h1v-4H4a1 1 0 0 1 0-2h1.018c.003-.067.007-.132.013-.195.032-.395.103-.789.296-1.167a3 3 0 0 1 1.311-1.311c.378-.193.772-.264 1.167-.296.063-.006.128-.01.195-.013V4a1 1 0 0 1 2 0v1h4V4a1 1 0 1 1 2 0v1.018c.067.003.132.007.195.013.395.032.789.103 1.167.296a3 3 0 0 1 1.311 1.311c.193.378.264.772.296 1.167.006.063.01.128.013.195H20ZM7.968 7.024c-.272.022-.373.06-.422.085a1 1 0 0 0-.437.437c-.025.05-.063.15-.085.422C7 8.25 7 8.623 7 9.2v5.6c0 .577 0 .949.024 1.232.022.272.06.372.085.422a1 1 0 0 0 .437.437c.05.025.15.063.422.085C8.25 17 8.623 17 9.2 17h5.6c.577 0 .949 0 1.232-.024.272-.022.372-.06.422-.085a1 1 0 0 0 .437-.437c.025-.05.063-.15.085-.422C17 15.75 17 15.377 17 14.8V9.2c0-.577 0-.949-.024-1.232-.022-.272-.06-.373-.085-.422a1 1 0 0 0-.437-.437c-.05-.025-.15-.063-.422-.085C15.75 7 15.377 7 14.8 7H9.2c-.577 0-.949 0-1.232.024Z"
      fill="#545454"
    />
    <path
      d="M10 11a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2Z"
      fill="#545454"
    />
  </svg>
);

export default TokenIcon;
