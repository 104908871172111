import React from 'react';
import { TooltipRenderProps } from 'react-joyride';
import { styled, Box, useTheme } from '@mui/material';

const TooltipBody = styled(Box)({
  padding: 16,
  borderRadius: 6,
  boxShadow: '0px 2px 16px 2px rgba(0, 0, 0, 0.15)',
  backgroundColor: '#fff',
  position: 'relative',
});

const TooltipArm = styled(Box)({
  backgroundColor: '#fff',
  boxShadow: '0px 2px 16px 2px rgba(0, 0, 0, 0.15)',
  height: 60,
  width: 4,
  borderRadius: 5,
});

const BasicBullet = styled(Box)({
  borderRadius: '50%',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});

const ArmBullet = () => {
  const { palette } = useTheme();
  return (
    <Box sx={{ position: 'relative' }}>
      <BasicBullet
        sx={{
          width: 32,
          height: 32,
          opacity: 0.5,
          background: palette.primary.main,
          border: `solid 2px ${palette.primary.light}`,
        }}
      />
      <BasicBullet
        sx={{
          width: 16,
          height: 16,
          background: palette.primary.main,
          border: `solid 2px ${palette.primary.light}`,
        }}
      />
      <BasicBullet
        sx={{
          width: 8,
          height: 8,
          background: palette.neutral.light,
        }}
      />
    </Box>
  );
};

export const CustomTooltip = ({ step, tooltipProps }: TooltipRenderProps) => {
  const { placement = 'bottom', offset = 40 } = step;

  const axis =
    placement?.includes('left') || placement?.includes('right')
      ? 'horizontal'
      : placement?.includes('top') || placement?.includes('bottom')
      ? 'vertical'
      : 'others';

  return (
    <TooltipBody {...tooltipProps}>
      {(axis === 'horizontal' || axis === 'vertical') && (
        <Box
          sx={{
            position: 'absolute',
            ...(axis === 'horizontal'
              ? {
                  display: 'flex',
                  height: 'min-content',
                  top: 0,
                  bottom: 0,
                  margin: 'auto 0',
                  ...(placement.includes('left')
                    ? { flexDirection: 'row-reverse', left: 'calc(100% + 2px)' }
                    : { right: 'calc(100% - 2px)' }),
                }
              : {
                  width: 'min-content',
                  left: 0,
                  right: 0,
                  margin: '0 auto',
                  ...(placement.includes('top')
                    ? { display: 'flex', flexDirection: 'column-reverse', top: 'calc(100% + 2px)' }
                    : { bottom: 'calc(100% - 2px)' }),
                }),
          }}>
          <ArmBullet />
          <TooltipArm
            sx={
              axis === 'horizontal'
                ? {
                    width: offset + 12,
                    height: 4,
                  }
                : {
                    width: 4,
                    height: offset + 12,
                  }
            }
          />
        </Box>
      )}
      {step.content}
    </TooltipBody>
  );
};
