import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
  FormControl,
  Box,
  Stack,
} from '@mui/material';
import { useFormik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { initialValues, validationSchema } from './schemeValidation';
import Confirm from '../../../components/Confirm';
import Dialog from '../../../components/Dialog';
import TextField from '../../../components/TextField';
import { ToastManagerContext } from '../../../components/ToastManager';
import {
  createService,
  getIntentionService,
  requestData,
} from '../../../redux/modules/responseAutoReply';
import { AppDispatch } from '../../../redux/store';

interface PropsModal {
  open: boolean;
  onClose: Function;
}

interface listIntentInterface {
  name: string;
  description: string;
}

export default function index({ open, onClose }: PropsModal) {
  const { create } = useContext(ToastManagerContext);
  const dispatch = useDispatch<AppDispatch>();
  const [listIntention, setIntention] = useState<listIntentInterface[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);

  useEffect(() => {
    dispatch(getIntentionService())
      .unwrap()
      .then((res) => {
        const listIntent = Object.entries(res.responseApi?.data).map(function (entry) {
          const key = entry[0];
          const value = entry[1];
          return {
            name: key,
            description: value?.toString() ?? ' ',
          };
        });
        setIntention(listIntent);
      })
      .catch((err) => {
        create('error', 'Error', err);
      });
  }, []);

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    dirty,
    resetForm,
    values,
    isValid,
    touched,
    errors,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async () => {
      setIsLoading(true);
      try {
        const data: requestData = {
          intention: values.intention,
          langCode: values.langCode,
          value: values.value,
        };
        dispatch(createService(data))
          .unwrap()
          .then((res) => {
            setIsLoading(false);

            create('success', 'Success', res.responseApi.message);
            resetForm();
            onClose();
          })
          .catch((err) => {
            setIsLoading(false);

            create('error', 'Error', err);
          });
      } catch (err) {
        console.error(err);
      }
    },
  });
  return (
    <>
      <Dialog open={open} onClose={() => onClose()} maxWidth="sm" fullWidth>
        <Typography variant="h3" fontWeight={600} mb={4}>
          Add Auto Reply Answer
        </Typography>

        <Stack spacing={3}>
          <Autocomplete
            id="tags-outlined"
            onChange={(event: any, newValue: listIntentInterface | null) => {
              setFieldValue('intention', newValue?.name ?? '');
            }}
            options={listIntention ?? []}
            getOptionLabel={(option) => option.description}
            renderInput={(params) => (
              <TextField {...params} label="Intention" placeholder="Choose intention" fullWidth />
            )}
          />

          <FormControl fullWidth>
            <FormLabel>Default Language Code</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="en"
              name="radio-buttons-group"
              onChange={(event) => setFieldValue('langCode', event.target.value)}>
              <FormControlLabel value="en" control={<Radio />} label="English" />
              <FormControlLabel value="id" control={<Radio />} label="Indonesian" />
            </RadioGroup>
          </FormControl>

          <TextField
            id="standard-textarea"
            label="Auto Reply Answer"
            fullWidth
            name="value"
            multiline
            rows={4}
            placeholder="Write your answer here"
            error={Boolean(touched.value && errors.value)}
            errorText={errors.value}
            value={values.value}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
            className="mt-0 mb-4"
            size="medium"
          />

          <Box display="flex" justifyContent="flex-end">
            <Button
              name="company"
              variant="contained"
              color="primary"
              disabled={!(dirty && isValid)}
              onClick={() => setIsConfirm(true)}>
              {isLoading ? (
                <CircularProgress sx={{ color: 'white' }} size={30} />
              ) : (
                <Typography component="span" className="loginButtonCaption">
                  {' '}
                  Create Answer
                </Typography>
              )}
            </Button>
          </Box>
        </Stack>
      </Dialog>

      <Confirm
        open={isConfirm}
        type={'Create'}
        handleAksi={() => {
          setIsConfirm(false);
          handleSubmit();
        }}
        loading={false}
        handleClose={() => setIsConfirm(false)}
        disable={false}
        handleContent={{
          title: 'Create Answer',
          message: 'Do you wish to Create this Answer?',
          button: 'Submit',
        }}
      />
    </>
  );
}
