import { useContext, useState, useEffect } from 'react';
import { ToastManagerContext } from '../../components/ToastManager';

import {
  Box,
  CircularProgress,
  TextField,
  Button,
  Grid,
  Container,
  Typography,
  Dialog,
  // Link,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { useParams } from 'react-router';
import './style.css';
import { useFormik } from 'formik';

import {
  editInitialValues as initialValues,
  editValidationSchema as validationSchema,
} from './schemeValidation';
import { useNavigate } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../redux/store';
import { changePasswordAsync, requestData } from '../../redux/modules/changePassword';

const OverviewWrapper = styled(Box)(
  () => `
    overflow: auto;
    flex: 1;
    overflow-x: hidden;
    align-items: center;
    padding-top:97px;
    padding-left:87px;
`
);

export default function index() {
  const [loading, setIsLoading] = useState(false);
  const { create } = useContext(ToastManagerContext);
  const dispatch = useDispatch<AppDispatch>();
  const [send, setIsSend] = useState(false);
  const navigate = useNavigate();

  const {
    handleChange,
    handleBlur,
    // setSubmitting,
    handleSubmit,
    setFieldValue,
    dirty,
    // resetForm,
    values,
    isValid,
    touched,
    errors,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async () => {
      setIsLoading(true);
      try {
        const data: requestData = {
          token: values.token,
          password: values.password,
        };
        dispatch(changePasswordAsync(data))
          .unwrap()
          .then((res) => {
            setIsLoading(false);
            setIsSend(true);
            create('success', 'Success', res.responseApi.message);
          })
          .catch((err) => {
            setIsLoading(false);

            create('error', 'Error', err);
          });
      } catch (error) {
        // handle any rejections/errors
      }
    },
  });
  const { id } = useParams();
  useEffect(() => {
    setFieldValue('token', id);
  }, []);
  return (
    <OverviewWrapper>
      {/* <Helmet>
        <title>Login</title>
      </Helmet> */}
      {/* <Link href="http://hg-web.xcidic.com:3000/" style={{ textDecoration: 'none' }}>
        <Typography
          style={{
            cursor: 'pointer',
            color: '#258BEA',
          }}>{`< Back To Website`}</Typography>
      </Link> */}

      <Container maxWidth="lg">
        <Box
          sx={{ display: 'flex', justifyContent: 'center', marginBottom: 5, cursor: 'pointer' }}
          onClick={() => navigate('/')}>
          <Box component="img" src="/axme-logo.svg" sx={{ width: 124 }} />
        </Box>
        <Box sx={{ textAlign: 'center', marginBottom: 5, marginTop: '175px' }}>
          <h2>Reset Password</h2>
          <Typography>{`Fill in the fields below to reset your password`}</Typography>
        </Box>
        <div style={{ justifyContent: 'center', display: 'flex' }}>
          <Grid container spacing={3} lg={4}>
            <Grid item lg={12}>
              {/* <Typography>{`New Password`}</Typography> */}
              <TextField
                fullWidth
                // autoFocus
                name="password"
                variant="outlined"
                type="password"
                label="New Password *"
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="off"
                className="mt-0 mb-4"
                size="medium"
              />
            </Grid>
            <Grid item lg={12}>
              <Button
                fullWidth
                className="loginButton"
                disabled={!(dirty && isValid)}
                onClick={() => handleSubmit()}
                variant="contained"
                color="primary">
                {loading ? (
                  <CircularProgress style={{ color: 'white' }} size={30} />
                ) : (
                  <span className="loginButtonCaption">Save New Password</span>
                )}
              </Button>
            </Grid>
          </Grid>
        </div>
      </Container>
      <div
        style={{
          textAlign: 'center',
          marginTop: '350px',
        }}>
        <Typography>{`Version 1.0 © Powered by XCIDIC. 2022`}</Typography>
      </div>
      <Dialog
        open={send}
        onClose={() => {
          setIsSend(false);
        }}>
        <div>
          <p style={{ cursor: 'pointer', textAlign: 'end' }} onClick={() => setIsSend(false)}>
            X
          </p>
          <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 5 }}>
            <Box component="img" src="/resetSuccess.svg" sx={{ width: 320 }} />
          </Box>
          <div style={{ textAlign: 'center', marginBottom: '10px' }}>
            <h2>Reset Password Success</h2>
            <Typography>{`You've successfully updated your password`}</Typography>
          </div>

          <Button
            fullWidth
            className="loginButton"
            // disabled={!(dirty && isValid)}
            onClick={() => navigate('/signin')}
            variant="contained"
            color="primary">
            <span className="loginButtonCaption">Back To Login</span>
          </Button>
        </div>
      </Dialog>
    </OverviewWrapper>
    // <div className="container">
    // <Box className="box-content">

    // </Box>
    // </div>
  );
}
