import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { BASE_URL } from '../../utils/url';
import axios from '../../utils/request';
import { getUser } from '../../utils/localStorage';

interface params {
  limit: number;
  offset: number;
}

export interface requestData {
  name: string;
}

export interface idContact {
  id: string;
}
export interface addContact {
  id: string;
  contacts: idContact[];
}
export interface deleteByCategory {
  id: string;
  idContact: string;
}
export interface requestDataEdit {
  id: string;
  name: string;
}

export interface deleteData {
  id: string;
}

interface errorRequest {
  message: string;
}

const initialState = {
  list: [],
  responseApi: {},
};

const create = (name: string) => {
  const { token } = getUser();

  return axios.post(
    `${BASE_URL}/user-contact/my/category`,
    {
      name,
    },
    {
      headers: {
        Authorization: `Bearer ${token as string}`,
      },
    }
  );
};

export const createService = createAsyncThunk(
  'module/createContactCategory',
  async ({ name }: requestData, thunkAPI) => {
    try {
      const data = await create(name);
      thunkAPI.fulfillWithValue(data.data);
      return { responseApi: data?.data };
    } catch (error) {
      const err = error as AxiosError;
      return thunkAPI.rejectWithValue((err.response?.data as errorRequest).message);
    }
  }
);

const createByCategory = (id: string, contacts: idContact[]) => {
  const { token } = getUser();

  return axios.post(
    `${BASE_URL}/user-contact/my/category/${id}/contact`,
    {
      contacts,
    },
    {
      headers: {
        Authorization: `Bearer ${token as string}`,
      },
    }
  );
};

export const createByCategoryService = createAsyncThunk(
  'module/createByCategoryService',
  async ({ id, contacts }: addContact, thunkAPI) => {
    try {
      const data = await createByCategory(id, contacts);
      thunkAPI.fulfillWithValue(data.data);
      return { responseApi: data?.data };
    } catch (error) {
      const err = error as AxiosError;
      return thunkAPI.rejectWithValue((err.response?.data as errorRequest).message);
    }
  }
);

const edit = (id: string, name: string) => {
  const { token } = getUser();

  return axios.patch(
    `${BASE_URL}/user-contact/my/category/${id}`,
    {
      name,
    },
    {
      headers: {
        Authorization: `Bearer ${token as string}`,
      },
    }
  );
};

export const editService = createAsyncThunk(
  'module/editContactCategory',
  async ({ id, name }: requestDataEdit, thunkAPI) => {
    try {
      const data = await edit(id, name);
      thunkAPI.fulfillWithValue(data.data);
      return { responseApi: data?.data };
    } catch (error) {
      const err = error as AxiosError;
      return thunkAPI.rejectWithValue((err.response?.data as errorRequest).message);
    }
  }
);

const get = (limit: number, offset: number) => {
  const { token } = getUser();
  return axios.get(`${BASE_URL}/user-contact/my/category?limit=${limit}&offset=${offset}`, {
    headers: {
      Authorization: `Bearer ${token as string}`,
    },
  });
};

export const getService = createAsyncThunk(
  'module/getContactCategory',
  async ({ limit, offset }: params, thunkAPI) => {
    try {
      const data = await get(limit, offset);
      // // console.log(data);
      thunkAPI.fulfillWithValue(data.data);
      return { listToken: data?.data };
    } catch (error) {
      const err = error as AxiosError;
      // // console.log(err);
      return thunkAPI.rejectWithValue((err.response?.data as errorRequest).message);
    }
  }
);

const deleteContactCategory = (id: string) => {
  const { token } = getUser();
  return axios.delete(`${BASE_URL}/user-contact/my/category/${id}`, {
    headers: {
      Authorization: `Bearer ${token as string}`,
    },
  });
};

export const deleteService = createAsyncThunk(
  'module/deleteContactCategory',
  async ({ id }: deleteData, thunkAPI) => {
    try {
      const data = await deleteContactCategory(id);
      thunkAPI.fulfillWithValue(data.data);
      return { responseApi: data?.data };
    } catch (error) {
      const err = error as AxiosError;
      // // console.log(err);
      return thunkAPI.rejectWithValue((err.response?.data as errorRequest).message);
    }
  }
);

const deleteContactByCategory = (id: string, idContact: string) => {
  const { token } = getUser();
  return axios.delete(`${BASE_URL}/user-contact/my/category/${id}/contact/${idContact}`, {
    headers: {
      Authorization: `Bearer ${token as string}`,
    },
  });
};

export const deleteContactByCategoryService = createAsyncThunk(
  'module/deleteContactByCategoryService',
  async ({ id, idContact }: deleteByCategory, thunkAPI) => {
    try {
      const data = await deleteContactByCategory(id, idContact);
      thunkAPI.fulfillWithValue(data.data);
      return { responseApi: data?.data };
    } catch (error) {
      const err = error as AxiosError;
      // // console.log(err);
      return thunkAPI.rejectWithValue((err.response?.data as errorRequest).message);
    }
  }
);
const contactCategory = createSlice({
  name: 'contactCategory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createService.fulfilled, (state, action) => {
      state.responseApi = action.payload.responseApi;
    });
    builder.addCase(createService.rejected, (state, action) => {
      state.responseApi = {};
    });

    builder.addCase(createByCategoryService.fulfilled, (state, action) => {
      state.responseApi = action.payload.responseApi;
    });
    builder.addCase(createByCategoryService.rejected, (state, action) => {
      state.responseApi = {};
    });
    builder.addCase(editService.fulfilled, (state, action) => {
      state.responseApi = action.payload.responseApi;
    });
    builder.addCase(editService.rejected, (state, action) => {
      state.responseApi = {};
    });

    builder.addCase(deleteContactByCategoryService.fulfilled, (state, action) => {
      state.responseApi = action.payload.responseApi;
    });
    builder.addCase(deleteContactByCategoryService.rejected, (state, action) => {
      state.responseApi = {};
    });

    builder.addCase(deleteService.fulfilled, (state, action) => {
      state.responseApi = action.payload.responseApi;
    });
    builder.addCase(deleteService.rejected, (state, action) => {
      state.responseApi = {};
    });
    builder.addCase(getService.fulfilled, (state, action) => {
      state.list = action.payload.listToken;
      // state.isLoggedIn = true;
      // state.user = action.payload.user;
    });
    builder.addCase(getService.rejected, (state, action) => {
      state.list = [];

      // state.isLoggedIn = false;
      // state.user = null;
    });
  },
});

const { reducer } = contactCategory;
export default reducer;
