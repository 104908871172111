import { array, object, string } from 'yup';

export const initialValues = {
  name: '',
  countryCode: '',
  number: '',
};

export const validationSchema = object({
  name: string().required("Name can't be blank"),
  number: string().required("Whatsapp Number can't be blank"),
});

export const editInitialValues = {
  name: '',
};

export const editValidationSchema = object({
  name: string().required("Name can't be blank"),
});

export const addContactInitialValues = {
  contacts: [],
};

export const addContactvalidationSchema = object({
  contacts: array().min(1, 'Must Select One Contact'),
});
