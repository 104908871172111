import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { STATS_URL } from '../../utils/url';
import axios from '../../utils/request';
import { getUser } from '../../utils/localStorage';

export interface params {
  url: string;
  type: string;
}

interface errorRequest {
  message: string;
}

const initialState = {
  list: [],
  responseApi: {},
};

const get = (url: string, type: string) => {
  const { token } = getUser();
  return axios.get(`${STATS_URL}/stats/${url}/?${type}`, {
    headers: {
      Authorization: `Bearer ${token as string}`,
    },
  });
};

export const getService = createAsyncThunk(
  'module/getStatistic',
  async ({ url, type }: params, thunkAPI) => {
    try {
      const data = await get(url, type);
      // // console.log(data);
      thunkAPI.fulfillWithValue(data.data);
      return { responseApi: data?.data };
    } catch (error) {
      const err = error as AxiosError;
      // // console.log(err);
      return thunkAPI.rejectWithValue((err.response?.data as errorRequest).message);
    }
  }
);

const statistic = createSlice({
  name: 'statistic',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getService.fulfilled, (state, action) => {
      state.list = action.payload.responseApi;
      // state.isLoggedIn = true;
      // state.user = action.payload.user;
    });
    builder.addCase(getService.rejected, (state, action) => {
      state.list = [];

      // state.isLoggedIn = false;
      // state.user = null;
    });
  },
});

const { reducer } = statistic;
export default reducer;
