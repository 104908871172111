import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

// material
import {
  Box,
  Button,
  Collapse,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
} from '@mui/material';

// redux
import { RootState } from '../../redux/store';
import { useSelector } from 'react-redux';

// icons
import { ChevronDownSqfr2, ChevronUpSqfr2, Logout } from '@xcidic/icons';
import { destroyUser } from '../../utils/localStorage';
import Scrollbar from '../../components/Scrollbar';

const NavButton = styled(ListItemButton)(({ theme: { palette } }) => ({
  color: palette.neutral[600],
  borderRadius: 4,
  '.MuiListItemIcon-root': {
    minWidth: 48,
    svg: {
      fontSize: 22,
    },
  },
  '.MuiListItemText-root .MuiTypography-root': {
    fontWeight: 500,
  },
  '.navItem-dropdown-icon': {
    fontSize: 20,
    color: palette.primary.main,
  },
  '&.Mui-selected': {
    color: palette.primary.main,
    '.MuiListItemIcon-root': {
      color: 'inherit',
    },
    '.MuiListItemText-root .MuiTypography-root': {
      color: 'inherit',
    },
  },
  margin: '4px 16px',
})) as typeof ListItemButton;

const SidebarContainer = styled(Box)({
  padding: '24px 16px 24px 16px',
  width: '100%',
  height: '100%',
  maxHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
});

const isActivePath = (navItem: NavigationItem, activePath: string): boolean => {
  if ('pathname' in navItem) {
    return navItem.pathname === activePath;
  }

  return navItem.subItems.some((item) => isActivePath(item, activePath));
};

// -----------------------------------------------------------------------------------------------

interface NavigationGroupType {
  id: string;
  label: string;
  icon?: JSX.Element;
  subItems: NavigationItem[];
}

type NavigationGroupProps = NavigationGroupType & {
  level: number;
  activePath: string;
  index: number;
};

const NavigationGroup = ({ level, activePath, index, ...navProps }: NavigationGroupProps) => {
  const guide = useSelector((state: RootState) => state.tour.guide);
  const stepIndex = useSelector((state: RootState) => state.tour.stepIndex);
  const { id, label, icon, subItems } = navProps;
  const isActive = isActivePath(navProps, activePath);
  const [isOpen, setIsOpen] = useState(isActive);

  useEffect(() => {
    if (index === 1 && guide === 'Company Guide' && stepIndex !== undefined && stepIndex >= 0) {
      // to open the list item when for add company guide tour
      setIsOpen(true);
    } else if (
      index === 1 &&
      guide === 'Token Guide' &&
      stepIndex !== undefined &&
      stepIndex <= 1
    ) {
      // to open the list item when for create token guide tour
      setIsOpen(true);
    } else if (index === 2 && guide === 'OTP Guide' && stepIndex !== undefined && stepIndex >= 0) {
      // to open the list item when for otp guide tour
      setIsOpen(true);
    } else if (
      index === 3 &&
      guide === 'Message Blast Guide' &&
      stepIndex !== undefined &&
      stepIndex >= 0
    ) {
      // to open the list item when for message blast guide tour
      setIsOpen(true);
    } else if (
      index === 4 &&
      guide === 'Auto Reply Guide' &&
      stepIndex !== undefined &&
      stepIndex >= 0
    ) {
      // to open the list item when for auto reply guide tour
      setIsOpen(true);
    } else if (guide === '') {
      // this will close all the list item when the guide is over
      setIsOpen(false);
    } else {
      // default
      setIsOpen(isActive);
    }
  }, [guide, stepIndex]);

  const handleToggle = () => {
    setIsOpen((state) => !state);
  };

  return (
    <>
      <NavButton id={id} selected={isActive} onClick={handleToggle}>
        {level === 0 && <ListItemIcon>{icon}</ListItemIcon>}
        <ListItemText primary={label} primaryTypographyProps={{ variant: 'b2', fontWeight: 500 }} />
        {isOpen ? (
          <ChevronUpSqfr2 className="navItem-dropdown-icon" />
        ) : (
          <ChevronDownSqfr2 className="navItem-dropdown-icon" />
        )}
      </NavButton>
      <Collapse
        id={index === 2 ? 'otp-list' : index === 3 ? 'blast-list' : undefined}
        in={isOpen}
        timeout={200}
        unmountOnExit>
        <Box display="flex" alignItems="stretch" py={0.5}>
          <Box mx={3} width="2px" borderRadius="1px" bgcolor="neutral.light" />
          <List disablePadding sx={{ flexGrow: 1, my: -1 }}>
            {subItems.map((item, index) =>
              'subItems' in item ? (
                <NavigationGroup
                  key={item.label}
                  {...item}
                  index={index}
                  activePath={activePath}
                  level={level + 1}
                />
              ) : (
                <NavigationLink
                  key={item.label}
                  {...item}
                  activePath={activePath}
                  level={level + 1}
                />
              )
            )}
          </List>
        </Box>
      </Collapse>
    </>
  );
};

// -----------------------------------------------------------------------------------------------

interface NavigationLinkType {
  id?: string;
  label: string;
  icon?: JSX.Element;
  pathname: string;
  newTab?: boolean;
}

type NavigationLinkProps = NavigationLinkType & { level: number; activePath: string };

const NavigationLink = ({ level, activePath, ...navProps }: NavigationLinkProps) => {
  const { id, label, icon, pathname, newTab } = navProps;
  const isActive = isActivePath(navProps, activePath);

  return (
    <NavButton
      id={id}
      component={Link}
      target={newTab ? '_blank' : undefined}
      to={pathname}
      selected={isActive}>
      {level === 0 && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText primary={label} primaryTypographyProps={{ variant: 'b2', fontWeight: 500 }} />
    </NavButton>
  );
};

// -------------------------------------------------------------------------------------

type NavigationItem = NavigationLinkType | NavigationGroupType;

export interface SidebarProps {
  navItems: NavigationItem[];
}

const DashboardSidebar = ({ navItems }: SidebarProps) => {
  const location = useLocation();
  const activePath = location.pathname === '/' ? '/' : location.pathname.replace(/\/+$/, '');

  const handleSignOut = () => {
    destroyUser();
    window.location.reload();
  };

  return (
    <SidebarContainer>
      <Box sx={{ display: 'flex', justifyContent: 'center', py: 6 }}>
        <Box component="img" src="/axme-logo.svg" sx={{ width: 124 }} />
      </Box>

      <Scrollbar sx={{ flexGrow: 1, maxHeight: '100%', overflow: 'auto', mx: -2 }}>
        <List disablePadding>
          {navItems.map((navItem, index) =>
            'subItems' in navItem ? (
              <NavigationGroup
                {...navItem}
                index={index}
                key={navItem.label}
                activePath={activePath}
                level={0}
              />
            ) : (
              <NavigationLink {...navItem} key={navItem.label} activePath={activePath} level={0} />
            )
          )}
        </List>
      </Scrollbar>

      <Box sx={{ mt: 4 }}>
        <Divider />
        <Button color="error" startIcon={<Logout />} onClick={handleSignOut} sx={{ mt: 2 }}>
          Sign Out
        </Button>
      </Box>
    </SidebarContainer>
  );
};

export default DashboardSidebar;
