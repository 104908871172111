import {
  Button,
  CircularProgress,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  InputAdornment,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  editInitialValues as initialValues,
  editValidationSchema as validationSchema,
} from './schemeValidation';
import Confirm from '../../../components/Confirm';
import { ToastManagerContext } from '../../../components/ToastManager';
import { editService, requestDataEdit } from '../../../redux/modules/otpConfiguration';
import { AppDispatch } from '../../../redux/store';
import Dialog from '../../../components/Dialog';

import { BankCards } from '@xcidic/icons';
// import { date } from 'yup/lib/locale';
// type CloseReason = 'backdropClick' | 'escapeKeyDown' | 'closeButtonClick';
interface PropsModal {
  open: boolean;
  // onClose: (reason: CloseReason) => void;
  onClose: Function;
  id: string;
  name: string;
  length: number;
  format: string;
  duration: number;
  productName: string;
  defaultLanguageCode: string;
  isAllowMultipleActive: Boolean;
}

export default function index({
  open,
  onClose,
  id,
  name,
  length,
  format,
  duration,
  defaultLanguageCode,
  productName,
  isAllowMultipleActive,
}: PropsModal) {
  const { create } = useContext(ToastManagerContext);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const [isConfirm, setIsConfirm] = useState(false);

  const {
    handleChange,
    handleBlur,
    // setSubmitting,
    handleSubmit,
    setFieldValue,
    dirty,
    // resetForm,
    values,
    isValid,
    touched,
    errors,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async () => {
      setIsLoading(true);
      try {
        const data: requestDataEdit = {
          id: values.id,
          name: values.name,
          length: values.length,
          format: values.format,
          duration: values.duration,
          productName: values.productName,
          defaultLanguageCode: values.defaultLanguageCode,
          isAllowMultipleActive: String(values.isAllowMulti) === 'true',
        };
        dispatch(editService(data))
          .unwrap()
          .then((res) => {
            setIsLoading(false);

            create('success', 'Success', res.responseApi.message);
            onClose();
          })
          .catch((err) => {
            setIsLoading(false);

            create('error', 'Error', err);
          });
      } catch (error) {
        // handle any rejections/errors
      }
    },
  });

  useEffect(() => {
    setFieldValue('id', id);
    setFieldValue('name', name);
    setFieldValue('length', length);
    setFieldValue('format', format);
    setFieldValue('duration', duration);
    setFieldValue('productName', productName);
    setFieldValue('isAllowMulti', isAllowMultipleActive);
    setFieldValue('defaultLanguageCode', defaultLanguageCode);
  }, [open]);

  return (
    <Dialog open={open} onClose={() => onClose()}>
      <Typography variant="h2" color="neutral.black" fontWeight="700" mb={1}>
        Edit Configuration
      </Typography>
      <Typography variant="b2" color="neutral.600" fontWeight="500">
        Configuration is the rule how you set up the OTP system.
      </Typography>

      <Grid container rowSpacing={1} mt={2}>
        <Grid item xs={12}>
          <FormLabel sx={{ color: touched.name && errors.name ? '#e11900' : undefined }}>
            Configuration Name
          </FormLabel>

          <TextField
            fullWidth
            // autoFocus
            name="name"
            variant="outlined"
            // type="email"
            placeholder="Configuration Name"
            // label="Configuration Name *"
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
            className="mt-0 mb-4"
            size="medium"
          />
        </Grid>

        <Grid item xs={12}>
          <FormLabel sx={{ color: touched.length && errors.length ? '#e11900' : undefined }}>
            OTP Length
          </FormLabel>

          <TextField
            fullWidth
            // autoFocus
            name="length"
            variant="outlined"
            // type="email"
            type="number"
            placeholder="OTP Length"
            // label="OTP Length *"
            error={Boolean(touched.length && errors.length)}
            helperText={touched.length && errors.length}
            value={values.length}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
            className="mt-0 mb-4"
            size="medium"
          />
          <Typography color="neutral.400" variant="b2">
            Code length minimum 4 and maximum 12
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <FormLabel sx={{ color: touched.format && errors.format ? '#e11900' : undefined }}>
            OTP Format
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="numeric"
            value={values.format}
            name="radio-buttons-group"
            onChange={(event) => setFieldValue('format', event.target.value)}>
            <FormControlLabel value="numeric" control={<Radio />} label="Numeric" />
            <FormControlLabel value="alpha" control={<Radio />} label="Alphabet" />
            <FormControlLabel value="alphanumeric" control={<Radio />} label="Alphanumeric" />
          </RadioGroup>
        </Grid>

        <Grid item xs={12}>
          <FormLabel style={{ color: touched.duration && errors.duration ? '#e11900' : undefined }}>
            OTP Duration
          </FormLabel>

          <TextField
            fullWidth
            // autoFocus
            name="duration"
            variant="outlined"
            // type="email"
            type="number"
            // label="Duration OTP Code *"
            error={Boolean(touched.duration && errors.duration)}
            helperText={touched.duration && errors.duration}
            InputProps={{
              endAdornment: <InputAdornment position="end">Seconds</InputAdornment>,
            }}
            value={values.duration}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
            className="mt-0 mb-4"
            size="medium"
          />
        </Grid>

        <Grid item xs={12}>
          <FormLabel
            style={{ color: touched.productName && errors.productName ? '#e11900' : undefined }}>
            Product Name
          </FormLabel>

          <TextField
            fullWidth
            // autoFocus
            name="productName"
            variant="outlined"
            // type="email"
            // type='number'
            placeholder="Product Name"
            // label="Product Name *"
            error={Boolean(touched.productName && errors.productName)}
            helperText={touched.productName && errors.productName}
            value={values.productName}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
            className="mt-0 mb-4"
            size="medium"
          />
        </Grid>

        <Grid item xs={12}>
          <FormLabel>Default Code Language</FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="en"
            value={values.defaultLanguageCode}
            name="radio-buttons-group"
            onChange={(event) => setFieldValue('defaultLanguageCode', event.target.value)}>
            <FormControlLabel value="en" control={<Radio />} label="English" />
            <FormControlLabel value="id" control={<Radio />} label="Indonesian" />
          </RadioGroup>
        </Grid>
        <Grid item xs={12}>
          <FormLabel>Allow Multiple Active OTP</FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={false}
            value={values.isAllowMulti}
            name="radio-buttons-group"
            onChange={(event) => {
              setFieldValue('isAllowMulti', event.target.value);
            }}>
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={10}>
              <TextField
                disabled
                fullWidth
                // autoFocus
                // name="productName"
                variant="outlined"
                // type="email"
                // type='number'
                label="Configuration ID"
                error={Boolean(touched?.id && errors?.id)}
                helperText={touched?.id && errors?.id}
                value={values?.id}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="off"
                className="mt-0 mb-4"
                size="medium"
              />
            </Grid>
            <Grid item xs={2}>
              <Button
                name="copy"
                variant="outlined"
                color="primary"
                fullWidth
                startIcon={<BankCards />}
                onClick={() => {
                  navigator.clipboard.writeText(values?.id);
                  create('success', 'Copy', 'Success Copy Configuration ID');
                }}
                // disabled={!(dirty && isValid)}
                // onClick={() => setIsConfirm(true)}
              >
                Copy
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button
            name="company"
            variant="contained"
            color="primary"
            fullWidth
            disabled={!(dirty && isValid)}
            onClick={() => setIsConfirm(true)}>
            {isLoading ? (
              <CircularProgress style={{ color: 'white' }} size={30} />
            ) : (
              <span className="loginButtonCaption">Save Change</span>
            )}
          </Button>
          <Confirm
            open={isConfirm}
            type={'Create'}
            handleAksi={() => {
              setIsConfirm(false);
              handleSubmit();
            }}
            loading={false}
            handleClose={() => setIsConfirm(false)}
            disable={false}
            handleContent={{
              title: 'Create Configuration',
              message: 'Do you wish to Create this Configuration?',
              button: 'Submit',
            }}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
}
