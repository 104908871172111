import { Button, Grid, Paper, Typography, Divider } from '@mui/material';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ToastManagerContext } from '../../components/ToastManager';
import {
  deleteService,
  getService,
  deleteContactByCategoryService,
} from '../../redux/modules/category';
import { getServiceByCategories } from '../../redux/modules/contact';

import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { AppDispatch } from '../../redux/store';
import { Pencil, BankCards, TrashCan } from '@xcidic/icons';
// import ListToken from './component'
import Breadcrumbs from '../../components/Breadcrumbs';
import DashboardHeader from '../../components/DashboardHeader';
import Confirm from '../../components/Confirm';
import Container from '../../components/Container';
import Table from '../../components/Table';
import Edit from './componentView/editModal';
import EditContact from '../UserContact/component/modal';

import AddContact from './componentView/chooseContact';
import EmptyState from '../../components/EmptyState';

import { WidgetFooter, WidgetHeader } from '../../components/Widget';
import Pagination from '../../components/TablePagination';
interface ObjectPropsData {
  countryCode: string;
  number: string;
  name: string;
  id: string;
}
interface ObjectPropsDataCategory {
  name: string;
  id: string;
}
interface meta {
  total: number;
}
export default function index() {
  const { create } = useContext(ToastManagerContext);
  const { id } = useParams();
  const [isConfirm, setIsConfirm] = useState(false);
  const [isConfirmContact, setIsConfirmContact] = useState(false);

  const navigate = useNavigate();
  // eslint-disable-next-line
  const [idState, setId] = useState('');

  const dispatch = useDispatch<AppDispatch>();
  const [list, setList] = useState<ObjectPropsData[]>();
  const [name, setName] = useState('');

  const [isOpen, setIsOpen] = useState(false);
  const [isEditOpen, setIsEditsOpen] = useState(false);
  const [isEditContactOpen, setIsEditContactOpen] = useState(false);

  const [totalData, setTotal] = useState<meta>({ total: 1 });

  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(getService({ limit, offset: page === 1 ? 0 : page === 2 ? limit : limit * page }))
      .unwrap()
      .then((res) => {
        const data = (res?.listToken?.data as ObjectPropsDataCategory[])?.find(
          (val) => val.id === id
        );

        setName(data?.name ?? '');
        // setList(res.listToken?.data)
        // create('success', 'Success', res.listToken.message)
      })
      .catch((err) => {
        console.log(err);
        // create('error', 'Error', err)
      });
  });
  useEffect(() => {
    dispatch(
      getServiceByCategories({
        limit,
        offset: page === 1 ? 0 : page === 2 ? limit : limit * page,
        id: id ?? '',
      })
    )
      .unwrap()
      .then((res) => {
        setList(res.listToken?.data);
        // create('success', 'Success', res.listToken.message);
        setTotal(res.listToken.meta);
      })
      .catch((err) => {
        // console.log(err)
        create('error', 'Error', err);
      });
  }, [page]);
  const updateCategory = () => {
    dispatch(
      getServiceByCategories({
        limit,
        offset: page === 1 ? 0 : page === 2 ? limit : limit * page,
        id: id ?? '',
      })
    )
      .unwrap()
      .then((res) => {
        // create('success', 'Success', res.listToken.message);
        setTotal(res.listToken.meta);

        setList(res.listToken?.data);
      })
      .catch((err) => {
        // console.log(err)
        create('error', 'Error', err);
      });
  };

  const combinePhone = useMemo(
    () =>
      list?.map(({ countryCode, number, ...rest }, index) => ({
        ...rest,
        number: countryCode + number,
      })),
    [list]
  );
  const deleteData = () => {
    dispatch(deleteService({ id: id ?? '' }))
      .unwrap()
      .then((res) => {
        navigate('/message/category');
        create('success', 'Success', res.responseApi.message);
      })
      .catch((err) => {
        // console.log(err)
        create('error', 'Error', err);
      });
  };
  const deleteDataByCategory = () => {
    dispatch(deleteContactByCategoryService({ id: id ?? '', idContact: idState }))
      .unwrap()
      .then((res) => {
        updateCategory();
        create('success', 'Success', res.responseApi.message);
      })
      .catch((err) => {
        // console.log(err)
        create('error', 'Error', err);
      });
  };
  return (
    <Container>
      <Breadcrumbs
        items={[
          {
            label: 'Message Blast',
            link: '/message',
          },
          {
            label: 'Contact Category',
            link: '/message/category',
          },
          {
            label: 'Contact Category Detail',
            link: '/message/category',
          },
        ]}
      />
      <DashboardHeader title="Contact Category Detail" />
      <Paper style={{ marginBottom: '20px' }}>
        <div style={{ margin: '16px 16px 16px 0px' }}>
          <Typography>Contact Category Name</Typography>
          <Typography>{name}</Typography>
        </div>

        <Divider />
        <div style={{ margin: '16px 16px 16px 0px' }}>
          <Typography>Contact Category ID</Typography>
          <Typography>
            {id}
            <Button
              style={{ marginLeft: 10 }}
              name="copy"
              variant="outlined"
              color="primary"
              // fullWidth
              startIcon={<BankCards />}
              onClick={() => {
                navigator.clipboard.writeText(id ?? '');
                create('success', 'Copy', 'Success Copy Contact Category ID');
              }}
              // disabled={!(dirty && isValid)}
              // onClick={() => setIsConfirm(true)}
            >
              Copy
            </Button>
          </Typography>
        </div>
      </Paper>
      <Paper>
        <div style={{ marginBottom: '20px' }}>
          <WidgetHeader title="Contact List">
            <Grid
              item
              lg={12}
              style={{ justifyContent: 'flex-end', display: 'flex', marginBottom: '10px' }}>
              <Button
                name="company"
                variant="contained"
                color="primary"
                onClick={() => setIsOpen(true)}>
                Add Contact
              </Button>
            </Grid>
          </WidgetHeader>
        </div>

        {/* <Grid container spacing={3} style={{ marginTop: 10 }}>
          <Grid item lg={12} style={{ marginTop: '30px', marginBottom: '30px' }}>
            <h2>Contact</h2>
          </Grid>
        </Grid> */}
        {combinePhone?.length === 0 && <EmptyState module="Contact" button="Add Contact" />}
        {combinePhone?.length !== 0 && (
          <>
            <Table
              // idHref="id"
              // name="message/contact"
              data={combinePhone ?? []}
              config={[
                {
                  label: 'Contact Name',
                  key: 'name',
                  render: (row) => {
                    return (
                      <Typography
                        sx={{ cursor: 'pointer' }}
                        onClick={() => navigate(`/message/contact/${row.id}`)}>
                        {row.name}
                      </Typography>
                    );
                  },
                },
                {
                  label: 'WhatsApp Phone Number',
                  key: 'number',
                  render: (row) => {
                    return (
                      <Typography
                        sx={{ cursor: 'pointer' }}
                        onClick={() => navigate(`/message/contact/${row.id}`)}>
                        {row.number}
                      </Typography>
                    );
                  },
                },
                {
                  label: 'Contact ID',
                  key: 'id',
                  render: (row) => {
                    return (
                      <Typography
                        sx={{ cursor: 'pointer' }}
                        onClick={() => navigate(`/message/contact/${row.id}`)}>
                        {row.id}
                      </Typography>
                    );
                  },
                },
              ]}
              actions={[
                {
                  label: 'Edit',
                  icon: <Pencil />,
                  onAction: (row) => {
                    setId(row.id);
                    setIsEditContactOpen(true);
                    // navigate(`/message/contact/${row.id}`);
                  },
                },
                {
                  label: 'Delete',
                  icon: <TrashCan />,
                  onAction: (row) => {
                    setIsConfirmContact(true);
                    setId(row.id);
                  },
                },
                {
                  label: 'Copy Id',
                  icon: <BankCards />,
                  onAction: (row) => {
                    navigator.clipboard.writeText(row?.id);
                    create('success', 'Copy', 'Success Copy  ID');
                  },
                },
              ]}
            />

            <WidgetFooter>
              <Pagination
                page={page}
                count={totalData?.total}
                rowsPerPage={limit}
                onPageChange={(_, newPage) => setPage(newPage)}
                onRowsPerPageChange={(e) => {
                  setLimit(e.target.value as number);
                  setPage(1);
                }}
              />
            </WidgetFooter>
          </>
        )}

        <AddContact
          open={isOpen}
          onClose={() => {
            setIsOpen(false);
            updateCategory();
          }}
        />
        <EditContact
          open={isEditContactOpen}
          onClose={() => {
            setIsEditContactOpen(false);
            updateCategory();
          }}
          idProps={idState}
          // content={user}
        />
        <Edit
          open={isEditOpen}
          onClose={() => {
            setIsEditsOpen(false);
            updateCategory();
          }}
          content={name}
        />
        <Confirm
          open={isConfirm}
          type={'Delete'}
          handleAksi={() => deleteData()}
          loading={false}
          handleClose={() => setIsConfirm(false)}
          disable={false}
          handleContent={{
            title: 'Delete Category Contact',
            message: 'Do you wish to Delete this  Category Contact?',
            button: 'Delete',
          }}
        />

        <Confirm
          open={isConfirmContact}
          type={'Delete'}
          handleAksi={() => {
            deleteDataByCategory();
            setIsConfirmContact(false);
          }}
          loading={false}
          handleClose={() => setIsConfirmContact(false)}
          disable={false}
          handleContent={{
            title: 'Delete Contact from Category',
            message: 'Do you wish to Delete this  Contact from Category?',
            button: 'Delete',
          }}
        />
      </Paper>
      <Grid item lg={12} style={{ justifyContent: 'flex-end', display: 'flex', marginTop: '20px' }}>
        <Button
          name="company"
          variant="outlined"
          color="error"
          startIcon={<TrashCan />}
          style={{ marginRight: 20 }}
          onClick={() => setIsConfirm(true)}>
          Delete Contact Category
        </Button>

        <Button
          name="company"
          variant="contained"
          color="primary"
          // style={{ marginRight: 50 }}
          onClick={() => setIsEditsOpen(true)}>
          Edit Contact Category
        </Button>
      </Grid>
    </Container>
  );
}
