import { Button, Box, List, ListItem, ListItemText } from '@mui/material';
import { TrashCan } from '@xcidic/icons';
import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { ToastManagerContext } from '../../components/ToastManager';
import { deleteService, getServiceById, paramsById } from '../../redux/modules/responseAutoReply';
import { AppDispatch } from '../../redux/store';

import Breadcrumbs from '../../components/Breadcrumbs';
import Confirm from '../../components/Confirm';
import Container from '../../components/Container';
import DashboardHeader from '../../components/DashboardHeader';
import Widget from '../../components/Widget';
import Edit from './component/editModal';

interface dataAnswerInterface {
  id: string;
  intention: string;
  langCode: string;
  value: string;
}
export default function index() {
  const { create } = useContext(ToastManagerContext);
  const [isOpenEdit, setIsOpenEdit] = useState(false);

  const [isConfirmDelete, setIsConfirmDelete] = useState(false);

  const [data, setData] = useState<dataAnswerInterface>();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getServiceById({ id: id ?? '' }))
      .unwrap()
      .then((res) => {
        setData(res.responseApi?.data);
      })
      .catch((err) => {
        create('error', 'Error', err);
      });
  }, [isOpenEdit]);

  const capitalizeFirstLetter = (string: string) => {
    return string[0].toUpperCase() + string.slice(1);
  };

  const deleteData = () => {
    const datas: paramsById = {
      id: data?.id ?? '',
    };
    dispatch(deleteService(datas))
      .unwrap()
      .then((res) => {
        navigate('/auto/answer');
        create('success', 'Success', res.responseApi.message);
      })
      .catch((err) => {
        create('success', 'Error', err);
      });
  };
  return (
    <Container>
      <Breadcrumbs
        items={[
          {
            label: 'Auto Reply',
            link: '/auto/answer',
          },
          {
            label: 'Answer',
            link: '/auto/answer',
          },
          {
            label: 'Answer Detail',
            link: `/auto/answer/${id ?? ''}`,
          },
        ]}
      />
      <DashboardHeader title="Answer Detail" />

      <Widget>
        <List>
          <ListItem divider>
            <ListItemText
              primary="Intention"
              secondary={capitalizeFirstLetter(data?.intention ?? 'A')}
              primaryTypographyProps={{ variant: 'b1', fontWeight: 500 }}
            />
          </ListItem>
          <ListItem divider>
            <ListItemText
              primary="Code Language"
              secondary={data?.langCode === 'en' ? 'English' : 'Indonesian'}
              primaryTypographyProps={{ variant: 'b1', fontWeight: 500 }}
            />
          </ListItem>
          <ListItem divider>
            <ListItemText
              primary="Answer"
              secondary={data?.value}
              primaryTypographyProps={{ variant: 'b1', fontWeight: 500 }}
            />
          </ListItem>
        </List>
        <Box display="flex" justifyContent="flex-end" gap={2} pt={4}>
          <Button
            name="company"
            startIcon={<TrashCan />}
            variant="outlined"
            color="error"
            onClick={() => {
              setIsConfirmDelete(true);
            }}>
            Delete Answer
          </Button>
          <Button
            name="company"
            variant="contained"
            color="primary"
            onClick={() => {
              setIsOpenEdit(true);
            }}>
            Edit Answer
          </Button>
        </Box>
        <Edit
          open={isOpenEdit}
          onClose={() => {
            setIsOpenEdit(false);
          }}
          id={data?.id ?? ''}
          value={data?.value ?? ''}
        />
        <Confirm
          open={isConfirmDelete}
          type={'Delete'}
          handleAksi={() => {
            setIsConfirmDelete(false);
            deleteData();
          }}
          loading={false}
          handleClose={() => setIsConfirmDelete(false)}
          disable={false}
          handleContent={{
            title: 'Delete Anwer',
            message: 'Do you wish to Delete this Answer?',
            button: 'Delete',
          }}
        />
      </Widget>
    </Container>
  );
}
