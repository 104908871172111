import React, { useState, useContext, useEffect } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { ToastManagerContext } from '../../components/ToastManager';
import { getServiceMonthly } from '../../redux/modules/xendit';
import { AppDispatch } from '../../redux/store';
import Container from '../../components/Container';
import Table from '../../components/Table';
import Breadcrumbs from '../../components/Breadcrumbs';
import DashboardHeader from '../../components/DashboardHeader';

import EmptyState from './EmptyState';
import Widget, { WidgetFooter, WidgetContent } from '../../components/Widget';
import Pagination from '../../components/TablePagination';
interface meta {
  total: number;
}

interface propsBilling {
  createdOn: number;
  invoiceNumber: number;
  totalCharged: number;
  totalReply: number;
  totalOtp: number;
  totalBlast: number;
}
function index() {
  const { create } = useContext(ToastManagerContext);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [orderBy, setOrderBy] = useState('createdOn');
  const [order, setOrder] = useState('desc');
  const [search, onSearch] = useState('');

  const [list, setList] = useState<propsBilling[]>([]);
  const [realList, setRealList] = useState<propsBilling[]>([]);

  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [totalData, setTotal] = useState<meta>({ total: 1 });
  useEffect(() => {
    dispatch(
      getServiceMonthly({
        id: `?offset=${
          page === 1 ? 0 : page === 2 ? limit : limit * page
        }&limit=${limit}&order=${order}&orderBy=${orderBy}&keyword=${search}`,
      })
    )
      .unwrap()
      .then((res) => {
        setTotal(res.responseApi.meta);
        setList(res.responseApi?.data);
        setRealList(res.responseApi?.data);
      })
      .catch((err) => {
        create('error', 'Error', err);
      });
  }, []);
  useEffect(() => {
    dispatch(
      getServiceMonthly({
        id: `?offset=${
          page === 1 ? 0 : page === 2 ? limit : limit * page
        }&limit=${limit}&order=${order}&orderBy=${orderBy}&keyword=${search}`,
      })
    )
      .unwrap()
      .then((res) => {
        setTotal(res.responseApi.meta);
        setList(res.responseApi?.data);
      })
      .catch((err) => {
        create('error', 'Error', err);
      });
  }, [page, order, orderBy, search]);
  return (
    <Container>
      <Breadcrumbs
        items={[
          {
            label: 'Billing',
            link: '/billing',
          },
          {
            label: 'Monthly Usage',
            link: '/invoice',
          },
        ]}
      />
      <DashboardHeader title="Monthly Usage"></DashboardHeader>

      <Widget>
        {realList?.length === 0 && <EmptyState />}
        {realList?.length !== 0 && (
          <>
            <WidgetContent sx={{ pt: 0 }}>
              <Table
                onSearch={(e) => {
                  setPage(1);
                  onSearch(e.target.value);
                }}
                data={list ?? []}
                config={[
                  {
                    label: 'Created Date',
                    key: 'transactionId',
                    render: (row) => moment(row.createdOn * 1000).format('MMM DD, YYYY | HH:MM'),
                    onSort: () => {
                      setOrder(order === 'asc' ? 'desc' : 'asc');
                      setOrderBy('createdOn');
                    },
                  },
                  {
                    label: 'Charged Amount',
                    key: 'totalCharged',
                    render: (row) =>
                      row.totalCharged
                        .toLocaleString('id-ID', {
                          style: 'currency',
                          currency: 'IDR',
                          maximumFractionDigits: 0,
                        })
                        .replace('Rp', 'IDR'),
                    onSort: () => {
                      setOrder(order === 'asc' ? 'desc' : 'asc');
                      setOrderBy('totalCharged');
                    },
                  },
                  {
                    label: 'OTP Sent',
                    key: 'totalOtp',
                  },
                  {
                    label: 'Blast Message Sent',
                    key: 'totalBlast',
                  },
                  {
                    label: 'Auto Reply Sent',
                    key: 'totalReply',
                  },
                  {
                    label: 'Invoice ID',
                    key: 'invoiceNumber',
                    onSort: () => {
                      setOrder(order === 'asc' ? 'desc' : 'asc');
                      setOrderBy('invoiceId');
                    },
                  },
                ]}
                onSelectRow={(row) => navigate(`/invoice/${row.invoiceNumber}`)}
              />
            </WidgetContent>
            <WidgetFooter>
              <Pagination
                page={page}
                count={totalData?.total}
                rowsPerPage={limit}
                onPageChange={(_, newPage) => setPage(newPage)}
                onRowsPerPageChange={(e) => {
                  setLimit(e.target.value as number);
                  setPage(1);
                }}
              />
            </WidgetFooter>
          </>
        )}
      </Widget>
    </Container>
  );
}

export default index;
