import { object, string } from 'yup';

export const initialValues = {
  validateOnMount: false,
  isUpdate: false,
  companyId: '',
  name: '',
  waNumber: '',
  apiKey: '',
  phoneID: '',
  countryCode: '',
  waBusinessAccountId: '',
};

export const validationSchema = object({
  name: string().required("Company Name can't be blank"),
  waNumber: string().required("WhatsApp Number can't be blank"),
  apiKey: string().required("WhatsApp Business API Token can't be blank"),
  phoneID: string().required("WhatsApp Business Phone ID can't be blank"),
  waBusinessAccountId: string().required("WhatsApp Business Account ID can't be blank"),
});

export const editInitialValues = {
  isUpdate: false,
  companyId: '',
  name: '',
  waNumber: '',
  apiKey: '',
  phoneID: '',
  countryCode: '',
  waBusinessAccountId: '',
};

export const editValidationSchema = object({
  name: string().required("Company Name can't be blank"),
  waNumber: string().required("WhatsApp Number can't be blank"),
  apiKey: string().required("WhatsApp Business API Token can't be blank"),
  phoneID: string().required("WhatsApp Business Phone ID can't be blank"),
  waBusinessAccountId: string().required("WhatsApp Business Account ID can't be blank"),
});
