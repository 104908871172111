import { Button, Paper, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ToastManagerContext } from '../../components/ToastManager';
import { getService } from '../../redux/modules/category';
import { AppDispatch } from '../../redux/store';
import { Pencil, PlusCrfr } from '@xcidic/icons';
import { useNavigate } from 'react-router-dom';
import Breadcrumbs from '../../components/Breadcrumbs';
import Container from '../../components/Container';
import Table from '../../components/Table';
import GenerateToken from './component/modal';
import Edit from './componentView/editModal';

import EmptyState from '../../components/EmptyState';
import DashboardHeader from '../../components/DashboardHeader';
import { WidgetFooter } from '../../components/Widget';
import Pagination from '../../components/TablePagination';
interface ObjectPropsData {
  name: string;
  id: string;
}
interface meta {
  total: number;
}

export default function index() {
  const { create } = useContext(ToastManagerContext);
  const dispatch = useDispatch<AppDispatch>();
  const [list, setList] = useState<ObjectPropsData[]>();
  const [isOpen, setIsOpen] = useState(false);
  const [limit, setLimit] = useState(10);
  const [isEditOpen, setIsEditsOpen] = useState(false);
  const [name, setName] = useState('');
  const [id, setId] = useState('');

  const [page, setPage] = useState(1);
  const [totalData, setTotal] = useState<meta>({ total: 1 });
  const navigate = useNavigate();

  useEffect(() => {
    updateCategory();
  }, [page]);
  const updateCategory = () => {
    dispatch(getService({ limit, offset: page === 1 ? 0 : page === 2 ? limit : limit * page }))
      .unwrap()
      .then((res) => {
        // create('success', 'Success', res.listToken.message);

        setList(res.listToken?.data);
        setTotal(res.listToken.meta);
      })
      .catch((err) => {
        // console.log(err)
        create('error', 'Error', err);
      });
  };

  return (
    <Container>
      <Breadcrumbs
        items={[
          {
            label: 'Message Blast',
            link: '/message',
          },
          {
            label: 'Contact Category',
            link: '/message/category',
          },
        ]}
      />
      <DashboardHeader title="Contact Category">
        <Button
          id="blast-add-category"
          variant="contained"
          startIcon={<PlusCrfr />}
          onClick={() => setIsOpen(true)}>
          Add Contact Category
        </Button>
      </DashboardHeader>
      <Paper>
        {list?.length === 0 && (
          <EmptyState module="Contact Category" button="Add Contact Category" />
        )}
        {list?.length !== 0 && (
          <Table
            // idHref="id"
            // name="message/category"
            data={list ?? []}
            config={[
              {
                label: 'Id',
                key: 'id',
                render: (row) => {
                  return (
                    <Typography
                      sx={{ cursor: 'pointer' }}
                      onClick={() => navigate(`/message/category/${row.id}`)}>
                      {row.id}
                    </Typography>
                  );
                },
              },
              {
                label: 'Name',
                key: 'name',
                render: (row) => {
                  return (
                    <Typography
                      sx={{ cursor: 'pointer' }}
                      onClick={() => navigate(`/message/category/${row.id}`)}>
                      {row.name}
                    </Typography>
                  );
                },
              },
            ]}
            actions={[
              // {
              //   label: 'View',
              //   icon: <Eye />,
              //   onAction: (row) => {
              //     navigate(`/message/category/${row.id}`);
              //   },
              // },
              {
                label: 'Edit',
                icon: <Pencil />,
                onAction: (row) => {
                  setIsEditsOpen(true);
                  setName(row.name);
                  setId(row.id);
                  // navigate(`/message/category/${row.id}`);
                },
              },
            ]}
          />
        )}

        {list?.length !== 0 && (
          <WidgetFooter>
            <Pagination
              page={page}
              count={totalData?.total}
              rowsPerPage={limit}
              onPageChange={(_, newPage) => setPage(newPage)}
              onRowsPerPageChange={(e) => {
                setLimit(e.target.value as number);
                setPage(1);
              }}
            />
          </WidgetFooter>
        )}
        <Edit
          open={isEditOpen}
          onClose={() => {
            setIsEditsOpen(false);
            updateCategory();
          }}
          idProps={id}
          content={name}
        />
        <GenerateToken
          open={isOpen}
          onClose={() => {
            setIsOpen(false);
            updateCategory();
          }}
        />
      </Paper>
    </Container>
  );
}
