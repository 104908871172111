import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  TextField,
  Typography,
  Dialog,
  IconButton,
  DialogContent,
  // Link,
} from '@mui/material';
import { Eye, EyeOff, RemoveSqfr2 } from '@xcidic/icons';
import { useDispatch } from 'react-redux';
import { login, resendValidation, loginData, resendEmailValidation } from '../../redux/auth';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppDispatch } from '../../redux/store';
import { useFormik } from 'formik';
import { initialValues, validationSchema } from './schemeValidation';
import { ToastManagerContext } from '../../components/ToastManager';
import ResetPassword from '../../modules/Verify/ResetPassword';

function Index() {
  const dispatch = useDispatch<AppDispatch>();
  const { create } = useContext(ToastManagerContext);

  const [loading, setIsLoading] = useState(false);
  const [verify, setIsVerify] = useState(false);
  const [reset, setIsReset] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [send, setIsSend] = useState(false);
  const handleToggleShowPass = () => {
    setShowPass((show) => !show);
  };
  const navigate = useNavigate();
  const {
    handleChange,
    handleBlur,
    // setSubmitting,
    handleSubmit,
    // setFieldValue,
    dirty,
    // resetForm,
    values,
    isValid,
    touched,
    errors,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => {
      setIsLoading(true);
      try {
        const data: loginData = {
          email: values.email,
          password: values.password,
        };
        dispatch(login(data))
          .unwrap()
          .then(() => {
            //   props.history.push('/dashboard');
            //   window.location.reload();
            navigate('/');
            setIsLoading(false);
            create('success', 'Success', 'Login Success!');
          })
          .catch((err) => {
            if (err === 'Email is not verified') {
              setIsVerify(true);
            }
            create('error', 'Error', err);
            setIsLoading(false);
          });
      } catch (error) {
        // handle any rejections/errors
      }
    },
  });
  const resendEmail = () => {
    const data: resendEmailValidation = {
      email: values.email,
    };
    dispatch(resendValidation(data))
      .unwrap()
      .then((res) => {
        //   props.history.push('/dashboard');
        //   window.location.reload();
        // navigate('/');
        // setIsLoading(false);
        create('success', 'Success', res.response.message);
        setIsVerify(false);
      })
      .catch((err) => {
        // if (err === 'Email is not verified') {
        //   setIsVerify(true);
        // }
        create('error', 'Error', err);
        setIsLoading(false);
      });
  };
  return (
    <Box sx={{ paddingLeft: { lg: '87px', xs: '16px' }, paddingTop: { lg: '97px', xs: '16px' } }}>
      {/* <Helmet>
        <title>Login</title>
      </Helmet> */}
      {/* <Link href="http://hg-web.xcidic.com:3000/" style={{ textDecoration: 'none' }}>
        <Typography
          style={{
            cursor: 'pointer',
            color: '#258BEA',
          }}>{`< Back To Website`}</Typography>
      </Link> */}

      <Container maxWidth="lg">
        {/* <Box display="flex" justifyContent="center" py={5} alignItems="center">
          <Logo />
        </Box> */}
        {/* <Card sx={{ p: 10, mb: 10, borderRadius: 12 }}> */}
        <Box
          sx={{ display: 'flex', justifyContent: 'center', marginBottom: 5, cursor: 'pointer' }}
          onClick={() => navigate('/')}>
          <Box component="img" src="/axme-logo.svg" sx={{ width: 124 }} />
        </Box>
        <Box sx={{ textAlign: 'center', marginBottom: 5, marginTop: '175px' }}>
          <Typography component="h2" variant="h2" sx={{ fontWeight: 700, marginBottom: '6px' }}>
            Welcome!
          </Typography>
          <Typography variant="b1" color="neutral.400">
            Fill in the fields below to login to your account
          </Typography>
        </Box>
        <Box justifyContent="center" display="flex">
          <Grid container spacing={3} xs={12} md={6} lg={4}>
            <Grid item xs={12}>
              <Typography>Email</Typography>
              <TextField
                fullWidth
                // // autoFocus
                name="email"
                variant="outlined"
                type="email"
                // label="Email *"
                placeholder="Input your email here"
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="off"
                className="mt-0 mb-4"
                size="medium"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography>Password</Typography>
              <TextField
                fullWidth
                // // autoFocus
                name="password"
                variant="outlined"
                type={showPass ? 'test' : 'password'}
                // label="Password *"
                placeholder="* * * * * *"
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={handleToggleShowPass}>
                      {showPass ? <Eye /> : <EyeOff />}
                    </IconButton>
                  ),
                }}
                autoComplete="off"
                className="mt-0 mb-4"
                size="medium"
              />
            </Grid>
            <Grid item xs={6}>
              {verify && (
                <p
                  onClick={() => resendEmail()}
                  style={{ cursor: 'pointer', color: '#258BEA', textDecoration: 'underline' }}>
                  Resend Email Verification
                </p>
              )}
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="b2"
                onClick={() => setIsReset(true)}
                color="primary.darkBlue"
                fontWeight="700"
                sx={{ cursor: 'pointer', textAlign: 'end' }}>
                Forgot Password?
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                fullWidth
                className="loginButton"
                disabled={!(dirty && isValid)}
                onClick={() => handleSubmit()}
                variant="contained"
                color="primary">
                {loading ? (
                  <CircularProgress style={{ color: 'white' }} size={30} />
                ) : (
                  <span className="loginButtonCaption">Login</span>
                )}
              </Button>
            </Grid>
          </Grid>
        </Box>

        {/* </Card> */}
      </Container>
      <Box
        sx={{
          textAlign: 'center',
          marginTop: '175px',
        }}>
        <Typography variant="b2" component="h3" color="neutral.400" fontWeight="700">
          Version 1.0 © Powered by XCIDIC. 2022
        </Typography>
      </Box>
      <ResetPassword
        open={reset}
        onSuccess={() => {
          setIsReset(false);
          setIsSend(true);
        }}
        onClose={() => {
          setIsReset(false);
        }}
        email={values.email}
      />
      <Dialog
        open={send}
        onClose={() => {
          setIsSend(false);
        }}>
        <IconButton
          onClick={() => setIsSend(false)}
          sx={{
            position: 'absolute',
            right: 24,
            top: 16,
            transition: '0.3s all ease',
            color: (theme) => theme.palette.neutral.black,
          }}>
          <RemoveSqfr2 sx={{ fontSize: 18 }} />
        </IconButton>
        <DialogContent sx={{ padding: 4, textAlign: 'center' }}>
          <Box component="img" src="/resetPassword.svg" sx={{ width: 320 }} />
          <Box mt={2} mb={2}>
            <Typography variant="h2" component="h2" fontWeight="700">
              Check your email!
            </Typography>
            <Typography variant="b1" component="h3" fontWeight="500" color="neutral.400">
              Link has been sent.
            </Typography>
          </Box>
          <Button
            fullWidth
            className="loginButton"
            // disabled={!(dirty && isValid)}
            onClick={() => setIsSend(false)}
            variant="contained"
            color="primary">
            <span className="loginButtonCaption">Back To Login</span>
          </Button>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default Index;
