import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Link,
  Paper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from '@mui/material';
import { SetStateAction, useEffect, useState } from 'react';
import Breadcrumbs from '../../components/Breadcrumbs';
import DashboardHeader from '../../components/DashboardHeader';

import Container from '../../components/Container';
import { getService as getTemplate } from '../../redux/modules/blastMessage';
import { get as getCompany } from '../../redux/modules/company';
import { get as getToken } from '../../redux/modules/token';

import { useDispatch } from 'react-redux';
import Code from '../../components/Code';
import { AppDispatch } from '../../redux/store';
import GenerateToken from '../Token/component/modal';
import { cSharp, js, php, python } from './codeText';
// import CategoryContact from '../CategoryContact/component/modal'
import UserContact from '../UserContact/component/create';
// import AddContactToCategory from '../Contact/component/modal'
// import GenerateTemplate from '../'
// import { } from 'yup'
// import { useFormik } from 'formik'
import './style.css';

interface TokenPropsData {
  description: string;
  numberOfRequests: number;
  lastAccessedOn: number;
  value: string;
  expiresOn: number;
  id: string;
}

interface TemplatePropsData {
  name: string;
  id: string;
}

interface objectState {
  id: string;
  countryCode: string;
  number: string;
  waPhoneId: string;
  waApiToken: string;
  waBusinessAccountId: string;
}

interface CompanyPropsData {
  name: String;
  phones: objectState[];
}
export default function index() {
  const [activeStep, setActiveStep] = useState(0);
  const dispatch = useDispatch<AppDispatch>();

  const [progammingLanguage, setProgrammingLanguage] = useState('');
  const [selectedToken, setSelectedToken] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState('');

  const [isOpenToken, setIsOpenToken] = useState(false);
  const [isOpenUser, setIsOpenUser] = useState(false);

  const [token, setToken] = useState<TokenPropsData[]>();
  const [template, setTemplate] = useState<TemplatePropsData[]>();
  const [data, setData] = useState<CompanyPropsData>({
    name: '',
    phones: [
      {
        countryCode: '',
        number: '',
        waPhoneId: '',
        waApiToken: '',
        waBusinessAccountId: '',
        id: '',
      },
    ],
  });
  const [handlerId, setHandlerId] = useState(data?.phones[0]?.id);

  useEffect(() => {
    updateData();
  }, []);

  useEffect(() => {
    setHandlerId(data?.phones[0]?.id);
  }, [data]);

  const updateData = () => {
    dispatch(getToken({ limit: 10000, offset: 0 }))
      .unwrap()
      .then((res) => {
        setToken(res.listToken?.data);
      })
      .catch((err) => {
        console.log(err);
        // create('error', 'Error', err)
      });
    dispatch(getTemplate({ limit: 1000, offset: 0 }))
      .unwrap()
      .then((res) => {
        setTemplate(res.listToken?.data);
        // create('success', 'Success', res.listToken.message)
      })
      .catch((err) => {
        console.log(err);
        // create('error', 'Error', err)
      });
    dispatch(getCompany())
      .unwrap()
      .then((res) => {
        if (res.list.data.length > 0) {
          const dataAPI = res.list.data[0];
          setData(dataAPI);
        }
        // console.log(res.list)
        // create('success', 'Success', res.list.message)
      })
      .catch((err) => {
        console.log(err);
        // create('error', 'Error', err)
      });
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // const handleReset = () => {
  //   setActiveStep(0);
  // };
  const chooseProgammingLang = (name: SetStateAction<string>) => {
    setProgrammingLanguage(name);
    setActiveStep(activeStep + 1);
  };
  return (
    <Container>
      <Breadcrumbs
        // title={'Integrated Guide Message Blast'}
        items={[
          {
            label: 'Message Blast',
            link: '/message',
          },
          {
            label: 'Integrated Guide Message Blast',
            link: '',
          },
        ]}
      />
      <DashboardHeader title="Integrated Guide Message Blast" />
      <Paper>
        <Grid container spacing={3}>
          <Grid item lg={2}>
            <Stepper activeStep={activeStep} orientation="vertical">
              <Step>
                <StepLabel>Choose Your Progamming Language</StepLabel>
                {/* <StepContent>
                  Lorem Ipsum

                  <Box sx={{ mb: 2 }}>
                    <div>
                      <Button
                        variant="contained"
                        onClick={handleNext}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        {'Continue'}
                      </Button>
                      <Button
                        disabled={true}
                        onClick={handleBack}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        Back
                      </Button>
                    </div>
                  </Box>

                </StepContent> */}
              </Step>
              <Step>
                <StepLabel>Setting OTP</StepLabel>
                <StepContent>
                  {/* Lorem Ipsum */}

                  <Box sx={{ mb: 2 }}>
                    <div>
                      <Button
                        variant="contained"
                        disabled={true}
                        onClick={handleNext}
                        sx={{ mt: 1, mr: 1 }}>
                        {'Continue'}
                      </Button>
                      <Button disabled={false} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                        Back
                      </Button>
                    </div>
                  </Box>
                </StepContent>
              </Step>
            </Stepper>
          </Grid>
          <Grid item lg={10}>
            {activeStep === 0 && (
              <div>
                <Grid container spacing={3}>
                  <Grid
                    item
                    lg={3}
                    onClick={() => chooseProgammingLang('javascript')}
                    style={{ cursor: 'pointer' }}>
                    <Box
                      sx={{
                        border: 1,
                        justifyContent: 'center',
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                      <div className="box-content">
                        <img
                          style={{ width: 100, height: 100, marginBottom: 20 }}
                          src={require('../../assets/images/javascript.png')}
                        />
                        <p style={{ fontSize: 20 }}>Javascript</p>
                      </div>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    lg={3}
                    onClick={() => chooseProgammingLang('python')}
                    style={{ cursor: 'pointer' }}>
                    <Box
                      sx={{
                        border: 1,
                        justifyContent: 'center',
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                      <div className="box-content">
                        <img
                          style={{ width: 100, height: 100, marginBottom: 20 }}
                          src={require('../../assets/images/python.png')}
                        />
                        <p style={{ fontSize: 20 }}>Python</p>
                      </div>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    lg={3}
                    onClick={() => chooseProgammingLang('php')}
                    style={{ cursor: 'pointer' }}>
                    <Box
                      sx={{
                        border: 1,
                        justifyContent: 'center',
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                      <div className="box-content">
                        <img
                          style={{ width: 100, height: 100, marginBottom: 20 }}
                          src={require('../../assets/images/Php.png')}
                        />
                        <p style={{ fontSize: 20 }}>PHP</p>
                      </div>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    lg={3}
                    onClick={() => chooseProgammingLang('csharp')}
                    style={{ cursor: 'pointer' }}>
                    <Box
                      sx={{
                        border: 1,
                        justifyContent: 'center',
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                      <div className="box-content">
                        <img
                          style={{ width: 100, height: 100, marginBottom: 20 }}
                          src={require('../../assets/images/CSharp.png')}
                        />
                        <p style={{ fontSize: 20 }}>C#</p>
                      </div>
                    </Box>
                  </Grid>
                </Grid>
              </div>
            )}
            {activeStep === 1 && (
              <div>
                <Typography>
                  1. Create Template on{' '}
                  <Link
                    rel="noopener noreferrer"
                    target={'_blank'}
                    href="https://business.facebook.com/wa/manage/message-templates">
                    WhatsApp Business.
                  </Link>
                </Typography>
                <Typography>
                  2. Create Company on{' '}
                  <Link rel="noopener noreferrer" href="/company" target={'_blank'}>
                    this.
                  </Link>
                </Typography>
                <Typography>3. Create Token</Typography>
                <Grid container spacing={3} style={{ marginTop: 5, marginBottom: 5 }}>
                  <Grid item lg={3}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={token ?? []}
                      onChange={(event: any, newValue: TokenPropsData | null) => {
                        // console.log(newValue)
                        setSelectedToken(newValue?.value ?? '');
                      }}
                      getOptionLabel={(option) => option.description}
                      // sx={{ width: 300 }}
                      renderInput={(params) => <TextField {...params} label="Token" fullWidth />}
                    />
                  </Grid>
                  <Grid item lg={6}>
                    <Button
                      variant={'contained'}
                      color={'primary'}
                      onClick={() => setIsOpenToken(true)}>
                      Create Token
                    </Button>
                  </Grid>
                  <GenerateToken
                    open={isOpenToken}
                    onClose={() => {
                      setIsOpenToken(false);
                      updateData();
                    }}
                  />
                </Grid>
                <Typography>
                  4. If you want to use Contact Category you can create on
                  <Link rel="noopener noreferrer" href="/message/category" target={'_blank'}>
                    {' '}
                    this.
                  </Link>{' '}
                  and select your category to add user phone number. you can also create User
                  Contact without create Contact Category on{' '}
                  <p
                    onClick={() => setIsOpenUser(true)}
                    style={{ cursor: 'pointer', color: '#258BEA', textDecoration: 'underline' }}>
                    this.
                  </p>
                </Typography>
                <UserContact
                  open={isOpenUser}
                  onClose={() => {
                    setIsOpenUser(false);
                    updateData();
                  }}
                />
                <Typography>
                  5. Create Template on{' '}
                  <Link rel="noopener noreferrer" href="/message/blast" target={'_blank'}>
                    this.
                  </Link>
                </Typography>

                <Grid container spacing={3} style={{ marginTop: 5, marginBottom: 5 }}>
                  <Grid item lg={3}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={template ?? []}
                      onChange={(event: any, newValue: TemplatePropsData | null) => {
                        // console.log(newValue)
                        setSelectedTemplate(newValue?.id ?? '');
                      }}
                      getOptionLabel={(option) => option.name}
                      // sx={{ width: 300 }}
                      renderInput={(params) => <TextField {...params} label="Template" fullWidth />}
                    />
                  </Grid>
                  <Grid item lg={6}>
                    <Button variant={'contained'} color={'primary'} onClick={() => updateData()}>
                      Refresh
                    </Button>
                  </Grid>
                </Grid>

                <Typography>
                  6. You can send message by using UI on detail message template, or you can use
                  code below
                </Typography>
                <Code
                  code={
                    progammingLanguage === 'javascript'
                      ? js(selectedToken, selectedTemplate, handlerId)
                      : progammingLanguage === 'python'
                      ? python(selectedToken, selectedTemplate, handlerId)
                      : progammingLanguage === 'php'
                      ? php(selectedToken, selectedTemplate, handlerId)
                      : cSharp(selectedToken, selectedTemplate, handlerId)
                  }
                  language={progammingLanguage}
                />
              </div>
            )}
            {activeStep === 2 && <div>hasukdhskadgjasgjkdfg</div>}
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
