import { Box, BoxProps } from '@mui/material';

export interface WidgetContentProps extends BoxProps {}

/**
 * Wrapper for widget content. This component should be placed inside `Widget` component.
 * Use this on widget that require `WidgetHeader` or `WidgetFooter`.
 */
const WidgetContent = ({ sx, ...others }: WidgetContentProps) => {
  return (
    <Box
      sx={{
        pt: 2.4,
        pb: 2.4,
        px: 2,
        mx: -2,
        flexGrow: 1,
        ...sx,
      }}
      {...others}
    />
  );
};

export default WidgetContent;
