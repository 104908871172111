import SimpleBarReact from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
// material
import { alpha, styled } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

export type ScrollbarProps = BoxProps;

const SimpleBarStyle = styled(SimpleBarReact)(({ theme }) => ({
  '& .simplebar-scrollbar': {
    '&:before': {
      backgroundColor: alpha(theme.palette.neutral[400], 0.48),
    },
    '&.simplebar-visible:before': {
      opacity: 1,
    },
  },
  '& .simplebar-track.simplebar-vertical': {
    width: 10,
  },
  '& .simplebar-track.simplebar-horizontal .simplebar-scrollbar': {
    height: 6,
  },
  '& .simplebar-mask': {
    zIndex: 'inherit',
  },
}));

// ----------------------------------------------------------------------

const Scrollbar = ({ children, sx }: ScrollbarProps) => {
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;

  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

  if (isMobile) {
    return <Box sx={{ overflowX: 'auto', ...sx }}>{children}</Box>;
  }

  return (
    <SimpleBarStyle timeout={500} clickOnTrack={false} sx={sx}>
      {children}
    </SimpleBarStyle>
  );
};

export default Scrollbar;
