import { Box, Button, Grid, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { object, string } from 'yup';
import { ToastManagerContext } from '../../components/ToastManager';
import { updateService } from '../../redux/modules/user';
import { AppDispatch } from '../../redux/store';
import { destroyUser, getUser } from '../../utils/localStorage';

interface requestData {
  email: string;
  name: string;
  password: string;
  oldPassword: string;
}

export const initialValues = {
  email: '',
  name: '',
  password: '',
  oldPassword: '',
};

export const validationSchema = object({
  email: string().email('Email is not valid').required("Email can't be blank"),
  name: string().required("Username can't be blank"),
  password: string().required("New Password can't be blank"),
  oldPassword: string().required("New Password can't be blank"),
});
export default function index() {
  const { create } = useContext(ToastManagerContext);
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const {
    handleChange,
    handleBlur,
    // setSubmitting,
    handleSubmit,
    setFieldValue,
    // dirty,
    // resetForm,
    values,
    // isValid,
    touched,
    errors,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async () => {
      setIsLoading(true);
      try {
        const data: requestData = {
          name: values.name,
          email: values.email,
          password: values.password,
          oldPassword: values.oldPassword,
        };
        dispatch(updateService(data))
          .unwrap()
          .then((res) => {
            setIsLoading(false);
            create('success', 'Success', res.responseApi.message);
            destroyUser();
            window.location.href = '/signin';
          })
          .catch((err) => {
            setIsLoading(false);

            create('error', 'Error', err);
          });
      } catch (error) {
        // handle any rejections/errors
      }
    },
  });

  useEffect(() => {
    setFieldValue('name', getUser().name);
    setFieldValue('email', getUser().email);
  }, []);
  return (
    <Box sx={{ p: 2, border: '1px solid grey', marginTop: 10 }}>
      <Grid container spacing={3}>
        <Grid item lg={12}>
          <TextField
            fullWidth
            // autoFocus
            name="email"
            variant="outlined"
            type="email"
            label="User Company Email"
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
            className="mt-0 mb-4"
            size="medium"
          />
        </Grid>
        <Grid item lg={12}>
          <TextField
            fullWidth
            // autoFocus
            name="name"
            variant="outlined"
            label="Username"
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
            className="mt-0 mb-4"
            size="medium"
          />
        </Grid>
        <Grid item lg={12}>
          <TextField
            fullWidth
            // autoFocus
            name="password"
            variant="outlined"
            label="New Password"
            error={Boolean(touched.password && errors.password)}
            helperText={touched.name && errors.password}
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
            className="mt-0 mb-4"
            size="medium"
          />
        </Grid>
        <Grid item lg={12}>
          <TextField
            fullWidth
            // autoFocus
            name="oldPassword"
            variant="outlined"
            label="Old Password"
            error={Boolean(touched.oldPassword && errors.oldPassword)}
            helperText={touched.oldPassword && errors.oldPassword}
            value={values.oldPassword}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
            className="mt-0 mb-4"
            size="medium"
          />
        </Grid>
        <Grid item lg={12}>
          <Button name="company" variant="outlined" color="primary" onClick={() => handleSubmit()}>
            Update User
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
