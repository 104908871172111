import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useDispatch } from 'react-redux';
import { Box, Typography, IconButton } from '@mui/material';

import { getService } from '../../../redux/modules/statistic';
import { AppDispatch } from '../../../redux/store';
import { Line2Up, Line2Down, ChevronLeftSqfr, ChevronRightSqfr } from '@xcidic/icons';
import { ApexOptions } from 'apexcharts';
import './Tooltip.css';

const labels = [
  {
    label: 'OTP',
    color: '#276ef1',
  },
  {
    label: 'Message Blast',
    color: '#6F9EF6',
  },
  {
    label: 'Auto Reply',
    color: '#C2D5F9',
  },
];

interface propsModal {
  url: string;
  type: string;
  typeChart:
    | 'area'
    | 'line'
    | 'bar'
    | 'histogram'
    | 'pie'
    | 'donut'
    | 'radialBar'
    | 'scatter'
    | 'bubble'
    | 'heatmap'
    | 'treemap'
    | 'boxPlot'
    | 'candlestick'
    | 'radar'
    | 'polarArea'
    | 'rangeBar'
    | undefined;
}

const MessageSent = ({ url, type, typeChart }: propsModal) => {
  const [options, setOption] = useState<ApexOptions | undefined>({
    xaxis: {
      categories: [],
    },
    chart: {
      id: 'message-sent',
      toolbar: { show: false },
    },

    stroke: {
      colors: ['transparent'],
      width: 15,
    },
    legend: {
      show: false,
    },
  });
  // eslint-disable-next-line
  const [series, setSeries] = useState<ApexAxisChartSeries | ApexNonAxisChartSeries | undefined>([
    {
      name: '',
      data: [],
    },
  ]);
  const [page, setPage] = useState(0);

  const [limit, setLimit] = useState(7);
  const [percent, setPercent] = useState(0);
  const [status, setStatus] = useState('');

  // const [labels,setLabel] = useState([])

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getService({ url, type }))
      .unwrap()
      .then((res) => {
        // console.log(res.responseApi);
        // console.log({
        //   name: type?.toLocaleUpperCase(),
        //   data: res.responseApi.data.data ?? [],
        // });

        setSeries([
          {
            name: 'OTP',
            color: '#276EF1',
            data: res.responseApi.data.data.otp?.slice(page === 0 ? page : limit - 7, limit),
          },
          {
            name: 'Blast',
            color: '#6F9EF6',

            data: res.responseApi.data.data.blast.slice(page === 0 ? page : limit - 7, limit),
          },
          {
            name: 'Reply',
            color: '#C2D5F9',
            data: res.responseApi.data.data.reply.slice(page === 0 ? page : limit - 7, limit),
          },
        ]);
        setOption({
          xaxis: {
            range: 3,
            categories: res.responseApi.data.labels.slice(page === 0 ? page : limit - 7, limit),
          },
          chart: {
            id: 'message-sent',
            toolbar: { show: false },
          },
          dataLabels: {
            enabled: false,
          },

          stroke: {
            colors: ['transparent'],
            width: 15,
          },
          legend: {
            show: false,
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return `${val}`;
              },
            },
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
              // console.log(series, seriesIndex, dataPointIndex, w);
              // console.log(w.config.labels[dataPointIndex]);
              // return renderToString(
              //   <div className="tooltip">
              //     <span className="tooltiptext">{w.globals.labels[dataPointIndex]}</span>
              //   </div>
              // );
              // console.log(series[seriesIndex][dataPointIndex]);
              return (
                // eslint-disable-next-line
                '<div class="arrow_box">' +
                '<span >' +
                w.globals.labels[dataPointIndex] +
                '<div class="blueDot"></div> ' +
                '<p class="textBlueDot">' +
                series[0][dataPointIndex] +
                ' ' +
                'messages' +
                '</p>' +
                '<div class="lightBlueDot"></div> ' +
                '<p class="textBlueDot">' +
                series[1][dataPointIndex] +
                ' ' +
                'messages' +
                '</p>' +
                '<div class="moreLightBlueDot"></div> ' +
                '<p class="textBlueDot">' +
                series[2][dataPointIndex] +
                ' ' +
                'messages' +
                '</p>' +
                '</span>' +
                '</div>'
              );
            },
          },
        });
        // setTotal(res.responseApi.data.total);
        setPercent(res.responseApi.data.persentance);
        setStatus(res.responseApi.data.type);
        // create('success', 'Success', res.list.message)
      })
      .catch(() => {
        // create('error', 'Error', err);
      });
  }, [type, page]);
  return (
    <Box>
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', gap: 1 }}>
          {/* <Typography variant="h4" sx={{ fontWeight: 700 }}>
            35%
          </Typography> */}
          <Box
            sx={{
              background: status === 'increase' ? '#BEF6DC' : '#f08c80',
              color: status === 'increase' ? '#12B76A' : '#e11900',
              padding: '2px 4px',
              borderRadius: '4px',
              display: 'flex',
              alignItems: 'center',
            }}>
            {status === 'increase' ? (
              <Line2Up sx={{ fontSize: 14 }} />
            ) : status === 'decrease' ? (
              <Line2Down sx={{ fontSize: 14 }} />
            ) : null}
            <Typography variant="b3">
              {status === 'increase' ? '+' : ''}
              {percent}%
            </Typography>
          </Box>
          <Typography variant="b3">From Last Week</Typography>

          {/* <Box
            sx={{
              background: '#aaeece',
              color: '#12b76a',
              padding: '0 4px',
              borderRadius: '4px',
              display: 'flex',
              alignItems: 'center',
            }}>
            <Line2Up sx={{ fontSize: 14 }} />
            <Typography variant="b3">+25% From Last Week</Typography>
          </Box> */}
        </Box>
        <Box sx={{ display: 'flex', gap: 1 }}>
          {labels.map((l) => (
            <Box key={l.label} sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
              <Box sx={{ width: 12, height: 12, background: l.color, borderRadius: '50%' }} />
              <Typography variant="b3">{l.label}</Typography>
            </Box>
          ))}
        </Box>
      </Box>
      <div style={{ position: 'relative' }}>
        <ReactApexChart type={typeChart} height={'264px'} series={series} options={options} />
        {type?.includes('month') && (
          <div style={{ position: 'absolute', bottom: 12, left: 50 }}>
            <IconButton
              onClick={() => {
                if (page > 0) {
                  setPage(page - 1);
                  setLimit(limit - 7);
                }
              }}>
              <ChevronLeftSqfr />
            </IconButton>
          </div>
        )}
        {type?.includes('month') && (
          <div style={{ position: 'absolute', bottom: 12, right: 20 }}>
            <IconButton
              onClick={() => {
                if (page < 4) {
                  setPage(page + 1);
                  setLimit(limit + 7);
                }
              }}>
              <ChevronRightSqfr />
            </IconButton>
          </div>
        )}
      </div>
    </Box>
  );
};

export default MessageSent;
