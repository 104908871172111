import { number, object, string } from 'yup';

export const initialValues = {
  name: '',
  length: undefined,
  format: 'numeric',
  duration: undefined,
  productName: '',
  defaultLanguageCode: 'en',
  isAllowMulti: false,
};

export const validationSchema = object({
  name: string().required("Configuration Name can't be blank"),
  length: number()
    .min(4, 'OTP length haven’t meet the requirement below')
    .max(12, 'OTP length haven’t meet the requirement below')
    .required("Length can't be blank"),
  format: string().required("Format can't be blank"),
  duration: number().min(60, 'Duration minimum 60 seconds').required("Duration can't be blank"),
  productName: string().required("Product Name can't be blank"),
  defaultLanguageCode: string().required("Default Language Code Name can't be blank"),
});

export const editInitialValues = {
  id: '',
  name: '',
  length: 0,
  format: 'numeric',
  duration: 0,
  productName: '',
  defaultLanguageCode: 'en',
  isAllowMulti: false,
};

export const editValidationSchema = object({
  name: string().required("Configuration Name can't be blank"),
  length: number()
    .min(4, 'OTP length haven’t meet the requirement below')
    .max(12, 'OTP length haven’t meet the requirement below')
    .required("Length can't be blank"),
  format: string().required("Format can't be blank"),
  duration: number().min(60, 'Duration minimum 60 seconds').required("Duration can't be blank"),
  productName: string().required("Product Name can't be blank"),
  defaultLanguageCode: string().required("Default Code Language can't be blank"),
});
