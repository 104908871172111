import React from 'react';
import {
  Dialog,
  Slide,
  CircularProgress,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';

import { TransitionProps } from '@mui/material/transitions';

interface handleContent {
  title: String;
  message: String;
  button: String;
}

interface propsModal {
  open: boolean;
  handleAksi: Function;
  disable: boolean;
  handleClose: Function;
  loading: Boolean;
  handleContent: handleContent;
  type: 'Create' | 'Delete';
}
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function index({
  open,
  handleClose,
  loading,
  handleAksi,
  handleContent,
  disable,
  type,
}: propsModal) {
  return (
    <Dialog
      aria-labelledby="confirm"
      open={open}
      TransitionComponent={Transition}
      onClose={() => handleClose}
      className={loading ? 'confirmNoRoot' : 'confirmRoot'}>
      {loading ? (
        <div className="confirmLoadingRoot">
          <CircularProgress className="confirmLoadingItem" thickness={3} size={60} />
        </div>
      ) : (
        <div className="confirmRoot">
          <div className="confirmHead">
            <div>
              {/* <ReportProblemIcon fontSize='default' style={{ marginLeft: 15, marginTop: 4 }} /> */}
              <DialogTitle className="confirmTypohead">
                {handleContent.title ?? 'Warning'}
              </DialogTitle>
            </div>
            {/* <IconButton onClick={props.handleClose} className='confirmIconClose'>
              <CloseIcon />
            </IconButton> */}
          </div>
          <DialogContent className="confirmContent">
            <DialogContentText className="confirmMessage">
              {handleContent.message ?? 'Warning'}
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button
              variant="outlined"
              className="mr-2"
              onClick={() => handleClose()}
              style={{
                fontSize: 14,
                textTransform: 'capitalize',
                borderRadius: 8,
                padding: 10,
                marginBottom: 6,
                backgroundColor: 'white',
                border: '1px solid rgba(33, 43, 54, 0.5)',
                boxShadow: 'none',
                color: '#000',
              }}>
              Cancel
            </Button>
            <Button
              variant="contained"
              className="mr-2"
              style={{
                fontSize: 14,
                textTransform: 'capitalize',
                borderRadius: 8,
                padding: 10,
                marginRight: 20,
                marginBottom: 6,
                color: disable ? '#FFF' : '',
              }}
              color={type === 'Create' ? 'primary' : 'error'}
              onClick={() => handleAksi()}
              disabled={disable}>
              {handleContent.button ?? 'DO'}
            </Button>
          </DialogActions>
        </div>
      )}
    </Dialog>
  );
}
