import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getService } from '../../../redux/modules/statistic';
import { Typography, Box, styled } from '@mui/material';
import { AppDispatch } from '../../../redux/store';
import moment from 'moment';
import Table from '../../../components/Table';

import { WidgetFooter } from '../../../components/Widget';
import Pagination from '../../../components/TablePagination';
interface meta {
  total: number;
}

interface propsTable {
  status: string;
  waContactId: string;
  senderType: string;
  createdOn: number;
  otpRequestId: string;
  messageBlastId: string;
  autoReplyId: string;
}

const Delivered = styled(Box)(({ theme }) => ({
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '5px',
    // right: '3px',
    width: 12,
    height: 12,
    borderRadius: '50%',
    backgroundColor: '#12B76A',
    border: 'solid 1px white',
  },
}));

const Failed = styled(Box)(({ theme }) => ({
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '5px',
    // right: '3px',
    width: 12,
    height: 12,
    borderRadius: '50%',
    backgroundColor: '#E11900',
    border: 'solid 1px white',
  },
}));

const Pending = styled(Box)(({ theme }) => ({
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '5px',
    // right: '3px',
    width: 12,
    height: 12,
    borderRadius: '50%',
    backgroundColor: '#FFC043',
    border: 'solid 1px white',
  },
}));

// #

const Read = styled(Box)(({ theme }) => ({
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '5px',
    // right: '3px',
    width: 12,
    height: 12,
    borderRadius: '50%',
    backgroundColor: '#276EF1',
    border: 'solid 1px white',
  },
}));

export default function index() {
  const dispatch = useDispatch<AppDispatch>();
  const [list, setList] = useState<propsTable[]>([]);

  // const [typeFilter, setTypeFilter] = useState('All');
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [orderBy, setOrderBy] = useState('created_on');
  const [order, setOrder] = useState('DESC');

  const [totalData, setTotal] = useState<meta>({ total: 1 });

  const [statusFilter, setStatusFilter] = useState('week');
  const [search, setSearch] = useState('');

  useEffect(() => {
    dispatch(
      getService({
        url: 'activity-feed',
        type: `limit=${limit}&offset=${
          page === 1 ? 0 : page === 2 ? limit : limit * page
        }&order=${order}&orderBy=${orderBy}&range=${statusFilter}&keyword=${search}`,
      })
    )
      .unwrap()
      .then((res) => {
        // console.log(res);
        setList(res.responseApi.data.data);
        setTotal({ total: res.responseApi.data.count });
      })
      .catch(() => {
        // create('error', 'Error', err);
      });
  }, [search, orderBy, order, page, statusFilter]);

  const capitalizeFirstLetter = (string: string) => {
    return string[0].toUpperCase() + string.slice(1);
  };
  return (
    <div>
      <Table
        data={list ?? []}
        config={[
          {
            label: 'WhatsApp Number',
            key: 'waContactId',
            onSort: () => {
              setOrder(order === 'ASC' ? 'DESC' : 'ASC');
              setOrderBy('wa_contact_id');
            },
          },
          {
            label: 'Type',
            key: 'value',
            render: (row) => {
              return (
                <p>
                  {row.otpRequestId !== null
                    ? 'OTP'
                    : row.messageBlastId !== null
                    ? 'Message Blast'
                    : 'Auto Reply'}
                </p>
              );
            },
            // onSort: () => {
            //   setOrder(order === 'ASC' ? 'DESC' : 'ASC');
            //   setOrderBy('sender_type');
            // },
          },
          {
            label: 'Account Type',
            key: 'senderType',
            render: (row) => {
              return <p>{row.senderType === 'user' ? 'Regular Account' : 'Shared Account'}</p>;
            },
            onSort: () => {
              setOrder(order === 'ASC' ? 'DESC' : 'ASC');
              setOrderBy('sender_type');
            },
          },
          {
            label: 'Status',
            key: 'status',
            render: (row) => {
              return row.status === 'delivered' ? (
                <Delivered>
                  <Typography
                    style={{
                      fontWeight: 300,
                      fontStyle: 'normal',
                      fontSize: '14px',
                      color: '#5d5d5d',
                      marginLeft: 14,
                    }}>
                    {capitalizeFirstLetter(row.status)}
                  </Typography>
                </Delivered>
              ) : row.status === 'pending' ? (
                <Pending>
                  <Typography
                    style={{
                      fontWeight: 300,
                      fontStyle: 'normal',
                      fontSize: '14px',
                      color: '#5d5d5d',
                      marginLeft: 14,
                    }}>
                    {capitalizeFirstLetter(row.status)}
                  </Typography>
                </Pending>
              ) : row.status === 'read' ? (
                <Read>
                  <Typography
                    style={{
                      fontWeight: 300,
                      fontStyle: 'normal',
                      fontSize: '14px',
                      color: '#5d5d5d',
                      marginLeft: 14,
                    }}>
                    {capitalizeFirstLetter(row.status)}
                  </Typography>
                </Read>
              ) : (
                <Failed>
                  <Typography
                    style={{
                      fontWeight: 300,
                      fontStyle: 'normal',
                      fontSize: '14px',
                      color: '#5d5d5d',
                      marginLeft: 14,
                    }}>
                    {capitalizeFirstLetter(row.status)}
                  </Typography>
                </Failed>
              );
            },
            onSort: () => {
              setOrder(order === 'ASC' ? 'DESC' : 'ASC');
              setOrderBy('status');
            },
          },
          {
            label: 'Date Sent',
            key: 'createdOn',
            render: (row) => {
              return <p>{moment(row.createdOn * 1000).format('MMM DD, YYYY | HH:mm')}</p>;
            },
            onSort: () => {
              setOrder(order === 'ASC' ? 'DESC' : 'ASC');
              setOrderBy('created_on');
            },
          },
        ]}
        filters={[
          // {
          //   label: 'Type',
          //   value: typeFilter,
          //   options: [
          //     'All',
          //     'Unassigned',
          //     'Subscription',
          //     { label: 'Non-Subscription', value: 'NonSubscription' },
          //   ],
          //   onChange: (value) => setTypeFilter(value),
          // },
          {
            label: 'Status',
            value: statusFilter,
            options: [
              {
                label: 'This Week',
                value: 'week',
              },
              {
                label: 'This Month',
                value: 'month',
              },
            ],
            onChange: (value) => {
              setPage(1);
              setStatusFilter(value);
            },
          },
        ]}
        searchValue={search}
        onSearch={(e) => {
          setPage(1);
          setSearch(e.target.value);
        }}
      />
      <WidgetFooter>
        <Pagination
          page={page}
          count={totalData?.total}
          rowsPerPage={limit}
          onPageChange={(_, newPage) => setPage(newPage)}
          onRowsPerPageChange={(e) => {
            setLimit(e.target.value as number);
            setPage(1);
          }}
        />
      </WidgetFooter>
    </div>
  );
}
