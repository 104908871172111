import {
  Pagination as MuiPagination,
  PaginationItem,
  PaginationProps as MuiPaginationProps,
  Typography,
  Box,
  useTheme,
} from '@mui/material';

export type PaginationProps = MuiPaginationProps;

const Pagination = (props: PaginationProps) => {
  const { palette } = useTheme();
  return (
    <Box
      sx={{
        width: 'fit-content',
        border: `solid 1px ${palette.neutral.light}`,
        borderRadius: 2,
        px: 0.4,
        py: 0.8,
      }}>
      <MuiPagination
        {...props}
        renderItem={(item) => (
          <PaginationItem
            {...item}
            size="small"
            components={{
              previous: () => <Typography variant="b3">Prev</Typography>,
              next: () => <Typography variant="b3">Next</Typography>,
            }}
            sx={{ fontSize: 12 }}
          />
        )}
      />
    </Box>
  );
};

export default Pagination;
