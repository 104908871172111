import { ThemeOptions } from '@mui/material';
import { CSSProperties } from 'react';
import responsiveStyle from '../utils/responsiveStyle';
declare module '@mui/material/styles' {
  interface TypographyVariants {
    b1: CSSProperties;
    b2: CSSProperties;
    b3: CSSProperties;
    b4: CSSProperties;

    p1: CSSProperties;
    p2: CSSProperties;
    p3: CSSProperties;
    p4: CSSProperties;

    l1: CSSProperties;
    l2: CSSProperties;
    l3: CSSProperties;
  }

  interface TypographyVariantsOptions {
    b1?: CSSProperties;
    b2?: CSSProperties;
    b3?: CSSProperties;
    b4?: CSSProperties;

    p1?: CSSProperties;
    p2?: CSSProperties;
    p3?: CSSProperties;
    p4?: CSSProperties;

    l1?: CSSProperties;
    l2?: CSSProperties;
    l3?: CSSProperties;
  }
}

export function pxToRem(value: number) {
  return `${value / 16}rem`;
}

const FONT_PRIMARY = 'Inter';

const typography: ThemeOptions['typography'] = {
  fontFamily: `${FONT_PRIMARY}, serif`,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  h1: responsiveStyle({
    xs: {
      fontSize: pxToRem(24),
      lineHeight: pxToRem(30),
    },
    sm: {
      fontSize: pxToRem(36),
      lineHeight: pxToRem(44),
    },
  }),
  h2: responsiveStyle({
    xs: {
      fontSize: pxToRem(16),
      lineHeight: pxToRem(20),
    },
    sm: {
      fontSize: pxToRem(28),
      lineHeight: pxToRem(36),
    },
  }),
  h3: responsiveStyle({
    xs: {
      fontSize: pxToRem(14),
      lineHeight: pxToRem(18),
    },
    sm: {
      fontSize: pxToRem(24),
      lineHeight: pxToRem(32),
    },
  }),
  h4: {
    fontSize: pxToRem(20),
    lineHeight: pxToRem(28),
  },
  h5: {
    fontSize: pxToRem(18),
    lineHeight: pxToRem(22),
  },
  b1: responsiveStyle({
    xs: {
      fontSize: pxToRem(16),
      lineHeight: pxToRem(20),
    },
    sm: {
      fontSize: pxToRem(16),
      lineHeight: pxToRem(24),
    },
  }),
  b2: responsiveStyle({
    xs: {
      fontSize: pxToRem(14),
      lineHeight: pxToRem(18),
    },
    sm: {
      fontSize: pxToRem(14),
      lineHeight: pxToRem(20),
    },
  }),
  b3: {
    fontSize: pxToRem(12),
    lineHeight: pxToRem(16),
  },
  b4: {
    fontSize: pxToRem(10),
    lineHeight: pxToRem(14),
  },
  p1: {
    fontSize: pxToRem(16),
    lineHeight: pxToRem(24),
  },
  p2: {
    fontSize: pxToRem(14),
    lineHeight: pxToRem(20),
  },
  p3: {
    fontSize: pxToRem(12),
    lineHeight: pxToRem(16),
  },
  p4: {
    fontSize: pxToRem(10),
    lineHeight: pxToRem(14),
  },
  l1: {
    fontSize: pxToRem(14),
    lineHeight: pxToRem(18),
  },
  l2: {
    fontSize: pxToRem(12),
    lineHeight: pxToRem(16),
  },
  l3: {
    fontSize: pxToRem(10),
    lineHeight: pxToRem(14),
  },
};

export default typography;
