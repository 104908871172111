import { Avatar, Box, Grid, Stack, styled, Typography, useTheme } from '@mui/material';

import { User } from '@xcidic/icons';
import moment from 'moment';
// import SocketsProvider, { useSockets } from '../../contexts/SocketContextComponent';

import { useSockets } from '../../../contexts/SocketContextComponent';

interface Props {
  id: string;
  name: string;
  selectedId: string;
  timeMessage: number;
  handleClick: Function;
  messageProps: string;
  lastMessage: number;
  isNewMessage: boolean;
  isReplied: boolean;
  isResolved: boolean;
}

const NotifNum = styled(Box)(({ theme }) => ({
  width: 14,
  height: 14,
  borderRadius: '4px',
  fontSize: 10,
  marginLeft: 10,
  background: theme.palette.info.main,
  color: '#FFF',
  display: 'grid',
  placeItems: 'center',
}));

export default function index({
  name,
  handleClick,
  messageProps,
  timeMessage,
  lastMessage,
  isNewMessage,
  selectedId,
  id,
  isReplied,
  isResolved,
}: Props) {
  const { palette } = useTheme();
  const { message, dataUser } = useSockets();

  // const showNotifNum = selectedId !== '' ?
  const filterData = message?.filter((val) => val.contactId === id);
  const messageData: string = filterData[filterData?.length - 1]?.textMessage ?? messageProps;
  const timeLast: number = filterData[filterData?.length - 1]?.messageOn ?? timeMessage;

  const showNewMessage =
    message?.filter((val) => val.contactId !== id)?.length > 0 &&
    message?.filter((val) => val.contactId === id)?.length > 0;
  const showData = showNewMessage ?? isNewMessage;
  const withSelected = selectedId !== '' ? id !== selectedId : true;
  const socketIsReplied = dataUser?.find((val) => val.id === id)?.isReplied ?? undefined;
  const socketIsResolved = dataUser?.find((val) => val.id === id)?.isResolved ?? undefined;
  const status =
    socketIsResolved ?? isResolved ? 'resolved' : socketIsReplied ?? isReplied ? 'replied' : '';
  // console.log(showData, isNewMessage, showNewMessage);
  return (
    <Stack
      direction="row"
      spacing={1}
      style={{
        padding: 12,
      }}
      sx={{
        '&:hover': { cursor: 'pointer' },
        backgroundColor: id === selectedId ? '#F6F8FC' : '#FFF',
      }}
      onClick={() => handleClick()}>
      <Avatar style={{ backgroundColor: '#E3E6EC' }}>
        <User style={{ color: '#000' }} />
      </Avatar>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          width: '100%',
        }}>
        <Grid style={{ display: 'flex', justifyContent: 'space-between', paddingRight: 12 }}>
          <Typography component="p" fontWeight="bold" variant="b2">
            {name}
          </Typography>

          <div
            style={{
              fontSize: 10,
              backgroundColor:
                status === 'replied' ? '#C2D5F9' : status === 'resolved' ? '#BEF7DC' : '#FFECC6',
              color:
                status === 'replied' ? '#276EF1' : status === 'resolved' ? '#12B76A' : '#D5A038',
              padding: 5,
              // height: 'fit-content',
              // marginLeft: 10,
              // marginRight: 10,
            }}>
            {status === 'replied' ? 'Replied' : status === 'resolved' ? 'Resolved' : 'Waiting'}
          </div>
          {/* {status === '' && selectedId !== id && (
            <div
              style={{
                fontSize: 10,
                backgroundColor: '#FFECC6',
                color: '#D5A038',
                padding: 5,
                height: 'fit-content',
                marginLeft: 10,
                marginRight: 10,
              }}>
              Waiting
            </div>
          )}
          {status !== '' && (
            
          )} */}
        </Grid>
        <Grid style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 7 }}>
          <Typography component="span" variant="b4" sx={{ color: palette.neutral[400] }}>
            {messageData?.length > 55 ? `${messageData?.slice(0, 55)}...` : messageData}
          </Typography>
        </Grid>
        <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography style={{ fontSize: 10 }}>{moment(timeLast * 1000).fromNow()}</Typography>

          {showData && withSelected && (
            <NotifNum>
              {message?.filter((val) => val.contactId === id)?.length + lastMessage}
            </NotifNum>
          )}
        </Grid>
      </Box>
    </Stack>
  );
}
