import { Theme, ThemeOptions } from '@mui/material';
import { pxToRem } from '../typography';

export default function Input(theme: Theme): ThemeOptions['components'] {
  return {
    MuiInputBase: {
      styleOverrides: {
        root: {
          '& > .MuiSvgIcon-root': {
            color: theme.palette.neutral[200],
          },
          '&.Mui-focused > .MuiSvgIcon-root': {
            color: theme.palette.primary.main,
          },
          '&.Mui-error > .MuiSvgIcon-root': {
            color: theme.palette.error.main,
            fontWeight: 300,
          },
          '&.Mui-disabled': {
            backgroundColor: '#f6f8fc',
          },
        },
        input: {
          borderRadius: 6,
          fontSize: pxToRem(14),
        },
        multiline: {
          '&.MuiInputBase-root': {
            paddingTop: 0,
            paddingBottom: 0,
          },
        },
        inputAdornedStart: {
          marginLeft: 10,
        },
        inputAdornedEnd: {
          marginRight: 10,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 6,
        },
        input: {
          paddingTop: 12.5,
          paddingBottom: 12.5,
          borderRadius: 6,
        },
        notchedOutline: {
          '.MuiOutlinedInput-root &': {
            borderColor: theme.palette.neutral[400],
          },
          '.MuiOutlinedInput-root.Mui-disabled &': {
            borderColor: theme.palette.neutral[200],
          },
        },
      },
    },
  };
}
