import { Box, Typography } from '@mui/material';
interface propsEmptyState {
  module: string;
  button: string;
  temp?: string;
}

export default function index({ module, button, temp }: propsEmptyState) {
  return (
    <>
      <Box sx={{ justifyContent: 'center', display: 'flex', width: '100%' }}>
        <Box component="img" src={`/emptyState.svg`} />
      </Box>
      <Box sx={{ textAlign: 'center' }}>
        <Typography color="neutral.black" variant="h2" component="h2" fontWeight="700" mb={1}>
          Whoops. No {module.toLocaleLowerCase()} has been created yet.
        </Typography>
        <Typography color="neutral.400" variant="b1" component="h3" fontWeight="500">
          Click on “{button}” button above to start creating a new{' '}
          {temp ?? module.toLocaleLowerCase()}
        </Typography>
      </Box>
    </>
  );
}
