import { createContext, PropsWithChildren, ReactElement, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Toast, { ToastVariant, ToastProps } from '../Toast';
import { Stack } from '@mui/material';

interface ToastManagerContextProps {
  create: (type: ToastVariant, title: string, message: string) => void;
}

interface ToastPropsWithId extends ToastProps {
  id: string;
}

const defaultProps: ToastManagerContextProps = {
  create: () => {},
};

export const ToastManagerContext = createContext<ToastManagerContextProps>(defaultProps);

interface ToastManagerProps extends PropsWithChildren {
  maxStack?: number;
}

const ToastManager = ({ children, maxStack = 3 }: ToastManagerProps): ReactElement => {
  const [toasts, setToasts] = useState<ToastPropsWithId[]>([]);

  const destroy = (id: string) => {
    setToasts((toasts) => toasts.filter((toast) => toast.id !== id));
  };

  const create = (type: ToastVariant, title: string, message: string) => {
    const id = uuidv4();
    const newToast: ToastPropsWithId = {
      id,
      title,
      message,
      open: true,
      variant: type,
      onClose: () => destroy(id),
    };

    setToasts((toasts) => {
      if (toasts.length >= maxStack) {
        toasts.pop();
      }
      return [newToast, ...toasts];
    });
  };

  return (
    <ToastManagerContext.Provider value={{ create }}>
      <>
        {children}
        <Stack spacing={1} sx={{ position: 'fixed', bottom: '24px', left: '24px', zIndex: 999999 }}>
          {toasts.map((toast) => (
            <Toast key={toast.id} {...toast} sx={{ position: 'static' }} />
          ))}
        </Stack>
      </>
    </ToastManagerContext.Provider>
  );
};

export default ToastManager;
