import React, { useEffect, useState } from 'react';
// material
import { styled, Box, Stack, Typography, useTheme } from '@mui/material';

import { useDispatch } from 'react-redux';
import { getService } from '../../redux/modules/xendit';
import { AppDispatch } from '../../redux/store';
// import { Link } from 'react-router-dom';

// components
// import Searchbar from '../../components/Searchbar';
import UserMenu from './UserMenu';
// icons
import { BankCard, BankCardExportOulc } from '@xcidic/icons';

export interface DashboardHeaderProps {
  activePath: string;
}

const HeaderContainer = styled(Box)(({ theme }) => ({
  position: 'sticky',
  width: '100%',
  top: 0,
  left: 0,
  padding: '14px 52px',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  backgroundColor: theme.palette.neutral.white,
  zIndex: 2,
}));

// const NotifNum = styled(Box)(({ theme }) => ({
//   width: 27,
//   height: 20,
//   borderRadius: '4px',
//   fontSize: 10,
//   background: theme.palette.neutral.white,
//   color: theme.palette.primary.main,
//   display: 'grid',
//   placeItems: 'center',
// }));

const DashboardHeader = ({ activePath }: DashboardHeaderProps) => {
  const { palette } = useTheme();
  const dispatch = useDispatch<AppDispatch>();
  const [balance, setBalance] = useState(0);
  const [usage, setUsage] = useState(0);

  useEffect(() => {
    dispatch(getService({ id: 'balance', limit: 10000, offset: 0 }))
      .unwrap()
      .then((res) => {
        setBalance(res.listToken.data.idr);
      })
      .catch(() => {
        // console.log(err)
        // create('error', 'Error', err);
      });

    dispatch(getService({ id: 'usage', limit: 10000, offset: 0 }))
      .unwrap()
      .then((res) => {
        const service: number = res.listToken.data.monthly.serviceCostIDR;
        const shared: number = res.listToken.data.monthly.sharedWhatsappCostIDR;
        setUsage(service + shared);
      })
      .catch(() => {
        // console.log(err)
        // create('error', 'Error', err);
      });
  }, []);
  return (
    <HeaderContainer>
      {/* <Searchbar /> */}
      <Box>
        <Stack direction="row" spacing={4}>
          <Stack
            direction="row"
            spacing={1}
            {...(true && { onClick: () => (window.location.href = '/billing') })}
            sx={{ alignItems: 'center', color: palette.neutral[400], cursor: 'pointer' }}>
            <Box
              sx={{
                position: 'relative',
                marginTop: '5px',
              }}>
              <BankCard sx={{ fontSize: 16 }} />
            </Box>
            <Typography variant="b2">Balance : </Typography>
            <Typography variant="b2" style={{ color: palette.primary.main }}>
              Rp. {balance}
            </Typography>
            <Box
              sx={{
                position: 'relative',
                marginTop: '5px',
              }}>
              <BankCardExportOulc sx={{ fontSize: 16 }} />
            </Box>
            <Typography variant="b2">Monthly Usage : </Typography>
            <Typography variant="b2" style={{ color: palette.primary.main }}>
              Rp. {usage}
            </Typography>
            {/* <h3>Remaining Credit : Rp. 500.000</h3> */}
          </Stack>
          {/* <Typography variant="b2">Notifikasi</Typography> */}

          <UserMenu activePath={activePath} />
        </Stack>
      </Box>
    </HeaderContainer>
  );
};

export default DashboardHeader;
