import Guide from '../Guide';
import { Step } from 'react-joyride';

const blastGuideSteps: Step[] = [
  {
    target: '#message-blast',
    content: (
      <Guide
        title="Create a message blast"
        content="Integrate and blast your message to many contact with this feature."
        step="1"
        totalSteps="9"
      />
    ),
    disableBeacon: true,
    offset: 25,
    placement: 'bottom-start',
  },
  {
    target: '#blast-list',
    content: (
      <Guide
        title="Create a message blast"
        content="To create a message blast, you have to create contact category, contact, template, then integrate your blast message code."
        step="2"
        totalSteps="9"
      />
    ),
    disableBeacon: true,
    offset: 25,
    placement: 'bottom-start',
  },
  {
    target: '#blast-category',
    content: (
      <Guide
        title="Create a message blast"
        content="Click here to open “Contact Category” menu and find “Add Contact Category” button."
        step="3"
        totalSteps="9"
      />
    ),
    disableBeacon: true,
    offset: 25,
    placement: 'bottom-start',
  },
  {
    target: '#blast-add-category',
    content: (
      <Guide
        title="Create a message blast"
        content="Click here to create contact category for your message blast."
        step="4"
        totalSteps="9"
      />
    ),
    disableBeacon: true,
    offset: 25,
    placement: 'bottom-start',
  },
  {
    target: '#blast-contact',
    content: (
      <Guide
        title="Create a message blast"
        content="Click here to open “Contact” menu and find “Add Contact” button."
        step="5"
        totalSteps="9"
      />
    ),
    disableBeacon: true,
    offset: 25,
    placement: 'bottom-start',
  },
  {
    target: '#blast-add-contact',
    content: (
      <Guide
        title="Create a message blast"
        content="Click here to create contact for your message blast."
        step="6"
        totalSteps="9"
      />
    ),
    disableBeacon: true,
    offset: 25,
    placement: 'bottom-start',
  },
  {
    target: '#blast-template',
    content: (
      <Guide
        title="Create a message blast"
        content="Click here to open “Template” menu and find “Add Template” button."
        step="7"
        totalSteps="9"
      />
    ),
    disableBeacon: true,
    offset: 25,
    placement: 'bottom-start',
  },
  {
    target: '#blast-add-template',
    content: (
      <Guide
        title="Create a message blast"
        content="Click here to create template for your message blast."
        step="8"
        totalSteps="9"
      />
    ),
    disableBeacon: true,
    offset: 25,
    placement: 'bottom-start',
  },
  {
    target: '#blast-guide',
    content: (
      <Guide
        title="Create a message blast"
        content="Click here to open “Integrated Guide” menu to  integrate your blast message by choosing the program language and copy the code."
        step="9"
        totalSteps="9"
      />
    ),
    disableBeacon: true,
    offset: 25,
    placement: 'bottom-start',
  },
];

export default blastGuideSteps;
