import { Button, MenuItem, Paper, Select, Typography, Box } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ToastManagerContext } from '../../components/ToastManager';
import { get as GetCompany } from '../../redux/modules/company';
import { changeDefaultHandler, get } from '../../redux/modules/otpConfiguration';
import { AppDispatch } from '../../redux/store';
import { Pencil, PlusCrfr } from '@xcidic/icons';
import { useNavigate } from 'react-router-dom';

import Breadcrumbs from '../../components/Breadcrumbs';
import Container from '../../components/Container';
import Table from '../../components/Table';
import GenerateConfiguration from './component/modal';

import EmptyState from '../../components/EmptyState';
import DashboardHeader from '../../components/DashboardHeader';
import { WidgetFooter } from '../../components/Widget';
import Pagination from '../../components/TablePagination';
import EditModal from './component/editModal';

// import Link from '../../components/Link';
interface ObjectPropsData {
  id: string;
  name: string;
  length: number;
  format: string;
  duration: number;
  productName: string;
  defaultLanguageCode: string;
  isActive: Boolean;
  isAllowMultipleActive: boolean;
}
interface objectState {
  id: string;
  isOtpHandler: Boolean;
  isOtpDefaultHandler: Boolean;
  countryCode: string;
  number: string;
}

interface requestData {
  name: string;
  phones: objectState[];
}
interface updateHandler {
  companyPhoneId: string;
  isSet: Boolean;
}
interface meta {
  total: number;
}

interface requestDataEdit {
  id: string;
  name: string;
  length: number;
  format: string;
  duration: number;
  productName: string;
  defaultLanguageCode: string;
  isAllowMultipleActive: Boolean;
  isActive: boolean;
}

export default function index() {
  const { create } = useContext(ToastManagerContext);
  const navigate = useNavigate();

  const dispatch = useDispatch<AppDispatch>();
  const [list, setList] = useState<ObjectPropsData[]>();
  const [isOpen, setIsOpen] = useState(false);
  const [phoneValue, setPhoneValue] = useState<objectState>({
    id: '0',
    isOtpHandler: false,
    isOtpDefaultHandler: false,
    countryCode: '0',
    number: '0',
  });
  const [isEdit, setIsEdit] = useState(false);

  const [editValue, setEditValue] = useState<requestDataEdit>({
    id: '0',
    name: '',
    length: 0,
    format: 'alpha',
    duration: 0,
    productName: '',
    defaultLanguageCode: 'en',
    isActive: false,
    isAllowMultipleActive: false,
  });

  const [selectValue, setSelectValue] = useState('default');
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const [totalData, setTotal] = useState<meta>({ total: 1 });

  // const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
  //   setPage(value);
  // };
  useEffect(() => {
    refreshData();
  }, [page]);
  useEffect(() => {
    dispatch(GetCompany())
      .unwrap()
      .then((res) => {
        const phone = (res?.list?.data[0] as requestData)?.phones[0];
        setPhoneValue(phone);
        setSelectValue(phone?.isOtpDefaultHandler ? 'your' : 'default');
      })
      .catch((err) => {
        // console.log(err)
        create('error', 'Error', err);
      });
  }, []);

  const refreshData = () => {
    dispatch(get({ limit, offset: page === 1 ? 0 : page === 2 ? limit : limit * page }))
      .unwrap()
      .then((res) => {
        // create('success', 'Success', res.listToken.message);
        setTotal(res.listToken.meta);

        setList(res.listToken?.data);
      })
      .catch((err) => {
        // console.log(err)
        create('error', 'Error', err);
      });
  };

  const changePhone = (value: string) => {
    setSelectValue(value);

    if (value === 'default') {
      const data: updateHandler = {
        companyPhoneId: phoneValue?.id,
        isSet: false,
      };
      dispatch(changeDefaultHandler(data))
        .unwrap()
        .then((res) => {
          create('success', 'Success', res.responseApi.message);
          dispatch(GetCompany())
            .unwrap()
            .then((res) => {
              const phone = (res?.list?.data[0] as requestData)?.phones[0];
              setPhoneValue(phone);
              setSelectValue(phone?.isOtpDefaultHandler ? 'your' : 'default');
            })
            .catch((err) => {
              // console.log(err)
              create('error', 'Error', err);
            });
        })
        .catch((err) => {
          // console.log(err)
          create('error', 'Error', err);
        });
      // dispatch(changeUserHandler(dataUser)).unwrap().then((res) => {
      //   create('success', 'Success', res.responseApi.message)

      // }).catch((err) => {
      //   // console.log(err)
      //   create('error', 'Error', err)

      // })
    } else {
      const dataDefault: updateHandler = {
        companyPhoneId: phoneValue?.id,
        isSet: true,
      };
      // dispatch(changeUserHandler(data)).unwrap().then((res) => {
      //   create('success', 'Success', res.responseApi.message)

      // }).catch((err) => {
      //   // console.log(err)
      //   create('error', 'Error', err)

      // })
      dispatch(changeDefaultHandler(dataDefault))
        .unwrap()
        .then((res) => {
          create('success', 'Success', res.responseApi.message);
          dispatch(GetCompany())
            .unwrap()
            .then((res) => {
              const phone = (res?.list?.data[0] as requestData)?.phones[0];
              setPhoneValue(phone);
              setSelectValue(phone?.isOtpDefaultHandler ? 'your' : 'default');
            })
            .catch((err) => {
              // console.log(err)
              create('error', 'Error', err);
            });
        })
        .catch((err) => {
          // console.log(err)
          create('error', 'Error', err);
        });
    }
  };

  return (
    <Container>
      <Breadcrumbs
        items={[
          {
            label: 'OTP',
            link: '/otp',
          },
          {
            label: 'Configuration',
            link: '/otp/configuration',
          },
        ]}
      />
      <DashboardHeader title="Otp Configuration">
        <Box sx={{ display: 'flex' }}>
          {list?.length !== 0 && (
            <>
              <Typography style={{ marginTop: 10, marginRight: 12 }}>
                Setting Handler OTP
              </Typography>

              <Select value={selectValue} onChange={(e) => changePhone(e.target.value as string)}>
                <MenuItem value={'default'}>Default</MenuItem>
                <MenuItem value={'your'}>{phoneValue?.countryCode + phoneValue?.number}</MenuItem>
              </Select>
            </>
          )}
          <Button
            id="otp-add-config"
            variant="contained"
            style={{ marginLeft: 12 }}
            startIcon={<PlusCrfr />}
            onClick={() => setIsOpen(true)}>
            Add Configuration
          </Button>
        </Box>
      </DashboardHeader>

      <Paper>
        {list?.length === 0 && <EmptyState module="Configuration" button="Add Configuration" />}
        {list?.length !== 0 && (
          <Table
            // idHref="id"
            // name="otp/configuration"
            data={list ?? []}
            config={[
              {
                label: 'Id',
                key: 'id',
                render: (row) => {
                  return (
                    <Typography
                      sx={{ cursor: 'pointer' }}
                      onClick={() => navigate(`/otp/configuration/${row.id}`)}>
                      {row.id}
                    </Typography>
                  );
                },
              },
              {
                label: 'Name',
                key: 'name',
                render: (row) => {
                  return (
                    <Typography
                      sx={{ cursor: 'pointer' }}
                      onClick={() => navigate(`/otp/configuration/${row.id}`)}>
                      {row.name}
                    </Typography>
                  );
                },
              },
            ]}
            actions={[
              {
                label: 'Edit',
                icon: <Pencil />,
                onAction: (row) => {
                  setEditValue({
                    id: row.id,
                    isAllowMultipleActive: Boolean(row.isAllowMultipleActive),
                    name: row.name,
                    length: row.length,
                    format: row.format,
                    duration: row.duration,
                    productName: row.productName,
                    defaultLanguageCode: row.defaultLanguageCode,
                    isActive: Boolean(row.isActive),
                  });
                  setIsEdit(true);
                  // navigate(`/otp/configuration/${row.id}`);
                },
              },
            ]}
          />
        )}

        {/* {list?.map((val, index) => (
          <Box sx={{ p: 2, border: '1px solid grey', borderTopWidth: index > 0 ? 0 : 1 }}>
            
          </Box>
          // <ListToken title={val.description} expiredOn={val.expiresOn} lastUsed={val.lastAccessedOn} token={val.value} index={index} id={val.id} updateToken={updateToken} />

        ))} */}
        {list?.length !== 0 && (
          // <Grid container spacing={3} style={{ marginTop: 10 }}>
          // <Grid item lg={12} style={{ justifyContent: 'center', display: 'flex' }}>
          <WidgetFooter>
            <Pagination
              page={page}
              count={totalData?.total}
              rowsPerPage={limit}
              onPageChange={(_, newPage) => setPage(newPage)}
              onRowsPerPageChange={(e) => {
                setLimit(e.target.value as number);
                setPage(1);
              }}
            />
          </WidgetFooter>

          // </Grid>
          // </Grid>
        )}
        {isEdit && (
          <EditModal
            open={isEdit}
            onClose={() => {
              setIsEdit(false);
              refreshData();
            }}
            isAllowMultipleActive={editValue.isAllowMultipleActive}
            id={editValue.id}
            name={editValue.name}
            length={editValue.length}
            format={editValue.format}
            duration={editValue.duration}
            productName={editValue.productName}
            defaultLanguageCode={editValue.defaultLanguageCode}
          />
        )}
        {/* {isOpen && ( */}
        <GenerateConfiguration
          open={isOpen}
          onClose={() => {
            setIsOpen(false);
            refreshData();
          }}
        />
        {/* )} */}
      </Paper>
    </Container>
  );
}
