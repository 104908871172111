import type {} from '@mui/x-date-pickers/themeAugmentation';
import { CSSInterpolation, Theme, ThemeOptions } from '@mui/material';
import { pxToRem } from '../typography';

export default function DatePickers({ palette }: Theme): ThemeOptions['components'] {
  const timePickerStyles: CSSInterpolation = {
    '&.XcTimePicker': {
      '.MuiPickersToolbar-content': {
        alignItems: 'center',
      },

      '.MuiTimePickerToolbar-hourMinuteLabel': {
        height: 64,

        '& > .MuiTypography-root': {
          height: '36px',
        },
        '.MuiButton-root': {
          height: '100%',
          width: 80,
          background: palette.neutral[200],
          color: palette.neutral.black,

          '&:has(.Mui-selected)': {
            background: palette.primary.light,
          },
        },

        '.MuiTypography-root': {
          fontSize: 48,

          '&.Mui-selected': {
            color: palette.primary.main,
          },
        },
      },

      '.MuiTimePickerToolbar-ampmSelection': {
        border: `solid 1px ${palette.neutral[200]}`,
        borderRadius: 4,

        '.MuiButton-root': {
          padding: 4,
          borderRadius: 0,

          '&:has(.MuiTimePickerToolbar-ampmLabel.Mui-selected)': {
            background: palette.primary.light,
          },
        },

        '.MuiTimePickerToolbar-ampmLabel': {
          fontSize: pxToRem(14),

          '&.Mui-selected': {
            color: palette.primary.main,
          },
        },
      },
    },
  };

  return {
    MuiPickersDay: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: palette.neutral.white,
            backgroundColor: palette.primary.main,
          },
          '&:focus.Mui-selected': {
            color: palette.neutral.white,
            backgroundColor: palette.primary.main,
          },
        },
      },
    },
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          '.MuiPickersArrowSwitcher-root': {
            '& + div .MuiButtonBase-root': {
              color: palette.primary.main,
            },
          },
          '.MuiPickersArrowSwitcher-button': {
            fontSize: 18,
            color: palette.primary.main,
            borderRadius: 3,
            border: `solid 1px ${palette.primary.main}`,
            padding: 0,
          },
        },
      },
    },
    MuiYearPicker: {
      styleOverrides: {
        root: {
          '.PrivatePickersYear-yearButton.Mui-selected': {
            color: palette.neutral.white,
          },
        },
      },
    },
    MuiClockPicker: {
      styleOverrides: {
        root: {
          'span.Mui-selected': {
            color: palette.neutral.white,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: timePickerStyles,
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: timePickerStyles,
      },
    },
    MuiPickerStaticWrapper: {
      styleOverrides: {
        content: timePickerStyles,
      },
    },
  };
}
