import { Button, Paper, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ToastManagerContext } from '../../components/ToastManager';
import { get, deleteService, deleteData } from '../../redux/modules/token';

// redux
import { AppDispatch } from '../../redux/store';
import { useNavigate } from 'react-router-dom';

import EmptyState from '../../components/EmptyState';
import Breadcrumbs from '../../components/Breadcrumbs';
import Container from '../../components/Container';
// import ListToken from './component';
import GenerateToken from './component/modal';
import moment from 'moment';

import DashboardHeader from '../../components/DashboardHeader';
import { PlusCrfr, Pencil, TrashCan, BankCards, Eye, EyeOff } from '@xcidic/icons';
import Table from '../../components/Table';

import Confirm from '../../components/Confirm';
import { WidgetFooter } from '../../components/Widget';
import Pagination from '../../components/TablePagination';

import EditToken from './component/edit';

interface ObjectPropsData {
  description: string;
  numberOfRequests: number;
  lastAccessedOn: number;
  value: string;
  expiresOn: number;
  id: string;
}

interface meta {
  total: number;
}

export default function index() {
  const { create } = useContext(ToastManagerContext);
  const navigate = useNavigate();

  const [rerender, setRerender] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const [list, setList] = useState<ObjectPropsData[]>();
  const [isOpen, setIsOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  // eslint-disable-next-line
  const [id, setId] = useState('');
  // eslint-disable-next-line
  const [description, setDescription] = useState('');
  const [show, setShow] = useState<string[]>([]);

  // const navigate = useNavigate();
  const [isConfirm, setIsConfirm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [totalData, setTotal] = useState<meta>({ total: 1 });
  // const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
  //   setPage(value);
  // };
  useEffect(() => {
    updateToken();
  }, [page, limit]);

  const updateToken = () => {
    dispatch(get({ limit, offset: page === 1 ? 0 : page === 2 ? limit : limit * page }))
      .unwrap()
      .then((res) => {
        // create('success', 'Success', res.listToken.message);

        setList(res.listToken?.data);
        setTotal(res.listToken.meta);
      })
      .catch((err) => {
        // console.log(err)
        create('error', 'Error', err);
      });
  };

  const deleteData = (id: string) => {
    const data: deleteData = {
      id,
    };
    dispatch(deleteService(data))
      .unwrap()
      .then((res) => {
        // console.log(res)
        create('success', 'Success', res.responseApi.message);
        updateToken();
      })
      .catch((err) => {
        create('success', 'Error', err);

        // console.log(err)
      });
  };

  return (
    <Container>
      <Breadcrumbs
        items={[
          {
            label: 'Home',
            link: '/',
          },
          {
            label: 'Token',
            link: '/token',
          },
        ]}
      />
      <DashboardHeader title="Token">
        <Button
          id="create-token"
          variant="contained"
          startIcon={<PlusCrfr />}
          onClick={() => setIsOpen(true)}>
          Add Token
        </Button>
      </DashboardHeader>
      <Paper>
        {list?.length === 0 && <EmptyState module="Token" button="Add Token" />}
        {/* {list?.map((val, index) => (
          // eslint-disable-next-line
          <ListToken
            title={val.description}
            expiredOn={val.expiresOn}
            lastUsed={val.lastAccessedOn}
            token={val.value}
            index={index}
            id={val.id}
            updateToken={updateToken}
          />
        ))} */}
        {list?.length !== 0 && (
          <Table
            data={list ?? []}
            config={[
              {
                label: 'Name',
                key: 'description',
                render: (row) => {
                  return (
                    <Typography
                      sx={{ cursor: 'pointer' }}
                      onClick={() => navigate(`/token/${row.id}`)}>
                      {row.description}
                    </Typography>
                  );
                },
              },
              {
                label: 'Expired On',
                key: 'expiresOn',
                render: (row) => {
                  return row.expiresOn > 0 ? (
                    <Typography
                      sx={{ cursor: 'pointer' }}
                      onClick={() => navigate(`/token/${row.id}`)}>
                      Expired on {moment(row.expiresOn * 1000).format('ddd, MMM DD YYYY')}
                    </Typography>
                  ) : (
                    <Typography
                      sx={{ cursor: 'pointer' }}
                      onClick={() => navigate(`/token/${row.id}`)}>
                      -
                    </Typography>
                  );
                },
              },
              {
                label: 'Last Used',
                key: 'lastAccessedOn',
                render: (row) => {
                  return (
                    <Typography
                      sx={{ cursor: 'pointer' }}
                      onClick={() => navigate(`/token/${row.id}`)}>
                      {row.lastAccessedOn === null
                        ? 'Never Used'
                        : `Last used within the ${moment(row.lastAccessedOn * 1000).fromNow()}`}
                    </Typography>
                  );
                },
              },
              {
                label: 'Token',
                key: 'value',
                render: (row) => (
                  <Typography
                    sx={{ cursor: 'pointer' }}
                    onClick={() => navigate(`/token/${row.id}`)}>
                    {show.find((val) => val === row.id)
                      ? row.value
                      : row.value.split('').map(() => '*')}
                  </Typography>
                ),
                // {
                //   return show.find((val) => val === row.id) ? (
                //     <p>{row.value}</p>
                //   ) : (
                //     <p>{row.value.split('').map(() => '*')}</p>
                //   );
                // },
              },
            ]}
            actions={[
              {
                label: 'Edit',
                icon: <Pencil />,
                onAction: (row) => {
                  // navigate(`/otp/configuration/${row.id}`);
                  setId(row.id);
                  setDescription(row.description);
                  setIsEdit(true);
                },
              },
              {
                label: 'Delete',
                icon: <TrashCan />,
                onAction: (row) => {
                  setIsConfirm(true);
                  setId(row.id);
                },
              },
              {
                label: 'Show Token',
                icon: <Eye />,
                renderLabel: (row) => {
                  return show.find((val) => val === row.id) ? 'Hide Token' : 'Show Token';
                },
                render: (row) => {
                  return show.find((val) => val === row.id) ? <EyeOff /> : <Eye />;
                },
                onAction: async (row) => {
                  // console.log(show);
                  if (show.find((val) => val === row.id)) {
                    // console.log('delete');
                    const index = show.findIndex((val) => val === row.id);
                    const data = show;
                    data?.splice(index, 1);
                    await setShow(data);
                    setRerender(!rerender);
                  } else {
                    // console.log('add');
                    setRerender(!rerender);
                    const newArray = [...show, row.id];
                    setShow(newArray ?? []);
                  }
                },
              },
              {
                label: 'Copy Token',
                icon: <BankCards />,
                onAction: (row) => {
                  navigator.clipboard.writeText(row?.value);
                  create('success', 'Copy', 'Success Copy  Token');
                },
              },
            ]}
          />
        )}

        {/* ))} */}
        {list?.length !== 0 && (
          // <Grid container spacing={3} style={{ marginTop: 10 }}>
          // <Grid item lg={12} style={{ justifyContent: 'center', display: 'flex' }}>
          <WidgetFooter>
            <Pagination
              page={page}
              count={totalData?.total}
              rowsPerPage={limit}
              onPageChange={(_, newPage) => setPage(newPage)}
              onRowsPerPageChange={(e) => {
                setLimit(e.target.value as number);
                setPage(1);
              }}
            />
          </WidgetFooter>

          // </Grid>
          // </Grid>
        )}

        <GenerateToken
          open={isOpen}
          onClose={() => {
            setIsOpen(false);
            updateToken();
          }}
        />
        <EditToken
          open={isEdit}
          onClose={() => {
            setIsEdit(false);
            updateToken();
          }}
          id={id}
          description={description}
        />
        <Confirm
          type={'Delete'}
          open={isConfirm}
          handleAksi={() => {
            setIsConfirm(false);
            deleteData(id);
          }}
          loading={false}
          handleClose={() => setIsConfirm(false)}
          disable={false}
          handleContent={{
            title: 'Delete Token',
            message: 'Do you wish to Delete this Token?',
            button: 'Submit',
          }}
        />
      </Paper>
    </Container>
  );
}
