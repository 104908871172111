/**
 * Extract keys of object `T` with value of type `V`
 */
export type ExtractKeyOfValueType<T, V> = { [K in keyof T]: T[K] extends V ? K : never }[keyof T];

/**
 * return `true` if `key` is in `object` keys
 *
 * @param object
 * @param key
 * @returns
 */
export function isExistObjectKey<T extends {}>(
  obj: T,
  key: string | number | symbol
): key is keyof T {
  return Object.keys(obj).some((x) => x === key);
}
