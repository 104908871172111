import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { BASE_URL } from '../../utils/url';
import axios from '../../utils/request';
import { getUser } from '../../utils/localStorage';

export interface requestData {
  email: string;
  name: string;
}

export interface requestEditPassword {
  password: string;
  oldPassword: string;
}
interface errorRequest {
  message: String;
}

const initialState = {
  responseApi: {},
};

const updateUser = (email: string, name: string) => {
  const { token } = getUser();

  return axios.patch(
    `${BASE_URL}/account/my`,
    {
      email,
      name,
    },
    {
      headers: {
        Authorization: `Bearer ${token as string}`,
      },
    }
  );
};

export const updateService = createAsyncThunk(
  'module/updateUser',
  async ({ email, name }: requestData, thunkAPI) => {
    try {
      const data = await updateUser(email, name);
      thunkAPI.fulfillWithValue(data.data);
      return { responseApi: data?.data };
    } catch (error) {
      const err = error as AxiosError;
      return thunkAPI.rejectWithValue((err.response?.data as errorRequest).message);
    }
  }
);
const updatePassword = (password: string, oldPassword: string) => {
  const { token } = getUser();

  return axios.patch(
    `${BASE_URL}/account/my`,
    {
      password,
      oldPassword,
    },
    {
      headers: {
        Authorization: `Bearer ${token as string}`,
      },
    }
  );
};

export const updatePasswordService = createAsyncThunk(
  'module/updateUserPassword',
  async ({ password, oldPassword }: requestEditPassword, thunkAPI) => {
    try {
      const data = await updatePassword(password, oldPassword);
      thunkAPI.fulfillWithValue(data.data);
      return { responseApi: data?.data };
    } catch (error) {
      const err = error as AxiosError;
      return thunkAPI.rejectWithValue((err.response?.data as errorRequest).message);
    }
  }
);

const userUpdate = createSlice({
  name: 'userUpdate',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateService.fulfilled, (state, action) => {
      state.responseApi = action.payload.responseApi;
      // state.isLoggedIn = true;
      // state.user = action.payload.user;
    });
    builder.addCase(updateService.rejected, (state, action) => {
      state.responseApi = {
        statusCode: 404,
        message: 'Verification code is invalid or has expired',
        error: 'Not Found',
      };

      // state.isLoggedIn = false;
      // state.user = null;
    });
    builder.addCase(updatePasswordService.fulfilled, (state, action) => {
      state.responseApi = action.payload.responseApi;
      // state.isLoggedIn = true;
      // state.user = action.payload.user;
    });
    builder.addCase(updatePasswordService.rejected, (state, action) => {
      state.responseApi = {
        statusCode: 404,
        message: 'Verification code is invalid or has expired',
        error: 'Not Found',
      };

      // state.isLoggedIn = false;
      // state.user = null;
    });
  },
});

const { reducer } = userUpdate;
export default reducer;
