import { Avatar } from '@mui/material';
import { User } from '@xcidic/icons';
import { forwardRef } from 'react';
import { useSockets } from '../../contexts/SocketContextComponent';

import moment from 'moment';

import Container from '../../components/Container';

interface props {
  id: string;
  lastMessageId: string[];
  onScroll: Function;
}

function index({ id, lastMessageId, onScroll }: props, ref: any) {
  const { message, status } = useSockets();

  const capitalizeFirstLetter = (string: string) => {
    return string[0].toUpperCase() + string.slice(1);
  };
  const filterData = status?.filter((val) => lastMessageId?.includes(val.messageId));
  // const onScroll = (e: any) => {
  //   console.log(e);
  // };
  return (
    <Container>
      <div
        style={{ width: '100%', height: '80vh', overflowY: 'scroll', paddingRight: 10 }}
        onScroll={(e) => onScroll(e)}>
        {message
          ?.filter(
            (item, index, obj) => obj.findIndex((t) => t.messageId === item.messageId) === index

            // && item.status !== 'sent'
          )
          ?.map((val, count) => {
            const anotherFilter = filterData?.filter((value) => val.messageId === value.messageId);
            const filterMessage = message?.filter(
              (value, index, obj) =>
                value.textMessage !== null &&
                value.contactId === id &&
                obj.findIndex((t) => t.messageId === value.messageId) === index

              // && value.status !== 'sent'
            );

            const indexNow = filterMessage?.findIndex((value) => val.messageId === value.messageId);
            // console.log(indexNow);
            const showDate =
              count === 0
                ? true
                : //  count + 1 === message.length
                  // ? false
                  // :
                  moment(val.messageOn * 1000).format('MMM DD, YYYY') !==
                  moment(filterMessage[indexNow - 1]?.messageOn * 1000).format('MMM DD, YYYY');
            // console.log(val.messageOn);
            // console.log(
            //   moment(val.messageOn * 1000).format('MMM DD, YYYY'),
            //   moment(message[count - 1]?.messageOn * 1000).format('MMM DD, YYYY'),
            //   filterMessage
            // );
            return (
              val?.textMessage !== null &&
              (val?.contactId ?? '') === id && (
                <div>
                  {showDate && (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginBottom: '10px',
                      }}>
                      <div
                        style={{
                          backgroundColor: '#F6F8FC',
                          paddingLeft: 16,
                          paddingRight: 16,
                          paddingTop: 4,
                          paddingBottom: 4,
                          color: '#AFAFAF',
                        }}>
                        <p>
                          {moment(new Date()).format('MMM DD, YYYY') ===
                          moment(val.messageOn * 1000).format('MMM DD, YYYY')
                            ? 'Today'
                            : moment(val.messageOn * 1000).format('MMM DD, YYYY')}
                        </p>
                      </div>
                    </div>
                  )}

                  {val?.isIncoming ? (
                    <div
                      style={{
                        marginTop: 10,
                        display: 'flex',
                        justifyContent: 'start',
                      }}>
                      <Avatar style={{ backgroundColor: '#E3E6EC', marginRight: 12 }}>
                        <User style={{ color: '#000' }} />
                      </Avatar>
                      <div style={{ display: 'inline-block' }}>
                        <div
                          style={{
                            backgroundColor: '#F6F8FC',
                            padding: 16,
                            maxWidth: '256px',
                            width: 'fit-content',
                            wordWrap: 'break-word',
                            // color: '#FFF',
                          }}>
                          <div>
                            <p>{val?.textMessage} </p>
                          </div>
                        </div>
                        <p style={{ color: '#AFAFAF', fontSize: 10, marginTop: 8 }}>
                          {' '}
                          {new Date(val.messageOn * 1000)
                            .toLocaleTimeString('en-US', {
                              hour: 'numeric',
                              hour12: true,
                              minute: 'numeric',
                            })
                            ?.replace('AM', 'am')
                            ?.replace('PM', 'pm')}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        marginTop: 10,

                        display: 'flex',
                        justifyContent: 'end',
                      }}>
                      <div>
                        <div
                          style={{
                            backgroundColor: '#276EF1',
                            color: '#FFF',
                            padding: 16,
                            maxWidth: '256px',
                            width: 'fit-content',
                            wordWrap: 'break-word',
                          }}>
                          <div>
                            <p>{val?.textMessage}</p>
                          </div>
                        </div>
                        <p
                          style={{
                            color: '#AFAFAF',
                            fontSize: 10,
                            textAlign: 'end',
                            marginTop: 8,
                          }}>
                          {new Date(val.messageOn * 1000)
                            .toLocaleTimeString('en-US', {
                              hour: 'numeric',
                              hour12: true,
                              minute: 'numeric',
                            })
                            ?.replace('AM', 'am')
                            ?.replace('PM', 'pm')}
                          <span style={{ color: '#AFAFAF', fontSize: 12 }}>
                            {` ${capitalizeFirstLetter(
                              anotherFilter[anotherFilter.length - 1]?.status ?? val?.status
                            )}`}
                          </span>
                        </p>
                      </div>

                      <Avatar style={{ marginLeft: 12 }} src={'/robotUser.svg'} alt="User" />
                    </div>
                  )}
                </div>
              )
            );
          })}
        <div ref={ref} />
      </div>
    </Container>
  );
}
export default forwardRef(index);
