import { Box, Button, Grid, Typography } from '@mui/material';

interface props {
  isBefore: false;
}

const NotifWallet = ({ isBefore }: props) => {
  return (
    <Box
      style={{
        backgroundColor: '#1A77E5',
        color: '#FFF',
        borderRadius: 12,
        paddingLeft: '24px',
      }}>
      <Grid container spacing={3}>
        <Grid item>
          <Box component="img" src="/wallet.svg" sx={{ width: 124 }} />
        </Grid>
        <Grid item xs={7}>
          <div>
            <Typography variant="h1">Please top up your balance</Typography>
            <Typography variant="p1">
              Your balance is predicted will not be enough to cover the usage for this month, please
              top-up your balance before the beginning of next month.
            </Typography>
          </div>
        </Grid>
        <Grid
          item
          xs={3}
          onClick={() => (window.location.href = '/billing')}
          style={{ alignItems: 'center', display: 'flex', justifyContent: 'flex-end' }}>
          <Button color="primary" style={{ backgroundColor: '#FFF', borderRadius: 12 }}>
            Top Up Balance
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
export default NotifWallet;
