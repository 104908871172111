import { Button, Paper } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Breadcrumbs from '../../components/Breadcrumbs';
import Container from '../../components/Container';
import Table from '../../components/Table';
import { ToastManagerContext } from '../../components/ToastManager';
import { get } from '../../redux/modules/otpTemplate';
import { AppDispatch } from '../../redux/store';
import GenerateTemplate from './component/modal';
import { Pencil, PlusCrfr } from '@xcidic/icons';
import { useNavigate } from 'react-router-dom';

import EmptyState from '../../components/EmptyState';
import DashboardHeader from '../../components/DashboardHeader';
import { WidgetFooter } from '../../components/Widget';
import Pagination from '../../components/TablePagination';
interface ObjectPropsData {
  id: string;
  name: String;
  content: string;
  isActive: Boolean;
}
interface meta {
  total: number;
}

export default function index() {
  const { create } = useContext(ToastManagerContext);
  const dispatch = useDispatch<AppDispatch>();
  const [list, setList] = useState<ObjectPropsData[]>();
  const [isOpen, setIsOpen] = useState(false);
  const [totalData, setTotal] = useState<meta>({ total: 1 });
  const navigate = useNavigate();

  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  useEffect(() => {
    refreshTemplate();
  }, [page]);
  const refreshTemplate = () => {
    dispatch(get({ limit, offset: page === 1 ? 0 : page === 2 ? limit : limit * page }))
      .unwrap()
      .then((res) => {
        // create('success', 'Success', res.listToken.message);
        setTotal(res.listToken.meta);

        setList(res.listToken?.data);
      })
      .catch((err) => {
        // console.log(err)
        create('error', 'Error', err);
      });
  };

  return (
    <Container>
      <Breadcrumbs
        items={[
          {
            label: 'OTP',
            link: '/otp',
          },
          {
            label: 'Template',
            link: '/otp/template',
          },
        ]}
      />
      <DashboardHeader title="Otp Template">
        <Button
          id="otp-add-template"
          variant="contained"
          startIcon={<PlusCrfr />}
          onClick={() => navigate('/otp/template/create')}>
          Add Template
        </Button>
      </DashboardHeader>
      <Paper>
        {/* <Grid container spacing={3} style={{ marginBottom: 10 }}>
          <Grid item lg={12} style={{ justifyContent: 'flex-end', display: 'flex' }}>
            <Button
              name="company"
              variant="contained"
              color="primary"
              onClick={() => setIsOpen(true)}>
             
            </Button>
          </Grid>
        </Grid> */}
        {list?.length === 0 && <EmptyState module="Template" button="Add Template" />}
        {list?.length !== 0 && (
          <Table
            // idHref="id"
            // name="otp/template"
            data={list ?? []}
            config={[
              {
                label: 'Id',
                key: 'id',
                render: (row) => {
                  return (
                    <p
                      style={{ cursor: 'pointer' }}
                      onClick={() => navigate(`/otp/template/view/${row.id}`)}>
                      {row.id}
                    </p>
                  );
                },
              },
              {
                label: 'Name',
                key: 'name',
                render: (row) => {
                  return (
                    <p
                      style={{ cursor: 'pointer' }}
                      onClick={() => navigate(`/otp/template/view/${row.id}`)}>
                      {row.name}
                    </p>
                  );
                },
              },
              // ,
              // {
              //   label: 'Created On',
              //   key: 'createdOn'
              // }
            ]}
            actions={[
              {
                label: 'Edit',
                icon: <Pencil />,
                onAction: (row) => {
                  navigate(`/otp/template/edit/${row.id}`);
                },
              },
            ]}
          />
        )}

        {list?.length !== 0 && (
          <WidgetFooter>
            <Pagination
              page={page}
              count={totalData?.total}
              rowsPerPage={limit}
              onPageChange={(_, newPage) => setPage(newPage)}
              onRowsPerPageChange={(e) => {
                setLimit(e.target.value as number);
                setPage(1);
              }}
            />
          </WidgetFooter>
        )}

        <GenerateTemplate
          open={isOpen}
          onClose={() => {
            setIsOpen(false);
            refreshTemplate();
          }}
        />
      </Paper>
    </Container>
  );
}
