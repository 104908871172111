import {
  Button,
  CircularProgress,
  // Dialog,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { MinusCrfr, Plus } from '@xcidic/icons';
import { useFormik } from 'formik';
import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import Confirm from '../../../components/Confirm';
import { initialValues, validationSchema } from './schemeValidation';
import { ToastManagerContext } from '../../../components/ToastManager';
import { createService, requestData } from '../../../redux/modules/otpTemplate';
import { AppDispatch } from '../../../redux/store';
import Dialog from '../../../components/Dialog';

// import { date } from 'yup/lib/locale';
// type CloseReason = 'backdropClick' | 'escapeKeyDown' | 'closeButtonClick';
interface PropsModal {
  open: boolean;
  // onClose: (reason: CloseReason) => void;
  onClose: Function;
}

export default function index({ open, onClose }: PropsModal) {
  const { create } = useContext(ToastManagerContext);
  const [isLoading, setIsLoading] = useState(false);
  const [content, setContent] = useState('');
  const [errorContent, setErrorContent] = useState('');
  const [isConfirm, setIsConfirm] = useState(false);

  const dispatch = useDispatch<AppDispatch>();

  const {
    handleChange,
    handleBlur,
    // setSubmitting,
    handleSubmit,
    setFieldValue,
    dirty,
    resetForm,
    values,
    isValid,
    touched,
    errors,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async () => {
      setIsLoading(true);
      try {
        // const parameters =
        const contentJson = {
          name: values.name,
          language: {
            code: values.languageCode,
          },
          components: [
            {
              type: 'body',
              parameters: values?.component?.map((val) => {
                return {
                  type: 'text',
                  text: val,
                };
              }),
            },
          ],
        };

        const data: requestData = {
          name: values.name,
          content: JSON.stringify(contentJson),
        };
        dispatch(createService(data))
          .unwrap()
          .then((res) => {
            setIsLoading(false);

            create('success', 'Success', res.responseApi.message);
            resetForm();
            onClose();
          })
          .catch((err) => {
            setIsLoading(false);

            create('error', 'Error', err);
          });
      } catch (error) {
        // handle any rejections/errors
      }
    },
  });

  const handleAdd = () => {
    // eslint-disable-next-line
    setFieldValue('component', [...values?.component, content]);
    setContent('');
    setErrorContent('');
  };

  const handleDelete = (index: number) => {
    const data = values.component;
    data?.splice(index, 1);
    setFieldValue('component', data);
  };

  const checkSubmit = () => {
    if (
      !values?.component?.find((val) => val === '[[otp]]') ??
      !values?.component?.find((val) => val === '[[product]]')
    ) {
      setErrorContent('Must Have [[otp]] and [[product]]');
      return;
    }
    setIsConfirm(true);
  };

  return (
    <Dialog open={open} onClose={() => onClose()}>
      <Typography variant="h2" color="neutral.black" fontWeight="700" mb={1}>
        Add Template
      </Typography>
      <Grid container spacing={3}>
        <Grid item lg={12}>
          <TextField
            fullWidth
            // autoFocus
            name="name"
            variant="outlined"
            // type="email"
            label="Template Name *"
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
            className="mt-0 mb-4"
            size="medium"
          />
        </Grid>
        <Grid item lg={12}>
          <InputLabel>Language Code</InputLabel>
          <Select
            fullWidth
            value={values.languageCode}
            onChange={(e) => setFieldValue('languageCode', e.target.value as string)}>
            <MenuItem value={'en'}>English</MenuItem>
            <MenuItem value={'id'}>Indonesian</MenuItem>
          </Select>
        </Grid>
        {values?.component?.map((val, index) => (
          <Grid item lg={12} key={index}>
            <Grid container spacing={2}>
              <Grid item lg={8}>
                <TextField
                  fullWidth
                  // autoFocus
                  name="Content"
                  variant="outlined"
                  // type="email"
                  label="Content *"
                  // error={Boolean(touched.name && errors.name)}
                  // helperText={touched.name && errors.name}
                  value={val}
                  disabled
                  //  onChange={(e) => setContent(e.target.value)}
                  // onBlur={handleBlur}
                  autoComplete="off"
                  className="mt-0 mb-4"
                  size="medium"
                />
              </Grid>

              <Grid item lg={4}>
                <Button
                  variant="contained"
                  color="primary"
                  // disabled={content === ""}
                  onClick={() => handleDelete(index)}>
                  <MinusCrfr />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ))}

        <Grid item lg={12}>
          <Grid container spacing={2}>
            <Grid item lg={10}>
              <TextField
                fullWidth
                // autoFocus
                name="Content"
                variant="outlined"
                // type="email"
                label="Content *"
                // error={Boolean(touched.name && errors.name)}
                // helperText={touched.name && errors.name}
                value={content}
                onChange={(e) => setContent(e.target.value)}
                // onBlur={handleBlur}
                autoComplete="off"
                className="mt-0 mb-4"
                size="medium"
              />
            </Grid>

            <Grid item lg={2}>
              <Button
                variant="contained"
                color="primary"
                disabled={content === ''}
                onClick={handleAdd}>
                <Plus />
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid item lg={12}>
          <TextField
            fullWidth
            // autoFocus
            name="content"
            variant="outlined"
            // type="email"
            label="Content *"
            multiline
            error={Boolean(touched.content && errors.content)}
            helperText={touched.content && errors.content}
            value={values.content}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
            className="mt-0 mb-4"
            size="medium"
          />
          <p>{`Use {{otp}} for Genrate OTP, Use {{company}} for Your Company Name`}</p>
        </Grid> */}
        <Grid item lg={12}>
          <p style={{ color: 'red' }}>{errorContent}</p>
        </Grid>

        <Grid item lg={12}>
          <Button
            name="company"
            variant="contained"
            color="primary"
            fullWidth
            disabled={!(dirty && isValid)}
            onClick={() => checkSubmit()}>
            {isLoading ? (
              <CircularProgress style={{ color: 'white' }} size={30} />
            ) : (
              <span className="loginButtonCaption"> Create Template</span>
            )}
          </Button>
          <Confirm
            open={isConfirm}
            type={'Create'}
            handleAksi={() => {
              setIsConfirm(false);
              handleSubmit();
            }}
            loading={false}
            handleClose={() => setIsConfirm(false)}
            disable={false}
            handleContent={{
              title: 'Create Template',
              message: 'Do you wish to Create this Template?',
              button: 'Submit',
            }}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
}
