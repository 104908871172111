import { Theme, ThemeOptions } from '@mui/material';
import { pxToRem } from '../typography';

export default function Form(theme: Theme): ThemeOptions['components'] {
  return {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: 500,
          marginBottom: 4,
          color: theme.palette.neutral.black,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: pxToRem(14),
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          marginLeft: 8,
          marginRight: 8,
          fontWeight: 'bold',
        },
      },
    },
  };
}
