import {
  Autocomplete,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { useContext, useState, useEffect } from 'react';
import 'react-phone-input-2/lib/material.css';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import Breadcrumbs from '../../components/Breadcrumbs';
import Confirm from '../../components/Confirm';
import Container from '../../components/Container';
import DashboardHeader from '../../components/DashboardHeader';
import { ToastManagerContext } from '../../components/ToastManager';
import { editService, requestDataEdit, getServiceById } from '../../redux/modules/otpTemplate';
import { AppDispatch } from '../../redux/store';
import {
  editInitialValues as initialValues,
  editValidationSchema as validationSchema,
} from './component/schemeValidation';
import Code from '../../components/Code';
import { BankCards } from '@xcidic/icons';
import { WidgetHeader } from '../../components/Widget';

interface parametersContent {
  type: String;
  text: String;
}
interface componentContent {
  type: String;
  parameters: parametersContent[];
}
interface jsonDataRequest {
  name: String;
  language: {
    code: String;
  };
  components: componentContent[];
}
export default function index() {
  const { create } = useContext(ToastManagerContext);
  const [isConfirm, setIsConfirm] = useState(false);
  const { id } = useParams();
  const [errorContent, setErrorContent] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const {
    handleChange,
    handleBlur,
    // setSubmitting,
    handleSubmit,
    setFieldValue,
    dirty,
    // resetForm,
    values,
    isValid,
    touched,
    errors,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async () => {
      setIsLoading(true);
      try {
        // const parameters =
        const contentJson = {
          name: values.name,
          language: {
            code: values.languageCode,
          },
          components: [
            {
              type: 'body',
              parameters: values?.component?.map((val) => {
                return {
                  type: 'text',
                  text: val,
                };
              }),
            },
          ],
        };

        const data: requestDataEdit = {
          id: values.id,
          name: values.name,
          content: JSON.stringify(contentJson),
        };
        dispatch(editService(data))
          .unwrap()
          .then((res) => {
            setIsLoading(false);

            create('success', 'Success', res.responseApi.message);
            navigate('/otp/template');
          })
          .catch((err) => {
            setIsLoading(false);

            create('error', 'Error', err);
          });
      } catch (error) {
        // handle any rejections/errors
      }
    },
  });
  const isJsonString = (str: string) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };
  useEffect(() => {
    dispatch(getServiceById({ id: id ?? '' }))
      .unwrap()
      .then((res) => {
        // create('success', 'Success', res.responseApi.message);
        setFieldValue('id', id ?? '');
        setFieldValue('name', res.responseApi.data.name);
        const jsonData: jsonDataRequest =
          isJsonString(res.responseApi.data.content) &&
          JSON.parse(
            res.responseApi.data.content ??
              `{
      "name":"verification",
      "language":{
         "code":"en"
      },
      "components":[
         {
            "type":"body",
            "parameters":[
               {
                  "type":"text",
                  "text":"[[otp]]"
               },
               {
                  "type":"text",
                  "text":"[[product]]"
               },
               {
                  "type":"text",
                  "text":"Hello World"
               }
            ]
         }
      ]
   }`
          );
        console.log(res);
        if (isJsonString(res.responseApi.data.content)) {
          setFieldValue('languageCode', jsonData?.language?.code);

          const component = jsonData?.components[0]?.parameters?.map((val: any) => {
            return val?.text;
          });

          setFieldValue('component', component);
        } else {
          setFieldValue('languageCode', 'en');
          setFieldValue('component', []);
        }
      })
      .catch((err) => {
        // console.log(err)
        create('error', 'Error', err);
      });
  }, []);
  const checkSubmit = () => {
    if (
      !values?.component?.find((val) => val === '[[otp]]') ??
      !values?.component?.find((val) => val === '[[product]]')
    ) {
      setErrorContent('Must Have [[otp]] and [[product]]');
      return;
    }
    setIsConfirm(true);
  };
  const jsonData = {
    name: values.name,
    language: {
      code: values.languageCode,
    },
    components: [
      {
        type: 'body',
        parameters: values?.component?.map((val) => {
          return {
            type: 'text',
            text: val,
          };
        }),
      },
    ],
  };
  return (
    <Container>
      <Breadcrumbs
        items={[
          {
            label: 'OTP',
            link: '/otp',
          },
          {
            label: 'Template',
            link: '/otp/template',
          },
          {
            label: 'Edit OTP Template',
            link: '/otp/template/create',
          },
        ]}
      />
      <DashboardHeader title="Edit Otp Template" />

      <Paper>
        <WidgetHeader title="Template Detail" />
        <Grid container spacing={3} style={{ marginTop: 20 }}>
          <Grid item lg={12}>
            <Grid container spacing={3}>
              <Grid item lg={2} style={{ display: 'flex', alignItems: 'center' }}>
                <Typography style={{ color: touched.name && errors.name ? '#e11900' : undefined }}>
                  Template Name
                </Typography>
              </Grid>
              <Grid item lg={10}>
                <TextField
                  fullWidth
                  // autoFocus
                  name="name"
                  variant="outlined"
                  // type="password"
                  // label="Template Name *"
                  // multiline
                  placeholder="Template Name"
                  // rows={4}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="off"
                  className="mt-0 mb-4"
                  size="medium"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={12}>
            <Grid container spacing={3}>
              <Grid item lg={2} style={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  style={{ color: touched.component && errors.component ? '#e11900' : undefined }}>
                  Add Variables
                </Typography>
              </Grid>
              <Grid item lg={10}>
                <Autocomplete
                  open={false}
                  multiple
                  onChange={(e, val) => {
                    setFieldValue('component', val !== null ? val : []);
                  }}
                  freeSolo
                  value={values.component}
                  id="free-solo-2-demo"
                  disableClearable
                  options={[]}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // label="Add Variable"
                      placeholder="Add more variables"
                      style={{ marginBottom: '10px' }}
                      InputProps={{
                        ...params.InputProps,
                        type: 'search',
                      }}
                    />
                  )}
                />
                <p style={{ color: 'red' }}>{errorContent}</p>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={12}>
            <Grid container spacing={3}>
              <Grid item lg={2} style={{ display: 'flex', alignItems: 'center' }}></Grid>
              <Grid item lg={10}>
                <Typography style={{ fontWeight: 'bold' }}>Variable Information</Typography>
                <ul style={{ marginLeft: 10, color: '#AFAFAF' }}>
                  <li>
                    Type in the default variables such as [[otp]] and [[product]], their positions
                    can be in any order
                  </li>
                  <li>You can add more variables and separated it by pressing enter</li>
                  <li>
                    {' '}
                    Currently, Axme only supports variables [[otp]] and [[product]] which can be
                    replaced with values from OTP Configuration. Additional parameters are plain
                    text like &quot;XYZ&quot; not [[xyz]].
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={12}>
            <Grid container spacing={3}>
              <Grid item lg={2} style={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  style={{
                    color: touched.languageCode && errors.languageCode ? '#e11900' : undefined,
                  }}>
                  Default Code Language
                </Typography>
              </Grid>
              <Grid item lg={10}>
                <Select
                  fullWidth
                  value={values.languageCode}
                  onChange={(e) => setFieldValue('languageCode', e.target.value as string)}>
                  <MenuItem value={'en'}>English</MenuItem>
                  <MenuItem value={'id'}>Indonesian</MenuItem>
                </Select>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={12}>
            <Grid container spacing={3}>
              <Grid item lg={2} style={{ display: 'flex', alignItems: 'center' }}>
                <Typography>Preview</Typography>
              </Grid>
              <Grid item lg={10}>
                <Code code={JSON.stringify(jsonData, null, 2)} language="js" />
                <Button
                  // style={{ marginLeft: 10 }}
                  name="copy"
                  variant="outlined"
                  color="primary"
                  // fullWidth
                  startIcon={<BankCards />}
                  onClick={() => {
                    navigator.clipboard.writeText(JSON.stringify(jsonData, null, 2));
                    create('success', 'Copy', 'Success  Webhook Unique Params');
                  }}
                  // disabled={!(dirty && isValid)}
                  // onClick={() => setIsConfirm(true)}
                >
                  Copy Code
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={12} style={{ justifyContent: 'flex-end', display: 'flex' }}>
            <Button
              name="company"
              variant="outlined"
              color="primary"
              style={{ marginRight: 10 }}
              onClick={() => navigate('/otp/template')}
              // disabled={!(dirty && isValid)}
            >
              Cancel
            </Button>
            <Button
              name="company"
              variant="contained"
              color="primary"
              disabled={!(dirty && isValid)}
              onClick={() => checkSubmit()}>
              {isLoading ? (
                <CircularProgress style={{ color: 'white' }} size={30} />
              ) : (
                <span className="loginButtonCaption"> Generate Template</span>
              )}
            </Button>
            <Confirm
              open={isConfirm}
              type={'Create'}
              handleAksi={() => {
                setIsConfirm(false);
                handleSubmit();
              }}
              loading={false}
              handleClose={() => setIsConfirm(false)}
              disable={false}
              handleContent={{
                title: 'Create Template',
                message: 'Do you wish to Create this Template?',
                button: 'Submit',
              }}
            />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
