import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { BASE_URL } from '../../utils/url';
import axios from '../../utils/request';

export interface requestData {
  id: string;
}

interface errorRequest {
  message: string;
}

const initialState = {
  responseApi: {
    statusCode: 404,
    message: 'Verification code is invalid or has expired',
    error: 'Not Found',
  },
};

const verifyService = (id: string) => {
  return axios.get(`${BASE_URL}/account/verify-email/${id}`);
};

export const verify = createAsyncThunk(
  'module/verifyEmail',
  async ({ id }: requestData, thunkAPI) => {
    try {
      const data = await verifyService(id);
      thunkAPI.fulfillWithValue(data.data);
      return { responseApi: data?.data };
    } catch (error) {
      const err = error as AxiosError;
      return thunkAPI.rejectWithValue((err.response?.data as errorRequest).message);
    }
  }
);

const verifyEmailSlice = createSlice({
  name: 'verifyEmail',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(verify.fulfilled, (state, action) => {
      state.responseApi = action.payload.responseApi;
      // state.isLoggedIn = true;
      // state.user = action.payload.user;
    });
    builder.addCase(verify.rejected, (state, action) => {
      state.responseApi = {
        statusCode: 404,
        message: 'Verification code is invalid or has expired',
        error: 'Not Found',
      };

      // state.isLoggedIn = false;
      // state.user = null;
    });
  },
});

const { reducer } = verifyEmailSlice;
export default reducer;
