import React from 'react';
// material
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
// components
import TextField from '../TextField';
import type { BaseDateTimePickerProps } from './DateTimePicker';

const DatePicker = ({
  label,
  onChange,
  textFieldProps,
  value = new Date(),
}: BaseDateTimePickerProps) => {
  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <MuiDatePicker
        label={label}
        inputFormat="MMM dd, yyyy"
        mask="___ __, ____"
        value={value}
        disablePast
        renderInput={(params) => <TextField {...params} {...textFieldProps} />}
        onChange={(newValue) => onChange?.(newValue)}
        showDaysOutsideCurrentMonth
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
