import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Container from '../../components/Container';

import Chart from './component/Chart';
// import { getService } from '../../redux/modules/statistic';
import { AppDispatch } from '../../redux/store';
// import ReactApexChart from 'react-apexcharts';
import MessageSent from './component/MessageSent';
import Widget, { WidgetContent, WidgetHeader } from '../../components/Widget';
import TableFilter from '../../components/Table/TableFilter';
import UsersTable from './component/UserTable';
import RareRead from './component/RareRead';
import { getUsageStatus } from '../../redux/modules/blastMessage';
import CostOverview from './component/CostOverview';
import AverageMessagesRead from './component/AverageMessagesRead';
import Usage from './component/Usage';
import Message from './component/Message';
import NotifWallet from '../../components/NotifWallet';
// import { Line2Up, Line2Down } from '@xcidic/icons';

// interface ObjectPropsData {
//   id: string;
//   waContactId: string;
//   otpRequestId: string;
//   messageBlastId: string;
//   status: string;
// }
interface usageStatus {
  restriction: boolean;
}
function index() {
  // eslint-disable-next-line
  const dispatch = useDispatch<AppDispatch>();

  const [range, setRange] = useState('week');
  const [rangeOverview, setRangeOverview] = useState('week');
  const [usage, setRangeUsage] = useState('week');
  const [average, setRangeAverage] = useState('week');
  const [statusFilter, setStatusFilter] = useState('week');
  const [restriction, setIsRestriction] = useState<usageStatus>({ restriction: false });

  useEffect(() => {
    dispatch(getUsageStatus())
      .unwrap()
      .then((res) => {
        setIsRestriction(res.responseApi);
        // console.log(res.responseApi);
        // create('success', 'Success', res.list.message)
      })
      .catch(() => {
        // create('error', 'Error', err);
      });
  }, []);
  // console.log(process.env);
  return (
    <Container>
      <Grid container spacing={2}>
        {restriction?.restriction && (
          <Grid item xs={12}>
            <div style={{ marginBottom: 16 }}>
              <NotifWallet isBefore={false} />
            </div>
          </Grid>
        )}
        <Grid item xs={3}>
          <Widget>
            <Chart url="status" title="Delivered" type="status=delivered" typeChart="line" />
          </Widget>
        </Grid>
        <Grid item xs={3}>
          <Widget>
            <Chart url="status" title="Opened" type="status=read" typeChart="line" />
          </Widget>
        </Grid>
        <Grid item xs={3}>
          <Widget>
            <Chart
              url="type-message"
              title="Regular Messages Sent"
              type="type=user"
              typeChart="line"
            />
          </Widget>
        </Grid>
        <Grid item xs={3}>
          <Widget>
            <Chart
              url="type-message"
              title="Shared Messages Sent"
              type="type=system"
              typeChart="line"
            />
          </Widget>
        </Grid>
        <Grid item xs={8}>
          <Widget style={{ height: '100%' }}>
            <WidgetHeader title="Messages Sent">
              <TableFilter
                label="Status"
                value={range}
                options={[
                  { label: 'This Week', value: 'week' },
                  { label: 'This Month', value: 'month' },
                ]}
                onChange={(val) => setRange(val)}
              />
            </WidgetHeader>
            <WidgetContent>
              <MessageSent url="sent-message" type={`range=${range}`} typeChart="bar" />
            </WidgetContent>
          </Widget>
        </Grid>
        <Grid item xs={4}>
          <Widget style={{ height: '100%' }}>
            <WidgetContent>
              <Message />
            </WidgetContent>
          </Widget>
        </Grid>
        <Grid item xs={4}>
          <Widget>
            <WidgetHeader title="Usage">
              <TableFilter
                label="Status"
                value={usage}
                options={[
                  { label: 'This Week', value: 'week' },
                  { label: 'This Month', value: 'month' },
                ]}
                onChange={(val) => setRangeUsage(val)}
              />
            </WidgetHeader>
            <WidgetContent>
              <Usage range={usage} />
            </WidgetContent>
          </Widget>
        </Grid>
        <Grid item xs={8}>
          <Widget style={{ height: '100%' }}>
            <WidgetHeader title="Top 5 Account that Rarely Read Blast Message">
              <TableFilter
                label="Status"
                value={statusFilter}
                options={[
                  { label: 'This Week', value: 'week' },
                  { label: 'This Month', value: 'month' },
                ]}
                onChange={(val) => setStatusFilter(val)}
              />
            </WidgetHeader>
            <WidgetContent>
              <RareRead statusFilter={statusFilter} />
            </WidgetContent>
          </Widget>
        </Grid>
        <Grid item xs={12}>
          <Widget>
            <WidgetHeader title="Average Messages Read">
              <TableFilter
                label="Status"
                value={average}
                options={[
                  { label: 'This Week', value: 'week' },
                  { label: 'This Month', value: 'month' },
                ]}
                onChange={(val) => setRangeAverage(val)}
              />
            </WidgetHeader>
            <WidgetContent>
              <AverageMessagesRead range={average} />
            </WidgetContent>
          </Widget>
        </Grid>
        <Grid item xs={12}>
          <Widget>
            <WidgetHeader title="Cost Overview">
              <TableFilter
                label="Status"
                value={rangeOverview}
                options={[
                  { label: 'This Week', value: 'week' },
                  { label: 'This Month', value: 'month' },
                ]}
                onChange={(val) => setRangeOverview(val)}
              />
            </WidgetHeader>
            <WidgetContent>
              <CostOverview range={rangeOverview} />
            </WidgetContent>
          </Widget>
        </Grid>
        <Grid item xs={12}>
          <Widget>
            <WidgetHeader title="Activity Feed" />
            <WidgetContent>
              <UsersTable />
            </WidgetContent>
          </Widget>
        </Grid>
      </Grid>
    </Container>
  );
}

export default index;
