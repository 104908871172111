import { Button, CircularProgress, Grid, Paper, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  editInitialValues as initialValues,
  editValidationSchema as validationSchema,
} from './schemeValidation';
import Breadcrumbs from '../../components/Breadcrumbs';
import DashboardHeader from '../../components/DashboardHeader';
import Confirm from '../../components/Confirm';
import Container from '../../components/Container';
import { ToastManagerContext } from '../../components/ToastManager';
import { updatePasswordService, requestEditPassword } from '../../redux/modules/user';
import { AppDispatch } from '../../redux/store';
import { destroyUser } from '../../utils/localStorage';

export default function index() {
  const { create } = useContext(ToastManagerContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);

  const dispatch = useDispatch<AppDispatch>();
  const {
    handleChange,
    handleBlur,
    // setSubmitting,
    handleSubmit,
    // setFieldValue,
    dirty,
    // resetForm,
    values,
    isValid,
    touched,
    errors,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async () => {
      setIsLoading(true);
      try {
        const data: requestEditPassword = {
          password: values.password,
          oldPassword: values.oldPassword,
        };
        dispatch(updatePasswordService(data))
          .unwrap()
          .then((res) => {
            setIsLoading(false);
            create('success', 'Success', res.responseApi.message);
            destroyUser();
            window.location.href = '/signin';
          })
          .catch((err) => {
            setIsLoading(false);

            create('error', 'Error', err);
          });
      } catch (error) {
        // handle any rejections/errors
      }
    },
  });
  return (
    <Container>
      <Breadcrumbs
        items={[
          {
            label: 'Business Info',
            link: '/company',
          },
          {
            label: 'User',
            link: '/user',
          },
          {
            label: 'Edit User Password',
            link: '/user/edit',
          },
        ]}
      />
      <DashboardHeader title="Edit User Password" />

      <Paper>
        <Grid container spacing={3}>
          <Grid item lg={12}>
            <TextField
              fullWidth
              // autoFocus
              name="password"
              variant="outlined"
              label="New Password"
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              autoComplete="off"
              className="mt-0 mb-4"
              size="medium"
            />
          </Grid>
          <Grid item lg={12}>
            <TextField
              fullWidth
              // autoFocus
              name="oldPassword"
              variant="outlined"
              label="Old Password"
              error={Boolean(touched.oldPassword && errors.oldPassword)}
              helperText={touched.oldPassword && errors.oldPassword}
              value={values.oldPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              autoComplete="off"
              className="mt-0 mb-4"
              size="medium"
            />
          </Grid>
          <Grid item lg={12} style={{ justifyContent: 'flex-end', display: 'flex' }}>
            <Button
              name="company"
              variant="contained"
              color="primary"
              onClick={() => setIsConfirm(true)}
              disabled={!(dirty && isValid)}>
              {isLoading ? (
                <CircularProgress style={{ color: 'white' }} size={30} />
              ) : (
                <span className="loginButtonCaption">Update Password</span>
              )}
            </Button>
            <Confirm
              type={'Create'}
              open={isConfirm}
              handleAksi={() => handleSubmit()}
              loading={false}
              handleClose={() => setIsConfirm(false)}
              disable={false}
              handleContent={{
                title: 'Edit User',
                message: 'Do you wish to Edit Password?',
                button: 'Confirm',
              }}
            />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
