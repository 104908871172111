/* eslint-disable */
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Link,
  MenuItem,
  Select,
  Step,
  Tooltip,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  IconButton,
} from '@mui/material';
import { SetStateAction, useEffect, useState, useContext } from 'react';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import Widget, { WidgetContent, WidgetFooter, WidgetHeader } from '../../components/Widget';

import { useDispatch } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
import { AppDispatch } from '../../redux/store';

import DashboardHeader from '../../components/DashboardHeader';
import Breadcrumbs from '../../components/Breadcrumbs';
import Container from '../../components/Container';
import { get as getCompany } from '../../redux/modules/company';
import { get as getOtpConfiguration } from '../../redux/modules/otpConfiguration';
import { get as getTemplate } from '../../redux/modules/otpTemplate';
import { get as getToken } from '../../redux/modules/token';
import { ToastManagerContext } from '../../components/ToastManager';

import GenerateConfiguration from '../OtpConfiguration/component/modal';
import GenerateTemplate from '../OtpTemplate/component/modal';
import GenerateToken from '../Token/component/modal';
import Code from './code';
import { ChevronLeftSqfr, InfoSqfr, BankCards } from '@xcidic/icons';
import {
  cSharp,
  js,
  php,
  python,
  jsVerify,
  cSharpVerify,
  phpVerify,
  pythonVerify,
} from './codeText';
// import { } from 'yup'
// import { useFormik } from 'formik'
import 'react-phone-input-2/lib/material.css';
import './style.css';

interface TokenPropsData {
  description: string;
  numberOfRequests: number;
  lastAccessedOn: number;
  value: string;
  expiresOn: number;
  id: string;
}
interface ConfigurationPropsData {
  id: string;
  name: string;
  length: Number;
  format: String;
  duration: Number;
  productName: String;
  defaultLanguageCode: String;
  isActive: Boolean;
}

interface TemplatePropsData {
  id: string;
  name: string;
  content: string;
  isActive: Boolean;
}

interface objectState {
  id: string;
  countryCode: string;
  number: string;
  waPhoneId: string;
  waApiToken: string;
  waBusinessAccountId: string;
}

interface CompanyPropsData {
  name: String;
  phones: objectState[];
}
export default function index() {
  const { create } = useContext(ToastManagerContext);

  const [activeStep, setActiveStep] = useState(0);
  const dispatch = useDispatch<AppDispatch>();
  // const navigate = useNavigate();

  const [progammingLanguage, setProgrammingLanguage] = useState('');
  const [selectedToken, setSelectedToken] = useState('');
  const [selectedConfiguration, setSelectedConfiguration] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState('');

  const [isOpenToken, setIsOpenToken] = useState(false);
  const [isOpenConfiguration, setIsOpenConfiguration] = useState(false);
  const [isOpenTemplate, setIsOpenTemplate] = useState(false);

  const [token, setToken] = useState<TokenPropsData[]>();
  const [configuration, setConfiguration] = useState<ConfigurationPropsData[]>();
  const [template, setTemplate] = useState<TemplatePropsData[]>();
  const [data, setData] = useState<CompanyPropsData>({
    name: '',
    phones: [
      {
        countryCode: '',
        number: '',
        waPhoneId: '',
        waApiToken: '',
        waBusinessAccountId: '',
        id: '',
      },
    ],
  });
  const [handlerId, setHandlerId] = useState(data?.phones[0]?.id);
  const [phoneNumber, setPhoneNumber] = useState(data?.phones[0]?.number);
  const [countryCode, setCountryCode] = useState(data?.phones[0]?.countryCode);
  const [selectValue, setSelectValue] = useState('default');

  useEffect(() => {
    dispatch(getToken({ limit: 10000, offset: 0 }))
      .unwrap()
      .then((res) => {
        setToken(res.listToken?.data);
      })
      .catch((err) => {
        console.log(err);
        // create('error', 'Error', err)
      });
    dispatch(getOtpConfiguration({ limit: 1000, offset: 0 }))
      .unwrap()
      .then((res) => {
        setConfiguration(res.listToken?.data);
        // create('success', 'Success', res.listToken.message)
      })
      .catch((err) => {
        console.log(err);
        // create('error', 'Error', err)
      });
    dispatch(getTemplate({ limit: 1000, offset: 0 }))
      .unwrap()
      .then((res) => {
        setTemplate(res.listToken?.data);
        // create('success', 'Success', res.listToken.message)
      })
      .catch((err) => {
        console.log(err);
        // create('error', 'Error', err)
      });
    dispatch(getCompany())
      .unwrap()
      .then((res) => {
        if (res.list.data.length > 0) {
          const dataAPI = res.list.data[0];
          setData(dataAPI);
        }
        // console.log(res.list)
        // create('success', 'Success', res.list.message)
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const updateData = () => {
    dispatch(getToken({ limit: 10000, offset: 0 }))
      .unwrap()
      .then((res) => {
        setToken(res.listToken?.data);
      })
      .catch((err) => {
        console.log(err);
        // create('error', 'Error', err)
      });
    dispatch(getOtpConfiguration({ limit: 1000, offset: 0 }))
      .unwrap()
      .then((res) => {
        setConfiguration(res.listToken?.data);
        // create('success', 'Success', res.listToken.message)
      })
      .catch((err) => {
        console.log(err);
        // create('error', 'Error', err)
      });
    dispatch(getTemplate({ limit: 1000, offset: 0 }))
      .unwrap()
      .then((res) => {
        setTemplate(res.listToken?.data);
        // create('success', 'Success', res.listToken.message)
      })
      .catch((err) => {
        console.log(err);
        // create('error', 'Error', err)
      });
    dispatch(getCompany())
      .unwrap()
      .then((res) => {
        if (res.list.data.length > 0) {
          const dataAPI = res.list.data[0];
          setData(dataAPI);
        }
        // console.log(res.list)
        // create('success', 'Success', res.list.message)
      })
      .catch((err) => {
        console.log(err);
        // create('error', 'Error', err)
      });
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setSelectValue('default');
    setPhoneNumber('');
    setCountryCode('');
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // const handleReset = () => {
  //   setActiveStep(0);
  // };
  const chooseProgammingLang = (name: SetStateAction<string>) => {
    setProgrammingLanguage(name);
    setActiveStep(activeStep + 1);
  };
  const changePhone = (value: string) => {
    setSelectValue(value);
    if (value === 'default') {
      setHandlerId('');
    } else {
      setHandlerId(data?.phones[0]?.id);
    }
  };
  const handleChangePhoneNumber = (value: string, country: CountryData) => {
    // const { setFieldValue } = this.props;
    // setFieldValue("waNumber", value);
    setPhoneNumber(value);
    setCountryCode(country?.dialCode);
    // setFieldValue("countryCode", country?.dialCode);
  };

  // const checkStringNull = (value: string) => {
  //   if (value === '') {
  //     return true;
  //   }
  //   return false;
  // };

  return (
    <Container>
      <Breadcrumbs
        // title={'Integrated Guide OTP'}
        items={[
          {
            label: 'OTP',
            link: '/otp',
          },
          {
            label: 'Integrated Guide OTP',
            link: '/otp/guide',
          },
        ]}
      />
      {/* <DashboardHeader title="Integrated Guide OTP" /> */}
      <div style={{ paddingTop: 42, paddingLeft: 30, paddingRight: 30 }}>
        <Stepper activeStep={activeStep} style={{ marginBottom: 30 }}>
          <Step>
            <StepLabel>Choose Program Language</StepLabel>
            {/* <StepContent>
                  Lorem Ipsum

                  <Box sx={{ mb: 2 }}>
                    <div>
                      <Button
                        variant="contained"
                        onClick={handleNext}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        {'Continue'}
                      </Button>
                      <Button
                        disabled={true}
                        onClick={handleBack}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        Back
                      </Button>
                    </div>
                  </Box>

                </StepContent> */}
          </Step>
          <Step>
            <StepLabel>Get Code for Setting OTP</StepLabel>
            {/* <StepContent>
            Lorem Ipsum

            <Box sx={{ mb: 2 }}>
              <div>
                <Button
                  variant="contained"
                  disabled={
                    true
                    handlerId === ''
                      ? Boolean(
                          checkStringNull(selectedToken) ||
                            checkStringNull(selectedConfiguration) ||
                            checkStringNull(countryCode) ||
                            checkStringNull(phoneNumber)
                        )
                      : Boolean(
                          checkStringNull(selectedToken) ||
                            checkStringNull(selectedConfiguration) ||
                            checkStringNull(selectedTemplate) ||
                            checkStringNull(countryCode) ||
                            checkStringNull(phoneNumber) ||
                            checkStringNull(handlerId)
                        )
                  }
                  onClick={handleNext}
                  sx={{ mt: 1, mr: 1 }}>
                  {'Continue'}
                </Button>
                <Button disabled={false} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                  Back
                </Button>
              </div>
            </Box>
          </StepContent> */}
          </Step>
          {/* <Step>
                <StepLabel>Testing OTP</StepLabel>
                <StepContent>

                  <Box sx={{ mb: 2 }}>
                    <div>
                      <Button
                        variant="contained"
                        disabled={true}
                        onClick={handleNext}
                        sx={{ mt: 1, mr: 1 }}>
                        {'Continue'}
                      </Button>
                      <Button disabled={false} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                        Back
                      </Button>
                    </div>
                  </Box>
                </StepContent>
              </Step> */}
        </Stepper>
        <Widget>
          <Grid container spacing={3}>
            <Grid item lg={12}>
              {activeStep === 0 && (
                <div>
                  <WidgetHeader title="Choose Programing Language" />
                  <div style={{ padding: 32 }}>
                    <Box
                      onClick={() => chooseProgammingLang('javascript')}
                      sx={{
                        border: 1,
                        cursor: 'pointer',
                        borderColor: '#E2E2E2',
                      }}
                      style={{ padding: 16, marginBottom: 24 }}>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <img
                            style={{ width: 56, height: 56, marginRight: 16 }}
                            src={require('../../assets/images/js.png')}
                          />
                          <Typography variant="h4">Javascript</Typography>
                        </div>
                        <Button
                          // fullWidth
                          variant={'contained'}
                          color={'primary'}
                          onClick={() => chooseProgammingLang('javascript')}>
                          Choose
                        </Button>
                      </div>
                    </Box>
                    <Box
                      sx={{
                        border: 1,
                        cursor: 'pointer',
                        borderColor: '#E2E2E2',
                      }}
                      onClick={() => chooseProgammingLang('python')}
                      style={{ padding: 16, marginBottom: 24 }}>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <img
                            style={{ width: 56, height: 56, marginRight: 16 }}
                            src={require('../../assets/images/python.png')}
                          />
                          <Typography variant="h4">Python</Typography>
                        </div>
                        <Button
                          // fullWidth
                          variant={'contained'}
                          color={'primary'}
                          onClick={() => chooseProgammingLang('python')}>
                          Choose
                        </Button>
                      </div>
                    </Box>
                    <Box
                      sx={{
                        border: 1,
                        cursor: 'pointer',

                        borderColor: '#E2E2E2',
                      }}
                      onClick={() => chooseProgammingLang('php')}
                      style={{ padding: 16, marginBottom: 24 }}>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <img
                            style={{ width: 56, height: 56, marginRight: 16 }}
                            src={require('../../assets/images/Php.png')}
                          />
                          <Typography variant="h4">Php</Typography>
                        </div>
                        <Button
                          // fullWidth
                          variant={'contained'}
                          color={'primary'}
                          onClick={() => chooseProgammingLang('php')}>
                          Choose
                        </Button>
                      </div>
                    </Box>
                    <Box
                      sx={{
                        border: 1,
                        borderColor: '#E2E2E2',
                        cursor: 'pointer',
                      }}
                      onClick={() => chooseProgammingLang('csharp')}
                      style={{ padding: 16, marginBottom: 24 }}>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <img
                            style={{ width: 56, height: 56, marginRight: 16 }}
                            src={require('../../assets/images/CSharp.png')}
                          />
                          <Typography variant="h4">C#</Typography>
                        </div>
                        <Button
                          // fullWidth
                          variant={'contained'}
                          color={'primary'}
                          onClick={() => chooseProgammingLang('csharp')}>
                          Choose
                        </Button>
                      </div>
                    </Box>
                  </div>
                </div>
              )}
              {activeStep === 1 && (
                <div>
                  <WidgetHeader title="Setting OTP" />
                  <div style={{ padding: 32 }}>
                    <Typography>
                      1. Create Template on{' '}
                      <Link
                        rel="noopener noreferrer"
                        target={'_blank'}
                        href="https://business.facebook.com/wa/manage/message-templates">
                        WhatsApp Business.
                      </Link>
                    </Typography>
                    <Typography>
                      2. Choose your default number
                      <Tooltip
                        placement="top"
                        title="If the phone number you want to use doesn't appear, you can press the “Refresh Data” button">
                        <IconButton style={{ color: '#258BEA' }}>
                          <InfoSqfr />
                        </IconButton>
                      </Tooltip>{' '}
                    </Typography>
                    <Grid container spacing={3} style={{ marginBottom: 15 }}>
                      <Grid item lg={10}>
                        <Select
                          fullWidth
                          value={selectValue}
                          onChange={(e) => changePhone(e.target.value as string)}>
                          <MenuItem value={'default'}>Default</MenuItem>
                          {data?.phones[0]?.id !== '' && (
                            <MenuItem value={'your'}>
                              {data?.phones[0]?.countryCode + data?.phones[0]?.number}
                            </MenuItem>
                          )}
                        </Select>
                      </Grid>
                      <Grid item lg={2}>
                        <Button
                          fullWidth
                          variant={'outlined'}
                          color={'primary'}
                          onClick={() => updateData()}>
                          Refresh Data
                        </Button>
                      </Grid>
                      <GenerateToken
                        open={isOpenToken}
                        onClose={() => {
                          setIsOpenToken(false);
                          updateData();
                        }}
                      />
                    </Grid>
                    <Typography>3. Choose Token</Typography>
                    <Grid container spacing={3} style={{ marginBottom: 15 }}>
                      <Grid item lg={10}>
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={token ?? []}
                          onChange={(event: any, newValue: TokenPropsData | null) => {
                            // console.log(newValue)
                            setSelectedToken(newValue?.value ?? '');
                          }}
                          getOptionLabel={(option) => option.description}
                          // sx={{ width: 300 }}
                          renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                      </Grid>
                      <Grid item lg={2}>
                        <Button
                          variant={'outlined'}
                          color={'primary'}
                          fullWidth
                          onClick={() => setIsOpenToken(true)}>
                          Create Token
                        </Button>
                      </Grid>
                      <GenerateToken
                        open={isOpenToken}
                        onClose={() => {
                          setIsOpenToken(false);
                          updateData();
                        }}
                      />
                    </Grid>
                    <Typography>4. Choose Configuration</Typography>
                    <Grid container spacing={3} style={{ marginBottom: 15 }}>
                      <Grid item lg={10}>
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={configuration?.filter((val) => val.isActive) ?? []}
                          onChange={(event: any, newValue: ConfigurationPropsData | null) => {
                            // console.log(newValue)
                            setSelectedConfiguration(newValue?.id ?? '');
                          }}
                          getOptionLabel={(option) => option.name}
                          // sx={{ width: 300 }}
                          renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                      </Grid>
                      <Grid item lg={2}>
                        <Button
                          variant={'outlined'}
                          fullWidth
                          color={'primary'}
                          onClick={() => setIsOpenConfiguration(true)}>
                          Create Configuration
                        </Button>
                      </Grid>
                      <GenerateConfiguration
                        open={isOpenConfiguration}
                        onClose={() => {
                          setIsOpenConfiguration(false);
                          updateData();
                        }}
                      />
                    </Grid>
                    {selectValue !== 'default' && (
                      <div>
                        <Typography>5. Choose Template</Typography>
                        <Grid container spacing={3} style={{ marginBottom: 15 }}>
                          <Grid item lg={10}>
                            <Autocomplete
                              disablePortal
                              id="combo-box-demo"
                              options={template?.filter((val) => val.isActive) ?? []}
                              onChange={(event: any, newValue: TemplatePropsData | null) => {
                                // console.log(newValue)
                                setSelectedTemplate(newValue?.id ?? '');
                              }}
                              getOptionLabel={(option) => option.name}
                              // sx={{ width: 300 }}
                              renderInput={(params) => <TextField {...params} fullWidth />}
                            />
                          </Grid>
                          <Grid item lg={2}>
                            <Button
                              variant={'outlined'}
                              color={'primary'}
                              fullWidth
                              onClick={() => setIsOpenTemplate(true)}>
                              Create Template
                            </Button>
                          </Grid>
                          <GenerateTemplate
                            open={isOpenTemplate}
                            onClose={() => {
                              setIsOpenTemplate(false);
                              updateData();
                            }}
                          />
                        </Grid>
                      </div>
                    )}
                    <Typography>
                      {selectValue === 'default' ? '5.' : '6.'} Phone Number Target
                    </Typography>
                    <Grid container spacing={3} style={{ marginBottom: 15 }}>
                      <Grid item lg={10}>
                        <PhoneInput
                          inputStyle={{ width: '100%' }}
                          // style={{ marginBottom: 10, marginTop: 10 }}
                          containerClass="phoneInput"
                          // name="phone"
                          // type="number"
                          specialLabel=""
                          // variant="outlined"
                          // size="small"
                          // margin="normal"
                          value={phoneNumber}
                          onChange={handleChangePhoneNumber}
                          // onBlur={handleBlur}
                          // error={Boolean(touched.waNumber && errors.waNumber)}
                          // helperText={touched.waNumber && errors.waNumber}
                        />
                      </Grid>
                    </Grid>

                    <Typography>{selectValue === 'default' ? '6.' : '7.'} Your Code</Typography>
                    <Code
                      code={
                        progammingLanguage === 'javascript'
                          ? js(
                              selectedToken,
                              selectedConfiguration,
                              selectedTemplate,
                              handlerId,
                              countryCode,
                              phoneNumber.replace(countryCode, '')
                            )
                          : progammingLanguage === 'python'
                          ? python(
                              selectedToken,
                              selectedConfiguration,
                              selectedTemplate,
                              handlerId,
                              countryCode,
                              phoneNumber.replace(countryCode, '')
                            )
                          : progammingLanguage === 'php'
                          ? php(
                              selectedToken,
                              selectedConfiguration,
                              selectedTemplate,
                              handlerId,
                              countryCode,
                              phoneNumber.replace(countryCode, '')
                            )
                          : cSharp(
                              selectedToken,
                              selectedConfiguration,
                              selectedTemplate,
                              handlerId,
                              countryCode,
                              phoneNumber.replace(countryCode, '')
                            )
                      }
                      language={progammingLanguage}
                    />
                    <Button
                      style={{ marginBottom: 10 }}
                      name="copy"
                      variant="outlined"
                      color="primary"
                      // fullWidth
                      startIcon={<BankCards />}
                      onClick={() => {
                        navigator.clipboard.writeText(
                          progammingLanguage === 'javascript'
                            ? js(
                                selectedToken,
                                selectedConfiguration,
                                selectedTemplate,
                                handlerId,
                                countryCode,
                                phoneNumber.replace(countryCode, '')
                              )
                            : progammingLanguage === 'python'
                            ? python(
                                selectedToken,
                                selectedConfiguration,
                                selectedTemplate,
                                handlerId,
                                countryCode,
                                phoneNumber.replace(countryCode, '')
                              )
                            : progammingLanguage === 'php'
                            ? php(
                                selectedToken,
                                selectedConfiguration,
                                selectedTemplate,
                                handlerId,
                                countryCode,
                                phoneNumber.replace(countryCode, '')
                              )
                            : cSharp(
                                selectedToken,
                                selectedConfiguration,
                                selectedTemplate,
                                handlerId,
                                countryCode,
                                phoneNumber.replace(countryCode, '')
                              )
                        );
                        create('success', 'Copy', 'Success Copy OTP Code');
                      }}
                      // disabled={!(dirty && isValid)}
                      // onClick={() => setIsConfirm(true)}
                    >
                      Copy Code
                    </Button>
                    <Typography>
                      {selectValue === 'default' ? '7.' : '8.'} Your Verify Code
                    </Typography>
                    <Code
                      code={
                        progammingLanguage === 'javascript'
                          ? jsVerify(
                              selectedToken,
                              countryCode,
                              phoneNumber.replace(countryCode, '')
                            )
                          : progammingLanguage === 'python'
                          ? pythonVerify(
                              selectedToken,
                              countryCode,
                              phoneNumber.replace(countryCode, '')
                            )
                          : progammingLanguage === 'php'
                          ? phpVerify(
                              selectedToken,
                              countryCode,
                              phoneNumber.replace(countryCode, '')
                            )
                          : cSharpVerify(
                              selectedToken,
                              countryCode,
                              phoneNumber.replace(countryCode, '')
                            )
                      }
                      language={progammingLanguage}
                    />
                    <Button
                      style={{ marginBottom: 10 }}
                      name="copy"
                      variant="outlined"
                      color="primary"
                      // fullWidth
                      startIcon={<BankCards />}
                      onClick={() => {
                        navigator.clipboard.writeText(
                          progammingLanguage === 'javascript'
                            ? jsVerify(
                                selectedToken,
                                countryCode,
                                phoneNumber.replace(countryCode, '')
                              )
                            : progammingLanguage === 'python'
                            ? pythonVerify(
                                selectedToken,
                                countryCode,
                                phoneNumber.replace(countryCode, '')
                              )
                            : progammingLanguage === 'php'
                            ? phpVerify(
                                selectedToken,
                                countryCode,
                                phoneNumber.replace(countryCode, '')
                              )
                            : cSharpVerify(
                                selectedToken,
                                countryCode,
                                phoneNumber.replace(countryCode, '')
                              )
                        );
                        create('success', 'Copy', 'Success Copy Verify OTP Code');
                      }}
                      // disabled={!(dirty && isValid)}
                      // onClick={() => setIsConfirm(true)}
                    >
                      Copy Code
                    </Button>
                  </div>

                  {/* <Typography>{selectValue === 'default' ? '7.' : '8.'} Testing Otp</Typography>
                <Button
                  variant="contained"
                  disabled={
                    handlerId === ''
                      ? Boolean(
                          checkStringNull(selectedToken) ||
                            checkStringNull(selectedConfiguration) ||
                            checkStringNull(countryCode) ||
                            checkStringNull(phoneNumber)
                        )
                      : Boolean(
                          checkStringNull(selectedToken) ||
                            checkStringNull(selectedConfiguration) ||
                            checkStringNull(selectedTemplate) ||
                            checkStringNull(countryCode) ||
                            checkStringNull(phoneNumber) ||
                            checkStringNull(handlerId)
                        )
                  }
                  onClick={handleNext}
                  sx={{ mt: 1, mr: 1 }}>
                  {'Continue'}
                </Button> */}
                  <WidgetFooter>
                    <Button
                      // fullWidth
                      className="loginButton"
                      startIcon={<ChevronLeftSqfr />}
                      onClick={() => handleBack()}
                      variant="contained"
                      color="primary">
                      Back Step
                    </Button>
                  </WidgetFooter>
                </div>
              )}
              {activeStep === 2 && (
                <div>
                  <Typography>1. Phone Number Target</Typography>
                  <Grid container spacing={3} style={{ marginTop: 5, marginBottom: 5 }}>
                    <Grid item lg={3}>
                      <PhoneInput
                        // style={{ marginBottom: 10, marginTop: 10 }}
                        containerClass="phoneInput"
                        // name="phone"
                        // type="number"
                        specialLabel="WA Number *"
                        // variant="outlined"
                        // size="small"
                        // margin="normal"
                        value={phoneNumber}
                        onChange={handleChangePhoneNumber}
                        // onBlur={handleBlur}
                        // error={Boolean(touched.waNumber && errors.waNumber)}
                        // helperText={touched.waNumber && errors.waNumber}
                      />
                    </Grid>
                    <Grid item lg={6}>
                      <Button
                        variant={'contained'}
                        color={'primary'}
                        onClick={() => setIsOpenTemplate(true)}>
                        Send OTP
                      </Button>
                    </Grid>
                  </Grid>
                  <Typography>2. Veriy Otp</Typography>
                  <Grid container spacing={3} style={{ marginTop: 5, marginBottom: 5 }}>
                    <Grid item lg={3}>
                      <TextField fullWidth variant="outlined" />
                    </Grid>
                    <Grid item lg={6}>
                      <Button
                        variant={'contained'}
                        color={'primary'}
                        onClick={() => setIsOpenTemplate(true)}>
                        Verify Otp
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              )}
            </Grid>
          </Grid>
        </Widget>
      </div>
    </Container>
  );
}
