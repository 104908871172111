// material
import { Box, Divider, Typography, Avatar, Button } from '@mui/material';
import { User } from '@xcidic/icons';
import { useSockets } from '../../contexts/SocketContextComponent';
// import moment from 'moment';
interface Props {
  handleResolve: Function;
  id: string;
  name: string;
  selectedId: string;
  timeMessage: number;
  messageProps: string;
  lastMessage: number;
  isNewMessage: boolean;
  isReplied: boolean;
  isResolved: boolean;
}

const DashboardHeader = ({
  name,
  timeMessage,
  isReplied,
  isResolved,
  selectedId,
  handleResolve,
}: Props) => {
  const { dataUser } = useSockets();
  // const lastMessageOn = timeMessage * 1000;
  // const socketLastMessage = dataUser?.find((val) => val.id === selectedId)?.lastMessageOn ?? 0;
  // const socketLastMessageOn = socketLastMessage * 1000;
  const socketIsReplied = dataUser?.find((val) => val.id === selectedId)?.isReplied ?? undefined;
  const socketIsResolved = dataUser?.find((val) => val.id === selectedId)?.isResolved ?? undefined;

  const status =
    // moment().valueOf() - lastMessageOn > 86400000 ??
    // moment().valueOf() - socketLastMessageOn > 86400000
    //   ? 'expired'
    //   :
    socketIsResolved ?? isResolved ? 'resolved' : socketIsReplied ?? isReplied ? 'replied' : '';
  return (
    <Box mb={2}>
      <Box
        sx={{
          mb: 1.2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        style={{
          paddingLeft: 16,
          paddingRight: 16,
        }}>
        <div style={{ display: 'flex' }}>
          <Avatar
            sx={{ width: 32, height: 32 }}
            style={{ backgroundColor: '#E3E6EC', marginRight: 12 }}>
            <User style={{ color: '#000' }} />
          </Avatar>
          <Typography
            component="h1"
            variant="h3"
            sx={{ fontWeight: 700 }}
            style={{ fontSize: '1rem', lineHeight: '2rem' }}>
            {name}
          </Typography>
        </div>

        <div style={{ display: 'flex' }}>
          <p
            style={{
              padding: 5,
            }}>
            Status :
          </p>
          <div
            style={{
              backgroundColor:
                // status === 'expired'
                //   ? '#E2E2E2'
                //   :

                status === 'replied' ? '#C2D5F9' : status === 'resolved' ? '#BEF7DC' : '#FFECC6',
              color:
                // status === 'expired'
                //   ? '#AFAFAF'
                //   :
                status === 'replied' ? '#276EF1' : status === 'resolved' ? '#12B76A' : '#D5A038',
              padding: 5,
              height: 'fit-content',
              marginLeft: 10,
              marginRight: 10,
            }}>
            {
              // status === 'expired'
              //   ? 'Expired'
              //   :
              status === 'replied' ? 'Replied' : status === 'resolved' ? 'Resolved' : 'Waiting'
            }
          </div>

          {status !== 'resolved' && (
            <Button size="small" variant="outlined" onClick={() => handleResolve()}>
              Resolve this message
            </Button>
          )}
        </div>
      </Box>
      <Divider />
    </Box>
  );
};

export default DashboardHeader;
