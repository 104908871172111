import { Theme, ThemeOptions } from '@mui/material';
import colors from './Colors';
export default function Table(theme: Theme): ThemeOptions['components'] {
  return {
    MuiTablePagination: {
      styleOverrides: {
        toolbar: {
          '& .MuiIconButton-root': {
            padding: 8,
          },
        },
        select: {
          '&:focus': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        head: {
          background: colors.alpha.black[5],
        },
        root: {
          transition: 'background-color .2s',

          '&.MuiTableRow-hover:hover': {
            backgroundColor: colors.alpha.black[5],
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottomColor: colors.alpha.black[10],
          fontSize: 14,
        },
        head: {
          fontSize: '14',
          fontWeight: 'bold',
          color: colors.alpha.black[70],
          backgroundColor: '#f1f8ff',
        },
      },
    },
  };
}
