import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { BASE_URL } from '../../utils/url';
import axios from '../../utils/request';

export interface requestData {
  email: String;
}

interface errorRequest {
  message: String;
}

const initialState = {
  responseApi: {
    statusCode: 404,
    message: 'Verification code is invalid or has expired',
    error: 'Not Found',
  },
};

const resetPassword = (email: String) => {
  return axios.post(`${BASE_URL}/account/request-password-reset`, {
    email,
  });
};

export const reset = createAsyncThunk(
  'module/resetPassword',
  async ({ email }: requestData, thunkAPI) => {
    try {
      const data = await resetPassword(email);
      thunkAPI.fulfillWithValue(data.data);
      return { responseApi: data?.data };
    } catch (error) {
      const err = error as AxiosError;
      return thunkAPI.rejectWithValue((err.response?.data as errorRequest).message);
    }
  }
);

const resetPasswordSlice = createSlice({
  name: 'resetPassword',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(reset.fulfilled, (state, action) => {
      state.responseApi = action.payload.responseApi;
      // state.isLoggedIn = true;
      // state.user = action.payload.user;
    });
    builder.addCase(reset.rejected, (state, action) => {
      state.responseApi = {
        statusCode: 404,
        message: 'Verification code is invalid or has expired',
        error: 'Not Found',
      };

      // state.isLoggedIn = false;
      // state.user = null;
    });
  },
});

const { reducer } = resetPasswordSlice;
export default reducer;
