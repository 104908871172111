import React, { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import io, { Socket } from 'socket.io-client';
import EVENTS from '../config/events';
import { getService } from '../redux/modules/socket';
import { AppDispatch } from '../redux/store';
import { SOCKET_URL } from '../utils/url';

let socket = io(SOCKET_URL, {
  transports: ['websocket'],
});

interface dataUserInterface {
  customerContactName: string | null;
  customerPhoneNumber: string;
  lastMessageOn: number;
  id: string;
  isNewMessage: boolean;
  lastMessagePreview: string;
  newMessageCount: number;
  isReplied: boolean;
  isResolved: boolean;
}
export interface messageData {
  textMessage: string;
  isIncoming: boolean;
  messageOn: number;
  status: string;
  contactId?: string;
  messageId?: string;
  createdOn: number;
  conversationExpiredOn: number;
}

export interface statusData {
  messageId: string;
  status: string;
}

export interface Context {
  socket?: Socket;
  status: statusData[];
  message: messageData[];
  dataUser: dataUserInterface[];
  setMessage: (value: messageData[]) => void;
  setResolve: (value: string) => void;
}

export interface SocketContextProviderProps {
  children: React.ReactNode;
}

export const SocketContext = createContext<Context>({
  socket,
  status: [],
  message: [],
  dataUser: [],
  setMessage: (value: messageData[]) => {},
  setResolve: (value: string) => {},
});

const SocketsProvider = ({ children }: SocketContextProviderProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const [dataUser, setDataUserSocket] = useState<dataUserInterface[]>([]);
  const [message, setMessage] = useState<messageData[]>([]);
  const [status, setStatus] = useState<statusData[]>([]);

  useEffect(() => {
    if (window.location.pathname === '/auto/chat') {
      dispatch(getService({ url: 'token' }))
        .unwrap()
        .then((res) => {
          socket = io(SOCKET_URL, {
            reconnection: true,
            reconnectionDelay: 500,
            reconnectionAttempts: 10,
            auth: { token: res.responseApi.data.value },
            transports: ['websocket'],
          });
          socket.connect();
        })
        .catch(() => {});
    }
  }, [window.location.pathname]);

  const onChangeUser = (msg: messageData) => {
    // console.log(msg);
    setMessage((messages) => [...messages, msg]);
    dispatch(getService({ url: '?limit=10000&offset=0&order=desc&orderBy=lastMessageOn' }))
      .unwrap()
      .then((res) => {
        // console.log('asdasd', res.responseApi.data);
        setDataUserSocket(() => res.responseApi.data);
      })
      .catch(() => {});
  };

  const onResolve = (selectedId: string) => {
    dispatch(getService({ url: `${selectedId ?? '0'}/resolved` }))
      .unwrap()
      .then(() => {})
      .catch(() => {});

    dispatch(getService({ url: '?limit=10000&offset=0&order=desc&orderBy=lastMessageOn' }))
      .unwrap()
      .then((res) => {
        setDataUserSocket(res.responseApi.data);
      })
      .catch(() => {});
  };

  useEffect(() => {
    socket?.on(EVENTS.NEW_MESSAGE, onChangeUser);
    socket?.on(EVENTS.STATUS_UPDATE, (msg) => {
      setStatus((status) => [...status, msg]);

      // console.log(msg, 'status update');
    });
    socket?.on(EVENTS.DISCONNECT, () => {
      // console.log('disconnect');
      // socket.connect();
    });
  }, [socket]);
  return (
    <SocketContext.Provider
      value={{ socket, message, setMessage, status, dataUser, setResolve: onResolve }}>
      {children}
    </SocketContext.Provider>
  );
};

export const useSockets = () => useContext(SocketContext);

export default SocketsProvider;
