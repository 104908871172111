import { Theme, ThemeOptions } from '@mui/material';

export default function SvgIcon(theme: Theme): ThemeOptions['components'] {
  return {
    MuiSvgIcon: {
      defaultProps: {
        fontSize: 'small',
      },
    },
  };
}
