import { Button, Paper, Divider, Typography, IconButton, Grid } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ToastManagerContext } from '../../components/ToastManager';
import { getById } from '../../redux/modules/token';
import { AppDispatch } from '../../redux/store';
// import { useNavigate } from 'react-router-dom';

import Breadcrumbs from '../../components/Breadcrumbs';
import Container from '../../components/Container';
// import ListToken from './component';
import moment from 'moment';
import { useParams } from 'react-router';

import DashboardHeader from '../../components/DashboardHeader';
import { BankCards, Eye, EyeOff } from '@xcidic/icons';

import EditToken from './component/edit';
import Field from '../../components/Fields';

interface dataProps {
  description: string;
  withExpiration: boolean;
  value: string;
  lastAccessedOn: number;
  expiresOn: number;
}

export default function index() {
  const { create } = useContext(ToastManagerContext);
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams();
  const [isToken, setIsToken] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [data, setData] = useState<dataProps>({
    description: '',
    withExpiration: true,
    value: '',
    lastAccessedOn: 0,
    expiresOn: 0,
  });
  useEffect(() => {
    dispatch(getById({ id: id ?? '' }))
      .unwrap()
      .then((res) => {
        // console.log(res.responseApi);
        // setUserContact(res.listToken?.data)
        setData(res?.responseApi?.data);
        // create('success', 'Success', res.responseApi.message);
      })
      .catch((err) => {
        // console.log(err)
        create('error', 'Error', err);
      });
  }, [isEdit]);
  return (
    <Container>
      <Breadcrumbs
        items={[
          {
            label: 'Home',
            link: '/',
          },
          {
            label: 'Token',
            link: '/token',
          },
          {
            label: 'Token Detail',
            link: '/token',
          },
        ]}
      />
      <DashboardHeader title="Token Detail"></DashboardHeader>
      <Paper>
        <Field withElement={false} small={false} label="Token Name" value={data?.description} />
        <Divider />
        <Field
          withElement={false}
          small={false}
          label="With Expiration"
          value={data?.withExpiration ? 'Yes' : 'No'}
        />
        <Divider />
        <Field
          withElement={false}
          small={false}
          label="Expiration Date"
          value={data?.withExpiration ? moment(data.expiresOn * 1000).format('MMM DD, YYYY') : '-'}
        />
        <Divider />
        <Field
          withElement={false}
          small={false}
          label="Last Used"
          value={
            data?.lastAccessedOn === null
              ? 'Never Used'
              : `${moment(data.lastAccessedOn * 1000).format('MMM DD,YYYY | HH:MM')}`
          }
        />
        <Divider />
        <Typography style={{ fontWeight: 500, fontStyle: 'normal', fontSize: '14px' }}>
          Token ID
        </Typography>
        <Typography
          style={{ fontWeight: 300, fontStyle: 'normal', fontSize: '14px', color: '#5d5d5d' }}>
          {isToken ? data?.value ?? '' : data?.value?.split('').map(() => '*') ?? ''}
          <IconButton onClick={() => setIsToken(!isToken)}>
            {isToken ? <EyeOff /> : <Eye />}
          </IconButton>
          <Button
            style={{ marginLeft: 10 }}
            name="copy"
            variant="outlined"
            color="primary"
            // fullWidth
            startIcon={<BankCards />}
            onClick={() => {
              navigator.clipboard.writeText(data?.value ?? '');
              create('success', 'Copy', 'Success Copy Token');
            }}
            // disabled={!(dirty && isValid)}
            // onClick={() => setIsConfirm(true)}
          >
            Copy
          </Button>
        </Typography>
        <Grid container spacing={3} style={{ marginTop: 10 }}>
          <Grid item lg={12} style={{ justifyContent: 'flex-end', display: 'flex' }}>
            <Grid item spacing={3}>
              <Button
                name="company"
                variant="contained"
                color="primary"
                onClick={() => {
                  setIsEdit(true);
                  // setEditValue({
                  //   id: data.id,
                  //   name: data.name,
                  //   content: data.content,
                  //   isActive: data.isActive,
                  // });
                }}>
                Edit Token
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <EditToken
          open={isEdit}
          onClose={() => {
            setIsEdit(false);
            // updateToken();
          }}
          id={id ?? ''}
          description={data.description}
        />
      </Paper>
    </Container>
  );
}
