/* eslint-disable */
import { Box, Grid, Paper, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import Breadcrumbs from '../../components/Breadcrumbs';
import Container from '../../components/Container';
import DashboardHeader from '../../components/DashboardHeader';
import { useSockets } from '../../contexts/SocketContextComponent';
import { getService, postService, blastService } from '../../redux/modules/socket';
import { AppDispatch } from '../../redux/store';
import Confirm from '../../components/Confirm';

import ChatBar from './Chatbar';
import ChatHead from './ChatHead';

import ChatBody from './Chatbody';
import ChatInput from './ChatInput';
import ChatExpired from './ChatExpired';

import moment from 'moment';

// import ChatFooter from './ChatFooter'
interface dataUserInterface {
  customerContactName: string | null;
  customerPhoneNumber: string;
  lastMessageOn: number;
  id: string;
  isNewMessage: boolean;
  lastMessagePreview: string;
  newMessageCount: number;
  isReplied: boolean;
  isResolved: boolean;
}

interface message {
  textMessage: string;
  isIncoming: boolean;
  messageOn: number;
  status: string;
  contactId?: string;
  messageId?: string;
  createdOn: number;
  conversationExpiredOn: number;
}
const ChatPage = () => {
  const { message, setMessage, setResolve, dataUser } = useSockets();
  const dispatch = useDispatch<AppDispatch>();
  const [dataUserState, setDataUser] = useState<dataUserInterface[]>([]);
  const [messageState, setMessasge] = useState<message[]>([]);
  // const [isBlast, setIsBlast] = useState(false);
  const [lastMessageOn, setLastMessageOn] = useState(0);
  // const [filterMessage, setFilterMessage] = useState<message[]>([]);

  const [selectedId, setSelectedId] = useState('');
  const [lastMessageId, setLastMessageId] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10000);
  // const [scroll, setScroll] = useState(0);
  const [isConfirmActive, setIsActivate] = useState(false);

  const lastMessageRef = useRef<null | HTMLDivElement>(null);
  // const paginationRef = useRef<null | HTMLDivElement>(null);
  // const ref = {
  //   lastMessageRef,
  //   paginationRef,
  // };

  useEffect(() => {
    setTimeout(() => {
      showMessage('');
    }, 100);
  }, []);

  useEffect(() => {
    dispatch(getService({ url: '?limit=10000&offset=0&order=desc&orderBy=lastMessageOn' }))
      .unwrap()
      .then((res) => {
        setDataUser(res.responseApi.data);
      })
      .catch(() => {});
    const lastMessage = dataUserState?.find((val) => val.id === selectedId)?.lastMessageOn ?? 0;
    setLastMessageOn(lastMessage * 1000);
  }, [selectedId]);

  useEffect(() => {
    const lastMessage = dataUser?.find((val) => val.id === selectedId)?.lastMessageOn ?? 0;
    setLastMessageOn(lastMessage * 1000);
  }, [dataUser]);

  const showMessage = (id: string) => {
    setSelectedId(id);
    dispatch(
      getService({
        url: `${id}?limit=${limit}&offset=${
          page === 1 ? 0 : page === 2 ? limit : limit * page
        }&order=asc&orderBy=messageOn`,
      })
    )
      .unwrap()
      .then((res) => {
        const data: message[] = (res?.responseApi?.data as message[])?.map((val) => {
          return {
            ...val,
            contactId: id,
          };
        });
        setMessasge(data);
      })
      .catch(() => {});
    dispatch(getService({ url: `${id}/read` }))
      .unwrap()
      .then(() => {})
      .catch(() => {});
  };

  useEffect(() => {
    // if(messageState.length > 0){

    // }
    // const lastMessage = messageState?.filter(
    //   (val) => val.textMessage !== null && val.messageOn !== null
    // );
    // const conversationExpiredOn = messageState?.filter(
    //   (val) => val.textMessage !== null && val.conversationExpiredOn !== null
    // );
    // const indexLastMessage = messageState?.findIndex(
    //   (val) => val.messageId === lastMessage[lastMessage.length - 1]?.messageId
    // );
    // const indexConversationExpiredOn = messageState?.findIndex(
    //   (val) => val.messageId === conversationExpiredOn[conversationExpiredOn.length - 1]?.messageId
    // );
    // if (indexConversationExpiredOn > indexLastMessage) {
    //   const expiredOn =
    //     conversationExpiredOn.length > 0
    //       ? conversationExpiredOn[conversationExpiredOn.length - 1].conversationExpiredOn
    //       : 0;
    //   const expiredOnCalculation = expiredOn * 1000;
    //   const isBlast = moment().valueOf() > expiredOnCalculation;
    //   setIsBlast(isBlast);
    // } else if (indexLastMessage > indexConversationExpiredOn) {
    //   const expiredOn = lastMessage.length > 0 ? lastMessage[lastMessage.length - 1].messageOn : 0;
    //   const expiredOnCalculation = expiredOn * 1000;
    //   // console.log(moment().valueOf(), expiredOnCalculation);
    //   // console.log(moment().valueOf() - expiredOnCalculation);
    //   const isBlast = moment().valueOf() - expiredOnCalculation > 86400000;
    //   setIsBlast(isBlast);
    // } else {
    //   const expiredOn =
    //     conversationExpiredOn.length > 0
    //       ? conversationExpiredOn[conversationExpiredOn.length - 1].conversationExpiredOn
    //       : 0;
    //   const expiredOnCalculation = expiredOn * 1000;
    //   const isBlast = moment().valueOf() > expiredOnCalculation;
    //   setIsBlast(isBlast);
    // }
    // console.log(indexLastMessage, indexConversationExpiredOn);
    // console.log(indexConversationExpiredOn, conversationExpiredOn);

    // console.log(lastMessage, conversationExpiredOn);
    // setLastMessageOn(0 * 1000);
    setMessage(messageState);
  }, [messageState]);

  // useEffect(() => {
  //   const lastMessage = message?.filter(
  //     (val) => val.textMessage !== null && val.messageOn !== null
  //   );
  //   const conversationExpiredOn = message?.filter(
  //     (val) => val.textMessage !== null && val.conversationExpiredOn !== null
  //   );
  //   const indexLastMessage = message?.findIndex(
  //     (val) => val.messageId === lastMessage[lastMessage.length - 1]?.messageId
  //   );
  //   const indexConversationExpiredOn = message?.findIndex(
  //     (val) => val.messageId === conversationExpiredOn[conversationExpiredOn.length - 1]?.messageId
  //   );
  //   if (indexConversationExpiredOn > indexLastMessage) {
  //     const expiredOn =
  //       conversationExpiredOn.length > 0
  //         ? conversationExpiredOn[conversationExpiredOn.length - 1].conversationExpiredOn
  //         : 0;
  //     const expiredOnCalculation = expiredOn * 1000;
  //     const isBlast = moment().valueOf() > expiredOnCalculation;
  //     setIsBlast(isBlast);
  //   } else if (indexLastMessage > indexConversationExpiredOn) {
  //     const expiredOn = lastMessage.length > 0 ? lastMessage[lastMessage.length - 1].messageOn : 0;
  //     const expiredOnCalculation = expiredOn * 1000;
  //     // console.log(moment().valueOf(), expiredOnCalculation);
  //     // console.log(moment().valueOf() - expiredOnCalculation);
  //     const isBlast = moment().valueOf() - expiredOnCalculation > 86400000;
  //     setIsBlast(isBlast);
  //   } else {
  //     const expiredOn =
  //       conversationExpiredOn.length > 0
  //         ? conversationExpiredOn[conversationExpiredOn.length - 1].conversationExpiredOn
  //         : 0;
  //     const expiredOnCalculation = expiredOn * 1000;
  //     const isBlast = moment().valueOf() > expiredOnCalculation;
  //     setIsBlast(isBlast);
  //   }
  // }, [message]);

  const sendMessage = (newNessage: string) => {
    dispatch(postService({ id: `${selectedId}`, text: newNessage }))
      .unwrap()
      .then((res) => {
        setLastMessageId((lastMessage) => [
          ...lastMessage,
          res.responseApi.data.messageId as never,
        ]);
      })
      .catch(() => {});
    dispatch(getService({ url: `${selectedId}?limit=10000&offset=0&order=desc&orderBy=messageOn` }))
      .unwrap()
      .then((res) => {})
      .catch(() => {});
  };
  const sendBlast = (newNessage: string) => {
    dispatch(blastService({ id: `${selectedId}`, blastId: newNessage }))
      .unwrap()
      .then((res) => {
        setLastMessageId((lastMessage) => [
          ...lastMessage,
          res.responseApi.data.messageId as never,
        ]);
      })
      .catch(() => {});
    dispatch(getService({ url: `${selectedId}?limit=10000&offset=0&order=desc&orderBy=messageOn` }))
      .unwrap()
      .then((res) => {})
      .catch(() => {});
  };

  useEffect(() => {
    lastMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
    dispatch(getService({ url: `${selectedId ?? '0'}/read` }))
      .unwrap()
      .then(() => {})
      .catch(() => {});
  }, [message]);

  const onScroll = (e: any) => {
    // if (e.target.scrollTop === 0) {
    //   showMessage(selectedId);
    //   setPage(page);
    //   setLimit(limit);
    // }
  };

  return (
    // <SocketsProvider>
    <div>
      <Container>
        <Breadcrumbs
          items={[
            {
              label: 'Auto Reply',
              link: '/auto/answer',
            },
            {
              label: 'Chat',
              link: '/auto/chat',
            },
          ]}
        />
        <DashboardHeader title="Chat" />
        <Paper>
          <Grid container>
            <Grid item lg={3} style={{ borderRight: '0.1em solid #E2E2E2' }}>
              <ChatBar
                id={selectedId}
                dataUser={dataUserState}
                onAction={(data) => showMessage(data)}
              />
            </Grid>
            <Grid item lg={9}>
              {selectedId === '' && (
                <div style={{ width: '100%', height: '100%' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                    }}>
                    <div>
                      <Box
                        component="img"
                        src={`/chat.svg`}
                        style={{ margin: 'auto', display: 'flex' }}
                      />
                      <Typography style={{ color: '#AFAFAF' }}>
                        Choose one of the message to support Auto Reply Messages by chatting
                        manually.
                      </Typography>
                    </div>
                  </div>
                </div>
              )}
              {selectedId !== '' && (
                <ChatHead
                  handleResolve={() => setIsActivate(true)}
                  id={selectedId}
                  selectedId={selectedId}
                  name={
                    dataUserState?.find((val) => val.id === selectedId)?.customerContactName ??
                    dataUserState?.find((val) => val.id === selectedId)?.customerPhoneNumber ??
                    ''
                  }
                  lastMessage={
                    dataUserState?.find((val) => val.id === selectedId)?.newMessageCount ?? 0
                  }
                  messageProps={
                    dataUserState?.find((val) => val.id === selectedId)?.lastMessagePreview ?? ''
                  }
                  timeMessage={
                    dataUserState?.find((val) => val.id === selectedId)?.lastMessageOn ?? 0
                  }
                  isNewMessage={
                    dataUserState?.find((val) => val.id === selectedId)?.isNewMessage ?? false
                  }
                  isReplied={
                    dataUserState?.find((val) => val.id === selectedId)?.isReplied ?? false
                  }
                  isResolved={
                    dataUserState?.find((val) => val.id === selectedId)?.isResolved ?? false
                  }
                />
              )}
              {selectedId !== '' && (
                <div style={{ height: '100%' }}>
                  <ChatBody
                    onScroll={onScroll}
                    id={selectedId}
                    ref={lastMessageRef}
                    lastMessageId={lastMessageId}
                  />
                  {/* {!isBlast && <ChatInput onSubmit={(data) => sendMessage(data)} />} */}
                  {/* {isBlast && <ChatExpired onSubmit={(data) => sendBlast(data)} />} */}
                  {new Date().getTime() - lastMessageOn < 86400000 && (
                    <ChatInput onSubmit={(data) => sendMessage(data)} />
                  )}
                  {new Date().getTime() - lastMessageOn > 86400000 && (
                    <ChatExpired onSubmit={(data) => sendBlast(data)} />
                  )}
                </div>
              )}
            </Grid>
          </Grid>
          <Confirm
            type={'Create'}
            open={isConfirmActive}
            handleAksi={() => {
              setResolve(selectedId);
              setIsActivate(false);
            }}
            disable={false}
            loading={false}
            handleClose={() => setIsActivate(false)}
            handleContent={{
              title: 'Resolve Chat',
              message: 'Are you sure you want to resolve this chat? This action cannot be undone.',
              button: 'Yes, resolve',
            }}
          />
        </Paper>
      </Container>
    </div>
    // </SocketsProvider>
  );
};

export default ChatPage;
