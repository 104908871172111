import { Theme, ThemeOptions } from '@mui/material';
export default function Table(theme: Theme): ThemeOptions['components'] {
  return {
    MuiPaper: {
      styleOverrides: {
        root: {
          color: 'rgba(0, 0, 0, 0.87)',
          transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          backgroundColor: 'white',
          padding: '20px',
        },
        elevation1: {
          boxShadow: '0px 0px 16px 0px #919EAB3D',
        },
        rounded: {
          borderRadius: '4px',
        },
      },
    },
  };
}
