import { Theme, ThemeOptions } from '@mui/material';
import { merge } from 'lodash';

import Button from './Button';
import CssBaseline from './CssBaseline';
import Dialog from './Dialog';
import Typography from './Typography';
import Input from './Input';
import Form from './Form';
import Pagination from './Pagination';
import Tabs from './Tabs';
import Table from './Table';
import Switch from './Switch';
import SvgIcon from './SVG';
import DatePickers from './DatePickers';
import Paper from './Paper';
export default function ComponentsOverrides(theme: Theme): ThemeOptions['components'] {
  return merge(
    Button(theme),
    CssBaseline(),
    Dialog(theme),
    Typography(),
    Input(theme),
    Form(theme),
    Pagination(theme),
    Tabs(theme),
    Table(theme),
    Switch(theme),
    SvgIcon(theme),
    DatePickers(theme),
    Paper(theme)
  );
}
