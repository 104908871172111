import React, { FC } from 'react';
import { Navigate } from 'react-router-dom';
import AppLayout from '../layouts/dashboard';

// interface ShouldProps {
//   component?: JSX.Element
//   location?: Object
//   // any props that come into the component
// }

interface PropType {
  component: React.FC;
}

export const PrivateRoute: FC<PropType> = ({ component: Component }) => {
  // const { isAuthenticated } = localStorage.getItem('user');

  if (localStorage.getItem('user'))
    return (
      <AppLayout>
        <Component />
      </AppLayout>
    );
  return <Navigate to="/signin" />;
};

export const OtherRoute = () => {
  // const { isAuthenticated } = localStorage.getItem('user');

  if (localStorage.getItem('user')) return <Navigate to="/404" replace />;
  return <Navigate to="/signin" replace />;
};

// export default PrivateRoute;
// export const AuthRoute = ({ component, ...rest }: Props) => {

//   return (
//     <Route {...rest} element={!localStorage.getItem('user') ? <><AppLayout>{component}</AppLayout></> : <Navigate to='/signin' state={{ from: location }} />} />
//   )
// }

// export const OtherRoute = () => (<Route
//   element={()=>
//     localStorage.getItem('user') ? <Navigate to='/error' /> : <Navigate to='/signin' />
// })

// export const ShouldRoute = ({ component, location, ...rest }: ShouldProps) => {
//   const { from } = location.state ?? { from: { pathname: '/', state: { from: location } } }
//   return (
//     <Route {...rest} element={localStorage.getItem('user') ? <><AppLayout>{component}</AppLayout></> : <Navigate to={from} />} />

//   )
// }
