/* eslint-disable */
import { useState, useEffect } from 'react';
import { Autocomplete, Button, Grid, TextField } from '@mui/material';
import { useDispatch } from 'react-redux';

import { LocationArrow, InfoSqfr } from '@xcidic/icons';
import { getService } from '../../redux/modules/blastMessage';
import { AppDispatch } from '../../redux/store';

interface props<T> {
  onSubmit: (data: T) => void;
}
interface ObjectPropsData {
  name: string;
  id: string;
}
export default function index({ onSubmit }: props<string>) {
  const dispatch = useDispatch<AppDispatch>();

  const [message, setMessages] = useState('');
  const [list, setList] = useState<ObjectPropsData[]>();
  useEffect(() => {
    dispatch(getService({ limit: 1000, offset: 0 }))
      .unwrap()
      .then((res) => {
        // console.log(res.listToken);

        setList(res.listToken?.data);
        // create('success', 'Success', res.listToken.message);
      })
      .catch(() => {
        // console.log(err)
        // create('error', 'Error', err);
      });
  }, []);
  return (
    <div
      style={{
        borderTop: '0.1em solid #E2E2E2',
        padding: 16,
        backgroundColor: '#F3FAFF',
      }}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <InfoSqfr />
      </div>
      <p>
        This chat has passed 24 hours. You can only reply this message using an message blast
        template. After your customer reply, then you can chat manually again. You can choose
        message blast that you've ever made or click here to create new{' '}
        <a href="/message/blast">message blast</a> and send it to this phone number.
      </p>

      <p style={{ fontWeight: 'bold', fontSize: 14, marginTop: 16, marginBottom: 16 }}>
        Choose Message Blast Template
      </p>
      <Grid container spacing={10}>
        <Grid item lg={10}>
          <Autocomplete
            id="tags-outlined"
            // multiple
            onChange={(event: any, newValue: ObjectPropsData | null) => {
              // console.log(newValue);
              setMessages(newValue?.id ?? '');
            }}
            // value={values?.intention}
            options={list ?? []}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                variant="outlined"
                placeholder="Choose existing message blast"
                // placeholder="Contact Categories"
              />
            )}
          />
        </Grid>
        <Grid item lg={2}>
          <Button
            disabled={message === ''}
            onClick={() => {
              onSubmit(message);
              setMessages('');
            }}
            variant="contained"
            color="primary"
            startIcon={<LocationArrow />}>
            Send
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
