import { RedocStandalone } from 'redoc';
import { DEV_URL } from '../../utils/url';

export default function index() {
  // const location = useLocation();

  const options = {
    swaggerDoc: {
      openapi: '3.0.0',
      paths: {
        '/otp/request': {
          post: {
            operationId: 'Generate Otp',
            security: [
              {
                TokenAuth: [],
              },
            ],
            parameters: [],
            requestBody: {
              required: true,
              content: {
                'application/json': {
                  schema: {
                    oneOf: [
                      {
                        $ref: '#/components/schemas/RequestOtpDefaultHandler',
                      },
                      {
                        $ref: '#/components/schemas/RequestOtpUserHandler',
                      },
                    ],
                  },
                  examples: {
                    'With Default Handler': {
                      value: {
                        configId: 'string',
                        phoneCountryCode: 'string',
                        phoneNumber: 'string',
                        isSync: false,
                      },
                    },
                    'With User Handler': {
                      value: {
                        configId: 'string',
                        handlerId: 'string',
                        templateId: 'string',
                        phoneCountryCode: 'string',
                        phoneNumber: 'string',
                        isSync: false,
                      },
                    },
                  },
                },
              },
            },
            responses: {
              '201': {
                description: '',
                content: {
                  'application/json': {
                    schema: {
                      oneOf: [
                        {
                          $ref: '#/components/schemas/RequestOtpDefaultHandler/components/schemas/responseSuccessRequest',
                        },
                        {
                          $ref: '#/components/schemas/RequestOtpDefaultHandler/components/schemas/responseSuccessRequestSync',
                        },
                      ],
                      //
                    },
                    examples: {
                      'isSync True': {
                        value: {
                          data: {
                            expiresOn: 1670564416,
                            createdOn: 1670564116,
                            isWaOtpSent: true,
                          },
                          message: 'OTP has been sent',
                          error: null,
                          meta: null,
                        },
                      },
                      'isSync False': {
                        value: {
                          data: {
                            expiresOn: 1670564642,
                            createdOn: 1670564342,
                            isWaOtpSent: true,
                          },
                          message: 'New OTP request has been triggered',
                          error: null,
                          meta: null,
                        },
                      },
                    },
                  },
                },
              },
              '400': {
                description: '',
                content: {
                  'application/json': {
                    schema: {
                      oneOf: [
                        {
                          $ref: '#/components/schemas/RequestOtpDefaultHandler/components/schemas/responseBadRequestArrayRequest',
                        },
                        {
                          $ref: '#/components/schemas/RequestOtpDefaultHandler/components/schemas/responseBadRequestRequest',
                        },
                      ],
                    },
                    examples: {
                      'Two Error': {
                        value: {
                          statusCode: 400,
                          message: ['configId should not be empty', 'configId must be a string'],
                          error: 'Bad Request',
                        },
                      },
                      'One Error': {
                        value: {
                          statusCode: 400,
                          message:
                            'OTP template is required for using handler provided by the user',
                          error: 'Bad Request',
                        },
                      },
                    },
                  },
                },
              },
              '403': {
                description: '',
                content: {
                  'application/json': {
                    schema: {
                      $ref: '#/components/schemas/RequestOtpDefaultHandler/components/schemas/responseForbiddenRequest',
                    },
                  },
                },
              },
              '404': {
                description: '',
                content: {
                  'application/json': {
                    schema: {
                      $ref: '#/components/schemas/RequestOtpDefaultHandler/components/schemas/responseNotFoundRequest',
                    },
                  },
                },
              },
            },
            tags: ['OTP'],
          },
        },
        '/otp/verify': {
          post: {
            operationId: 'Verify Otp',
            security: [
              {
                TokenAuth: [],
              },
            ],
            parameters: [],
            requestBody: {
              required: true,
              content: {
                'application/json': {
                  schema: {
                    $ref: '#/components/schemas/VerifyOtpDto',
                  },
                },
              },
            },
            responses: {
              '201': {
                description: '',
                content: {
                  'application/json': {
                    schema: {
                      $ref: '#/components/schemas/VerifyOtpDto/components/schemas/responseSuccessRequest',
                    },
                  },
                },
              },
              '404': {
                description: '',
                content: {
                  'application/json': {
                    schema: {
                      $ref: '#/components/schemas/VerifyOtpDto/components/schemas/responseNotFoundRequest',
                    },
                  },
                },
              },
            },
            tags: ['OTP'],
          },
        },
        '/blast/wa': {
          post: {
            operationId: 'Blast Message',
            security: [
              {
                TokenAuth: [],
              },
            ],
            parameters: [],
            requestBody: {
              required: true,
              content: {
                'application/json': {
                  schema: {
                    $ref: '#/components/schemas/RequestWhatsappBlast',
                  },
                },
              },
            },
            responses: {
              '201': {
                description: '',
                content: {
                  'application/json': {
                    schema: {
                      $ref: '#/components/schemas/RequestWhatsappBlast/components/schemas/responseSuccessRequest',
                    },
                  },
                },
              },
              '400': {
                description: '',
                content: {
                  'application/json': {
                    schema: {
                      $ref: '#/components/schemas/RequestWhatsappBlast/components/schemas/responseNotExistRequest',
                    },
                  },
                },
              },
              '404': {
                description: '',
                content: {
                  'application/json': {
                    schema: {
                      $ref: '#/components/schemas/RequestWhatsappBlast/components/schemas/responseNotFoundRequest',
                    },
                  },
                },
              },
            },
            tags: ['Message Blast'],
          },
        },
      },
      info: {
        title: 'Mercury API',
        description: 'API for Mercury Chatbot',
        version: '1.0',
        contact: {},
        'x-logo': {
          url: '/axme-logo.svg',
          backgroundColor: '#FFFFFF',
          altText: 'Example logo',
          href: '/',
        },
      },
      tags: [],
      servers: [
        {
          url: DEV_URL,
        },
      ],
      components: {
        securitySchemes: {
          TokenAuth: {
            type: 'apiKey',
            name: 'x-token',
            description: 'A token that you create on /token dashboard',
          },
        },
        schemas: {
          RequestOtpDefaultHandler: {
            type: 'object',
            components: {
              schemas: {
                responseSuccessRequestSync: {
                  type: 'object',
                  properties: {
                    data: {
                      type: 'object',
                      properties: {
                        expiresOn: {
                          type: 'Number',
                          example: 123456789,
                        },
                        createdOn: {
                          type: 'Number',
                          example: 123456789,
                        },
                        isWaOtpSent: {
                          type: 'Boolean',
                          example: true,
                        },
                      },
                    },
                    message: {
                      type: 'String',
                      example: 'OTP has been sent',
                    },
                    error: {
                      type: 'String',
                      example: null,
                    },
                    meta: {
                      type: 'String',
                      example: null,
                    },
                  },
                },
                responseSuccessRequest: {
                  type: 'object',
                  properties: {
                    data: {
                      type: 'object',
                      properties: {
                        expiresOn: {
                          type: 'Number',
                          example: 123456789,
                        },
                        createdOn: {
                          type: 'Number',
                          example: 123456789,
                        },
                        isWaOtpSent: {
                          type: 'Boolean',
                          example: true,
                        },
                      },
                    },
                    message: {
                      type: 'String',
                      example: 'New OTP request has been created',
                    },
                    error: {
                      type: 'String',
                      example: null,
                    },
                    meta: {
                      type: 'String',
                      example: null,
                    },
                  },
                },
                responseBadRequestArrayRequest: {
                  name: 'Bad Request Array',
                  type: 'object',
                  properties: {
                    statusCode: {
                      type: 'Number',
                      example: 400,
                    },
                    message: {
                      type: 'Array',
                      example: ['configId should not be empty', 'configId must be a string'],
                    },
                    error: {
                      type: 'String',
                      example: 'Bad Request',
                    },
                  },
                },
                responseBadRequestRequest: {
                  type: 'object',
                  properties: {
                    statusCode: {
                      type: 'Number',
                      example: 400,
                    },
                    message: {
                      type: 'String',
                      example: 'OTP template is required for using handler provided by the user',
                    },
                    error: {
                      type: 'String',
                      example: 'Bad Request',
                    },
                  },
                },
                responseForbiddenRequest: {
                  type: 'object',
                  properties: {
                    statusCode: {
                      type: 'Number',
                      example: 403,
                    },
                    message: {
                      type: 'String',
                      example: 'Forbidden resource',
                    },
                    error: {
                      type: 'String',
                      example: 'Forbidden',
                    },
                  },
                },
                responseNotFoundRequest: {
                  type: 'object',
                  properties: {
                    statusCode: {
                      type: 'Number',
                      example: 404,
                    },
                    message: {
                      type: 'String',
                      example: 'OTP template is not found or inactive',
                    },
                    error: {
                      type: 'String',
                      example: 'Not Found',
                    },
                  },
                },
              },
            },
            properties: {
              configId: {
                type: 'string',
                description: 'A id config that you create on /otp/configuration dashboard',
              },

              phoneCountryCode: {
                type: 'string',
                description: 'A Phone Country Code user',
              },
              phoneNumber: {
                type: 'string',
                description: 'A Phone Number without phone country code',
              },
              isSync: {
                type: 'Boolean',
                description: 'For Synchronous Request Otp',

                // example:true
              },
            },
            required: ['configId', 'phoneCountryCode', 'phoneNumber'],
          },
          RequestOtpUserHandler: {
            type: 'object',

            properties: {
              configId: {
                type: 'string',
                description: 'A id config that you create on /otp/configuration dashboard',
              },
              templateId: {
                type: 'string',
                description: 'A id Template that you create on /otp/template dashboard',
              },
              handlerId: {
                type: 'string',
                description: 'A id Phone Number that you have on /company dashboard',
              },
              phoneCountryCode: {
                type: 'string',
                description: 'A Phone Country Code user',
              },
              phoneNumber: {
                type: 'string',
                description: 'A Phone Number without phone country code',
              },
              isSync: {
                type: 'Boolean',
                description: 'For Synchronous Request Otp',

                // example:true
              },
            },
            required: ['configId', 'handlerId', 'templateId', 'phoneCountryCode', 'phoneNumber'],
          },
          RequestWhatsappBlast: {
            type: 'object',
            components: {
              schemas: {
                responseSuccessRequest: {
                  type: 'object',
                  properties: {
                    data: {
                      type: 'object',
                      properties: {
                        blastId: {
                          type: 'string',
                          example: '',
                        },
                        userId: {
                          type: 'string',
                          example: '',
                        },
                      },
                    },
                    message: {
                      type: 'String',
                      example: 'Blast message sending has been initiated',
                    },
                    error: {
                      type: 'String',
                      example: null,
                    },
                    meta: {
                      type: 'String',
                      example: null,
                    },
                  },
                },
                responseNotExistRequest: {
                  type: 'object',
                  properties: {
                    statusCode: {
                      type: 'Number',
                      example: 400,
                    },
                    message: {
                      type: 'String',
                      example: 'Handler does not exist or is inactive',
                    },
                    error: {
                      type: 'String',
                      example: 'Not Found',
                    },
                  },
                },
                responseNotFoundRequest: {
                  type: 'object',
                  properties: {
                    statusCode: {
                      type: 'Number',
                      example: 404,
                    },
                    message: {
                      type: 'String',
                      example: 'Blast template is not found for the user',
                    },
                    error: {
                      type: 'String',
                      example: 'Not Found',
                    },
                  },
                },
              },
            },
            properties: {
              handlerId: {
                type: 'string',
                description: 'A id Phone Number that you have on /company dashboard',
              },
              blastId: {
                type: 'string',
                description: 'A id Template that you create on /message/blast dashboard',
              },
            },
            required: ['blastId', 'handlerId'],
          },
          VerifyOtpDto: {
            type: 'object',
            components: {
              schemas: {
                responseSuccessRequest: {
                  type: 'object',
                  properties: {
                    data: {
                      type: 'object',
                      properties: {
                        isVerified: {
                          type: 'Boolean',
                          example: true,
                        },
                        updatedOn: {
                          type: 'Number',
                          example: 123456789,
                        },
                      },
                    },
                    message: {
                      type: 'String',
                      example: 'The OTP request has been updated for verification',
                    },
                    error: {
                      type: 'String',
                      example: null,
                    },
                    meta: {
                      type: 'String',
                      example: null,
                    },
                  },
                },
                responseNotFoundRequest: {
                  type: 'object',
                  properties: {
                    statusCode: {
                      type: 'Number',
                      example: 404,
                    },
                    message: {
                      type: 'String',
                      example: 'No valid OTP code is found',
                    },
                    error: {
                      type: 'String',
                      example: 'Not Found',
                    },
                  },
                },
              },
            },
            properties: {
              value: {
                type: 'string',
                minLength: 4,
                maxLength: 12,
                description: 'The value of the OTP that is being checked',
              },
              phoneCountryCode: {
                type: 'string',
                description: 'A Phone Country Code user',
              },
              phoneNumber: {
                type: 'string',
                description: 'A Phone Number without phone country code',
              },
            },
            required: ['value', 'phoneCountryCode', 'phoneNumber'],
          },
        },
      },
    },
  };

  return (
    <header>
      {/* <Box> */}
      {/* <Box component="img" src="/XCIDIC-Black.png" sx={{ width: 124 }} /> */}
      {/* </Box> */}
      {/* <DashboardHeader activePath={location.pathname} /> */}
      {/* <Box> */}
      <RedocStandalone spec={options?.swaggerDoc} />

      {/* </Box> */}
    </header>
  );
}
