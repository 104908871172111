export const setUser = (param: Object) => {
  const oneDay = 1440 * 60 * 1000;
  const record = {
    value: param,
    timestamp: new Date().getTime() + oneDay,
  };
  window.localStorage.setItem('user', JSON.stringify(record));
  return param;
};

export const getUser = () => {
  try {
    const user = window.localStorage.getItem('user');
    if (!user) return false;
    const record = JSON.parse(user);
    // return (new Date().getTime() < record.timestamp && record.value);
    return record;
  } catch (e) {
    return false;
  }
};

export const setCompany = (param: Object) => {
  const oneDay = 1440 * 60 * 1000;
  const record = {
    value: param,
    timestamp: new Date().getTime() + oneDay,
  };
  window.localStorage.setItem('company', JSON.stringify(record));
  return param;
};

export const getCompany = () => {
  try {
    const company = window.localStorage.getItem('company');
    if (!company) return false;
    const record = JSON.parse(company);
    // return (new Date().getTime() < record.timestamp && record.value);
    return record.value;
  } catch (e) {
    return false;
  }
};

export const setCountry = (param: Object) => {
  const oneDay = 1440 * 60 * 1000;
  const record = {
    value: param,
    timestamp: new Date().getTime() + oneDay,
  };
  window.localStorage.setItem('country', JSON.stringify(record));
  return param;
};

export const getCountry = () => {
  try {
    const country = window.localStorage.getItem('country');
    if (!country) return false;
    const record = JSON.parse(country);
    // return (new Date().getTime() < record.timestamp && record.value);
    return record.value;
  } catch (e) {
    return false;
  }
};

export const setProperty = (param: Object) => {
  const oneDay = 1440 * 60 * 1000;
  const record = {
    value: param,
    timestamp: new Date().getTime() + oneDay,
  };
  window.localStorage.setItem('properties', JSON.stringify(record));
  return param;
};

export const getProperty = () => {
  try {
    const property = window.localStorage.getItem('properties');
    if (!property) return false;
    const record = JSON.parse(property);
    // return (new Date().getTime() < record.timestamp && record.value);
    return record.value;
  } catch (e) {
    return false;
  }
};

export const setGym = (param: Object) => {
  const oneDay = 1440 * 60 * 1000;
  const record = {
    value: param,
    timestamp: new Date().getTime() + oneDay,
  };
  window.localStorage.setItem('gym', JSON.stringify(record));
  return param;
};

export const getGym = () => {
  try {
    const property = window.localStorage.getItem('gym');
    if (!property) return false;
    const record = JSON.parse(property);
    // return (new Date().getTime() < record.timestamp && record.value);
    return record.value;
  } catch (e) {
    return false;
  }
};

export const destroyUser = () => window.localStorage.clear();
