import React from 'react';
import { createTheme, ThemeProvider, useTheme, TextField, TextFieldProps } from '@mui/material';
import { Search } from '@xcidic/icons';
import { pxToRem } from '../../theme/typography';

type SearchbarProps = TextFieldProps;

const Searchbar = ({ placeholder = 'Search', ...textfieldProps }: SearchbarProps) => {
  const { palette, typography } = useTheme();
  const theme = createTheme({
    typography,
    palette,
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            fontSize: pxToRem(14),
            backgroundColor: palette.neutral.white,
            color: palette.neutral[400],
          },
          notchedOutline: {
            borderRadius: 8,
            borderColor: palette.neutral[400],
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <TextField
        {...textfieldProps}
        placeholder={placeholder}
        InputProps={{
          startAdornment: <Search sx={{ color: palette.primary.main, mr: 1 }} />,
        }}
      />
    </ThemeProvider>
  );
};

export default Searchbar;
