import React from 'react';
import { Box, Grid, SvgIcon, Typography } from '@mui/material';
import Button from '../../components/Button';
import { CheckSqfr } from '@xcidic/icons';

interface FTUCardProps {
  cardIcon: any;
  title: string;
  desc: string;
  buttonName: string;
  fullButton?: boolean;
  disabled?: boolean;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
}

function FTUCard({
  cardIcon,
  title,
  desc,
  fullButton = false,
  disabled,
  buttonName,
  onClick,
}: FTUCardProps) {
  return (
    <Box sx={{ padding: '1rem', border: '1px solid #E2E2E2', borderRadius: '0.5rem' }}>
      <Grid container>
        <Grid item xs={12}>
          <Box
            sx={{
              mb: '1rem',
              height: '3rem',
              width: '3rem',
              borderRadius: '0.5rem',
              backgroundColor: '#F6F8FC',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <SvgIcon component={cardIcon} />
          </Box>
        </Grid>
      </Grid>
      <Box>
        <Typography
          mb={1}
          sx={{ color: '#1F1F1F', fontSize: '14', fontWeight: '700', lineHeight: '20px' }}>
          {title}
        </Typography>
        <Typography
          mb={2.5}
          sx={{ color: '#545454', fontSize: '12px', fontWeight: '500', lineHeight: '16px' }}>
          {desc}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            fullWidth={fullButton}
            disabled={disabled}
            variant="contained"
            color={buttonName === 'Completed' ? 'success' : 'primary'}
            onClick={onClick}
            startIcon={buttonName === 'Completed' ? <CheckSqfr /> : null}
            sx={{ color: '#fff' }}>
            {buttonName}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default FTUCard;
