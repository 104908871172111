import { Theme, ThemeOptions } from '@mui/material';

export default function Dialog(theme: Theme): ThemeOptions['components'] {
  return {
    MuiDialog: {
      styleOverrides: {
        root: {
          '.MuiDialog-paper': {
            minWidth: '20vw',
          },
        },
      },
    },
  };
}
