import { Button, Divider, Paper } from '@mui/material';
import 'react-phone-input-2/lib/material.css';
// import { useNavigate } from 'react-router-dom';
import Breadcrumbs from '../../components/Breadcrumbs';
import Container from '../../components/Container';
import Field from '../../components/Fields';

import DashboardHeader from '../../components/DashboardHeader';
import { Pencil } from '@xcidic/icons';
import Link from '../../components/Link';

import { getUser } from '../../utils/localStorage';

export default function index() {
  // const navigate = useNavigate();

  return (
    <Container>
      <Breadcrumbs
        items={[
          {
            label: 'Business Info',
            link: '/company',
          },
          {
            label: 'User',
            link: '/user',
          },
        ]}
      />
      <DashboardHeader title="User Details">
        <div>
          <Button
            variant="contained"
            startIcon={<Pencil />}
            LinkComponent={Link}
            style={{ marginRight: 10 }}
            href={'editpassword'}>
            Update Password
          </Button>
          <Button variant="contained" startIcon={<Pencil />} LinkComponent={Link} href={'edit'}>
            Update User
          </Button>
        </div>
      </DashboardHeader>
      <Paper>
        <Field withElement={true} small={false} label="Name" value={getUser().name} />
        <Divider />
        <Field withElement={true} small={false} label="Email" value={getUser().email} />
        <Divider />
      </Paper>
    </Container>
  );
}
