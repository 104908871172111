import Guide from '../Guide';
import { Step } from 'react-joyride';

const autoGuideSteps: Step[] = [
  {
    target: '#auto-reply',
    content: (
      <Guide
        title="Create an auto reply answer"
        content="Search for answer menu to add auto reply answer based on the intention."
        step="1"
        totalSteps="3"
      />
    ),
    disableBeacon: true,
    offset: 25,
    placement: 'bottom-start',
  },
  {
    target: '#auto-answer',
    content: (
      <Guide
        title="Create an auto reply answer"
        content="Click here to open “Answer” menu and find “Add Auto Reply Answer” button."
        step="2"
        totalSteps="3"
      />
    ),
    disableBeacon: true,
    offset: 25,
    placement: 'bottom-start',
  },
  {
    target: '#auto-add-answer',
    content: (
      <Guide
        title="Create an auto reply answer"
        content="Click here to create auto reply answer based on the intention."
        step="3"
        totalSteps="3"
      />
    ),
    disableBeacon: true,
    offset: 25,
    placement: 'bottom-start',
  },
];

export default autoGuideSteps;
