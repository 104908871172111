import { Button, CircularProgress, Grid, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import Dialog from '../../../components/Dialog';

import {
  editInitialValues as initialValues,
  editValidationSchema as validationSchema,
} from './schemeValidation';
import { ToastManagerContext } from '../../../components/ToastManager';

import Confirm from '../../../components/Confirm';
import { editService, requestDataEdit as requestData } from '../../../redux/modules/category';
import { AppDispatch } from '../../../redux/store';
import Typography from '@mui/material/Typography';

// import { date } from 'yup/lib/locale';
// type CloseReason = 'backdropClick' | 'escapeKeyDown' | 'closeButtonClick';
interface PropsModal {
  open: boolean;
  // onClose: (reason: CloseReason) => void;
  idProps?: string;
  onClose: Function;
  content: string;
}

export default function index({ open, onClose, content, idProps }: PropsModal) {
  const { create } = useContext(ToastManagerContext);
  const [isConfirm, setIsConfirm] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams();

  const {
    handleChange,
    handleBlur,
    // setSubmitting,
    handleSubmit,
    setFieldValue,
    dirty,
    resetForm,
    values,
    isValid,
    touched,
    errors,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async () => {
      setIsLoading(true);
      const useId = idProps ?? id;
      try {
        const data: requestData = {
          name: values.name,
          id: useId ?? '',
        };
        dispatch(editService(data))
          .unwrap()
          .then((res) => {
            setIsLoading(false);

            create('success', 'Success', res.responseApi.message);
            resetForm();
            onClose();
          })
          .catch((err) => {
            setIsLoading(false);

            create('error', 'Error', err);
          });
      } catch (error) {
        // handle any rejections/errors
      }
    },
  });

  useEffect(() => {
    setFieldValue('name', content);
  }, [open]);
  return (
    <Dialog open={open} onClose={() => onClose()}>
      <Typography variant="h2" color="neutral.black" fontWeight="700" mb={2}>
        Edit Contact Category
      </Typography>
      <Grid container rowSpacing={2}>
        <Grid item lg={12}>
          <TextField
            fullWidth
            // autoFocus
            name="name"
            variant="outlined"
            // type="email"
            label="Category Name *"
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
            className="mt-0 mb-4"
            size="medium"
          />
        </Grid>

        <Grid item lg={12}>
          <Button
            fullWidth
            name="company"
            variant="contained"
            color="primary"
            disabled={!(dirty && isValid)}
            onClick={() => setIsConfirm(true)}>
            {isLoading ? (
              <CircularProgress style={{ color: 'white' }} size={30} />
            ) : (
              <span className="loginButtonCaption">Save Changes</span>
            )}
          </Button>

          <Confirm
            type={'Create'}
            open={isConfirm}
            handleAksi={() => {
              setIsConfirm(false);
              handleSubmit();
            }}
            loading={false}
            handleClose={() => setIsConfirm(false)}
            disable={false}
            handleContent={{
              title: 'Edit Contact Category',
              message: 'Do you wish to Edit this Contact Category?',
              button: 'Submit',
            }}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
}
