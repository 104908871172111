import {
  Button,
  Grid,
  Typography,
  IconButton,
  Box,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Eye, Pencil, PlusCrfr, BankCards, EyeOff, TrashCan } from '@xcidic/icons';
import { useNavigate } from 'react-router-dom';
import { ToastManagerContext } from '../../components/ToastManager';
import {
  getService,
  getConfigService,
  editConfigByIdService,
  requestConfigEdit,
  deleteService,
  paramsById,
} from '../../redux/modules/responseAutoReply';
import { get as getCompany } from '../../redux/modules/company';

import { AppDispatch } from '../../redux/store';
import Breadcrumbs from '../../components/Breadcrumbs';
import Container from '../../components/Container';
import Table from '../../components/Table';

import Create from './component/modal';
import Edit from './component/editModal';
import Confirm from '../../components/Confirm';

import EmptyState from '../../components/EmptyState';
import DashboardHeader from '../../components/DashboardHeader';

import Pagination from '../../components/TablePagination';
import Widget, { WidgetFooter } from '../../components/Widget';

interface dataAnswerInterface {
  id: string;
  intention: string;
  langCode: string;
  value: string;
}

interface configData {
  id: string;
  isActive: boolean;
  handlerId: string;
  webhookAddress: string;
  webhookUniqueParam: string;
  webhookVerificationToken: string;
}

interface objectState {
  id: string;
  countryCode: string;
  number: string;
  waPhoneId: string;
  waApiToken: string;
  waBusinessAccountId: string;
}

interface requestData {
  name: String;
  phones: objectState[];
}

interface meta {
  total: number;
}

export default function index() {
  const { create } = useContext(ToastManagerContext);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [data, setData] = useState<requestData>({
    name: '',
    phones: [
      {
        countryCode: '',
        number: '',
        waPhoneId: '',
        waApiToken: '',
        waBusinessAccountId: '',
        id: '',
      },
    ],
  });
  const [dataAnswer, setDataAnswer] = useState<dataAnswerInterface[]>();
  const [dataEdit, setDataEdit] = useState<dataAnswerInterface>();
  const [configDataState, setConfigData] = useState<configData>();
  const [totalData, setTotal] = useState<meta>({ total: 1 });

  const [isToken, setIsToken] = useState(false);
  const [isAddress, setIsAddress] = useState(false);

  const [isConfirmActive, setIsActivate] = useState(false);
  const [isConfirmDeactive, setIsDeactive] = useState(false);
  const [isConfirmDelete, setIsConfirmDelete] = useState(false);

  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    dispatch(getConfigService())
      .unwrap()
      .then((res) => {
        setConfigData(res.responseApi.data);
      })
      .catch((err) => {
        create('error', 'Error', err);
      });
    dispatch(getCompany())
      .unwrap()
      .then((res) => {
        if (res.list.data.length > 0) {
          const dataAPI = res.list.data[0];
          setData(dataAPI);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    updateData();
  }, [page]);

  const updateData = () => {
    dispatch(getService({ limit, offset: page === 1 ? 0 : page === 2 ? limit : limit * page }))
      .unwrap()
      .then((res) => {
        setDataAnswer(res?.listToken?.data);
        setTotal(res.listToken.meta);
      })
      .catch((err) => {
        create('error', 'Error', err);
      });
  };

  const activateFeature = () => {
    const dataEdit: requestConfigEdit = {
      id: configDataState?.id ?? '',
      isActive: !configDataState?.isActive,
      handlerId: data.phones[0].id,
    };
    dispatch(editConfigByIdService(dataEdit))
      .unwrap()
      .then((res) => {
        dispatch(getConfigService())
          .unwrap()
          .then((res) => {
            setConfigData(res.responseApi.data);
          })
          .catch((err) => {
            create('error', 'Error', err);
          });
      })
      .catch((err) => {
        create('error', 'Error', err);
      });
  };
  const deleteData = () => {
    const data: paramsById = {
      id: dataEdit?.id ?? '',
    };
    dispatch(deleteService(data))
      .unwrap()
      .then((res) => {
        updateData();
        create('success', 'Success', res.responseApi.message);
      })
      .catch((err) => {
        create('success', 'Error', err);
      });
  };

  return (
    <>
      <Container>
        <Breadcrumbs
          items={[
            {
              label: 'Auto Reply',
              link: '/auto/answer',
            },
            {
              label: 'Answer',
              link: '/auto/answer',
            },
          ]}
        />

        <DashboardHeader title="List Answer">
          <Box display="flex">
            {dataAnswer?.length !== 0 && (
              <Button
                name="company"
                variant="contained"
                startIcon={<Pencil />}
                color={
                  !configDataState?.isActive || configDataState?.handlerId === null
                    ? 'primary'
                    : 'error'
                }
                style={{ marginRight: '10px' }}
                onClick={() =>
                  !configDataState?.isActive || configDataState?.handlerId === null
                    ? setIsActivate(true)
                    : setIsDeactive(true)
                }>
                {!configDataState?.isActive || configDataState?.handlerId === null
                  ? `Activate This Feature`
                  : 'Deactive This Feature'}
              </Button>
            )}
            <Button
              id="auto-add-answer"
              name="company"
              variant="contained"
              color="primary"
              startIcon={<PlusCrfr />}
              onClick={() => setIsOpenCreate(true)}>
              Add Auto Reply Answer
            </Button>
          </Box>
        </DashboardHeader>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Widget sx={{ py: 0 }}>
              <List>
                <ListItem divider sx={{ py: 2 }}>
                  <ListItemText
                    primary="Webhook Address"
                    primaryTypographyProps={{ variant: 'b2' }}
                    secondary={
                      <Box display="flex" gap={2} mt={1}>
                        <Box display="flex" gap={1} alignItems="center">
                          <Typography variant="b2" color="neutral.600" fontWeight={300}>
                            {isAddress
                              ? configDataState?.webhookAddress ?? ''
                              : configDataState?.webhookAddress?.split('').map(() => '*') ?? ''}
                          </Typography>
                          <IconButton onClick={() => setIsAddress(!isAddress)}>
                            {isAddress ? <EyeOff /> : <Eye />}
                          </IconButton>
                        </Box>
                        <Button
                          name="copy"
                          variant="outlined"
                          color="primary"
                          startIcon={<BankCards />}
                          onClick={() => {
                            navigator.clipboard.writeText(configDataState?.webhookAddress ?? '');
                            create('success', 'Copy', 'Success Copy  Webhook Address');
                          }}>
                          Copy
                        </Button>
                      </Box>
                    }
                  />
                </ListItem>
                <ListItem sx={{ py: 2 }}>
                  <ListItemText
                    primary="Webhook Verification Token"
                    primaryTypographyProps={{ variant: 'b2' }}
                    secondary={
                      <Box display="flex" gap={2} mt={1}>
                        <Box display="flex" gap={1} alignItems="center">
                          <Typography variant="b2" color="neutral.600" fontWeight={300}>
                            {isToken
                              ? configDataState?.webhookVerificationToken ?? ''
                              : configDataState?.webhookVerificationToken
                                  ?.split('')
                                  .map(() => '*') ?? ''}
                          </Typography>
                          <IconButton onClick={() => setIsToken(!isToken)}>
                            {isToken ? <EyeOff /> : <Eye />}
                          </IconButton>
                        </Box>
                        <Button
                          name="copy"
                          variant="outlined"
                          color="primary"
                          startIcon={<BankCards />}
                          onClick={() => {
                            navigator.clipboard.writeText(
                              configDataState?.webhookVerificationToken ?? ''
                            );
                            create('success', 'Copy', 'Success Copy Webhook Verification Token');
                          }}>
                          Copy
                        </Button>
                      </Box>
                    }
                  />
                </ListItem>
              </List>
            </Widget>
          </Grid>
          <Grid item xs={12}>
            <Widget>
              {dataAnswer?.length === 0 && (
                <EmptyState module="auto reply answer" button="Add Auto Reply Answer" />
              )}
              {dataAnswer?.length !== 0 && (
                <Table
                  data={dataAnswer ?? []}
                  config={[
                    {
                      label: 'Intention',
                      key: 'intention',
                      render: (row) => {
                        return (
                          <Typography
                            sx={{ cursor: 'pointer' }}
                            onClick={() => navigate(`/auto/answer/${row.id}`)}>
                            {row.intention}
                          </Typography>
                        );
                      },
                    },
                    {
                      label: 'Language',
                      key: 'langCode',
                      render: (row) => {
                        return (
                          <Typography
                            sx={{ cursor: 'pointer' }}
                            onClick={() => navigate(`/auto/answer/${row.id}`)}>
                            {row.langCode}
                          </Typography>
                        );
                      },
                    },
                    {
                      label: 'Answer',
                      key: 'value',
                      render: (row) => {
                        return (
                          <Typography
                            sx={{ cursor: 'pointer' }}
                            onClick={() => navigate(`/auto/answer/${row.id}`)}>
                            {row.value}
                          </Typography>
                        );
                      },
                    },
                  ]}
                  actions={[
                    {
                      label: 'Edit',
                      icon: <Pencil />,
                      onAction: (row) => {
                        setDataEdit(row);
                        setIsOpenEdit(true);
                      },
                    },
                    {
                      label: 'Delete',
                      icon: <TrashCan />,
                      onAction: (row) => {
                        setDataEdit(row);
                        setIsConfirmDelete(true);
                      },
                    },
                  ]}
                />
              )}
              {dataAnswer?.length !== 0 && (
                <WidgetFooter>
                  <Pagination
                    page={page}
                    count={totalData?.total}
                    rowsPerPage={limit}
                    onPageChange={(_, newPage) => setPage(newPage)}
                    onRowsPerPageChange={(e) => {
                      setLimit(e.target.value as number);
                      setPage(1);
                    }}
                  />
                </WidgetFooter>
              )}
            </Widget>
          </Grid>
        </Grid>
        <Create
          open={isOpenCreate}
          onClose={() => {
            setIsOpenCreate(false);
            updateData();
          }}
        />
        <Edit
          open={isOpenEdit}
          onClose={() => {
            setIsOpenEdit(false);
            updateData();
          }}
          id={dataEdit?.id ?? ''}
          value={dataEdit?.value ?? ''}
        />
        <Confirm
          type={'Create'}
          open={isConfirmActive}
          handleAksi={() => {
            activateFeature();
            setIsActivate(false);
          }}
          loading={false}
          handleClose={() => setIsActivate(false)}
          disable={data?.phones[0].id === ''}
          handleContent={{
            title: 'Activate Feature',
            message:
              data?.phones[0].id !== ''
                ? 'Do you wish to Activate this Feature?'
                : 'You Need Create Company',
            button: 'Submit',
          }}
        />
        <Confirm
          type={'Delete'}
          open={isConfirmDeactive}
          handleAksi={() => {
            activateFeature();
            setIsDeactive(false);
          }}
          loading={false}
          handleClose={() => setIsDeactive(false)}
          disable={false}
          handleContent={{
            title: 'Deactive Feature',
            message: 'Do you wish to Deactive this Feature?',
            button: 'Deactive',
          }}
        />
      </Container>
      <Confirm
        open={isConfirmDelete}
        type={'Delete'}
        handleAksi={() => {
          setIsConfirmDelete(false);
          deleteData();
        }}
        loading={false}
        handleClose={() => setIsConfirmDelete(false)}
        disable={false}
        handleContent={{
          title: 'Delete Anwer',
          message: 'Do you wish to Delete this Answer?',
          button: 'Delete',
        }}
      />
    </>
  );
}
