import { PropsWithChildren } from 'react';
import { Box, useTheme } from '@mui/material';

// components
import DashboardHeader from './DashboardHeader';
import DashboardSidebar from './DashboardSidebar';

// data
import navItems from './SidebarNavItems';

const DashboardLayout = ({ children }: PropsWithChildren) => {
  const { palette } = useTheme();
  const sidebarWidth = 280;

  return (
    <Box>
      <Box sx={{ position: 'fixed', width: sidebarWidth, height: '100vh', maxHeight: '100vh' }}>
        <DashboardSidebar navItems={navItems} />
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          backgroundColor: palette.neutral.dashboardBg,
          ml: `${sidebarWidth}px`,
          minHeight: '100vh',
        }}>
        <DashboardHeader activePath={location.pathname} />
        <Box component="main" sx={{ m: 4 }}>
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardLayout;
