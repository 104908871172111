import { ReactElement, useState, useEffect } from 'react';
import Router from './routes';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { BrowserRouter } from 'react-router-dom';
import ThemeConfig from './theme';
import ToastManager from './components/ToastManager';
import axios from 'axios';
import Maintenance from './modules/Error/Maintenance';
import SocketsProvider from './contexts/SocketContextComponent';

import { PollingConfig, Online } from 'react-detect-offline';
import { BASE_URL } from './utils/url';
import './App.css';

// coachmark
import { AppProvider } from './modules/FTU/context';
import CompanyTour from './modules/FTU/Tours/TourGuide';

const detectorOptions: PollingConfig = {
  url: 'https://httpbin.org/',
  enabled: true,
  interval: 10000,
  timeout: 30 * 1000,
};

const App = (): ReactElement => {
  const [serverOnline, setStatus] = useState(true);

  useEffect(() => {
    const config = {
      method: 'get',
      url: `${BASE_URL}`,
      headers: {
        'Content-Type': 'application/json',
      },
    };
    setInterval(() => {
      // console.log('calling');
      axios(config)
        .then(() => {
          setStatus(true);
        })
        .catch(() => {
          setStatus(false);
        });
    }, 60000);
  }, []);

  return (
    <Provider store={store}>
      <ThemeConfig>
        <AppProvider>
          <BrowserRouter>
            <CompanyTour />
            <SocketsProvider>
              <ToastManager>
                <Online polling={detectorOptions}>
                  {serverOnline ? <Router /> : <Maintenance />}
                </Online>
              </ToastManager>
            </SocketsProvider>
          </BrowserRouter>
        </AppProvider>
      </ThemeConfig>
    </Provider>
  );
};

export default App;
